import { Typography } from '@mui/material'
import React from 'react'

function StatusText({ text,mt }) {
    const getColor = () => {
        switch (text) {
            case 'High Risk':
                return '#ff0000';
                break;
            case 'Above Average Risk':
                return '#dd6e0f';
                break;
            case 'Average Risk':
                return '#ffa500';
                break;
            case 'Low Risk':
                return '#98fb98';
                break;
            case 'Open':
                return '#98fb98';
                break;
            default:
                return '#000';
        }
    }


    return (
        <Typography variant='h6' sx={{ letterSpacing: '1.5px', fontSize: '13px' }} textTransform={'none'} component={'h5'} color={getColor(text)} fontWeight={600} letterSpacing={'0.75px'} mt={mt || 1}>{text}</Typography>
    )
}

export default StatusText