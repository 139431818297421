import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    data: "",
    status: '',
    userEmail: "",
    userType: "",
    token: "",
    loading: false,
    error: false,
    message: ''
};
export const VerifyEmailForgotPassword = createAsyncThunk("/VerifyEmailForgotPassword", async (userdata) => {
    let data = new FormData();
    data.append('email', userdata.email);
    const response = await ApihelperFunction({
        urlPath: "api/password/request",
        formData: data,
        method: "post",
    });
    return response;
});
export const VerifyOTPForgotPassword = createAsyncThunk("/VerifyOTP", async (userdata) => {
    let data = new FormData();
    data.append('token', userdata.token);
    data.append('otp', userdata.otp);

    const response = await ApihelperFunction({
        urlPath: "api/password/otp-verify",
        formData: data,
        method: "post",
    });
    return response;
});
export const SetPasswordForgotPasswod = createAsyncThunk("/SetPassword", async (passwordData) => {
    console.log(passwordData,'userrrrrrrrr')
    let data = new FormData();
    console.log(passwordData)
    data.append('token', passwordData.token);
    data.append('password', passwordData.password);
    data.append('confirm_password', passwordData.password_confirmation);
    console.log(data)
    const response = await ApihelperFunction({
        urlPath: "api/password/reset",
        formData: data,
        method: "post",
    });
    return response;
});
const forgotPasswordSlice = createSlice({
    name: 'ForgotPassword',
    initialState,
    reducers: {
        resetStateForgetPassword: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(VerifyEmailForgotPassword.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(VerifyEmailForgotPassword.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                    state.token=action?.payload?.data[0].token
                }
                else {
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors?.[0]]
                }
                console.log(action.payload, 'PAYLOAD')
                state.loading = false
                state.data = action?.payload?.data;
            })
            .addCase(VerifyEmailForgotPassword.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false
            })
            .addCase(VerifyOTPForgotPassword.pending, (state) => {
                state.status = 'LOADING'
                state.loading=true
                state.error=false
                state.message=''
            })
            .addCase(VerifyOTPForgotPassword.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.status = "SUCCESSOTPVERIFIED"
                    state.message = action?.payload?.message
                }
                else {
                    state.status = "ERROROTPVERIFIED"
                    state.error = [action?.payload?.errors?.[0]]
                }
                state.token = action?.payload?.data.token
                state.loading = false

                localStorage.setItem('token', action?.payload?.data.token)

                // console.log(action.payload,'payload')
            })
            .addCase(VerifyOTPForgotPassword.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false

                // console.log(action.payload, 'PAYLOAD DANGER')
            })
            .addCase(SetPasswordForgotPasswod.pending, (state) => {
                state.status = 'LOADING'
                state.loading=true
                state.error=false
                state.message=''
            })
            .addCase(SetPasswordForgotPasswod.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loading = false
                    state.error = false
                    state.status = "SUCCESSPASSWORD"
                    state.message = action?.payload?.message
                }
                else {
                    state.loading = false
                    state.status = "ERRORPASSWORD"
                    state.error = [action?.payload?.errors]
                }
                state.data = action?.payload?.data;
            })
            .addCase(SetPasswordForgotPasswod.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERRORPASSWORD'
                console.log(action.payload, 'PAYLOAD DANGER')
            })
    }
})

export const { resetStateForgetPassword } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;