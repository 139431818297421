import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    bidderRank: null,
    bidderRankStatus: '',
    bidderRankLoader: false,
    bidderRankError: null
};
export const BidderRank = createAsyncThunk("/BidderRank", async (data) => {
    const response = await ApihelperFunction({
        urlPath: `api/funder/getBidderRank?auction_id=${data?.auction_id}&offer_amount=${data?.offer_amount}&roi=${data?.roi_amount}`,
        method: "get",
    });
    return response;
});
const bidderRankSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateGetBidderRank: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(BidderRank.pending, (state) => {
                state.bidderRankStatus = 'LOADING'
                state.bidderRankLoader = true
                state.bidderRankError = null
            })
            .addCase(BidderRank.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.bidderRankStatus = "SUCCESS"
                    state.bidderRank = action?.payload?.data?.leader_board_rank
                }
                else {
                    state.bidderRankStatus = "ERROR"
                    state.bidderRankError = [action?.payload?.errors?.[0]]
                }
                state.bidderRankLoader = false
            })
            .addCase(BidderRank.rejected, (state, action) => {
                state.bidderRankStatus = 'ERROR'
                state.bidderRankLoader = false
            })
    }
})

export const { resetStateGetBidderRank } = bidderRankSlice.actions;
export default bidderRankSlice.reducer;