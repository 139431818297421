import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    editFunder: null,
    editFunderStatus: '',
    editFunderLoader: false,
    editFunderError: null,
    editFunderMessage: ''
};
export const EditFunder = createAsyncThunk("/EditFunder", async (editCompanyData) => {
    console.log(editCompanyData, 'EDITDATA')
    let data = new FormData();
    for (const key in editCompanyData) {
        if (editCompanyData.hasOwnProperty(key)) {
            if (key != 'business_website' && key != 'zipcode') {
                data.append(key, editCompanyData[key])
            }
        }
    }
    if (editCompanyData?.business_website) {
        data.append('business_website', 'https://' + editCompanyData.business_website);
    }
    if (editCompanyData?.zipcode) {
        data.append('zipcode', editCompanyData.zipcode.toUpperCase());
    }
    const response = await ApihelperFunction({
        urlPath: `api/updateFunderChangeRequest`,
        method: "post",
        formData: data,
    });
    return response;
});
const editFunderSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateUpdateFunder: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(EditFunder.pending, (state) => {
                state.editFunderStatus = 'LOADING'
                state.editFunderLoader = true
                state.editFunderError = null
            })
            .addCase(EditFunder.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.editFunderStatus = "SUCCESS"
                    state.editFunderMessage = action?.payload?.message
                    state.editFunder = action?.payload?.data
                }
                else {
                    state.editFunderStatus = "ERROR"
                    state.editFunderError = [action?.payload?.errors?.[0]]
                }
                state.editFunderLoader = false
            })
            .addCase(EditFunder.rejected, (state, action) => {
                state.editFunderStatus = 'ERROR'
                state.editFunderLoader = false
            })
    }
})

export const { resetStateUpdateFunder } = editFunderSlice.actions;
export default editFunderSlice.reducer;