import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    data: "",
    status: '',
    userEmail: "",
    userType: "",
    token: "",
    loading: false,
    error: false,
    message: '',
    role: '',
    userStatus: null,
    id: null,
    bidid: null,
    headerName: null,
    company_details_stat: false,
    company_account_details: false,
    stage: null,
    isRegistrationDone: null
};
export const Loginauth = createAsyncThunk("/Login", async (userdata) => {
    let data = new FormData();
    data.append('username', userdata.username);
    data.append('password', userdata.password);
    const response = await ApihelperFunction({
        urlPath: "api/login?user_agent= postman&app_version= 1.0&os= android",
        formData: data,
        method: "post",
    });
    return response;
});
const loginSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateLogin: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(Loginauth.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(Loginauth.fulfilled, (state, action) => {
                localStorage.clear()
                if (action?.payload?.status) {
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                    state.role = action?.payload?.data?.user_type
                    if (action?.payload?.data.user_type == 'Funder') {
                        if(action?.payload?.data?.funder_basic_detail_stat || action?.payload?.data?.funder_account_details){
                            state.isRegistrationDone=true
                            state.userStatus = action?.payload?.data?.Funder?.status
                        }else{
                            state.isRegistrationDone=false
                            state.userStatus = action?.payload?.data?.status
                        }
                        state.id = action?.payload?.data?.Funder?.id
                        state.bidid = action?.payload?.data?.id
                        state.headerName = action?.payload?.data?.Funder?.username
                        localStorage.setItem('customerId', action?.payload?.data?.Funder?.funder_basic_detail?.account_number)
                    }
                    else if (action?.payload?.data.user_type == 'Company') {
                        if (action?.payload?.data?.Company?.length) {
                            if (action?.payload?.data?.company_details_stat && action?.payload?.data?.company_account_details) {
                                state.userStatus = action?.payload?.data?.Company[0]?.status
                                state.company_details_stat = true
                                state.company_account_details = true
                                state.isRegistrationDone = true
                            }
                            else if(action?.payload?.data?.company_details_stat && !action?.payload?.data?.company_account_details){
                                state.userStatus = action?.payload?.data?.status
                                state.company_details_stat = true
                                state.company_account_details = false
                                state.isRegistrationDone = false
                            }
                        } else {
                            state.userStatus = action?.payload?.data?.status
                            state.stage = action?.payload?.data?.stage
                            state.isRegistrationDone = false
                        }
                        state.bidid = action?.payload?.data?.id
                        state.id = action?.payload?.data?.Company[0]?.id
                        state.headerName = action?.payload?.data?.Company[0]?.name
                        localStorage.setItem('customerId', action?.payload?.data?.Company[0]?.account_number)
                    }
                }
                else {
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors?.[0]]
                }
                state.loading = false
                state.data = action?.payload?.data;
                state.userEmail = action?.payload?.data.email;
                state.userType = action?.payload?.data.user_type;
                state.token = action?.payload?.data.token;
                localStorage.setItem('role', state.role)
                localStorage.setItem('userStatus', state.userStatus)
                localStorage.setItem('token', state.token)
                localStorage.setItem('id', state.id)
                localStorage.setItem('bidid', state.bidid)
                localStorage.setItem('userName', state.headerName)
                localStorage.setItem('userEmail', action?.payload?.data.email)
            })
            .addCase(Loginauth.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false
            })
    }
})

export const { resetStateLogin } = loginSlice.actions;
export default loginSlice.reducer;