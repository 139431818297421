import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Header from '../DashboardLayout/Header'

const MinimalLayout = () => {
    const location = useLocation()
    return (
        <>
            {
                location?.search != '?edit' && location?.pathname != '/' && <Navbar />
            }
            {
                location?.search == '?edit' && <Header page='edit' />
            }
            <div>
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default MinimalLayout