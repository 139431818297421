import React, { useEffect, useState } from 'react'
import HorizontalLinearAlternativeLabelStepper from '../../SignUp/Stepper'
import StepOne from './StepOne'
import { Typography } from '@mui/material'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import StepFive from './StepFive'
import StepSix from './StepSix'
import StepSeven from './StepSeven'
import StepEight from './StepEight'
import { useDispatch, useSelector } from 'react-redux'
import { resetStateCreateAuction } from '../../../Redux/Slices/CreateAuctionSlice'

function CreateAuction() {
    const { stepNo } = useSelector((state) => state.createAuction)
    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = ''; // For some browsers to display the prompt message
          return ''; // For other browsers
        };
    
        const showConfirmation = () => {
          return 'Are you sure you want to leave? Your changes may not be saved.';
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.onbeforeunload = showConfirmation;
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
          window.onbeforeunload = null;
        };
      }, []);
    return (
        <>
            <Typography variant='h4' textTransform={'none'} color='#272D3B' fontWeight={'600'} mt={1} sx={{ letterSpacing: "0.7px" }}>
                Create auction
            </Typography>
            <HorizontalLinearAlternativeLabelStepper stepNo={stepNo} val={stepNo - 1} />
            {
                stepNo == 1 && <StepOne stepNo={stepNo} />
            }
            {
                stepNo == 2 && <StepTwo stepNo={stepNo} />
            }
            {
                stepNo == 3 && <StepThree stepNo={stepNo} />
            }
            {
                stepNo == 4 && <StepFour stepNo={stepNo} />
            }
            {
                stepNo == 5 && <StepFive stepNo={stepNo} />
            }
            {
                stepNo == 6 && <StepSix stepNo={stepNo} />
            }
            {
                stepNo == 7 && <StepSeven stepNo={stepNo} />
            }
            {
                stepNo == 8 && <StepEight stepNo={stepNo} />
            }
        </>

    )
}

export default CreateAuction