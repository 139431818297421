import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    state: [],
    stateStatus:'',
    stateLoader:false,
    stateError:null
};
export const State = createAsyncThunk("/State", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getState?country_id=${data}`,
        method: "get",
    });
    return response;
});
const stateSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(State.pending, (state) => {
                state.stateStatus = 'LOADING'
                state.stateLoader = true
                state.stateError = null
            })
            .addCase(State.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.stateStatus = "SUCCESS"
                    state.state = action?.payload?.data
                }
                else {
                    state.stateStatus = "ERROR"
                    state.stateError = [action?.payload?.errors?.[0]]
                }
                state.stateLoader = false
            })
            .addCase(State.rejected, (state, action) => {
                state.stateStatus = 'ERROR'
                state.stateLoader = false
            })
    }
})

export const { resetState } = stateSlice.actions;
export default stateSlice.reducer;