import { Box, Button, Container, FormControl, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputComp from '../../../components/InputComp'
import { Controller, useForm } from 'react-hook-form'
import ErrorMessageComp from '../../../components/ErrorMessageComp'
import { useDispatch, useSelector } from 'react-redux'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Link, useNavigate } from 'react-router-dom'
import { TradingPartnerList, resetStateTradingPartnerList } from '../../../Redux/Slices/GetTradingPartnerListSlice'
import { addAuctionData } from '../../../Redux/Slices/CreateAuctionSlice'
import { addAuctionFile } from '../../../Redux/Slices/UploadFileSlice'

function StepTwo() {
    const [values, setValues] = useState({})
    const dispatch = useDispatch()
    const { tradingPartnerListInitial } = useSelector((state) => state.tradingPartnerList)
    const auctionData = useSelector((state) => state.createAuction)
    const auctionFile = useSelector((state) => state.uploadFile)
    const [selectedFile, setSelectedFile] = useState(auctionData?.data?.auction_docs)
    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: auctionData?.data
    })
    console.log(auctionFile, 'FILESTRING')

    //onChange
    const handleChange = (e) => {
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
    }

    //form submit
    const onSubmit = async (data) => {
        console.log(data, 'DUDU DATA')
        await dispatch(addAuctionData(data))
    }
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(TradingPartnerList())
    }, [])
    useEffect(() => {
        dispatch(resetStateTradingPartnerList())
    }, [dispatch])
    console.log(tradingPartnerListInitial,'tradingPartnerListInitial')
    return (
        <Container maxWidth={'md'}>
            <Box component="form">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Controller
                            name='trading_partner_id'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                    <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select Trading Partner</Typography></label>
                                    <TextField
                                        id="outlined-select"
                                        select
                                        placeholder='Select your city'
                                        name='company_type'
                                        onBlur={handleChange}
                                        onChange={onChange} value={value || 'Trading partner *'}
                                        defaultValue=""
                                        sx={{
                                            mt: 0,
                                            '& .MuiOutlinedInput-root': {
                                                background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                border: 'none',
                                                borderRadius: '7px',
                                                width: { lg: '100%', md: '400px', xs: '100%' },
                                                height: { md: '50px', xs: '50px' },
                                                boxShadow: '0px 3px 10px #0000000D',
                                                opacity: 1
                                            }
                                        }}
                                    >
                                        <MenuItem disabled selected value='Trading partner *'>
                                            <Typography variant='p' textTransform={'none'}>Trading partner *</Typography>
                                        </MenuItem>
                                        {tradingPartnerListInitial && tradingPartnerListInitial?.map((option) => (
                                            <MenuItem key={option.id} value={option.id} disabled={option.status==4}>
                                                <Typography variant='p' color={'#000'}>{option.name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            )}
                        />
                        <Button onClick={() => { navigate('/createTradingPartner'); localStorage.setItem('lastPageUrl', 'createAuction') }} style={{ cursor: "pointer", display: 'block', marginTop: "5px",fontSize:'13px',padding:'0px' }}>Add new trading partner</Button>
                        {errors.trading_partner_id?.type === 'required' && <ErrorMessageComp message={'Trading partner is required'} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputComp label={'Invoice Details'} onChanges={handleChange} width='100%' name={'invoice_number'} required={true} placeholder={'Invoice number *'} control={control} fieldType={'registration_number'} />
                        {errors.invoice_number?.type === 'required' && <ErrorMessageComp message={'Invoice number is required'} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Controller
                            name='description'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                    <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Description</Typography></label>
                                    <TextareaAutosize onChange={onChange} value={value} name='description' style={{ fontSize: '15px', fontFamily: 'Roboto', borderRadius: '8px', borderColor: '#cdcdcd', padding: '10px', width: '100%' }} height={'100px'} minRows={5}
                                        placeholder="Provide a brief description of the goods or services against this invoice" className='textAreaCustom' />
                                </FormControl>
                            )}
                        />
                        {errors.description?.type === 'required' && <ErrorMessageComp message={'Description is required'} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2}><label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Upload invoice *</Typography></label>
                        <Controller
                            name='auction_docs'
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <FormControl className='file-upload-container' style={{ width: '100%' }}>
                                        <p className='file-name' style={{ margin: 0, fontSize: '15px', position: 'absolute', left: '8px', top: '25%' }}>{selectedFile?.name}</p>
                                        <label for="file-upload" class="custom-file-upload">
                                            <Button variant="contained" size='small' sx={{
                                                padding: '7px 30px',
                                                backgroundColor: '#0B2244',
                                                textTransform: 'Uppercase',
                                            }}
                                            >Upload</Button>
                                        </label>
                                        <input id="file-upload" type="file" className='file-upload' name='auction_docs' onChange={(e) => { onChange(e.target.files[0]); dispatch(addAuctionFile(e.target.files[0])); setSelectedFile(e.target.files[0]) }} />
                                    </FormControl>
                                    <Typography variant='p' color={'#000'} component={'p'} fontSize={'12px'} textTransform={'none'} mt={0.5}>Upload the invoice against which you would like to raise funding (in the form of .jpg, .png, .doc or PDF)</Typography>
                                </>
                            )}
                        />
                        {errors.auction_docs?.type === 'required' && <ErrorMessageComp message={'auction_docs is required'} />
                        }
                    </Grid>
                </Grid>
                <Stack justifyContent="center" direction="row" margin={10}>
                    <Button variant="contained" sx={{
                        padding: '10px 60px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}
                        onClick={handleSubmit(onSubmit)}>NEXT</Button>
                </Stack>
            </Box >
        </Container>
    )
}

export default StepTwo