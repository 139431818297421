import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    country: null,
    countryStatus:'',
    countryLoader:false,
    countryError:null
};
export const Country = createAsyncThunk("/Country", async () => {
    const response = await ApihelperFunction({
        urlPath: "api/getCountry",
        method: "get",
    });
    return response;
});
const countrySlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(Country.pending, (state) => {
                state.countryStatus = 'LOADING'
                state.countryLoader = true
                state.countryError = null
            })
            .addCase(Country.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.countryStatus = "SUCCESS"
                    state.country = action?.payload?.data
                }
                else {
                    state.countryStatus = "ERROR"
                    state.countryError = [action?.payload?.errors?.[0]]
                }
                state.countryLoader = false
            })
            .addCase(Country.rejected, (state, action) => {
                state.countryStatus = 'ERROR'
                state.countryLoader = false
            })
    }
})

export const { resetState } = countrySlice.actions;
export default countrySlice.reducer;