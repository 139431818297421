import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    companyMyFundingDetailsClosed: [],
    companyFundingDetailsClosedStatus:'',
    companyFundingDetailsClosedLoader:false,
    companyFundingDetailsClosedError:null,
    companyClosedFundingCount:''
};
export const companyFundingDetailsClosed = createAsyncThunk("/companyFundingDetailsClosed", async (data) => {
    console.log(data,'function called')
    const response = await ApihelperFunction({
        urlPath: `api/company/getCloseFunding`,
        method: "get",
    });
    return response;
});
const companyFundingDetailsClosedSlice = createSlice({
    name: 'companyFundingDetailsClosed',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(companyFundingDetailsClosed.pending, (state) => {
                state.companyFundingDetailsClosedStatus = 'LOADING'
                state.companyFundingDetailsClosedLoader = true
                state.companyFundingDetailsClosedError = null
            })
            .addCase(companyFundingDetailsClosed.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.companyFundingDetailsClosedStatus = "SUCCESS"
                    state.companyMyFundingDetailsClosed = action?.payload?.data
                    state.companyClosedFundingCount=action?.payload?.data?.length
                }
                else {
                    state.companyFundingDetailsClosedStatus = "ERROR"
                    state.companyMyFundingDetailsClosed = []
                    state.companyClosedFundingCount=0
                    state.companyFundingDetailsClosedError = [action?.payload?.errors?.[0]]
                }
                state.companyFundingDetailsClosedLoader = false
            })
            .addCase(companyFundingDetailsClosed.rejected, (state, action) => {
                state.companyFundingDetailsClosedStatus = 'ERROR'
                state.companyFundingDetailsClosedLoader = false
            })
    }
})

export const { resetState } = companyFundingDetailsClosedSlice.actions;
export default companyFundingDetailsClosedSlice.reducer;