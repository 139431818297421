import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    getFundingPurpose: null,
    getFundingPurposeStatus:'',
    getFundingPurposeLoader:false,
    getFundingPurposeError:null
};
export const GetFundingPurpose = createAsyncThunk("/GetFundingPurpose", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getFundingPurpose`,
        method: "get",
    });
    return response;
});
const getFundingPurposeSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFundingPurpose.pending, (state) => {
                state.getFundingPurposeStatus = 'LOADING'
                state.getFundingPurposeLoader = true
                state.getFundingPurposeError = null
            })
            .addCase(GetFundingPurpose.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.getFundingPurposeStatus = "SUCCESS"
                    state.getFundingPurpose = action.payload?.data
                }
                else {
                    state.getFundingPurposeStatus = "ERROR"
                    state.getFundingPurposeError = [action?.payload?.errors?.[0]]
                }
                state.getFundingPurposeLoader = false
            })
            .addCase(GetFundingPurpose.rejected, (state, action) => {
                state.getFundingPurposeStatus = 'ERROR'
                state.getFundingPurposeLoader = false
            })
    }
})

export const { resetState } = getFundingPurposeSlice.actions;
export default getFundingPurposeSlice.reducer;