import { Box, Toolbar, Typography } from '@mui/material'
import React from 'react'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function StatusBadgeTradingPartner({ text, disabled }) {
    const selectedColor = text == 'Active' ? '#3CB500' : text == 'Rejected' ? '#E10000' : text == 'New' ? '#570271' : text == 'Pending' ? '#ffc000' : '#BCBCBC'
    return (
        <Box mr={2}>
            <Toolbar sx={{ border: '1px solid', borderColor: disabled ? '#fff' : selectedColor, borderRadius: '8px', minHeight: 'fit-content !important', padding: '10px 15px  !important', maxWidth: 'max-content', background: disabled ? 'grey' : 'transparent' }}>
                <Typography color={disabled ? '#fff' : selectedColor} fontSize={'15px'} variant="overline" component="overline" sx={{ flexGrow: 1, textAlign: 'center', textTransform: 'capitalize', mr: 4, ml: 1, textTransform: 'capitalize' }}>
                    {text}
                </Typography>
                {
                    text == 'Active' && <CheckCircleOutlineRoundedIcon sx={{ color: disabled ? '#fff' : selectedColor, marginRight: '5px', fontSize: "22px" }} />
                }
                {
                    text == 'Rejected' && <CancelOutlinedIcon sx={{ color: disabled ? '#fff' : selectedColor, marginRight: '5px', fontSize: '22px' }} />
                }
                {
                    text == 'New' && <AddCircleOutlineIcon sx={{ color: disabled ? '#fff' : selectedColor, marginRight: '5px', fontSize: '22px' }} />
                }
                {
                    (text != 'Active' && text != 'Rejected' && text != 'New') && <WatchLaterOutlinedIcon sx={{ color: disabled ? '#fff' : selectedColor, marginRight: '5px', fontSize: '22px' }} />
                }
            </Toolbar>
        </Box>
    )
}

export default StatusBadgeTradingPartner