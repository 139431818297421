import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    walletBalance: null,
    walletBalanceStatus:'',
    walletBalanceLoader:false,
    walletBalanceError:null
};
export const WalletBalance = createAsyncThunk("/WalletBalance", async () => {
    const response = await ApihelperFunction({
        urlPath: `api/getWalletBalance`,
        method: "get",
    });
    return response;
});
const walletBalanceSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateWalletBalance: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(WalletBalance.pending, (state) => {
                state.walletBalanceStatus = 'LOADING'
                state.walletBalanceLoader = true
                state.walletBalanceError = null
            })
            .addCase(WalletBalance.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.walletBalanceStatus = "SUCCESS"
                    state.walletBalance = action?.payload?.data
                }
                else {
                    state.walletBalanceStatus = "ERROR"
                    state.walletBalance = action?.payload?.data
                    state.walletBalanceError = [action?.payload?.errors?.[0]]
                }
                state.walletBalanceLoader = false
            })
            .addCase(WalletBalance.rejected, (state, action) => {
                state.walletBalanceStatus = 'ERROR'
                state.walletBalanceLoader = false
            })
    }
})

export const { resetStateWalletBalance } = walletBalanceSlice.actions;
export default walletBalanceSlice.reducer;