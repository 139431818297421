import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    funderMyInvestmentDetails: null,
    funderInvestmentDetailsStatus: '',
    funderInvestmentDetailsLoader: false,
    funderInvestmentDetailsError: null,
    funderOpenInvestmentCount: ''
};
export const funderInvestmentDetails = createAsyncThunk("/funderInvestmentDetails", async (data) => {
    console.log(data, 'function called')
    const response = await ApihelperFunction({
        urlPath: `api/funder/getOpenInvestment`,
        method: "get",
    });
    return response;
});
const funderInvestmentDetailsSlice = createSlice({
    name: 'funderInvestmentDetails',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(funderInvestmentDetails.pending, (state) => {
                state.funderInvestmentDetailsStatus = 'LOADING'
                state.funderInvestmentDetailsLoader = true
                state.funderInvestmentDetailsError = null
            })
            .addCase(funderInvestmentDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.funderInvestmentDetailsStatus = "SUCCESS"
                    state.funderMyInvestmentDetails = action?.payload?.data
                    state.funderOpenInvestmentCount = action?.payload?.data?.length
                }
                else {
                    state.funderInvestmentDetailsStatus = "ERROR"
                    state.funderMyInvestmentDetails = []
                    state.funderOpenInvestmentCount = 0
                    state.funderInvestmentDetailsError = [action?.payload?.errors?.[0]]
                }
                state.funderInvestmentDetailsLoader = false
            })
            .addCase(funderInvestmentDetails.rejected, (state, action) => {
                state.funderInvestmentDetailsStatus = 'ERROR'
                state.funderMyInvestmentDetails = []
                state.funderInvestmentDetailsLoader = false
            })
    }
})

export const { resetState } = funderInvestmentDetailsSlice.actions;
export default funderInvestmentDetailsSlice.reducer;