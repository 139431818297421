import axios from "axios";
let API_URL = "https://p2pfunding.uat-api.tsprojects.net/";

export { API_URL };

export const ApihelperFunction = async (data) => {
  const { urlPath, method, formData, typecontent } = data;
  // debugger
  let token = "";
  try {
    token = localStorage.getItem("token");
  } catch (err) {
  }
  let config = {
    data: formData,
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      Accept: "application/json",
      "Content-Type": typecontent ? "multipart/form-data" : "application/json",
      "Authorization": `Bearer ${token}`,
    },
  };
  let responseData = "";
  await axios(config)
    .then((response) => {
      responseData = response?.data;
    })
    .catch((error) => {
      responseData = error?.response?.data;
      // if (error?.response?.status==403) {
      //     window.location.href="http://localhost:3000/"
      // }
    });

  return responseData;
};
