import { Box, Button, Container, Grid, Slider, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import InputComp from '../../../components/InputComp';
import { useForm } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import SliderInputComp from '../../../components/SliderInputComp';
import { useEffect } from 'react';
import { getFeeDetails } from '../../../Redux/Slices/GetFeeDetailsSlice';
import { addAuctionData } from '../../../Redux/Slices/CreateAuctionSlice';
import ErrorMessageComp from '../../../components/ErrorMessageComp';
function StepThree() {
    const creditLimit = useSelector((state) => state.checkUserCreditLimit)
    const auctionData = useSelector((state) => state.createAuction)
    const [creditLimitError, setcreditLimitError] = useState(false)
    const { feeDetails, brokerFeeDetails, fjFeeDetails } = useSelector((state) => state.feeDetails)
    const dispatch = useDispatch()
    const { handleSubmit, control, formState: { errors }, getValues, setValue, watch } = useForm({
        defaultValues: auctionData?.data
    })
    const getFundingRequiredValue = (value) => {
        return (Math.round((value * 90) / 100))
    }
    const getROIamountValue = (funding_amount, roi_percent) => {
        return (Math.round((funding_amount * roi_percent) / 100))
    }
    const auction_amount = getFundingRequiredValue(watch('invoice_amount')) || getFundingRequiredValue(20000)
    const funding_amount = watch('auction_amount')
    const roi_percent = watch('roi_percent')
    let FJ_fee_percentage;
    let broker_fee_percentage;
    const retainer_percentage = feeDetails?.retainer_fee_percentage
    const transaction_fee_percentage = feeDetails?.transaction_fee
    const retainer_amount = Math.round((funding_amount * retainer_percentage) / 100)
    const transaction_fee = Math.round((funding_amount * transaction_fee_percentage) / 100)
    const getBrokerFeePercentage = (value) => {
        for (let i = 0; i < brokerFeeDetails.length; i++) {
            if (value >= brokerFeeDetails[i]?.min_amount && value <= brokerFeeDetails[i]?.max_amount) {
                if (brokerFeeDetails?.[i]?.broker_fee_percentage) {
                    broker_fee_percentage = brokerFeeDetails?.[i]?.broker_fee_percentage
                    return Math.round((value * brokerFeeDetails?.[i]?.broker_fee_percentage) / 100)
                } else {
                    broker_fee_percentage = null
                    return brokerFeeDetails?.[i]?.broker_fee_flat
                }
            }
        }
    }
    const getFJFeePercentage = (value) => {
        for (let i = 0; i < fjFeeDetails.length; i++) {
            if (value >= fjFeeDetails[i]?.min_amount && value <= fjFeeDetails[i]?.max_amount) {
                if (fjFeeDetails?.[i]?.fj_fee_percentage) {
                    FJ_fee_percentage = fjFeeDetails?.[i]?.fj_fee_percentage;
                    return Math.round((value * fjFeeDetails?.[i]?.fj_fee_percentage) / 100)
                } else {
                    FJ_fee_percentage = null;
                    return fjFeeDetails?.[i]?.fj_fee_flat
                }
            }
        }
    }
    const broker_fee = getBrokerFeePercentage(funding_amount)
    const FJ_fee = getFJFeePercentage(funding_amount)
    useEffect(() => {
        dispatch(getFeeDetails())
    }, [])
    useEffect(() => {
        if (funding_amount > Number(creditLimit?.userCreditLimit?.availableCreditLimit)) {
            setcreditLimitError(true)
        } else {
            setcreditLimitError(false)
        }
    }, [funding_amount])
    const marks = [
        {
            value: Math.round(feeDetails?.min_invoice_amount),
            label: `£ ${Math.round(feeDetails?.min_invoice_amount)?.toLocaleString('en-us')}`,
        },
        {
            value: Math.round(feeDetails?.max_invoice_amount),
            label: `£ ${Math.round(feeDetails?.max_invoice_amount)?.toLocaleString('en-us')}`,
        },
    ];
    const fundingRequired = [
        {
            value: Math.round(feeDetails?.min_funding_amount),
            label: `£ ${Math.round(feeDetails?.min_funding_amount)?.toLocaleString('en-us')}`,
        },
        {
            value: Math.round(auction_amount),
            label: `£ ${Math.round(auction_amount)?.toLocaleString('en-us')}`,
        },
    ];
    const ROI = [
        {
            value: Math.round(feeDetails?.min_roi),
            label: Math.round(feeDetails?.min_roi),
        },
        {
            value: Math.round(feeDetails?.max_roi),
            label: Math.round(feeDetails?.max_roi),
        },
    ];
    const onSubmit = (data) => {
        if (!creditLimitError) {
            dispatch(addAuctionData({ ...data, 'broker_fee_percentage':broker_fee_percentage, 'fj_fee': FJ_fee, 'fj_percentage': FJ_fee_percentage, 'retainer_percentage': retainer_percentage, 'retainer_amount': retainer_amount, 'transaction_fee_percentage': transaction_fee_percentage || 0, 'transaction_fee': transaction_fee }))
        }
    }
    return (
        <Container maxWidth={'md'}>
            <Box component="form">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <label><Typography variant='nav' fontWeight={'500'}>Invoice Value</Typography></label>
                        <SliderInputComp beforeText={'£'} showSlider={true} mt={15} minValue={0} maxValue={marks[1].value} defaultValue={marks[0].value} name={'invoice_amount'} watch={watch} marginLeft={'0px'} marks={marks} control={control} setValue={setValue} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <label><Typography variant='nav' fontWeight={'500'}>Amount Of Funding Required</Typography></label>
                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'10px'} component={'p'}>(Maximum 90% Of Invoice Value)</Typography></label>
                        <SliderInputComp beforeText={'£'} showSlider={true} key={auction_amount} minValue={0} maxValue={auction_amount} defaultValue={fundingRequired[0].value} name={'auction_amount'} watch={watch} marginLeft={'0px'} marks={fundingRequired} control={control} setValue={setValue} />
                        {
                            creditLimitError && <ErrorMessageComp message={`Your current credit limit is £${Number(creditLimit?.userCreditLimit?.availableCreditLimit).toLocaleString('en-us')}. The funding required must not exceed this`} />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <label><Typography variant='nav' fontWeight={'500'}>Maximum ROI <Tooltip title="Maximum ROI,as percentage or absolute amount" placement='right-start'><InfoOutlinedIcon fontSize='15px' style={{ marginLeft: '5px' }} /></Tooltip></Typography></label>
                        <SliderInputComp beforeText={'£'} showSlider={true} width={'95%'} amount_name={'roi_amount'} key={funding_amount} percentageValue={true} minValue={ROI[0].value} maxValue={ROI[1].value} defaultValue={ROI[0].value} amount={getROIamountValue(funding_amount, roi_percent)} name={'roi_percent'} watch={watch} marginLeft={'0px'} marks={ROI} control={control} setValue={setValue} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={3} style={{ paddingRight: '20px' }}>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Box sx={{ padding: '10px 20px', backgroundColor: '#F2F2F2', border: '1px solid #c3c3c3', borderRadius: '7px' }}>
                            <Typography variant='p' component={'p'} style={{ display: 'flex', alignItems: 'center' }}>Funding joint fee <Tooltip title={`Funding joint fee £ ${FJ_fee?.toLocaleString('en-us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}><InfoOutlinedIcon fontSize='15px' style={{ marginLeft: '5px' }} /></Tooltip></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Box sx={{ padding: '10px 20px', backgroundColor: '#F2F2F2', border: '1px solid #c3c3c3', borderRadius: '7px' }}>
                            <Typography variant='p' component={'p'} style={{ display: 'flex', alignItems: 'center' }}>Broker's fee <Tooltip title={`Broker's fee is £ ${broker_fee?.toLocaleString('en-us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}><InfoOutlinedIcon fontSize='15px' style={{ marginLeft: '5px' }} /></Tooltip></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Box sx={{ padding: '10px 20px', backgroundColor: '#F2F2F2', border: '1px solid #c3c3c3', borderRadius: '7px', width: '90%' }}>
                            <Typography variant='p' component={'p'} style={{ display: 'flex', alignItems: 'center' }}>Retainer fee <Tooltip title={`Retainer fee is ${retainer_percentage}% i.e £ ${retainer_amount?.toLocaleString('en-us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}><InfoOutlinedIcon fontSize='15px' style={{ marginLeft: '5px' }} /></Tooltip></Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Stack justifyContent="center" direction="row" margin={10}>
                    <Button variant="contained" sx={{
                        padding: '10px 60px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}
                        onClick={handleSubmit(onSubmit)}>CALCULATE</Button>
                </Stack>
            </Box>
        </Container>
    )
}

export default StepThree