import { Box, Button, Container, FormControl, Grid, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs'
import ErrorMessageComp from '../../../components/ErrorMessageComp';
import { Controller, useForm } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDispatch, useSelector } from 'react-redux';
import { addAuctionData } from '../../../Redux/Slices/CreateAuctionSlice';
import moment from 'moment';

function StepSix() {
    dayjs.extend(customParseFormat);
    const auctionData = useSelector((state) => state.createAuction)
    console.log(auctionData, "DUDU STEP 6")

    const dispatch = useDispatch()
    const lastYear = `${new Date().getMonth() - 1}/${new Date().getDate()}/${new Date().getFullYear()}`
    console.log(lastYear, 'oneYearAgo')
    const [noOfInstallments, setNoOfInstallments] = useState([1, 2, 3, 4, 5, 6])
    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        defaultValues: { ...auctionData?.data }
    })
    const onSubmit = (data) => {
        dispatch(addAuctionData({ ...data, issue_date: moment(data.issue_date,'DD-MM-YYYY').format('DD-MM-YYYY'), due_date: moment(data.due_date,'DD-MM-YYYY').format('DD-MM-YYYY') }))
    }
    return (
        <>
            <Container maxWidth={'md'}>
                <Box component="form">
                    <Grid container spacing={2}>
                        <Grid mt={2} className='registration-datepicker' item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Invoice Issue Date</Typography></label>
                            <Controller
                                name='issue_date'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                defaultValue={value ? dayjs(value, "DD-MM-YYYY") : null}
                                                onChange={(val) => { onChange(val.$d) }}
                                                maxDate={dayjs()}
                                                minDate={dayjs().subtract(30, 'day')}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                )}
                            />
                            {errors.issue_date?.type === 'required' && <ErrorMessageComp message={'Invoice issue date is required'} />
                            }
                        </Grid>
                        <Grid mt={2} className='registration-datepicker' item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Invoice Due Date</Typography></label>
                            <Controller
                                name='due_date'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                defaultValue={value && dayjs(value, "DD-MM-YYYY")}
                                                onChange={(val) => { onChange(val.$d) }}
                                                minDate={dayjs().add(15, 'day')}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                )}
                            />
                            {errors.due_date?.type === 'required' && <ErrorMessageComp message={'Invoice due date is required'} />
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Controller
                                name='number_of_instalment'
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Number Of Monthly Instalments <Tooltip title="Select the numbers of monthly installments to repay your loan" placement='right-start'><InfoOutlinedIcon fontSize='15px' style={{ marginLeft: '5px' }} /></Tooltip></Typography></label>
                                        <TextField
                                            id="outlined-select"
                                            select
                                            placeholder='Select your city'
                                            name='company_type'
                                            onChange={onChange} value={value || 'Number of instalments'}
                                            defaultValue=""
                                            sx={{
                                                mt: 0,
                                                '& .MuiOutlinedInput-root': {
                                                    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                    border: 'none',
                                                    borderRadius: '7px',
                                                    width: { lg: '100%', md: '400px', xs: '100%' },
                                                    height: { md: '50px', xs: '50px' },
                                                    boxShadow: '0px 3px 10px #0000000D',
                                                    opacity: 1
                                                }
                                            }}
                                        >
                                            <MenuItem value={'Number of instalments'} disabled>
                                                <Typography variant='p'>{'Number of instalments'}</Typography>
                                            </MenuItem>
                                            {noOfInstallments && noOfInstallments?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Typography variant='p'>{option}</Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                )}
                            />
                            {errors.number_of_instalment?.type === 'required' && <ErrorMessageComp message={'Number of installments is required'} />
                            }
                        </Grid>
                    </Grid>
                    <Stack justifyContent="center" direction="row" margin={10}>
                        <Button variant="contained" sx={{
                            padding: '10px 60px',
                            backgroundColor: '#B92C82',
                            textTransform: 'Uppercase'
                        }}
                            onClick={handleSubmit(onSubmit)}>NEXT</Button>
                    </Stack>
                </Box>
            </Container>
        </>
    )
}

export default StepSix