import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    data: "",
    status: '',
    userEmail: "",
    userType: "",
    loading: false,
    error: false,
    message: ''
};
export const FunderRegistration = createAsyncThunk("/funderRegistration", async (funderData) => {
    console.log(funderData, 'FUNDER DATA')
    let data = new FormData();
    for (const key in funderData) {
        if (funderData.hasOwnProperty(key)) {
            if (key != 'business_website' && key != 'sort_code' && key != 'iban' && key != 'swift_code') {
                data.append(key, funderData[key]);
            }
        }
    }
    if (funderData?.business_website) {
        data.append('business_website', 'https://' + funderData.business_website);
    }
    funderData.bank_name.forEach((bank, index) => {
        data.append(`bank_name[${index}]`, bank);
    });
    if (funderData.sort_code && funderData.country == 230) {
        funderData.sort_code.forEach((bank, index) => {
            data.append(`sort_code[${index}]`, bank);
        });
    }
    funderData.account_number.forEach((bank, index) => {
        data.append(`account_number[${index}]`, bank);
    });
    funderData.account_name.forEach((bank, index) => {
        data.append(`account_name[${index}]`, bank);
    });
    if (funderData.iban && funderData.country != 230) {
        funderData.iban.forEach((bank, index) => {
            data.append(`iban[${index}]`, bank);
        });
    }
    if (funderData.swift_code && funderData.country != 230) {
        funderData.swift_code.forEach((bank, index) => {
            data.append(`swift_code[${index}]`, bank);
        });
    }
    const response = await ApihelperFunction({
        urlPath: "api/AddFunderDetail",
        formData: data,
        method: "post",
    });
    return response;
});
const funderRegistrationSlice = createSlice({
    name: 'FunderRegistration',
    initialState,
    reducers: {
        resetStateFunderRegistration: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(FunderRegistration.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(FunderRegistration.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                    state.loading = false
                }
                else {
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors?.[0]]
                    state.loading = false
                }
            })
            .addCase(FunderRegistration.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false
                // console.log(action.payload, 'PAYLOAD DANGER')
            })
    }
})

export const { resetStateFunderRegistration } = funderRegistrationSlice.actions;
export default funderRegistrationSlice.reducer;