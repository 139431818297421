import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import UpperLayout from '../../components/UpperLayout'
import InformationCard from '../../components/InformationCard'
import { useTheme } from '@emotion/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuctionDetails } from '../../Redux/Slices/GetAuctionDetailsSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Loader from '../../components/Loader'

function AuctionDetails() {
    const location = useLocation()
    const dispatch = useDispatch()
    const auction_id = location?.search?.slice(1, location?.search?.length)
    const { auctionDetails, auctionDetailsStatus, auctionDetailsLoader, auctionDetailsError } = useSelector((state) => state.getAuctionDetails)
    console.log(auctionDetails, auctionDetailsStatus, auctionDetailsLoader, auctionDetailsError, 'AUCTION DETAILS')
    const data = [
        {
            heading: 'Credit Rating',
            type:'creditRating',
            data: auctionDetails?.companies?.company_credit_rating?.credit_rating
        },
        {
            heading: 'Auction type',
            data: auctionDetails?.auction_type_name
        },
        {
            heading: 'Auction number',
            data: auctionDetails?.auction_number
        },
        {
            heading: 'Funding achieved against target',
            value: Math.round(auctionDetails?.totalBidAmount / auctionDetails?.auction_amount * 100),
            type: 'linearProgress',
            data: auctionDetails?.totalBidAmount ? Math.round(auctionDetails?.totalBidAmount?.toLocaleString('en-us')) : Math.round(auctionDetails?.totalBidAmount),
            upperLabel: Math.round(auctionDetails?.auction_amount),
            bidAmount: 'totalBidAmount',
        },
        {
            heading: 'Auction time left ',
            type: 'circularProgress',
            startData: auctionDetails?.auction_start_date,
            endData: auctionDetails?.auction_end_date,
            subHeading: 'No. of bids:',
            subData: String(auctionDetails?.totalBidCount).padStart(2, '0')
        }
    ]
    const infoData = [
        {
            infoName: 'Company Information',
            details: [
                {
                    heading: 'Company name',
                    data: auctionDetails?.companies?.name || '---'
                },
                {
                    heading: 'Business type',
                    data: auctionDetails?.companies?.company_category?.name || '---'
                },
                {
                    heading: 'Annual turnover',
                    data: auctionDetails?.companies?.annual_turnover || '---'
                },
                {
                    heading: 'Company registration no.',
                    data: auctionDetails?.companies?.registration_number || '---',
                    type: 'reg_no'
                },
                {
                    heading: 'Website address',
                    data: auctionDetails?.companies?.website || '---',
                    type: 'website'
                },
                {
                    heading: 'Description of goods',
                    data: auctionDetails?.description || '---'
                },
                {
                    heading: 'Purpose of funding',
                    data: auctionDetails?.funding_purpose,
                    type: 'purpose'
                }
            ]
        },
        {
            infoName: 'Trading Partner',
            details: [
                {
                    heading: 'Company name',
                    data: auctionDetails?.trading_partner?.name || '---'
                },
                {
                    heading: 'Business type',
                    data: auctionDetails?.trading_partner?.company_category?.name || '---'
                },
                {
                    heading: 'Annual turnover',
                    data: auctionDetails?.trading_partner?.annual_turnover || '---'
                },
                {
                    heading: 'Company registration no.',
                    data: auctionDetails?.trading_partner?.registration_number || '---',
                    type: 'reg_no'
                },
                {
                    heading: 'Company registration date',
                    data: auctionDetails?.trading_partner?.established_date || '---'
                },
                {
                    heading: 'Credit rating',
                    data: auctionDetails?.trading_partner?.trading_credit_rating?.credit_rating || '---'
                },
                {
                    heading: 'Website address',
                    data: auctionDetails?.trading_partner?.website || '---',
                    type: 'website'
                }
            ]
        },
        {
            infoName: 'Funding Details',
            details: [
                {
                    heading: 'Funding target',
                    data: `£ ${Math.round(auctionDetails?.auction_amount).toLocaleString('en-us')}`
                },
                {
                    heading: 'Max ROI',
                    data: `${auctionDetails?.roi_percent}% / £${Math.round(auctionDetails?.roi_amount).toLocaleString('en-us')}`
                },
                {
                    heading: 'No. of repayments',
                    data: auctionDetails?.repayments?.length
                },
                {
                    heading: 'First repayment due date',
                    data: moment(auctionDetails?.due_date).format('DD/MM/YYYY')
                }
            ]
        }
    ]
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    useEffect(() => {
        dispatch(getAuctionDetails(auction_id))
    }, [])
    return (
        !auctionDetailsLoader ? <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }} mb={0}>
                <Typography variant='h4' textTransform={'none'} color='#272D3B' fontWeight={'600'} mb={0.5} sx={{
                    letterSpacing: "0.7px",
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: !isMobile ? 'max-content' : '100%',
                    alignItems: !isMobile ? 'center' : 'flex-start',
                    flexDirection: !isMobile ? 'row' : 'column'
                }}>
                    {auctionDetails?.companies?.name} <hr class="vertical-line" style={{ display: isMobile ? 'none' : "auto" }}></hr> <span style={{ fontSize: '12px', fontWeight: '500', marginLeft: !isMobile ? '15px' : '0px', textWrap: 'wrap' }}>{auctionDetails?.description}</span>
                </Typography>
            </Box>
            <Typography mb={3} fontSize={'15px'}>{auctionDetails?.auction_type_name=='Seller Finance'?'A company requires finance against an invoice they have issued':'A company requires finance against an invoice they have received'}</Typography>
            <UpperLayout data={data} />
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', alignItems: 'stretch' }}>
                {
                    infoData?.map((info, index) => {
                        return (
                            <InformationCard data={info} marginRight={index == infoData?.length - 1 ? '0px' : '20px'} />
                        )
                    })
                }
            </Box>
        </> : <Loader />
    )
}

export default AuctionDetails