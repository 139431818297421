import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    totalMyAuction: null,
    myAuction: null,
    myAuctionStatus: '',
    myAuctionLoader: false,
    myAuctionError: null
};
export const MyAuction = createAsyncThunk("/MyAuction", async (data) => {
    const response = await ApihelperFunction({
        urlPath: `api/company/getAllMyAuction?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}`,
        method: "get",
    });
    return response;
});
const myAuctionSlice = createSlice({
    name: 'MyAuction',
    initialState,
    reducers: {
        resetStateMyAuction: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(MyAuction.pending, (state) => {
                state.myAuctionStatus = 'LOADING'
                state.myAuctionLoader = true
                state.myAuctionError = null
                state.myAuction=null
            })
            .addCase(MyAuction.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.myAuctionStatus = "SUCCESS"
                    state.myAuction = action?.payload?.data?.auction
                    state.totalMyAuction = action?.payload?.data?.auction?.length
                    state.myAuctionLoader = false
                }
                else {
                    state.myAuctionLoader = false
                    state.myAuctionStatus = "ERROR"
                    state.myAuctionError = [action?.payload?.errors?.[0]]
                    if (action?.payload?.errors?.[0] == 'Company auction details not found!') {
                        state.totalMyAuction = 0
                        state.myAuction = []
                    }
                }
                state.myAuctionLoader = false
            })
            .addCase(MyAuction.rejected, (state, action) => {
                state.myAuctionStatus = 'ERROR'
                state.myAuctionLoader = false
            })
    }
})

export const { resetStateMyAuction } = myAuctionSlice.actions;
export default myAuctionSlice.reducer;