import { useRoutes } from 'react-router-dom'
import MainRoute from './MainRoute'
import LoginRoute from './LoginRoute'
import SignUpRoute from './SignUpRoute'
import { DashboardRoute } from './DashboardRoute'

const Routes = () => {
    return useRoutes([MainRoute,LoginRoute, SignUpRoute,DashboardRoute])
}

export default Routes