import { Box, Button, Card, CircularProgress, Grid, Link, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SelectInputComp from '../../components/SelectInputComp'
import moment from 'moment';
import { GetPaymentMode } from '../../Redux/Slices/GetPaymentModeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AddFundRequest, resetStateAddFundRequest } from '../../Redux/Slices/AddFundRequestSlice'
import { toast } from 'react-toastify'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs'
import InputComp from '../../components/InputComp'
import ModalComp from '../../components/ModalComp'
import { GetWalletText } from '../../Redux/Slices/GetWalletTextSlice'
import ErrorMessageComp from '../../components/ErrorMessageComp'
import copyIcon from '../../assets/img/copy.png'
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';


function AddTransactionModal({ open, handleClose, money, setAddValue }) {
  const [defaultValues, setDefaulValues] = useState()
  const role = localStorage.getItem('role')
  const [openProgressModal, setOpenProgressModal] = useState(false)
  const { handleSubmit, control, formState: { errors }, watch } = useForm({
    defaultValues: defaultValues
  })
  const paymentModeData = watch('payment_mode')
  const { getWalletText } = useSelector((state) => state.walletText)
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedFile, setSelectedFile] = useState()
  const dispatch = useDispatch()
  const { paymentMode, paymentModeStatus, paymentModeLoader, paymentModeError } = useSelector((state) => state.paymentMode)
  const { data, status, error, loading, message } = useSelector((state) => state.addTransactionReq)
  const customerId = localStorage?.getItem('customerId')
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    background: 'transparent linear-gradient(90deg, #B92C82 0%, #3A0CA3 100%) 0% 0% no-repeat padding-box',
    boxShadow: 24,
    borderRadius: '8px',
    color: '#fff',
    p: 4,
  };
console.log(paymentModeData,'paymentModeData')
  useEffect(() => {
    dispatch(GetPaymentMode())
    dispatch(resetStateAddFundRequest())
    dispatch(GetWalletText())
  }, [])

  //firing toast
  useEffect(() => {
    if (error) {
      toast.error('' + error[0], error[1], error[2], error[3]);
      handleClose()
      dispatch(resetStateAddFundRequest())
    } if (status === "SUCCESS") {
      toast.success('' + message, {
        style: { fontSize: "15px" },
        onOpen: () => {
          handleClose()
          setAddValue(null)
          setSelectedDate(null)
          setSelectedFile(null)
          setDefaulValues(null)
          window.location.reload()
        }
      });
    }
  }, [error, status]);
  useEffect(() => {
    dispatch(resetStateAddFundRequest())
  }, [dispatch])
  const onSubmit = (data) => {
    if (!dateError) {
      dispatch(AddFundRequest({ ...data, 'total_amount': money, 'transaction_time': moment(selectedDate?.$d).format("DD-MM-YYYY") }))
    }
  }
  const [dateError, setDateError] = useState(null)
  const textToCopyRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const copyToClipboard = () => {
    if (textToCopyRef.current) {
      navigator.clipboard.writeText(textToCopyRef.current.innerText)
        .then(() => {
          setShowTooltip(true);
          setTimeout(() => {
            setShowTooltip(false);
          }, 1000); // Hide the tooltip after 1.5 seconds
        })
        .catch((err) => {
          // Handle any errors that might occur during the copy process
          console.error('Unable to copy:', err);
        });
    }
  };

  console.log('getWalletText', getWalletText)
  const navigate = useNavigate()
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: 'scroll' }}
      >
        <Box sx={style}>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6}>
              <SelectInputComp marginTop={0} placeholderLabel={'Payment mode'} width='100%' name={'payment_mode'} required={true} placeholder={'Payment mode'} control={control} fieldType={'company_name'} options={paymentMode} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mt={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className='custom-datepicker'
                    format='DD/MM/YYYY'
                    label="Select a Date"
                    value={selectedDate}
                    maxDate={dayjs()}
                    onError={(error) => setDateError(error)}
                    onChange={(val) => { console.log(val, 'VAL'); setSelectedDate(val) }}
                    renderInput={(params) => <TextField sx={{ ":-ms-input-placeholder": '#fff' }} {...params} variant="outlined" onChange={(newValue) => console.log(newValue)} />} // Use TextField for input
                  />
                </LocalizationProvider>
                {
                  dateError && <ErrorMessageComp message={'Please select a valid date'} />
                }
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ marginTop: role == 'Company' ? 3.5 : 0 }}>
              <Typography fontSize={'13px'} mb={1} mt={2}>Customer ID</Typography>
              <div style={{ display: 'flex', justifyContent: "space-between", padding: "12px 10px", background: "#fff", borderRadius: "8px", position: 'relative' }}>
                <Typography ref={textToCopyRef} fontSize={'15px'} color={'grey'}>
                  {customerId}
                </Typography>
                <img src={copyIcon} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard()} />
                {showTooltip && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '-30px',
                      right: 0,
                      background: '#e02aa0',
                      color: '#fff',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      fontSize: '12px',
                    }}
                  >
                    Copied!
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              {
                role == 'Company' && <>
                  <InputComp required={role == 'Company' && true} subText={'Enter auction number this payment refers to or N/A'} width='100%' name={'transaction_desc'} placeholder={'Closed Auction Number'} control={control} fieldType={'address_3'} />
                  {errors.transaction_desc?.type === 'required' && <ErrorMessageComp message={'Auction number is required'} />
                  }
                  <Link href="/myAuction/auctionList?ClosedAuction" target='_blank' style={{ display: 'block', marginTop: '10px', width: 'max-content', textTransform: 'none', border: '1px solid #fff', color: '#fff', padding: '5px 10px', borderRadius: "5px", fontSize: "13px", textDecoration: "none" }}>
                    {'Find auction number'}
                  </Link>
                </>
              }
            </Grid>
          </Grid>
          {
            paymentModeData == 1 && <Card sx={{ marginTop: 2, padding: 1, }}>
              {
                getWalletText?.split('\r\n').slice(0, 3).map((line, index) => {
                  return (
                    <>
                      <Typography variant='overline' textTransform={'none'} mt={0} color={'#000'}>{line}</Typography>
                      <br />
                    </>)
                })
              }
              <Grid container sx={{ paddingRight: '10px', marginTop: '16px' }}>
                {
                  getWalletText?.split('\r\n').slice(4, getWalletText?.split('\r\n').length).map((line, index) => {
                    return (
                      line?.split(':').map((child, childIndex) => {
                        return (
                          <>
                            {
                              (childIndex == 0 || childIndex % 2 == 0) &&
                              <Grid item xs={5}>
                                <Typography mb={0.5} component={'p'} variant='overline' textTransform={'none'} mt={0} color={'#000'} className={index} textOverflow={'wrap'}>{child} :</Typography>
                              </Grid>
                            }
                            {
                              (childIndex % 2 == 1) &&
                              <Grid item xs={7}>
                                <Typography mb={0.5} component={'p'} variant='overline' textTransform={'none'} mt={0} color={'#000'} className={index} textOverflow={'wrap'}>{child}</Typography>
                              </Grid>
                            }
                          </>
                        )
                      })
                    )
                  })
                }
              </Grid>
            </Card>
          }
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
            <Button onClick={handleSubmit(onSubmit)} sx={{ mt: 2, mr: 1, textTransform: 'none' }} variant="outlined" color='white'>
              {loading ? <CircularProgress color="white" size={'25px'} /> : 'Add fund'}
            </Button>
            <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <ModalComp closeButton={"Ok"} open={openProgressModal} message={`Work in progress`} handleClose={() => { setOpenProgressModal(false) }} />
    </>
  )
}

export default AddTransactionModal