import React, { useEffect, useState } from 'react'
import GlobalTable from '../../components/GlobalTable'
import { Box, Tab, Tabs, Typography, makeStyles } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MyClosedAuction, resetStateMyClosedAuction } from '../../Redux/Slices/GetAllMyClosedAuctionsSlice';
import { MyLiveAuction, resetStateMyLiveAuction } from '../../Redux/Slices/GetAllMyLiveAuctionsSlice';
import { MyAuction, resetStateMyAuction } from '../../Redux/Slices/GetAllMyAuctionSlice';
import Loader from '../../components/Loader';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AuctionList() {
    const location = useLocation()
    const navigate = useNavigate()
    const auction_type = location?.search?.slice(1, location?.search?.length)
    const dispatch = useDispatch()
    const [value, setValue] = useState(0);
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('Newest_to_Oldest')
    const [filterBy, setFilterBy] = useState()
    const { totalMyAuction, myAuction, myAuctionStatus, myAuctionLoader, myAuctionError } = useSelector((state) => state.myAuction)
    const { totalMyClosedAuction, myClosedAuction, myClosedAuctionStatus, myClosedAuctionLoader, myClosedAuctionError, retry } = useSelector((state) => state.myClosedAuction)
    const { totalMyLiveAuction, myLiveAuction, myLiveAuctionStatus, myLiveAuctionLoader, myLiveAuctionError, retryLive } = useSelector((state) => state.myLiveAuction)
    const role = localStorage.getItem('role')
    const filterOptions = [{
        name: 'Buyer Finance',
        value: 'Buyer Finance'
    },
    {
        name: 'Seller Finance',
        value: 'Seller Finance'
    },
    {
        name: 'Clear Filters',
        value: ''
    }]
    const sortOptions = [{
        name: 'Newest to Oldest',
        value: 'Newest_to_Oldest'
    },
    {
        name: 'Oldest to Newest',
        value: 'Oldest_to_Newest'
    },
    {
        name: 'Clear Filters',
        value: ''
    }]
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (location?.search == '?ClosedAuction') {
            if (role == 'Funder') {
                setValue(1)
            } else {
                setValue(2)
            }
        } else if (location?.search == '?LiveAuction') {
            if (role == 'Funder') {
                setValue(0)
            } else {
                setValue(1)
            }
        } else {
            setValue(0)
        }
    }, [location?.search])
    useEffect(() => {
        role == 'Company' && dispatch(MyAuction({ page: page, limit: limit, sortBy: sortBy, filterBy: filterBy }))
        retry > 0 && dispatch(MyClosedAuction({ page: page, limit: limit, sortBy: sortBy, filterBy: filterBy }))
        retryLive>0 && dispatch(MyLiveAuction({ page: page, limit: limit, sortBy: sortBy, filterBy: filterBy }))
    }, [page, limit, filterBy, sortBy, retry, retryLive])
    useEffect(() => {
        dispatch(resetStateMyAuction())
        dispatch(resetStateMyClosedAuction())
        dispatch(resetStateMyLiveAuction())
    }, [dispatch])
    return (
        (role == "Funder" || totalMyAuction != null) ?
            <>
                <Typography variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                    My auction
                </Typography>
                {((role == 'Company' && auction_type == 'AllAuction') || role == 'Funder') && <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 0 }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}>
                            {role != 'Funder' && <Tab label={`All Auctions (${totalMyAuction || 0})`} {...a11yProps(0)} sx={{
                                borderRadius: '8px 8px 0px 0px',
                                backgroundColor: '#FBFBFB',
                                letterSpacing: 0.8,
                                color: '#000',
                                fontWeight: '600',
                                fontSize: '15px',
                                '&.Mui-selected': {
                                    backgroundColor: '#B92C82', // Remove the underline of the selected tab
                                    color: '#fff', // Customize the text color of the selected tab
                                    fontWeight: '500',
                                    letterSpacing: 0.4
                                }
                            }} />}
                            <Tab label={`Live Auctions (${totalMyLiveAuction || 0})`} {...a11yProps(1)} sx={{
                                borderRadius: '8px 8px 0px 0px',
                                backgroundColor: '#FBFBFB',
                                letterSpacing: 0.8,
                                color: '#000',
                                fontWeight: '600',
                                fontSize: '15px',
                                '&.Mui-selected': {
                                    backgroundColor: '#B92C82',
                                    color: '#fff',
                                    fontWeight: '500',
                                    letterSpacing: 0.4
                                }
                            }} />
                            <Tab disabled={auction_type == 'LiveAuction'} label={`Closed Auctions (${totalMyClosedAuction || 0})`} {...a11yProps(2)} sx={{
                                borderRadius: '8px 8px 0px 0px',
                                backgroundColor: '#FBFBFB',
                                letterSpacing: 0.8,
                                color: '#000',
                                fontWeight: '600',
                                fontSize: '15px',
                                '&.Mui-selected': {
                                    backgroundColor: '#B92C82', // Remove the underline of the selected tab
                                    color: '#fff', // Customize the text color of the selected tab
                                    fontWeight: '500',
                                    letterSpacing: 0.4
                                }
                            }} />
                        </Tabs>
                    </Box>
                    {role != 'Funder' && <CustomTabPanel value={value} index={0} >
                        {
                            (!myAuction || myAuctionLoader) ? <Loader /> : <GlobalTable
                                sortOptions={sortOptions}
                                sortBy={sortBy}
                                setSortOptions={setSortBy}
                                filterOptions={filterOptions}
                                filterBy={filterBy}
                                setFilterOptions={setFilterBy}
                                showPagination='true'
                                rowsPerPage={limit}
                                setRowsPerPage={setLimit}
                                page={page}
                                setPage={setPage}
                                count={totalMyAuction}
                                showUpperBox={true}
                                showNumberOfLoans={false}
                                tableName={'All Auctions'}
                                rows={myAuction}
                                headCells={
                                    [
                                        {
                                            align: "left",
                                            label: "Auction Type",
                                            fieldName: "auction_type_name",
                                        },
                                        {
                                            align: "left",
                                            label: "Auction Number",
                                            fieldName: "auction_number",
                                        },
                                        {
                                            align: "left",
                                            label: "Status",
                                            type: 'auctionStatus',
                                            fieldName: "auction_status",
                                            subFieldName: "status",
                                        },
                                        {
                                            align: "left",
                                            label: "Company",
                                            fieldName: "companies",
                                            type: 'company',
                                            showValue: (val) => {
                                                return val?.name;
                                            },
                                        },
                                        {
                                            align: "left",
                                            label: "Funding Required",
                                            fieldName: "auction_amount",
                                            type: 'money',
                                            showValue: (val) => {
                                                return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                                            },
                                        },
                                        {
                                            align: "left",
                                            label: "Funding Achieved",
                                            fieldName: "fundingAchieved",
                                            upperLabel: "auction_amount",
                                            bidAmount: 'totalBidAmount',
                                            type: 'linearProgress'
                                        },
                                        {
                                            align: "left",
                                            label: "Max ROI",
                                            type: 'roi',
                                            fieldName: "roi_percent",
                                            subFieldName: "roi_amount"
                                        },
                                        {
                                            align: "left",
                                            type: "creditRating",
                                            label: "Credit Rating",
                                            fieldName: "companies",
                                            showValue: (val) => {
                                                return val?.company_credit_rating?.credit_rating;
                                            },
                                        },
                                        {
                                            align: "left",
                                            label: "Auction Time Left",
                                            fieldName: "auction_start_date",
                                            subFieldName: "auction_end_date",
                                            type: 'roundProgress'
                                        },
                                        {
                                            align: "left",
                                            label: "No. Of BIDS",
                                            fieldName: "totalBidCount",
                                        }
                                    ]
                                }
                            />
                        }
                    </CustomTabPanel>}
                    <CustomTabPanel value={value} index={role == 'Funder' ? 0 : 1} >
                        {(!myLiveAuction || myLiveAuctionLoader) ? <Loader /> : <GlobalTable
                            sortOptions={sortOptions}
                            sortBy={sortBy}
                            setSortOptions={setSortBy}
                            filterOptions={filterOptions}
                            filterBy={filterBy}
                            setFilterOptions={setFilterBy}
                            showPagination='true'
                            rowsPerPage={limit}
                            setRowsPerPage={setLimit}
                            page={page}
                            setPage={setPage}
                            count={totalMyLiveAuction}
                            showUpperBox={true}
                            showNumberOfLoans={false}
                            tableName={'Live Auctions'}
                            rows={myLiveAuction}
                            headCells={
                                [
                                    {
                                        align: "left",
                                        label: "Auction Type",
                                        fieldName: "auction_type_name",
                                    },
                                    {
                                        align: "left",
                                        label: "Auction Number",
                                        fieldName: "auction_number",
                                    },
                                    {
                                        align: "left",
                                        label: "Status",
                                        fieldName: "auction_status",
                                        subFieldName: 'status',
                                        type: 'auctionStatus'
                                    },
                                    {
                                        align: "left",
                                        label: "Company",
                                        fieldName: "companies",
                                        type: 'company',
                                        showValue: (val) => {
                                            return val?.name;
                                        },
                                    },
                                    {
                                        align: "left",
                                        label: "Funding Required",
                                        fieldName: "auction_amount",
                                        type: 'money',
                                        showValue: (val) => {
                                            return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                                        },
                                    },
                                    {
                                        align: "left",
                                        label: "Funding Achieved",
                                        fieldName: "fundingAchieved",
                                        upperLabel: "auction_amount",
                                        bidAmount: 'totalBidAmount',
                                        type: 'linearProgress'
                                    },
                                    {
                                        align: "left",
                                        label: "Max ROI",
                                        type: 'roi',
                                        fieldName: "roi_percent",
                                        subFieldName: "roi_amount"
                                    },
                                    {
                                        align: "left",
                                        type: "creditRating",
                                        label: "Credit Rating",
                                        fieldName: "companies",
                                        showValue: (val) => {
                                            return val?.company_credit_rating?.credit_rating;
                                        },
                                    },
                                    {
                                        align: "left",
                                        label: "Auction Time Left",
                                        fieldName: "auction_start_date",
                                        subFieldName: "auction_end_date",
                                        type: 'roundProgress'
                                    },
                                    {
                                        align: "left",
                                        label: "No. Of BIDS",
                                        fieldName: "totalBidCount",
                                    },

                                ]
                            }
                        />}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={role == 'Funder' ? 1 : 2}>
                        {(totalMyClosedAuction == null || myClosedAuctionLoader) ? <Loader /> : <GlobalTable
                            sortOptions={sortOptions}
                            sortBy={sortBy}
                            setSortOptions={setSortBy}
                            filterOptions={filterOptions}
                            filterBy={filterBy}
                            setFilterOptions={setFilterBy}
                            showPagination='true'
                            rowsPerPage={limit}
                            setRowsPerPage={setLimit}
                            page={page}
                            setPage={setPage}
                            count={totalMyClosedAuction}
                            showUpperBox={true}
                            showNumberOfLoans={false}
                            tableName={'Closed Auctions'}
                            rows={myClosedAuction}
                            headCells={
                                [
                                    {
                                        align: "left",
                                        label: "Auction Type",
                                        fieldName: "auction_type_name",
                                    },
                                    {
                                        align: "left",
                                        label: "Auction Number",
                                        fieldName: "auction_number",
                                    },
                                    {
                                        align: "left",
                                        label: "Status",
                                        type: 'auctionStatus',
                                        fieldName: "auction_status",
                                        subFieldName: 'status'
                                    },
                                    {
                                        align: "left",
                                        label: "Company",
                                        fieldName: "companies",
                                        type: 'company',
                                        showValue: (val) => {
                                            return val?.name;
                                        },
                                    },
                                    {
                                        align: "left",
                                        label: "Funding Required",
                                        fieldName: "auction_amount",
                                        type: 'money',
                                        showValue: (val) => {
                                            return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                                        },
                                    },
                                    {
                                        align: "left",
                                        label: "Funding Achieved",
                                        fieldName: "fundingAchieved",
                                        upperLabel: "auction_amount",
                                        bidAmount: 'totalBidAmount',
                                        type: 'linearProgress'
                                    },
                                    {
                                        align: "left",
                                        label: "Max ROI",
                                        type: 'roi',
                                        fieldName: "roi_percent",
                                        subFieldName: "roi_amount"
                                    },
                                    {
                                        align: "left",
                                        label: "Credit Rating",
                                        fieldName: "companies",
                                        type: "creditRating",
                                        showValue: (val) => {
                                            return val?.company_credit_rating?.credit_rating;
                                        },
                                    },
                                    {
                                        align: "left",
                                        label: "Auction Time Left",
                                        fieldName: "auction_start_date",
                                        subFieldName: "auction_end_date",
                                        type: 'roundProgress'
                                    },
                                    {
                                        align: "left",
                                        label: "No. Of BIDS",
                                        fieldName: "totalBidCount",
                                    },

                                ]
                            }
                        />}
                    </CustomTabPanel>
                </Box >}
                {
                    role == 'Company' && auction_type !== 'AllAuction' && (auction_type === 'LiveAuction' ? (!myLiveAuction || myLiveAuctionLoader) ? <Loader /> : <GlobalTable
                        sortOptions={sortOptions}
                        sortBy={sortBy}
                        setSortOptions={setSortBy}
                        filterOptions={filterOptions}
                        filterBy={filterBy}
                        setFilterOptions={setFilterBy}
                        showPagination='true'
                        rowsPerPage={limit}
                        setRowsPerPage={setLimit}
                        page={page}
                        setPage={setPage}
                        count={totalMyLiveAuction}
                        showUpperBox={true}
                        showNumberOfLoans={false}
                        tableName={`Live Auctions (${totalMyLiveAuction})`}
                        rows={myLiveAuction}
                        headCells={
                            [
                                {
                                    align: "left",
                                    label: "Auction Type",
                                    fieldName: "auction_type_name",
                                },
                                {
                                    align: "left",
                                    label: "Auction Number",
                                    fieldName: "auction_number",
                                },
                                {
                                    align: "left",
                                    label: "Status",
                                    fieldName: "auction_status",
                                    subFieldName: 'status',
                                    type: 'auctionStatus'
                                },
                                {
                                    align: "left",
                                    label: "Company",
                                    fieldName: "companies",
                                    type: 'company',
                                    showValue: (val) => {
                                        return val?.name;
                                    },
                                },
                                {
                                    align: "left",
                                    label: "Funding Required",
                                    fieldName: "auction_amount",
                                    type: 'money',
                                    showValue: (val) => {
                                        return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                                    },
                                },
                                {
                                    align: "left",
                                    label: "Funding Achieved",
                                    fieldName: "fundingAchieved",
                                    upperLabel: "auction_amount",
                                    bidAmount: 'totalBidAmount',
                                    type: 'linearProgress'
                                },
                                {
                                    align: "left",
                                    label: "Max ROI",
                                    type: 'roi',
                                    fieldName: "roi_percent",
                                    subFieldName: "roi_amount"
                                },
                                {
                                    align: "left",
                                    type: "creditRating",
                                    label: "Credit Rating",
                                    fieldName: "companies",
                                    showValue: (val) => {
                                        return val?.company_credit_rating?.credit_rating;
                                    },
                                },
                                {
                                    align: "left",
                                    label: "Auction Time Left",
                                    fieldName: "auction_start_date",
                                    subFieldName: "auction_end_date",
                                    type: 'roundProgress'
                                },
                                {
                                    align: "left",
                                    label: "No. Of BIDS",
                                    fieldName: "totalBidCount",
                                },

                            ]
                        }
                    /> : (totalMyClosedAuction == null || myClosedAuctionLoader) ? <Loader /> : <GlobalTable
                        sortOptions={sortOptions}
                        sortBy={sortBy}
                        setSortOptions={setSortBy}
                        filterOptions={filterOptions}
                        filterBy={filterBy}
                        setFilterOptions={setFilterBy}
                        showPagination='true'
                        rowsPerPage={limit}
                        setRowsPerPage={setLimit}
                        page={page}
                        setPage={setPage}
                        count={totalMyClosedAuction}
                        showUpperBox={true}
                        showNumberOfLoans={false}
                        tableName={`Closed Auctions (${totalMyClosedAuction})`}
                        rows={myClosedAuction}
                        headCells={
                            [
                                {
                                    align: "left",
                                    label: "Auction Type",
                                    fieldName: "auction_type_name",
                                },
                                {
                                    align: "left",
                                    label: "Auction Number",
                                    fieldName: "auction_number",
                                },
                                {
                                    align: "left",
                                    label: "Status",
                                    type: 'auctionStatus',
                                    fieldName: "auction_status",
                                    subFieldName: 'status'
                                },
                                {
                                    align: "left",
                                    label: "Company",
                                    fieldName: "companies",
                                    type: 'company',
                                    showValue: (val) => {
                                        return val?.name;
                                    },
                                },
                                {
                                    align: "left",
                                    label: "Funding Required",
                                    fieldName: "auction_amount",
                                    type: 'money',
                                    showValue: (val) => {
                                        return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                                    },
                                },
                                {
                                    align: "left",
                                    label: "Funding Achieved",
                                    fieldName: "fundingAchieved",
                                    upperLabel: "auction_amount",
                                    bidAmount: 'totalBidAmount',
                                    type: 'linearProgress'
                                },
                                {
                                    align: "left",
                                    label: "Max ROI",
                                    type: 'roi',
                                    fieldName: "roi_percent",
                                    subFieldName: "roi_amount"
                                },
                                {
                                    align: "left",
                                    label: "Credit Rating",
                                    fieldName: "companies",
                                    type: "creditRating",
                                    showValue: (val) => {
                                        return val?.company_credit_rating?.credit_rating;
                                    },
                                },
                                {
                                    align: "left",
                                    label: "Auction Time Left",
                                    fieldName: "auction_start_date",
                                    subFieldName: "auction_end_date",
                                    type: 'roundProgress'
                                },
                                {
                                    align: "left",
                                    label: "No. Of BIDS",
                                    fieldName: "totalBidCount",
                                },

                            ]
                        }
                    />)
                }

            </> : <Loader />
    )
}

export default AuctionList