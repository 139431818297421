import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    auctionDetails: null,
    auctionDetailsStatus:'',
    auctionDetailsLoader:false,
    auctionDetailsError:null
};
export const getAuctionDetails = createAsyncThunk("/getAuctionDetails", async (id) => {
    const response = await ApihelperFunction({
        urlPath: `api/company/getAuctionDetail?auction_id=${id}`,
        method: "get",
    });
    return response;
});
const getAuctionDetailsSlice = createSlice({
    name: 'AuctionDetails',
    initialState,
    reducers: {
        resetStateAuctionDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuctionDetails.pending, (state) => {
                state.auctionDetailsStatus = 'LOADING'
                state.auctionDetailsLoader = true
                state.auctionDetailsError = null
            })
            .addCase(getAuctionDetails.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.auctionDetailsStatus = "SUCCESS"
                    state.auctionDetails = action?.payload?.data
                }
                else {
                    state.auctionDetailsStatus = "ERROR"
                    state.auctionDetailsError = [action?.payload?.errors?.[0]]
                }
                state.auctionDetailsLoader = false
            })
            .addCase(getAuctionDetails.rejected, (state, action) => {
                state.auctionDetailsStatus = 'ERROR'
                state.auctionDetailsLoader = false
            })
    }
})

export const { resetStateAuctionDetails } = getAuctionDetailsSlice.actions;
export default getAuctionDetailsSlice.reducer;