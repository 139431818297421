import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    data: "",
    status: '',
    userEmail: "",
    userType: "",
    loading: false,
    error: false,
    message: ''
};
export const CompanyDetails = createAsyncThunk("/CompanyDetails", async (companydata) => {
    const response = await ApihelperFunction({
        urlPath: "api/AddCompanyDetail",
        formData: companydata,
        method: "post",
    });
    return response;
});
export const CompanyAccount = createAsyncThunk("/CompanyAccount", async (companyaccountdata) => {
    let data = new FormData();
    data.append('company_category', companyaccountdata.company_category);
    data.append('company_type', companyaccountdata.company_type);
    data.append('established_year', companyaccountdata.established_year);
    data.append('annual_turnover', companyaccountdata.annual_turnover);
    data.append('trading_period', companyaccountdata.trading_period);
    data.append('website', 'https://' + companyaccountdata.website);
    companyaccountdata.bank_name.forEach((bank, index) => {
        data.append(`bank_name[${index}]`, bank);
    });
    companyaccountdata.sort_code.forEach((bank, index) => {
        data.append(`sort_code[${index}]`, bank);
    });
    companyaccountdata.account_number.forEach((bank, index) => {
        data.append(`account_number[${index}]`, bank);
    });
    const response = await ApihelperFunction({
        urlPath: "api/AddCompanyAcc",
        formData: data,
        method: "post",
    });
    return response;
});
const companyRegistrationSlice = createSlice({
    name: 'CompanyRegistration',
    initialState,
    reducers: {
        resetStateCompanyRegistration: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(CompanyDetails.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(CompanyDetails.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                }
                else {
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors?.[0]]
                }
                console.log(action.payload, 'PAYLOAD')
                state.loading = false
                state.data = action?.payload?.data;
                state.userEmail = action?.payload?.data?.email;
                state.userType = action?.payload?.data?.user_type;
            })
            .addCase(CompanyDetails.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false

                // console.log(action.payload, 'PAYLOAD DANGER')
            })
            .addCase(CompanyAccount.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(CompanyAccount.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.status = "SUCCESSCOMPANYACCOUNT"
                    state.message = action?.payload?.message
                }
                else {
                    state.status = "ERRORCOMPANYACCOUNT"
                    state.error = [action?.payload?.errors?.[0]]
                }
                state.loading = false

            })
            .addCase(CompanyAccount.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false

                // console.log(action.payload, 'PAYLOAD DANGER')
            })
    }
})

export const { resetStateCompanyRegistration } = companyRegistrationSlice.actions;
export default companyRegistrationSlice.reducer;
