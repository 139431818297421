import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    file: null
}
const UploadFileSlice = createSlice({
    name: "UploadFile",
    initialState,
    reducers: {
        resetStateCreateAuction: () => initialState,
        addAuctionFile: (state, action) => {
            state.file = action.payload
        }
    }
})

export const { resetStateCreateAuction, addAuctionFile } = UploadFileSlice.actions;
export default UploadFileSlice.reducer