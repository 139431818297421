import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    funderMyInvestmentDetailsClosed: [],
    funderInvestmentClosedDetailsStatus:'',
    funderInvestmentClosedDetailsLoader:false,
    funderInvestmentClosedDetailsError:null,
    funderClosedInvestmentCount:''
};
export const funderInvestmentClosedDetails = createAsyncThunk("/funderInvestmentClosedDetails", async (data) => {
    console.log(data,'function called')
    const response = await ApihelperFunction({
        urlPath: `api/funder/getCloseInvestment`,
        method: "get",
    });
    return response;
});
const funderInvestmentClosedDetailsSlice = createSlice({
    name: 'funderInvestmentClosedDetails',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(funderInvestmentClosedDetails.pending, (state) => {
                state.funderInvestmentClosedDetailsStatus = 'LOADING'
                state.funderInvestmentClosedDetailsLoader = true
                state.funderInvestmentClosedDetailsError = null
            })
            .addCase(funderInvestmentClosedDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.funderInvestmentClosedDetailsStatus = "SUCCESS"
                    state.funderMyInvestmentDetailsClosed = action?.payload?.data
                    state.funderClosedInvestmentCount=action?.payload?.data?.length
                }
                else {
                    state.funderInvestmentClosedDetailsStatus = "ERROR"
                    state.funderMyInvestmentDetailsClosed = []
                    state.funderClosedInvestmentCount=0
                    state.funderInvestmentClosedDetailsError = [action?.payload?.errors?.[0]]
                }
                state.funderInvestmentClosedDetailsLoader = false
            })
            .addCase(funderInvestmentClosedDetails.rejected, (state, action) => {
                state.funderInvestmentClosedDetailsStatus = 'ERROR'
                state.funderInvestmentClosedDetailsLoader = false
            })
    }
})

export const { resetState } = funderInvestmentClosedDetailsSlice.actions;
export default funderInvestmentClosedDetailsSlice.reducer;