import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import checked from '../assets/img/checked.png'
import { useSelector } from 'react-redux'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import CreateIcon from '@mui/icons-material/Create';
import { Button } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

function TradingPartnerCard({ marginRight }) {
    const { tradingPartnerDetails } = useSelector((state) => state.tradingPartnerDetails)
    const navigate = useNavigate()
    const cardStyle = {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 30px #15223214',
        borderRadius: '8px',
        padding: '20px',
        marginTop: '15px'
    }
    return (
        <div style={{ marginRight: marginRight, flex: 0.60 }}>
            <Card sx={cardStyle}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box>
                        <Typography variant='h6' color='#B92C82' fontWeight={'600'} mb={0} sx={{ letterSpacing: "0.7px" }}>
                            {tradingPartnerDetails?.name}
                        </Typography>
                        <Typography variant='p' component={'p'} color={'#000'} fontSize={'13px'} textTransform={'none'} mb={3}>{tradingPartnerDetails?.contact_email}</Typography>
                    </Box>
                    <Button style={{ background: 'transparent', border: 'none', padding: '0px' }} onClick={() => { tradingPartnerDetails?.status_name == 'Active' && navigate(`/editTradingPartner?${tradingPartnerDetails?.id}`) }} disabled={tradingPartnerDetails?.status_name != 'Active'}><CreateIcon style={{ color: tradingPartnerDetails?.status_name != 'Active' ? 'grey' : '#000', cursor: tradingPartnerDetails?.status_name != 'Active' ? 'not-allowed' : 'pointer' }} /></Button>
                </Box>
                <Stack direction={{ md: 'row', xs: 'column' }}>
                    <Stack sx={{ flex: 0.6 }}>
                        {tradingPartnerDetails?.registration_number &&
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                                <img src={checked} alt='' width={'15px'} style={{ marginRight: '15px' }} />
                                <Box>
                                    <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Company registration number</Typography>
                                    <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{tradingPartnerDetails?.registration_number}</Typography>
                                </Box>
                            </Box>
                        }
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <img src={checked} alt='' width={'15px'} style={{ marginRight: '15px' }} />
                            <Box>
                                <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Company type</Typography>
                                <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{tradingPartnerDetails?.company_type?.name}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <img src={checked} alt='' width={'15px'} style={{ marginRight: '15px' }} />
                            <Box>
                                <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Business type</Typography>
                                <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{tradingPartnerDetails?.company_category?.name}</Typography>
                            </Box>
                        </Box>
                    </Stack>
                    <Divider orientation='vertical' sx={{ background: '#000', width: 2, mt: { lg: 0, md: 0, xs: 3 }, mx: 5, opacity: 0.2 }} flexItem={true} />
                    <Stack sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <LocationOnIcon style={{ color: '#3A0CA3', fontSize: "20px", marginRight: '10px' }} />
                            <Box>
                                <Typography variant='overline' component={'p'} color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{`${tradingPartnerDetails?.address_1} , ${tradingPartnerDetails?.address_2} , ${tradingPartnerDetails?.address_3 ? `${tradingPartnerDetails?.address_3},`:''}`}</Typography>
                                <Typography variant='overline' component={'p'} color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{`${tradingPartnerDetails?.country?.name} , ${tradingPartnerDetails?.state?.name} , ${tradingPartnerDetails?.city?.name?`${tradingPartnerDetails?.city?.name},`:''} ${tradingPartnerDetails?.postcode} `}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <LocalPhoneIcon style={{ color: '#3A0CA3', fontSize: "20px", marginRight: '10px' }} />
                            <Box>
                                <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{`(+${tradingPartnerDetails?.phone_code})${tradingPartnerDetails?.phone_number}`}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <LanguageIcon style={{ color: '#3A0CA3', fontSize: "20px", marginRight: '10px' }} />
                            <Box>
                                <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{tradingPartnerDetails?.website}</Typography>
                            </Box>
                        </Box>
                        <Typography variant='h6' component={'h6'} mb={0} color={'#3A0CA3'} fontWeight={'600'}>{`${tradingPartnerDetails?.contact_title} ${tradingPartnerDetails?.contact_first_name} ${tradingPartnerDetails?.contact_last_name}`}</Typography>
                        <Typography variant='overline' color={'#000'} textTransform={'capitalize'} fontSize={'13px'} component={'overline'} mb={2}>{`(${tradingPartnerDetails?.contact_job_title})`}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <LocalPhoneIcon style={{ color: '#3A0CA3', fontSize: "20px", marginRight: '10px' }} />
                            <Box>
                                <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{`(+${tradingPartnerDetails?.contact_phone_code}) ${tradingPartnerDetails?.contact_mobile}`}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                            <EmailIcon style={{ color: '#3A0CA3', fontSize: "20px", marginRight: '10px' }} />
                            <Box>
                                <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{tradingPartnerDetails?.contact_email}</Typography>
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
            </Card>
        </div >
    )
}

export default TradingPartnerCard