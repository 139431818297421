import { useTheme } from '@emotion/react'
import { AppBar, Box, IconButton, LinearProgress, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { useLocation } from 'react-router-dom'
import StatusText from './StatusText'

function UpperLayout({ data }) {
    const location = useLocation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const toolbarStyle = {
        overflowX: "auto",
        width: "auto",
        boxShadow: '0px 1px 30px #15223214',
        borderRadius: '8px',
        padding: '20px',
        background: location?.pathname == '/myAuction/leaderBoard' ? 'transparent linear-gradient(95deg, #ED4886 0%, #B855A4 100%) 0% 0% no-repeat padding-box' : 'transparent linear-gradient(95deg, #875EC0 0%, #5446BA 100%) 0% 0% no-repeat padding-box',
        justifyContent: 'space-around',
        alignItems: 'stretch'
    };
    //PROGRESS BAR//
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box mt={0.5} sx={{ minWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography variant="overline" color="#fff" >{`£${Number(props.data).toLocaleString('en-us')}(${Math.round(
                        props.value,
                    )}%)`}</Typography>
                </Box>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress className='funding-progress' color="primary" variant={props?.value > 100 ? 'Indeterminate' : "determinate"} {...props} sx={{
                        height: 5,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#B02464', // Customize the progress bar color here
                        },
                    }} />
                </Box>
                <Box mt={0.5} sx={{ minWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="overline" color="#fff">£0</Typography>
                    <Typography variant="overline" color="#fff">£{Number(props?.upperLabel).toLocaleString('en-us')}</Typography>
                </Box>
            </Box>
        );
    }
    const getAuctionTimeLeft = (startingValue, endValue) => {
        const startDate = new Date(startingValue).getTime();
        const endDate = new Date(endValue).getTime();
        const currentDate = new Date().getTime();
        const totalDuration = endDate - startDate;
        const elapsedDuration = currentDate - startDate;
        let text;
        const endDateDifference = new Date(endValue);
        const currentDateDifference = new Date();
        const differenceInMilliseconds = endDateDifference - currentDateDifference;
        const differenceInSeconds = differenceInMilliseconds / 1000;
        const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
        const hours = Math.floor((differenceInSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
        const seconds = Math.floor(differenceInSeconds % 60);
        if (days > 0) {
            text = `${days} days`
        } else if (days == 0 && hours > 0 && minutes > 0) {
            text = `${hours}:${minutes} hrs`
        } else if (days < 0) {
            text = 'Closed'
        }
        const elapsedPercentage = (elapsedDuration / totalDuration) * 100;
        console.log(days, hours, minutes, seconds, elapsedPercentage, 'DHMS')
        return { 'elapsedPercentage': elapsedPercentage, 'text': text }
    }
    return (
        <>
            <AppBar position="static" sx={{ mb: 3, background: 'transparent', borderRadius: '8px' }}>
                <Toolbar variant="dense" style={toolbarStyle}>
                    {
                        data?.map((details, index) => {
                            return (
                                <div style={{ borderRight: (index !== data.length - 1 && !isMobile) ? '1px solid #fff' : 'none', padding: '5px', paddingLeft: '20px', paddingRight: index == data?.length - 1 ? '0px' : '20px', display: 'flex', justifyContent: !isMobile ? 'flex-start' : 'space-between', alignItems: "center" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant='overline' component={'overline'} textTransform={'none'} sx={{ textWrap: 'nowrap' }}>{details?.heading}</Typography>
                                        {
                                            !details?.type &&
                                            <Typography variant='h6' sx={{ letterSpacing: '1.5px', fontSize: '13px' }} textTransform={'none'} component={'h5'} color={'#fff'} fontWeight={600} letterSpacing={'0.75px'} mt={1}>{details?.data}</Typography>
                                        }
                                        {
                                            details?.type === 'linearProgress' && <LinearProgressWithLabel value={details?.value} data={details.data} upperLabel={details?.upperLabel} />
                                        }
                                        {
                                            details?.type == 'creditRating' && <StatusText text={details?.data} />
                                        }
                                        {
                                            details?.subHeading &&
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                                                {
                                                    details?.subHeading &&
                                                    <Typography variant='overline' component={'overline'} textTransform={'none'} sx={{ textWrap: 'nowrap' }} fontSize={'11px'}>{details?.subHeading}</Typography>
                                                }
                                                {details?.subData &&
                                                    <Typography variant='h6' sx={{ letterSpacing: '1.5px' }} textTransform={'none'} component={'h5'} color={'#fff'} fontWeight={600} letterSpacing={'0.75px'} ml={1}>{details?.subData}</Typography>

                                                }
                                            </div>
                                        }
                                    </div>
                                    {
                                        details?.type === 'circularProgress' &&
                                        <div style={{ background: '#fff', padding: '5px', borderRadius: "50%", width: "80px" }}>
                                            <CircularProgressbar className={getAuctionTimeLeft(details?.startData, details?.endData).elapsedPercentage > 100 ? 'closed' : ''} value={getAuctionTimeLeft(details?.startData, details?.endData).elapsedPercentage} text={getAuctionTimeLeft(details?.startData, details?.endData).text} strokeWidth={10} />
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </Toolbar>
            </AppBar>
        </>
    )
}

export default UpperLayout
