import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    companyType: null,
    companyTypeStatus:'',
    companyTypeLoader:false,
    companyTypeError:null
};
export const CompanyType = createAsyncThunk("/CompanyType", async () => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: "api/getCompanyType",
        method: "get",
    });
    return response;
});
const companyTypeSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(CompanyType.pending, (state) => {
                state.companyTypeStatus = 'LOADING'
                state.companyTypeLoader = true
                state.companyTypeError = null
            })
            .addCase(CompanyType.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.companyTypeStatus = "SUCCESS"
                    state.companyType = action?.payload?.data
                }
                else {
                    state.companyTypeStatus = "ERROR"
                    state.companyTypeError = [action?.payload?.errors?.[0]]
                }
                state.companyTypeLoader = false
            })
            .addCase(CompanyType.rejected, (state, action) => {
                state.companyTypeStatus = 'ERROR'
                state.companyTypeLoader = false
            })
    }
})

export const { resetState } = companyTypeSlice.actions;
export default companyTypeSlice.reducer;