import { Button, Card, Checkbox, List, ListItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteNotification, ReadNotification } from '../../Redux/Slices/UpdateNotificationSlice'
import { GetNotificationDetails } from '../../Redux/Slices/GetNotificationSlice'
import Loader from '../../components/Loader'
import open from '../../assets/img/open-mail.png'
import trash from '../../assets/img/recycle-bin.png'

function NotificationList() {
    const { getNotificationDetails, getNotificationDetailsStatus, getNotificationDetailsLoader, getNotificationDetailsError, totalRead, totalRecord } = useSelector((state) => state.notification)
    const { notificationUpdate, notificationUpdateLoader, notificationUpdateStatus, notificationUpdateError } = useSelector((state) => state.updateNotification)
    const [notificationSelectedList, setNotificationSelectedList] = useState([])
    const dispatch = useDispatch()
    const handleSelectedNotification = (notificationId) => {
        let tempArray = [...notificationSelectedList]
        if (!notificationSelectedList.includes(JSON.stringify(notificationId))) {
            tempArray?.push(JSON.stringify(notificationId))
            setNotificationSelectedList([...tempArray])
        } else {
            tempArray.splice(tempArray.indexOf(JSON.stringify(notificationId)), 1);
            setNotificationSelectedList([...tempArray])
        }
    }
    useEffect(() => {
        if (notificationUpdateStatus) {
            dispatch(GetNotificationDetails())
            setNotificationSelectedList([])
        }
    }, [notificationUpdateLoader, notificationUpdateStatus])

    console.log(notificationSelectedList, 'notificationSelectedList')
    return (
        (notificationUpdateLoader || getNotificationDetailsLoader) ? <Loader /> : <Card>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {notificationSelectedList?.length > 0 && <img src={trash} width={'24px'} style={{ margin: "10px", cursor: 'pointer' }} onClick={() => dispatch(DeleteNotification(notificationSelectedList))} />}
            </div>
            <List>
                {
                    getNotificationDetails?.map((x, index) => {
                        return (
                            <ListItem sx={{ justifyContent: 'space-between', cursor: 'pointer', borderBottom: (index == getNotificationDetails?.length - 1) ? '' : '1px solid grey' }} onClick={console.log(x?.id)}>
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <Checkbox sx={{ paddingLeft: '0px' }} checked={notificationSelectedList?.includes(JSON.stringify(x?.id))} onChange={() => handleSelectedNotification(x?.id)} size='small' />
                                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                                        <Typography variant='overline' component={'p'} color={'grey'} fontSize={'13px'} textTransform={'none'} textAlign={'left'}>{x.title}</Typography>
                                        <Typography variant='p' component={'p'} textAlign={'left'} color={'#000'} textTransform={'none'} fontWeight={!x?.read_at && 600}>{x.message}</Typography>
                                    </div>
                                </div>
                                {
                                    !x?.read_at && <button onClick={() => dispatch(ReadNotification(JSON.stringify(x?.id)))} style={{ border: 'none', background: 'transparent', padding: '0px', cursor: 'pointer' }}><img src={open} width={'20px'} /></button>
                                }
                            </ListItem>
                        )
                    })
                }
                {
                    getNotificationDetails?.length == 0 && <Typography variant='p' component={'p'} textAlign={'center'} color={'#000'} textTransform={'none'} fontWeight={500}>No notifications available</Typography>
                }
            </List>
        </Card >
    )
}

export default NotificationList