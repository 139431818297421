import { Box, Card, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GlobalTable from '../../components/GlobalTable'
import InputFieldWithButton from '../../components/InputFieldWithButton'
import AddTransactionModal from './AddTransactionModal'
import WithdrawTransactionModal from './WithdrawTransactionModal'
import { useDispatch, useSelector } from 'react-redux'
import { WalletBalance, resetStateWalletBalance } from '../../Redux/Slices/GetWalletBalanceSlice'
import { WalletHistory, editWalletHistory, resetStateWalletHistory } from '../../Redux/Slices/GetWalletHistorySlice'
import { TransactionHistory, editTransactionHistory, resetStateTransactionHistory } from '../../Redux/Slices/GetTransactionHistorySlice'
import Loader from '../../components/Loader'
import moment from 'moment'
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function Wallet() {
    const dispatch = useDispatch()
    const [addValue, setAddValue] = useState()
    const [withdrawValue, setWithdrawValue] = useState()
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedDateRange, setSelectedDateRange] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [selectedType, setSelectedType] = useState('all')
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const { walletBalance } = useSelector((state) => state.getWalletBalance)
    const { walletHistory, walletHistoryInitial, walletHistoryStatus, walletHistoryLoader, walletHistoryError } = useSelector((state) => state.walletHistory)
    const { transactionHistory, transactionHistoryInitial, transactionHistoryStatus, transactionHistoryLoader, transactionHistoryError } = useSelector((state) => state.transactionHistory)
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        dispatch(WalletBalance())
        dispatch(WalletHistory())
        dispatch(TransactionHistory())
    }, [])
    useEffect(() => {
        return () => {
            dispatch(resetStateWalletBalance())
            dispatch(resetStateWalletHistory())
            dispatch(resetStateTransactionHistory())
        }
    }, [dispatch])
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const gettingAppropriateDate = (date) => {
        // Extract day, month, and year components
        var parts = date?.split('-');
        var day = parseInt(parts[0]);
        var month = parseInt(parts[1]) - 1; // Month is zero-based in JavaScript
        var year = parseInt(parts[2]);

        // Create a Date object
        return (new Date(year, month, day));

    }
    useEffect(() => {
        if (value == 0) {
            if (selectedType == 'all') {
                dispatch(WalletHistory())
            } else if (selectedType == 'today') {
                dispatch(editWalletHistory(walletHistoryInitial.filter(x => (new Date(x?.created_at).getDate() == new Date(selectedDate).getDate() && new Date(x?.created_at).getMonth() == new Date(selectedDate).getMonth() && new Date(x?.created_at).getFullYear() == new Date(selectedDate).getFullYear()))))
            } else if (selectedType == 'weekly' || selectedType == 'monthly' || selectedType == "custom") {
                if (selectedDateRange?.startDate && selectedDateRange?.endDate) {
                    console.log('dateType', selectedType, selectedDateRange)
                    dispatch(editWalletHistory(walletHistoryInitial.filter(x => {
                        const createdDate = new Date(x.created_at);
                        const startDate = new Date(selectedDateRange?.startDate);
                        const endDate = new Date(selectedDateRange?.endDate);

                        console.log('dateType', selectedType, createdDate, startDate, endDate)
                        // Set the time component to midnight for comparison
                        createdDate.setHours(0, 0, 0, 0);
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setHours(0, 0, 0, 0);

                        return createdDate >= startDate && createdDate <= endDate;
                    })))
                }
            }
        } else if (value == 1) {
            if (selectedType == 'all') {
                dispatch(TransactionHistory())
            } else if (selectedType == 'today') {
                dispatch(editTransactionHistory(transactionHistoryInitial.filter(x => (gettingAppropriateDate(x?.transaction_time).getDate() == new Date(selectedDate).getDate() && gettingAppropriateDate(x?.transaction_time).getMonth() == new Date(selectedDate).getMonth() && gettingAppropriateDate(x?.transaction_time).getFullYear() == new Date(selectedDate).getFullYear()))))
            } else if (selectedType == 'weekly' || selectedType == 'monthly' || selectedType == "custom") {
                if (selectedDateRange?.startDate && selectedDateRange?.endDate) {
                    dispatch(editTransactionHistory(transactionHistoryInitial.filter(x => gettingAppropriateDate(x?.transaction_time) >= new Date(selectedDateRange?.startDate) && gettingAppropriateDate(x?.transaction_time) <= new Date(selectedDateRange?.endDate))))
                }
            }
        }
    }, [selectedType, selectedDateRange])
    useEffect(() => {
        setSelectedDateRange(null)
        setSelectedDate(null)
        setSelectedType('all')
    }, [value])
    return (
        walletBalance ? <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                    Wallet
                </Typography>
            </Box>
            <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ background: 'transparent linear-gradient(106deg, #ED4886 0%, #B855A4 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <CardContent>
                            <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                                Current balance
                            </Typography>
                            <Typography variant="h4" color="#fff" mt={3} fontWeight={700} sx={{ letterSpacing: '1px' }}>
                                £ {walletBalance?.last_balance_amount ? Number(walletBalance?.last_balance_amount).toLocaleString("en-us", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ background: 'transparent linear-gradient(106deg, #875EC0 0%, #5446BA 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <CardContent>
                            <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                                Request withdrawal of funds
                            </Typography>
                            <InputFieldWithButton totalBalance={walletBalance?.last_balance_amount} bgColor={'#3A0CA3'} setOpenModal={setOpenWithdrawModal} setWithdrawValue={setWithdrawValue} modalType={'withdraw'} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ background: 'transparent linear-gradient(106deg, #46C5F3 0%, #6592DA 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                        <CardContent>
                            <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none', lineHeight: 'inherit' }}>
                                Add funds
                            </Typography>
                            <InputFieldWithButton totalBalance={walletBalance?.last_balance_amount} setOpenModal={setOpenAddModal} setAddValue={setAddValue} value={addValue} bgColor={'#44CCFE'} modalType={'add'} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
                '& .MuiTabs-indicator': {
                    display: 'none', // Remove the indicator from the selected tab
                },
            }}>
                <Tab label={`Wallet history (${walletHistory?.length})`} {...a11yProps(0)} sx={{
                    borderRadius: '8px 8px 0px 0px',
                    backgroundColor: '#FBFBFB',
                    letterSpacing: 0.8,
                    color: '#000',
                    fontWeight: '600',
                    fontSize: '15px',
                    '&.Mui-selected': {
                        backgroundColor: '#B92C82', // Remove the underline of the selected tab
                        color: '#fff', // Customize the text color of the selected tab
                        fontWeight: '500',
                        letterSpacing: 0.4
                    }
                }} />
                <Tab label={`Transaction history (${transactionHistory?.length})`} {...a11yProps(1)} sx={{
                    borderRadius: '8px 8px 0px 0px',
                    backgroundColor: '#FBFBFB',
                    letterSpacing: 0.8,
                    color: '#000',
                    fontWeight: '600',
                    fontSize: '15px',
                    '&.Mui-selected': {
                        backgroundColor: '#B92C82', // Remove the underline of the selected tab
                        color: '#fff', // Customize the text color of the selected tab
                        fontWeight: '500',
                        letterSpacing: 0.4
                    }
                }} />
            </Tabs>
            <CustomTabPanel value={value} index={0} >
                <GlobalTable
                    walletUpperBoxTitle={'Statement of ledger'}
                    tableName={'wallet history'}
                    walletUpperBox={true}
                    count={walletHistory?.length}
                    rows={walletHistory}
                    selectedDateWallet={setSelectedDate}
                    dateRange={selectedDateRange}
                    selectedDateRange={setSelectedDateRange}
                    selectedType={setSelectedType}
                    type={selectedType}
                    headCells={[
                        {
                            align: "left",
                            label: "Description",
                            type: "Description & Date",
                            fieldName: "transaction_desc",
                            subFieldName: "created_at",
                            color: "#3a0ca3"
                        },
                        {
                            align: "left",
                            label: "Credit",
                            type: "credit",
                            fieldName: "transaction_amount",
                            subFieldName: "status",
                            color: 'danger'
                        },
                        {
                            align: "left",
                            label: "Debit",
                            type: "debit",
                            fieldName: "transaction_amount",
                            subFieldName: "status",
                            color: 'danger'
                        },
                        {
                            align: "left",
                            label: "Balance",
                            fieldName: "balance_amount",
                            showValue: (val) => {
                                return `£ ${Number(val).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            },
                        }
                    ]}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} >
                <GlobalTable
                    walletUpperBoxTitle={'Statement of transactions'}
                    walletUpperBox={true}
                    count={transactionHistory?.length}
                    rows={transactionHistory}
                    selectedDateWallet={setSelectedDate}
                    selectedDateRange={setSelectedDateRange}
                    selectedType={setSelectedType}
                    type={selectedType}
                    headCells={[
                        {
                            align: "left",
                            label: "Date",
                            fieldName: "transaction_time",
                            showValue: (val) => {
                                return moment(val,'DD-MM-YYYY').format('DD/MM/YYYY')
                            },
                        },
                        {
                            align: "left",
                            label: "Amount",
                            fieldName: "total_amount",
                            color: "#3a0ca3",
                            showValue: (val) => {
                                return `£ ${Number(val).toLocaleString("en-us", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                            },
                        },
                        {
                            align: "left",
                            label: "Type",
                            fieldName: "transaction_type",
                            showValue: (val) => {
                                return val?.transaction_type
                            },
                        },
                        {
                            label: "Status",
                            type: "walletStatus",
                            fieldName: "status_name",
                        }
                    ]}
                />
            </CustomTabPanel>
            <AddTransactionModal open={openAddModal} money={addValue} handleClose={() => setOpenAddModal(false)} setAddValue={setAddValue} />
            <WithdrawTransactionModal open={openWithdrawModal} money={withdrawValue} handleClose={() => setOpenWithdrawModal(false)} setWithdrawValue={setWithdrawValue} />
        </> : <Loader />
    )
}

export default Wallet