import { Typography } from '@mui/material'
import React from 'react'

function ErrorMessageComp({ message, width, textAlign,color }) {
    return (
        <Typography fontSize={'13px'} textAlign={textAlign || 'inherit'} variant="p" component={'span'} color={color || 'red'} mt={1} width={{ lg: width || '84%', md: '400px', xs: '100%' }} sx={{ ml: 'auto' }} textTransform={'none'}>
            {message}
        </Typography>
    )
}

export default ErrorMessageComp