import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    sourceOfFund: null,
    sourceOfFundStatus:'',
    sourceOfFundLoader:false,
    sourceOfFundError:null
};
export const SourceOfFund = createAsyncThunk("/SourceOfFund", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getSourceOfFund`,
        method: "get",
    });
    return response;
});
const sourceOfFundSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(SourceOfFund.pending, (state) => {
                state.sourceOfFundStatus = 'LOADING'
                state.sourceOfFundLoader = true
                state.sourceOfFundError = null
            })
            .addCase(SourceOfFund.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.sourceOfFundStatus = "SUCCESS"
                    state.sourceOfFund = action?.payload?.data
                }
                else {
                    state.sourceOfFundStatus = "ERROR"
                    state.sourceOfFundError = [action?.payload?.errors?.[0]]
                }
                state.sourceOfFundLoader = false
            })
            .addCase(SourceOfFund.rejected, (state, action) => {
                state.sourceOfFundStatus = 'ERROR'
                state.sourceOfFundLoader = false
            })
    }
})

export const { resetState } = sourceOfFundSlice.actions;
export default sourceOfFundSlice.reducer;