import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    companyDashboardDetails: null,
    companyDashboardDetailsStatus:'',
    companyDashboardDetailsLoader:false,
    companyDashboardDetailsError:null
};
export const getCompanyDashboardDetails = createAsyncThunk("/getCompanyDashboardDetails", async (id) => {
    const response = await ApihelperFunction({
        urlPath: `api/company/dashboard`,
        method: "get",
    });
    return response;
});
const getCompanyDashboardDetailsSlice = createSlice({
    name: 'FeeDetails',
    initialState,
    reducers: {
        resetCompanyDashboardDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyDashboardDetails.pending, (state) => {
                state.companyDashboardDetailsStatus = 'LOADING'
                state.companyDashboardDetailsLoader = true
                state.companyDashboardDetailsError = null
            })
            .addCase(getCompanyDashboardDetails.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.companyDashboardDetailsStatus = "SUCCESS"
                    state.companyDashboardDetails = action?.payload?.data
                }
                else {
                    state.companyDashboardDetailsStatus = "ERROR"
                    state.companyDashboardDetailsError = [action?.payload?.errors?.[0]]
                }
                state.companyDashboardDetailsLoader = false
            })
            .addCase(getCompanyDashboardDetails.rejected, (state, action) => {
                state.companyDashboardDetailsStatus = 'ERROR'
                state.companyDashboardDetailsLoader = false
            })
    }
})

export const { resetCompanyDashboardDetails } = getCompanyDashboardDetailsSlice.actions;
export default getCompanyDashboardDetailsSlice.reducer;