import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    notificationUpdate:'',
    notificationUpdateLoader:false,
    notificationUpdateStatus:null,
    notificationUpdateError:null
}

export const ReadNotification = createAsyncThunk("/ReadNotification", async (notoficationIDs) => {
    const response = await ApihelperFunction({
        urlPath: "api/notification/read",
        formData: {notification_id:notoficationIDs},
        method: "post",
    });
    return response;
});
export const DeleteNotification = createAsyncThunk("/DeleteNotification", async (notoficationIDs) => {
    const response = await ApihelperFunction({
        urlPath: "api/notification/clear",
        formData: {notification_id:notoficationIDs},
        method: "post",
    });
    return response;
});

const ReadNotificationSlice = createSlice({
    name: "ReadNotification",
    initialState,
    reducers: {
        resetStateReadNotification: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(ReadNotification.pending, (state) => {
                state.notificationUpdateLoader=true
            })
            .addCase(ReadNotification.fulfilled, (state, action) => {
                state.notificationUpdateLoader=false
                if (action?.payload?.status) {
                    state.notificationUpdateStatus='SUCCESS'
                }
                else {
                    state.notificationUpdateStatus='ERROR'
                }
            })
            .addCase(ReadNotification.rejected, (state, action) => {
                state.notificationUpdateLoader=false
                state.notificationUpdateStatus='ERROR'
            })
            .addCase(DeleteNotification.pending, (state) => {
                state.notificationUpdateLoader=true
            })
            .addCase(DeleteNotification.fulfilled, (state, action) => {
                state.notificationUpdateLoader=false
                if (action?.payload?.status) {
                    state.notificationUpdateStatus='SUCCESS'
                }
                else {
                    state.notificationUpdateStatus='ERROR'
                }
            })
            .addCase(DeleteNotification.rejected, (state, action) => {
                state.notificationUpdateLoader=false
                state.notificationUpdateStatus='ERROR'
            })
    }
})

export const { resetStateReadNotification } = ReadNotificationSlice.actions;
export default ReadNotificationSlice.reducer