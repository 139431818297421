import { CircularProgress, Container } from '@mui/material'
import React from 'react'

function Loader() {
  return (
    <Container sx={{minHeight:'80vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <CircularProgress/>
    </Container>
  )
}

export default Loader