import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/style/style.css'
import { BrowserRouter } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme';
import ToastWrapper from './components/ToastWrapper';
import store from './Redux/Store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const container = document.getElementById('root');
const root = createRoot(container);
export const persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <BrowserRouter basename=''>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ToastWrapper>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </ToastWrapper>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
