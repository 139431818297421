import React, { useEffect, useState } from 'react'
import { AppBar, IconButton, InputBase, Badge, Avatar, Box, Container, Menu, MenuItem, useMediaQuery, Typography, Tooltip, List, ListItem } from '@mui/material';
import userIcon from '../../assets/img/profile.jpg'
import { useTheme } from '@emotion/react';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Logout, resetStateLogout } from '../../Redux/Slices/LogoutSlice';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ModalComp from '../../components/ModalComp';
import logout from '../../assets/img/logout.png'
import { Profile } from '../../Redux/Slices/GetProfileSlice';
import { persistor } from '../..';
import { resetStateCreateAuction } from '../../Redux/Slices/CreateAuctionSlice';
import { GetNotificationDetails } from '../../Redux/Slices/GetNotificationSlice';
function Header({ page }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const { logoutStatus, message, logoutLoader, logoutError } = useSelector((state) => state.logout)
  const { getNotificationDetails, getNotificationDetailsStatus, getNotificationDetailsLoader, getNotificationDetailsError, totalRead, totalRecord } = useSelector((state) => state.notification)
  const userName = localStorage.getItem('userName')
  const userEmail = localStorage.getItem('userEmail')
  useEffect(() => {
    if (logoutError) {
      toast.error('' + logoutError, {
        style: { fontSize: "15px" }
      });
    } if (logoutStatus === "SUCCESS") {
      toast.success('' + message, {
        style: { fontSize: "15px" },
        onOpen: () => {
          dispatch(resetStateLogout())
          localStorage.clear()
          navigate('/login')
        }
      });
    }
  }, [logoutError, logoutStatus])
  const location = useLocation()
  useEffect(() => {
    dispatch(Profile())
    dispatch(GetNotificationDetails())
  }, [])
  return (
    <AppBar position="static" className='header_component' sx={{ width: { md: '100%', xs: '90%' } }}>
      <Container maxWidth={{ lg: 'false' }} sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'flex-end', margin: '0px', padding: '0px !important', boxShadow: page == 'edit' ? '0px 0px 7px #a09c9c' : '' }}>
        {
          !isMobile && <div style={{ display: 'flex' }}>
            {/* User Profile */}
            <div style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
              <img src={userIcon} alt='' width={'50px'} style={{ borderRadius: '50%', overflow: 'hidden' }} />
              <div style={{ marginLeft: '10px' }}>
                <h6 style={{ margin: '0px', fontWeight: 'normal', fontSize: '14px' }}>{userName}</h6>
                <Typography variant='overline' component='p' style={{ textTransform: 'none', color: 'grey', margin: '0px', lineHeight: 'inherit' }}>{userEmail}</Typography>
              </div>
            </div>
            {/* Notification Button */}
            <Tooltip title="Notifications">
              <IconButton size="large" color="inherit" onClick={() => setShowNotification(!showNotification)}>
                <Badge badgeContent={totalRecord} color="blue">
                  <NotificationsOutlinedIcon sx={{ color: '#0B2244' }} />
                </Badge>
              </IconButton>
            </Tooltip>
            {/* Dialogue box */}
            <Box className={`notification-dialogue-${showNotification ? 'show' : 'hide'}`}>
              <Typography variant='p' component={'p'} ml={1} mt={1}>{`${totalRead} read messages`}</Typography>
              <Typography variant='p' component={'p'} ml={1} mt={1}>{`${totalRecord} unread messages`}</Typography>
              <hr style={{marginBottom:'0px'}}/>
              {
                (totalRead == 0 && totalRecord == 0) ? <ListItem>
                  <Typography variant='p' component={'p'} textAlign={'right'}>No notifications available</Typography>
                </ListItem>:<ListItem style={{cursor:'pointer'}} onClick={()=>{navigate('/notificationList');setShowNotification(false)}}>
                  <Typography variant='p' component={'p'} textAlign={'right'}>See all notification</Typography>
                </ListItem>
              }
            </Box>
            {/* edit Button */}
            {
              location?.pathname == '/viewProfile' && <Tooltip title="Edit profile">
                <IconButton size="large" color="inherit" onClick={() => { navigate('/signUp2?edit'); localStorage.setItem('fromEdit', true) }}>
                  <EditNoteIcon sx={{ color: '#0B2244', fontSize: '35px' }} />
                </IconButton>
              </Tooltip>
            }
            {/* logout Button */}
            <Tooltip title="Logout">
              <IconButton size="large" color="inherit" onClick={() => setOpenModal(true)}>
                <img src={logout} width={'22px'} />
              </IconButton>
            </Tooltip>

          </div>
        }
        {
          isMobile && <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <img src={userIcon} alt='' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
            >
              <MenuItem>
                <IconButton size="large" color="inherit">
                  <Badge badgeContent={3} color="primary">
                    <Typography variant='h6' component='h6'>Notifications</Typography>
                  </Badge>
                </IconButton>
              </MenuItem>
              <MenuItem onClick={() => setOpenModal(true)}>
                <IconButton size="large" color="inherit">
                  <Typography variant='h6' component='h6'>Logout</Typography>
                </IconButton>
              </MenuItem>
            </Menu>
          </div>
        }
      </Container>
      <ModalComp loading={logoutLoader} open={openModal} message={'Are you sure you want to logout?'} ButtonText='Logout' handleClose={() => setOpenModal(false)} handleAction={() => { persistor.purge(); dispatch(Logout()); dispatch(resetStateCreateAuction()) }} />
    </AppBar>
  )
}

export default Header