import { Button, styled } from "@mui/material"


const LoginCustomButton = styled(Button)(({ theme ,width}) => (
    theme.unstable_sx({
        width: { lg: width||'85%', md: '400px', xs: '90%' },
        marginLeft:{md:'auto',xs:'auto'},
        marginRight:{md:'inherit',xs:'auto'},
        height: { md: '55px', xs: '45px' },
        bgcolor: ' var(--unnamed-color-b92c82)',
        boxShadow: '0px 3px 10px #0000000D',
        color: 'white',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: 'var(--unnamed-color-b92c82)',
            borderColor: 'var(--unnamed-color-3a0ca3)',
        },
        '&:active': {
            backgroundColor: 'var(--unnamed-color-3a0ca3)',
            borderColor: 'var(--unnamed-color-3a0ca3)',
        },
        '&:focus': {
            boxShadow: 'var(--unnamed-color-000000)',
        },
    })
))

export default LoginCustomButton