import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import EmailVerificationSLice from './Slices/EmailVerificationSLice';
import SetPasswordSLice from './Slices/SetPasswordSLice';
import CompanyRegistrationSlice from './Slices/CompanyRegistrationSlice';
import FunderRegistrationSlice from './Slices/FunderRegistrationSlice';
import LoginSlice from './Slices/LoginSlice';
import ForgotPasswordSlice from './Slices/ForgotPasswordSlice';
import CountrySlice from './Slices/CountrySlice';
import StateSlice from './Slices/StateSlice';
import CitySlice from './Slices/CitySlice';
import CompanyTypeSlice from './Slices/CompanyTypeSlice';
import CompanyCategorySlice from './Slices/CompanyCategorySlice';
import TurnOverSlice from './Slices/TurnOverSlice';
import EmploymentSlice from './Slices/EmploymentSlice';
import SourceOfFundSlice from './Slices/SourceOfFundSlice';
import LogoutSlice from './Slices/LogoutSlice';
import GetProfileSlice from './Slices/GetProfileSlice';
import EditCompanySlice from './Slices/EditCompanySlice';
import EditFunderSlice from './Slices/EditFunderSlice';
import GetPaymentModeSlice from './Slices/GetPaymentModeSlice';
import GetCompanyBankDetailsSlice from './Slices/GetCompanyBankDetailsSlice';
import GetFunderBankDetailsSlice from './Slices/GetFunderBankDetailsSlice';
import WithdrawFundRequestSlice from './Slices/WithdrawFundRequestSlice';
import GetWalletBalanceSlice from './Slices/GetWalletBalanceSlice';
import GetWalletHistorySlice from './Slices/GetWalletHistorySlice';
import GetTransactionHistorySlice from './Slices/GetTransactionHistorySlice';
import AddFundRequestSlice from './Slices/AddFundRequestSlice';
import AddTradingPartnerSlice from './Slices/AddTradingPartnerSlice';
import GetTradingPartnerListSlice from './Slices/GetTradingPartnerListSlice';
import GetTradingPartnerDetailsSlice from './Slices/GetTradingPartnerDetailsSlice';
import UpdateTradingPartnerChangeRequestSlice from './Slices/UpdateTradingPartnerChangeRequestSlice';
import GetAllMyClosedAuctionsSlice from './Slices/GetAllMyClosedAuctionsSlice';
import GetAllMyLiveAuctionsSlice from './Slices/GetAllMyLiveAuctionsSlice';
import GetAllAuctionLiveSlice from './Slices/GetAllAuctionLiveSlice';
import GetAllAuctionClosedSlice from './Slices/GetAllAuctionClosedSlice';
import GetWalletTextSlice from './Slices/GetWalletTextSlice';
import CheckUserStatusSlice from './Slices/CheckUserStatusSlice';
import CheckUserNameSlice from './Slices/CheckUserNameSlice';
import GetAuctionDetailsSlice from './Slices/GetAuctionDetailsSlice';
import GetAllMyAuctionSlice from './Slices/GetAllMyAuctionSlice';
import GetLeaderBoardDetailsSlice from './Slices/GetLeaderBoardDetailsSlice';
import CheckWithdrawBalanceValidationSlice from './Slices/CheckWithdrawBalanceValidationSlice';
import CreateAuctionSlice from './Slices/CreateAuctionSlice';
import GetFeeDetailsSlice from './Slices/GetFeeDetailsSlice';
import GetFundingPurposeSlice from './Slices/GetFundingPurposeSlice';
import UploadFileSlice from './Slices/UploadFileSlice';
import CheckCreditLimitSlice from './Slices/CheckCreditLimitSlice';
import GetBidderRankSlice from './Slices/GetBidderRankSlice';
import AddBidSlice from './Slices/AddBidSlice';
import GetMyBidDetails from './Slices/GetMyBidDetails';
import EditBidSlice from './Slices/EditBidSlice';
import ShowCompanyFundingDetailsSlice from './Slices/ShowCompanyFundingDetailsSlice';
import ShowCompanyMyFundingClosedSlice from './Slices/ShowCompanyMyFundingClosedSlice';
import ShowFunderInvestmentOpenSlice from './Slices/ShowFunderInvestmentOpenSlice';
import ShowFunderInvestmentClosedSlice from './Slices/ShowFunderInvestmentClosedSlice';
import GetDashboardSlice from './Slices/GetDashboardSlice';
import GetDashboardFunderDetailsSlice from './Slices/GetDashboardFunderDetailsSlice';
import GetFundingDetailsSlice from './Slices/GetFundingDetailsSlice';
import GetInvestmentDetailsSlice from './Slices/GetInvestmentDetailsSlice';
import GetNotificationSlice from './Slices/GetNotificationSlice';
import UpdateNotificationSlice from './Slices/UpdateNotificationSlice';

const reducers = combineReducers({
  emailVerify: EmailVerificationSLice,
  setPassword: SetPasswordSLice,
  companyRegister: CompanyRegistrationSlice,
  funderRegister: FunderRegistrationSlice,
  login: LoginSlice,
  forgotPassword: ForgotPasswordSlice,
  country: CountrySlice,
  state: StateSlice,
  city: CitySlice,
  companyType: CompanyTypeSlice,
  companyCategory: CompanyCategorySlice,
  turnover: TurnOverSlice,
  employment: EmploymentSlice,
  sourceOfFund: SourceOfFundSlice,
  logout: LogoutSlice,
  profile: GetProfileSlice,
  updateCompany: EditCompanySlice,
  updateFunder: EditFunderSlice,
  paymentMode: GetPaymentModeSlice,
  companyBankDetails: GetCompanyBankDetailsSlice,
  funderBankDetails: GetFunderBankDetailsSlice,
  addTransactionReq: AddFundRequestSlice,
  withdrawTransactionReq: WithdrawFundRequestSlice,
  getWalletBalance: GetWalletBalanceSlice,
  walletHistory: GetWalletHistorySlice,
  transactionHistory: GetTransactionHistorySlice,
  tradingPartnerList: GetTradingPartnerListSlice,
  tradingPartnerDetails: GetTradingPartnerDetailsSlice,
  addTradingPartner: AddTradingPartnerSlice,
  updateTradingPartner: UpdateTradingPartnerChangeRequestSlice,
  myClosedAuction: GetAllMyClosedAuctionsSlice,
  myLiveAuction: GetAllMyLiveAuctionsSlice,
  myAuction: GetAllMyAuctionSlice,
  marketLiveAuction: GetAllAuctionLiveSlice,
  marketClosedAuction: GetAllAuctionClosedSlice,
  walletText: GetWalletTextSlice,
  userStatus: CheckUserStatusSlice,
  userNameCheck: CheckUserNameSlice,
  getAuctionDetails: GetAuctionDetailsSlice,
  getLeaderBoardDetails: GetLeaderBoardDetailsSlice,
  withdrawBalanceValidation: CheckWithdrawBalanceValidationSlice,
  createAuction: CreateAuctionSlice,
  feeDetails: GetFeeDetailsSlice,
  fundingPurpose:GetFundingPurposeSlice,
  uploadFile:UploadFileSlice,
  checkUserCreditLimit:CheckCreditLimitSlice,
  getbidderRank:GetBidderRankSlice,
  createBid:AddBidSlice,
  editBid:EditBidSlice,
  bidDetails:GetMyBidDetails,
  myFunding:ShowCompanyFundingDetailsSlice,
  myFundingClosed:ShowCompanyMyFundingClosedSlice,
  myInvestment:ShowFunderInvestmentOpenSlice,
  myInvestmentClosed:ShowFunderInvestmentClosedSlice,
  fundingDetails:GetFundingDetailsSlice,
  investmentDetails:GetInvestmentDetailsSlice,
  companyDashboard:GetDashboardSlice,
  funderDashboard:GetDashboardFunderDetailsSlice,
  notification:GetNotificationSlice,
  updateNotification:UpdateNotificationSlice
})
const persistConfig = {
  key: 'counter',
  storage,
  whitelist: ['createAuction','uploadFile','checkUserCreditLimit']
};
const persistedReducer = persistReducer(persistConfig, reducers);

// export default store
export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});