import {
    Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel,
    FormGroup, Stack, styled, TextField,
    Typography
} from '@mui/material';

import { Controller, useForm } from 'react-hook-form'
import ErrorMessageComp from '../../components/ErrorMessageComp';


const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
        borderBottomColor: 'white !important'
    },
    "& .MuiInput-underline": {
        borderBottomColor: 'white !important'
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: 'white !important'
    },
    input: {
        color: 'white !important',
        opacity: '1 !importnat',
        "&::placeholder": {
            color: 'white !important',
            opacity: '1 !important',
            fontSize: '16px !important'
        },
    }
})

const SubscribeButton = styled(Button)(({ theme }) => (
    theme.unstable_sx({
        backgroundColor: 'var(--unnamed-color-3a0ca3)',
        position: 'relative',
        padding: { lg: '15px 30px', md: '15px 30px', xs: '10px 20px' },
        color: '#fff',
        borderRadius: '11px',
        mt: 5,
        ml: { md: 0, sm: 0, xs: 0 },
        '&:hover': {
            backgroundColor: 'var(--unnamed-color-3a0ca3)',
            borderColor: 'var(--unnamed-color-3a0ca3)',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'var(--unnamed-color-3a0ca3)',
            borderColor: 'var(--unnamed-color-3a0ca3)',
        },
        '&:focus': {
            boxShadow: 'var(--unnamed-color-3a0ca3)',
        },
    })
))

const CustomCheckBox = styled(Checkbox)({
    input: {
        opacity: '1 !important',
        width: '17.5px',
        left: '14px',
        top: '0.5px'
    }
})

const FormTextField = ({ formControlWidth, name, control, placeholder,
    pattern, required = true, fieldType = 'text' }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required, pattern }}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: formControlWidth, pt: 5 }} variant='standard'>
                    <CustomTextField placeholder={placeholder || name} variant='standard'
                        type={fieldType} onChange={onChange} value={value || ''} />
                </FormControl>
            )}
        />
    )
}

const ContainerWithForm = () => {

    const { handleSubmit, formState: { errors }, control } = useForm()
    const formControlWidth = '100%'

    const onSubmit = (data) => console.log(data);

    return (
        <Box sx={{ backgroundColor: 'var(--unnamed-color-b92c82)', py: { md: 15, xs: 5 } }}>
            <Container maxWidth={'xl'} sx={{ mb: 10 }} alignContent={'center'}>
                <Stack direction={{ md: 'row', xs: 'column' }} px={{ md: 8, xs: 2 }} mx={{ xl: 6, md: 0, xs: 1 }}>
                    <Stack sx={{ pt: 5 }} width={{ lg: '55%', md: '100%' }}>
                        <Box>
                            <Typography variant='body2' sx={{ color: '#fff' }} fontSize={'35px'} letterSpacing={'2px'}>
                                SUBSCRIBE TO OUR NEWSLETTER
                            </Typography>
                            <Typography textTransform={'none'} variant='h5' sx={{ color: '#fff', letterSpacing: '1px', fontSize: '25px' }} mt={{ lg: 10, md: 5, xs: 3 }}>
                                Enter your details and we’ll keep you updated with <br /> the latest news and developments
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider orientation='vertical' sx={{ background: 'white', width: 2, mt: { lg: 6, md: 5, xs: 3 }, mx: 5, opacity: 0.2 }} flexItem={true} />
                    <Stack pl={{ lg: 3.5, xs: 0 }} width={{ lg: '50%', sm: '70%', xs: '100%' }} direction={'column'} justifyContent={'start'} alignItems={'start'}>
                        <FormTextField formControlWidth={formControlWidth} name={'Firstname'} placeholder={'First name'} control={control} />
                        {errors.Firstname?.type === 'required' && <ErrorMessageComp width={'100%'} color={'#7c0e0e'} message={'FirstName is required'} />}
                        <FormTextField formControlWidth={formControlWidth} name={'Surname'} control={control} />
                        {errors.Surname?.type === 'required' && <ErrorMessageComp width={'100%'} color={'#7c0e0e'} message={'Surname is required'} />}
                        <FormTextField formControlWidth={formControlWidth} name={'CompanyName'} placeholder={'Company name'} control={control} />
                        {errors.CompanyName?.type === 'required' && <ErrorMessageComp width={'100%'} color={'#7c0e0e'} message={'Company name is required'} />}
                        <FormTextField formControlWidth={formControlWidth} name={'email'} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
                            placeholder={'E-mail Address'} fieldType={'email'} control={control} />
                        {errors.email?.type === 'required' && <ErrorMessageComp width={'100%'} color={'#7c0e0e'} message={'E-mail Address is required'} />}
                        {errors.email?.type === 'pattern' && <ErrorMessageComp width={'100%'} color={'#7c0e0e'} message={'Invalid E-mail Address'} />}
                        <FormGroup sx={{ mt: 2 }}>
                            <Controller
                                name='releasingCash'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={<input type='checkbox' width={'40px'} style={{border:'1px solid #000'}}/>}
                                        sx={{
                                            '& .MuiFormControlLabel-root': {
                                                alignItems: 'flex-start !important'
                                            },
                                            '& .MuiFormControlLabel-label': {
                                                color: '#fff',
                                                fontSize: '16px',
                                                mt:{lg:0,xs:'15px'}
                                            },

                                        }}
                                        label='My business is interested in releasing cash from invoices' />
                                )}
                            />
                            
                            <Controller
                                name='investingInvoices'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={<input type='checkbox' width={'40px'}/>}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                color: '#fff',
                                                fontSize: '16px',
                                                mt:{lg:0,xs:'15px'}
                                            },
                                            mt:{lg:0,xs:1}
                                        }}
                                        color='white'
                                        label="I’m interested in returns from investing in invoices" />
                                )}
                            />
                        </FormGroup>
                        <SubscribeButton size='medium' onClick={handleSubmit(onSubmit)} >
                            <Typography variant='caption' textTransform={'uppercase'} fontSize={'15px'}>
                                SUBSCRIBE NOW
                            </Typography>
                        </SubscribeButton>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

export default ContainerWithForm
