import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    withdrawBalance: '',
    withdrawBalanceStatus:null,
    withdrawBalanceLoader:false,
    withdrawBalanceError:null,
    withdrawBalanceMessage:''
};
export const WithdrawBalance = createAsyncThunk("/WithdrawBalance", async (data) => {
    const response = await ApihelperFunction({
        urlPath: `api/withdrawFundTransactionValidation`,
        method: "post",
        formData:{'total_amount':data}
    });
    return response;
});
const withdrawBalanceSlice = createSlice({
    name: 'WithdrawBalance',
    initialState,
    reducers: {
        resetWithdrawBalance: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(WithdrawBalance.pending, (state) => {
                state.withdrawBalanceLoader = true
                state.withdrawBalanceError = null
                state.withdrawBalanceStatus=null
            })
            .addCase(WithdrawBalance.fulfilled, (state, action) => {
                console.log(action?.payload?.status,'withdrawBalanceStatus')
                state.withdrawBalanceLoader = false
                state.withdrawBalanceStatus=action?.payload?.status
                state.withdrawBalanceMessage=action?.payload?.message
                state.withdrawBalanceError = action?.payload?.errors?.[0]
            })
            .addCase(WithdrawBalance.rejected, (state, action) => {
                state.withdrawBalanceStatus = 'ERROR'
                state.withdrawBalanceLoader = false
            })
    }
})

export const { resetWithdrawBalance } = withdrawBalanceSlice.actions;
export default withdrawBalanceSlice.reducer;