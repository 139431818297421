import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    walletHistoryInitial:[],
    walletHistory: [],
    walletHistoryStatus: '',
    walletHistoryLoader: false,
    walletHistoryError: null
};
export const WalletHistory = createAsyncThunk("/WalletHistory", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getWalletHistroy`,
        method: "get",
    });
    return response;
});
const walletHistorySlice = createSlice({
    name: 'WalletHistory',
    initialState,
    reducers: {
        resetStateWalletHistory: () => initialState,
        editWalletHistory: (state, action) => {
            state.walletHistory = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(WalletHistory.pending, (state) => {
                state.walletHistoryStatus = 'LOADING'
                state.walletHistoryLoader = true
                state.walletHistoryError = null
            })
            .addCase(WalletHistory.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.walletHistoryStatus = "SUCCESS"
                    state.walletHistoryInitial = action?.payload?.data
                    state.walletHistory = action?.payload?.data
                }
                else {
                    state.walletHistoryStatus = "ERROR"
                    state.walletHistoryError = [action?.payload?.errors?.[0]]
                }
                state.walletHistoryLoader = false
            })
            .addCase(WalletHistory.rejected, (state, action) => {
                state.walletHistoryStatus = 'ERROR'
                state.walletHistoryLoader = false
            })
    }
})

export const { resetStateWalletHistory, editWalletHistory } = walletHistorySlice.actions;
export default walletHistorySlice.reducer;