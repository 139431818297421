import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    profile: null,
    profileStatus: '',
    profileLoader: false,
    profileError: null
};
export const Profile = createAsyncThunk("/Profile", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getProfile`,
        method: "get",
    });
    return response;
});
const profileSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateGetProfile: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(Profile.pending, (state) => {
                state.profileStatus = 'LOADING'
                state.profileLoader = true
                state.profileError = null
            })
            .addCase(Profile.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.profileStatus = "SUCCESS"
                    state.profile = action?.payload?.data
                    localStorage.setItem('role', action?.payload?.data.user_type)
                    localStorage.setItem('editData', JSON.stringify(action?.payload?.data))
                    if (action?.payload?.data.previous_request.status_name == 'New') {
                        localStorage.setItem('prevReq', JSON.stringify('Pending'))
                    }else{
                        localStorage.setItem('prevReq', JSON.stringify(action?.payload?.data.previous_request.status_name))
                    }
                }
                else {
                    state.profileStatus = "ERROR"
                    state.profileError = [action?.payload?.errors?.[0]]
                }
                state.profileLoader = false
            })
            .addCase(Profile.rejected, (state, action) => {
                state.profileStatus = 'ERROR'
                state.profileLoader = false
            })
    }
})

export const { resetStateGetProfile } = profileSlice.actions;
export default profileSlice.reducer;