import {
    Box, FormControl, Container, TextField, Typography, Stack, CircularProgress,
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import FormContainer from "./FormContainer";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginCustomButton from "../../components/LoginCustomButton";
import { useDispatch, useSelector } from "react-redux";
import { VerifyEmail, VerifyOTP, resetState, resetStateEmailVerification } from "../../Redux/Slices/EmailVerificationSLice";
import { toast } from "react-toastify";
import { VerifyEmailForgotPassword, VerifyOTPForgotPassword, resetStateForgetPassword } from "../../Redux/Slices/ForgotPasswordSlice";


//main text field component
const FormTextField = ({ name, control, currentIdx, foucusChange, onKeyDown }) => {
    const currentIdxNumber = Number.parseInt(currentIdx.replace("f", ""))
    const [focusEnable, setFocusEnable] = useState(true)


    //next key
    const handleKeyUp = (e) => {
        const current = e.currentTarget;

        if (e.key === "ArrowLeft" || e.key === "Backspace") {
            if (currentIdxNumber !== 1 && focusEnable) {
                foucusChange(`f${currentIdxNumber - 1}`)
                return;
            }
            return;
        }

        if (e.key === "ArrowRight") {
            if (currentIdxNumber !== 6) {
                foucusChange(`f${currentIdxNumber + 1}`)
                return;
            }
        }
    };


    //focus
    const handleFoucus = () => {
        setFocusEnable(false)
        foucusChange(name)
        setFocusEnable(true)
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
                <TextField id={name}
                    onKeyDown={onKeyDown}
                    inputProps={{
                        style: { textAlign: "center" },
                        inputMode: 'numeric', pattern: '[0-9]*'
                    }}
                    inputRef={(input) => {
                        if (name === currentIdx) {
                            input?.focus()
                        }
                    }}
                    onFocus={handleFoucus}
                    onKeyUp={handleKeyUp}
                    onChange={(e) => {
                        if (/\d/.test(e.target.value)) {
                            onChange(e.target.value.slice(-1))
                            if (currentIdxNumber !== 6) {
                                foucusChange(`f${currentIdxNumber + 1}`)
                            }
                        } else {
                            onChange('')
                        }
                    }} value={value || ''} sx={{
                        mt: 5,
                        '& .MuiOutlinedInput-root': {
                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                            border: 'none',
                            px: 1,
                            py: 0,
                            fontSize: '15x',
                            color: '#6C6C6D',
                            fontWeight: '600',
                            textAlign: 'center',
                            borderRadius: '7px',
                            width: { lg: '100%', md: '61px', xs: '51px' },
                            height: { md: '55px', xs: '60px' },
                            boxShadow: '0px 3px 10px #0000000D',
                            opacity: 1,

                        }
                    }}
                />
            )}
        />
    )
}

const EmailVerification = () => {

    //values
    const { handleSubmit, control, formState: { errors }, getValues } = useForm()
    const [resendClick, setResetClick] = useState(false)
    const [currentIdx, setCurrentIdx] = useState('f1')
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const dataFromSliceA = useSelector(state => state.emailVerify);
    const dataFromSliceB = useSelector(state => state.forgotPassword);
    const { status, loading, message, error, token } = location.pathname == '/emailverification' ? dataFromSliceA : dataFromSliceB
    const dataFromSliceASendOTP = useSelector(state => state.emailVerify);
    const dataFromSliceBSendOTP = useSelector(state => state.forgotPassword);
    const SendOtpData = location.pathname == '/emailverification' ? dataFromSliceASendOTP : dataFromSliceBSendOTP
    console.log(SendOtpData, 'SendOtpData')
    //form submit
    const onSubmit = (data) => {
        if (location?.pathname == '/otpVerificaton') {
            dispatch(VerifyOTPForgotPassword({ otp: Object.values(data).join(""), token: localStorage.getItem('token') }))
        } else {
            dispatch(VerifyOTP({ otp: Object.values(data).join(""), email: localStorage.getItem('userEmail'), user_type: localStorage.getItem('role') }))
        }
    }

    //focus change
    const onFocusChange = (e) => {
        setCurrentIdx(e)
    }

    //firing toast and further navigation
    useEffect(() => {
        if (error) {
            toast.error('' + error, {
                style: { fontSize: "15px" }
            });
        } if (status === "SUCCESSOTPVERIFIED") {
            toast.success('' + message, {
                style: { fontSize: '15px' },
                onOpen: () => {
                    if (location?.pathname == '/otpVerificaton') {
                        navigate('/resetPassword', { state: token })
                        dispatch(resetStateEmailVerification())
                        dispatch(resetStateForgetPassword())
                    } else if (location?.pathname == '/emailverification') {
                        navigate('/addPassword')
                        dispatch(resetStateEmailVerification())
                        dispatch(resetStateForgetPassword())
                    }
                }
            });
        }
    }, [error, status]);
    useEffect(() => {
        if (resendClick) {
            if (SendOtpData.error) {
                toast.error('' + error, {
                    style: { fontSize: "15px" }
                });
            }
            if (SendOtpData.status === "SUCCESS") {
                setRemainingTime(35)
                toast.success('' + 'OTP has been emailed to you', {
                    style: { fontSize: '15px' },
                });
            }
        }
    }, [SendOtpData.status]);

    //timer code 
    const initialTime = 35; // Change this to the desired initial time
    const [remainingTime, setRemainingTime] = useState(initialTime);
    // Function to update and display the remaining time
    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setTimeout(() => {
                setRemainingTime(remainingTime - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [remainingTime]);


    //resend otp
    const sendOtp = () => {
        setResetClick(true)
        location.pathname == '/emailverification' ?
            dispatch(VerifyEmail({ user_type: localStorage.getItem('role'), email: localStorage.getItem('userEmail') })) :
            dispatch(VerifyEmailForgotPassword({ email: localStorage.getItem('userEmail') }))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const values = getValues()
            if (location?.pathname == '/otpVerificaton') {
                dispatch(VerifyOTPForgotPassword({ otp: Object.values(values).join(""), token: localStorage.getItem('token') }))
            } else {
                dispatch(VerifyOTP({ otp: Object.values(values).join(""), email: localStorage.getItem('userEmail'), user_type: localStorage.getItem('role') }))
            }
        }
    };
    return <FormContainer>
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column', alignItems: 'center',
                mt: { md: 0, sm: 0, xs: 5 },
                mb: { md: 10, sm: 0, xs: 5 }
            }}
            maxWidth={'md'}>
            <Box sx={{
                width: { lg: '457px', md: '400px', xs: '290px' },
                color: 'var(--unnamed-color-0b2244)'
            }}>
                <Typography variant="h2" fontWeight={700} align="center">
                    Email verification
                </Typography>
                <Typography variant="overline" fontWeight={600} color='#A5A5A5' align="center" sx={{ display: "block", marginTop: '15px', textTransform: 'none' }}>
                    Enter the OTP to verify your email address
                </Typography>
            </Box>

            <Stack direction={'row'} spacing={2} sx={{ mt: 2, width: { lg: '457px', md: '400px', xs: '290px' }, }}>
                {'1,2,3,4,5,6'.split(",").map(i => (
                    <FormControl key={i}
                        sx={{ mb: { md: 6, sm: 5, xs: 3 }, pl: { xs: 0.2 } }}>
                        <FormTextField name={`f${i}`} control={control} currentIdx={currentIdx} foucusChange={onFocusChange} />
                    </FormControl>))}
            </Stack>
            <FormControl sx={{ mt: 2, width: { lg: '457px', md: '400px', xs: '290px' } }}>
                <LoginCustomButton onClick={handleSubmit(onSubmit)} fullWidth width='100%'>
                    {
                        !loading && <Typography variant="h6" component={'h6'} sx={{ color: '#fff' }}>
                            VERIFY OTP
                        </Typography>
                    }
                    {
                        loading && <CircularProgress color="white" />
                    }
                </LoginCustomButton>
            </FormControl>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pt: 4 }}>
                <Typography fontSize={'14px'} sx={{ color: 'var(--unnamed-color-6c6c6d)' }}>
                    Didn't receive the code?
                </Typography>
                <Link to={''} style={{ color: 'var(--unnamed-color-3a0ca3)' }}>
                    <Typography ml={1} fontSize={'14px'} align="center" sx={{ textDecoration: 'underline', cursor: 'inherit' }}><button style={{ background: 'transparent', border: 'none', textDecoration: 'underline', padding: 0, color: remainingTime > 0 ? 'grey' : '#3a0ca3', cursor: remainingTime > 0 ? 'not-allowed' : 'pointer' }} onClick={() => remainingTime > 0 ? null : sendOtp()}>Resend Code</button>{remainingTime > 0 && `00:${remainingTime}`}</Typography>
                </Link>
            </Box>
        </Container>
    </FormContainer >
}

export default EmailVerification