import { Box, Container, Divider, IconButton, Stack, Typography } from "@mui/material"

import fbimg from '../assets/img/facebook.svg'
import linkdinimg from '../assets/img/linkedin-in.svg'
import instaimg from '../assets/img/instagram.svg'
import twitterimg from '../assets/img/twitter.svg'
import { Link, useNavigate } from "react-router-dom"

const Footer = () => {
    const rowFristFontSize = { fontSize: { md: '13px', xs: '13px' }, pb: { lg: 0, xs: 1 }, color: '#fff' }
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ backgroundColor: '#000' }}>
                <Container maxWidth={'xl'} sx={{ mb: 2 }}>
                    <Stack spacing={{ xs: 0, md: 10 }} sx={{ color: '#fff' }}
                        mx={{ xl: 13, md: 10, xs: 1 }}
                        justifyContent={'space-between'}
                        direction={{ xs: 'column', md: 'row' }} alignItems={'center'} pt={2}>
                        <Stack sx={{ display: 'flex', flexDirection: { sm: 'row', xs: 'column' } }}>
                            <Typography sx={rowFristFontSize} align="center">&#169; Funding Joint - Copyright 2023</Typography>
                            <Stack
                                spacing={{ md: 2, sm: 1, xs: 0.2 }}
                                justifyContent={'space-between'}
                                direction={{ xs: 'column', sm: 'row' }}
                                marginLeft={{ sm: '20px', xs: '0px' }}
                                divider={<Divider orientation="vertical"
                                    flexItem sx={{
                                        backgroundColor: '#fff',
                                        height: { xs: 0, sm: 25 }
                                    }} />}>
                                <Stack direction={'row'}
                                    spacing={{ md: 2, sm: 1, xs: 0.35 }}
                                    divider={<Divider orientation="vertical"
                                        flexItem sx={{
                                            backgroundColor: '#fff',
                                            height: { xs: 0, sm: 25 }
                                        }} />}>
                                    <Link to={'/disclaimer'} style={{ textDecoration: 'none', textAlign: 'center' }}>
                                        <Typography sx={rowFristFontSize}>
                                            Disclaimer
                                        </Typography>
                                    </Link>
                                    <Link to={'/termsOfService'} style={{ textDecoration: 'none', textAlign: 'center' }}>
                                        <Typography sx={rowFristFontSize}>
                                            Terms Of Service
                                        </Typography>
                                    </Link>
                                    <Link to={'/privacyPolicy'} style={{ textDecoration: 'none', textAlign: 'center' }}>
                                        <Typography sx={rowFristFontSize}>
                                            Privacy Policy
                                        </Typography>
                                    </Link>
                                    <Link to={'/contactUs'} style={{ textDecoration: 'none', textAlign: 'center' }}>
                                        <Typography sx={rowFristFontSize}>
                                            Contact Us
                                        </Typography></Link>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack justifyContent={'flex-end'} style={{ mt: { lg: 0, xs: 2 } }} direction={'row'} alignItems={'center'}>
                            <a href='https://www.facebook.com/' target="_blank" style={{ marginLeft: '15px' }}><img src={fbimg} alt='fbimg' width={'100%'} /></a>
                            <a href='https://twitter.com/i/flow/login' target="_blank" style={{ marginLeft: '15px' }}><img src={twitterimg} alt='twitterimg' width={'100%'} /></a>
                            <a href='https://www.instagram.com/' target="_blank" style={{ marginLeft: '15px' }}><img src={instaimg} alt='instaimg' /></a>
                            <a href='https://www.linkedin.com/' target="_blank" style={{ marginLeft: '15px' }}><img src={linkdinimg} alt='linkdinimg' width={'100%'} /></a>
                        </Stack>
                    </Stack>
                    <Divider
                        sx={{
                            height: 2,
                            backgroundColor: 'grey',
                            my: 2,
                            mx: { xl: 13, md: 10, xs: 1 }
                        }} />
                </Container>
                <Container>
                    <Box sx={{ color: '#fff', borderBottom: '1px sold white' }} pb={1}>
                        <Typography variant="h6" align="center" sx={rowFristFontSize} textTransform={'none'}>
                            In the event of non-payment, the borrower will be liable for any losses or costs incurred.
                        </Typography>
                        <Typography variant="h6" align="center" sx={rowFristFontSize} textTransform={'none'} mb={1}>
                            Investment opportunities offered may not be suitable for all investors and there is risk you many not get back your money.
                        </Typography>
                    </Box>
                </Container>
            </Box >
        </>
    )
}

export default Footer