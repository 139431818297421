import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"


const ListItemWithIconAndText = ({
    image, imagealt, textWrap, children, textSize,
    color = '#000',
    fontFamily = 'Roboto', iconSize = { xl: '33px', md: '25px', xs: '20px' } }) => {
    return (
        <ListItem sx={{ paddingLeft: '0px', mt: 2, mb: 2,alignItems:'flex-start' }} width={{lg:'65%',xs:'100%'}}>
            <ListItemIcon sx={{ mr: { md: '20px', xs: '10px' } }}>
                <Box sx={{ width: { xl: '33px', md: '30px', xs: '28px' } }}>
                    <img src={image} alt={imagealt} width={'100%'} height={'auto'} />
                </Box>
            </ListItemIcon>
            <ListItemText sx={{ color: 'var(--unnamed-color-000000)',margin:'0px' }}>
                {textWrap
                    ? <Typography variant='h6' m={0} textTransform={'none'} fontSize={textSize || '17px'} sx={{ ...textWrap, ...color }} fontFamily={fontFamily}>
                        {children}
                    </Typography>
                    : <Typography variant='h6' m={0} textTransform={'none'} fontSize={textSize || '17px'} fontFamily={fontFamily} sx={{ ...color }}>
                        {children}
                    </Typography>}
            </ListItemText>
        </ListItem>
    )
}

export default ListItemWithIconAndText
