import {
    Box, FormControl, Container, Typography, CircularProgress,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginCustomButton from "../../components/LoginCustomButton";
import FormContainer from "./FormContainer";
import InputComp from "../../components/InputComp";
import ErrorMessageComp from "../../components/ErrorMessageComp";
import { useDispatch, useSelector } from "react-redux";
import { Loginauth, resetStateLogin } from "../../Redux/Slices/LoginSlice";
import { toast } from "react-toastify";




const Login = () => {

    //values
    const { handleSubmit, control, formState: { errors }, getValues } = useForm()
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { loading, error, status, message, role, userStatus, company_details_stat, isRegistrationDone, company_account_details, stage } = useSelector(state => state.login)
    console.log(userStatus, company_details_stat, company_account_details, stage, 'userStatus')
    //firing toast and further navigation

    useEffect(() => {
        if (error) {
            toast.error('' + error, {
                style: { fontSize: "15px" }
            });
        } if (status === "SUCCESS") {
            toast.success('' + message, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    if (isRegistrationDone) {
                        navigate('/dashboard')
                    } else {
                        if (userStatus == 0) {
                            if (!company_details_stat) {
                                navigate('/signUp2')
                            } else {
                                navigate('/signUp5')
                            }
                        }
                    }
                    dispatch(resetStateLogin())
                }
            });
        }
    }, [error, status]);

    //form submit
    const onSubmit = (data) => {
        dispatch(Loginauth(data))
    }

    //enter key form submit
    const handleKeyDown = (e) => {
        console.log(e.target.name, e.target.value, 'event')
        if (e.key === 'Enter') {
            const values = getValues()
            dispatch(Loginauth(values))
        }
    };

    return <FormContainer>
        <Container
            sx={{
                display: 'flex', flexDirection:
                    'column', alignItems: 'center',
                mt: { md: 0, sm: 0, xs: 3 },
                mb: { md: 0, sm: 0, xs: 3 }
            }}
        >
            <Box sx={{
                width: { lg: '457px', md: '400px', xs: '290px' },
                color: 'var(--unnamed-color-0b2244)'
            }}>
                <Typography variant="h2" fontWeight={700} align="center">
                    Sign in
                </Typography>
            </Box>
            <FormControl sx={{ width: { lg: '457px', md: '400px', xs: '290px' }, mt: { md: 3, xs: 1 } }}>
                <InputComp fieldType={'email'} maxLength={40} name={'username'} placeholder={'Email *'} control={control}
                    pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} startIcon={true} startImg={'email.png'} required={true} />
                {errors.username?.type === 'required' &&
                    <ErrorMessageComp message={'Email is required'} />
                }
                {errors.username?.type === 'pattern' &&
                    <ErrorMessageComp message={'Email is invalid'} />
                }
                {errors.username?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
                }
                <InputComp onKeyDown={handleKeyDown} name={'password'}
                    placeholder={'Password *'} control={control}
                    fieldType={'password'} startIcon={true} startImg={'lock.png'} endIcon={true} endImg={'eye.png'} alternativeEndImg={'hide.png'} show={show} setShow={setShow} required={true} />
                {errors.password?.type === 'required' && <ErrorMessageComp message={'Password is required'} />}
            </FormControl>
            <Box sx={{ width: { lg: '457px', md: '400px', xs: '290px' }, pb: 2, pt: 1 }}>
                <Link to={'/forgotpassword'} style={{ color: 'var(--unnamed-color-3a0ca3)' }}>
                    <Typography align="right" mt={2} mb={2} fontSize={'13px'}
                        justifyContent={'center'} sx={{ textDecoration: 'underline' }}>Forgot Password?
                    </Typography>
                </Link>
            </Box>
            <FormControl sx={{ mt: 0, width: { lg: '457px', md: '400px', xs: '290px' } }}>
                <LoginCustomButton onClick={handleSubmit(onSubmit)} fullWidth >
                    {
                        !loading ? <Typography variant="h6" component={'h6'} sx={{ color: '#fff' }}>
                            SIGN IN
                        </Typography> : <CircularProgress color="white" />
                    }
                </LoginCustomButton>
            </FormControl>
            <Box sx={{ width: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'center', pt: 4, ml: { lg: '50px', md: '0px' } }}>
                <Typography fontSize={'13px'} sx={{ color: 'var(--unnamed-color-6c6c6d)' }}>
                    Don't have account?
                </Typography>
                <Link to='/signUp1' style={{ color: 'var(--unnamed-color-3a0ca3)' }}>
                    <Typography ml={1} fontSize={'13px'} sx={{ textDecoration: 'underline' }}>Sign Up</Typography>
                </Link>
            </Box>
        </Container>
    </FormContainer>
}

export default Login