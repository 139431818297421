import {
    Box, Container, Stack, Typography,
} from "@mui/material"

import LoginBg from '../../assets/img/login_bg.jpg'
import LoginFormBg from '../../assets/img/login_form_bg.png'

const FormContainer = ({ children }) => {

    return (
        <Stack
            direction={{ md: 'row', sm: 'row', xs: 'column' }}>
            <Box sx={{
                width: '50%', backgroundImage: `url(${LoginBg})`,
                backgroundSize: 'cover',
                height: { xl: '85vh', lg: 'auto' },
                display: { sm: 'block', xs: 'none' }
            }}>
                <Container sx={{
                    display: 'flex', flexDirection: 'column', alignItems:'center',justifyContent:'center',height:'100%',
                    color: 'var(--unnamed-color-f9fafc)',
                    p:{lg:'80px !important',sm:'24px !important'}
                }}
                    maxWidth={'sm'} >
                    <Typography variant="h1" component={'h1'} align="center" textAlign={'start'} textTransform={'none'}>
                        Release cash from your trade invoices and fund your business growth
                    </Typography>
                </Container>
            </Box>
            <Stack justifyContent={'center'}
                sx={{
                    paddingTop:'30px',
                    paddingBottom:'30px',
                    backgroundImage: `url(${LoginFormBg})`,
                    backgroundSize: 'contain',
                    width: { md: '50%', sm: '50%', xs: '100%' },
                    minHeight:'70vh'
                }}>
               { children }
            </Stack>
        </Stack>
    )
}

export default FormContainer
