import { Box, Card, CardContent, Container, Grid, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StatusText from '../../components/StatusText';
import GlobalTable from '../../components/GlobalTable';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetFundingDetails } from '../../Redux/Slices/GetFundingDetailsSlice';
import moment from 'moment';
import { GetInvestmentDetails } from '../../Redux/Slices/GetInvestmentDetailsSlice';
import Loader from '../../components/Loader';

function LoanDetails() {
    const location = useLocation()
    const dispatch = useDispatch()
    const auctionID = location?.search?.slice(1, location?.search?.length)
    const role = localStorage.getItem('role')
    const auctionType = location?.state
    const getStatus = (status) => {
        console.log(status, 'STATUSSSSSSSSSS')
        if (status == '0') {
            return 'Pending'
        } else if (status == 4) {
            return 'Before done'
        }
    }
    const { fundingDetails } = useSelector((state) => state.fundingDetails)
    const { investmentDetails } = useSelector((state) => state.investmentDetails)
    useEffect(() => {
        role == 'Company' ? dispatch(GetFundingDetails({ auctionID: auctionID, auctionType: auctionType })) : dispatch(GetInvestmentDetails({ biddingID: auctionID, auctionType: auctionType }))
    }, [])
    let leaderBoardData = []
    let FinalleaderBoardData = []
    let unFullfilledLeaderBoardData = []
    let FinalunFullfilledLeaderBoardData = []
    let sum = 0
    for (let i = 0; i < fundingDetails?.company_bidding_by_rank?.length; i++) {
        let x = fundingDetails?.company_bidding_by_rank[i]
        if (x?.is_split == 0) {
            sum += Number(fundingDetails?.company_bidding_by_rank[i]?.offer_amount)
        } else {
            sum += Number(fundingDetails?.company_bidding_by_rank[i]?.funding_share_amount)
        }
        if (Number(fundingDetails?.auction_amount) >= sum) {
            if (x?.is_split == 1) {
                unFullfilledLeaderBoardData?.push(x)
            }
            leaderBoardData?.push(x)
        } else {
            unFullfilledLeaderBoardData?.push(x)
        }
    }
    FinalleaderBoardData = leaderBoardData?.map((x) => {
        return (
            {
                rank: x?.rank_leader_board,
                name: x?.users?.first_name,
                userId: x?.user_id,
                bidAmount: x?.is_split == 0 ? x?.offer_amount : x?.funding_share_amount,
                fundingShare: x?.is_split == 0 ? x?.return_amount > 0 ? x?.return_share_percentage : x?.funding_share_percentage : x?.funding_share_percentage,
                roi: Math.round(Number(x?.funding_roi_amount)),
                roi_percent: x?.offer_roi_percentage,
                isSplit: x?.is_split,
                status: x?.status
            }
        )
    })
    FinalunFullfilledLeaderBoardData = unFullfilledLeaderBoardData?.map((x) => {
        return (
            {
                rank: x?.rank_leader_board,
                userId: x?.user_id,
                bidAmount: x?.is_split == 0 ? x?.offer_amount : x?.return_amount,
                fundingShare: x?.is_split == 0 ? x?.return_amount > 0 ? x?.return_share_percentage : x?.funding_share_percentage : Math.round(Number(x?.return_share_percentage)),
                roi: x?.is_split == 0 ? Math.round(Number(x?.offer_roi_amount)) : Math.round(Number(x?.return_roi_amount)),
                roi_percent: x?.offer_roi_percentage,
                isSplit: x?.is_split,
                isFullfilled: 0
            }
        )
    })
    let repaymentDetails = []
    for (let i = 0; i < investmentDetails?.user_funder_payouts?.length; i++) {
        let x = investmentDetails?.user_funder_payouts[i]
        repaymentDetails.push(
            {
                installmentNo: i + 1, 
                received:'',
                outstanding:'',
                ...x
            }
        )
    }
    console.log(repaymentDetails,'REPAYMENTDETAILS')
    let finalInvestmentDetails = []
    for (let i = 0; i < fundingDetails?.repayments?.length; i++) {
        let x = fundingDetails?.repayments[i]
        let paid = 0, outstanding = 0;
        for (let j = 0; j < x.repayment_completed?.length; j++) {
            paid = paid + Number(x.repayment_completed[j].amount_received)
        }
        outstanding = x.repayment_total_amount - paid
        finalInvestmentDetails.push(
            {
                installmentNo: i + 1,
                paid:paid,
                outstanding: outstanding,
                ...x
            }
        )
    }
    console.log(FinalleaderBoardData, 'FINAL DETAILS')
    return (
        (role == 'Company' ? !fundingDetails : !investmentDetails) ? <Loader /> : <>
            <Box sx={{ width: '100%' }}>
                <Grid mt={1} container spacing={2} mb={4}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card sx={{ background: 'transparent linear-gradient(106deg, #ED4886 0%, #B855A4 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent>
                                <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                                    Auction number :
                                </Typography>
                                <Typography variant="h4" color="#fff" fontWeight={700} sx={{ letterSpacing: '1px' }} mb={2}>
                                    {role == 'Company' ? fundingDetails?.auction_number : investmentDetails?.company_auction?.auction_number}
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none', fontSize: '12px', display: "flex", alignItems: "flex-end", textWrap: 'nowrap' }}>
                                        Funding Amount <span style={{ fontSize: "13px", fontWeight: "600", marginLeft: '5px' }}>{role == 'Company' ? `£ ${Math.round(Number(fundingDetails?.auction_amount)).toLocaleString('en-us')}` : `£ ${Math.round(Number(investmentDetails?.company_auction?.auction_amount)).toLocaleString('en-us')}`}</span>
                                    </Typography>
                                    <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none', fontSize: '12px', marginLeft: "10px", display: "flex", alignItems: "center", textWrap: 'nowrap' }}>
                                        Auction Status <span style={{ marginLeft: "5px" }}><StatusText mt={'0px'} text={role == 'Company' ? fundingDetails?.loan_status_name : investmentDetails?.company_auction?.loan_status_name} /></span>
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card sx={{ background: 'transparent linear-gradient(106deg, #875EC0 0%, #5446BA 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent>
                                <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                                    Capital & ROI
                                </Typography>
                                <Box sx={{ display: "flex", justifyContent: 'space-between', marginTop: "5px" }}>
                                    <Box>
                                        <Typography variant="p" component="p" color='#fff' sx={{ textTransform: 'none', fontSize: '12px', marginTop: '10px', display: "flex", alignItems: "flex-end", textWrap: 'nowrap' }}>
                                            Capital Payable
                                        </Typography>
                                        <Typography variant="p" component="p" color='#fff' sx={{ textTransform: 'none', fontSize: '13px', marginTop: '10px', fontWeight: "600" }}>
                                            {role == 'Company' ? `£ ${Math.round(Number(fundingDetails?.fund_raise_amount)).toLocaleString('en-us')}` : `£ ${Math.round(Number(investmentDetails?.offer_amount)).toLocaleString('en-us')}`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="p" component="p" color='#fff' sx={{ textTransform: 'none', fontSize: '12px', marginTop: '10px', display: "flex", alignItems: "flex-end", textWrap: 'nowrap' }}>
                                            ROI Payable
                                        </Typography>
                                        <Typography variant="p" component="p" color='#fff' sx={{ textTransform: 'none', fontSize: '13px', marginTop: '10px', fontWeight: "600" }}>
                                            {role == 'Company' ? `£ ${Math.round(Number(fundingDetails?.fund_raise_roi)).toLocaleString('en-us')}` : `£ ${Math.round(Number(investmentDetails?.offer_roi_amount)).toLocaleString('en-us')}`}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="p" component="p" color='#fff' sx={{ textTransform: 'none', fontSize: '12px', marginTop: '10px', display: "flex", alignItems: "flex-end", textWrap: 'nowrap' }}>
                                            No. of installments
                                        </Typography>
                                        <Typography variant="p" component="p" color='#fff' sx={{ textTransform: 'none', fontSize: '13px', marginTop: '10px', fontWeight: "600", textAlign: "center" }}>
                                            {role == 'Company' ? fundingDetails?.number_of_instalment : investmentDetails?.user_funder_payouts?.length}
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Typography variant='h5' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                    Installment
                </Typography>
                {role == 'Company' && <GlobalTable
                    count={finalInvestmentDetails?.length}
                    showUpperBox={false}
                    rows={finalInvestmentDetails}
                    headCells={
                        [
                            {
                                align: "left",
                                label: "Installment No.",
                                fieldName: "installmentNo"
                            },
                            {
                                align: "left",
                                label: "Date",
                                fieldName: "repayment_time",
                                showValue: (val) => {
                                    return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
                                }
                            },
                            {
                                align: "left",
                                label: "Amount",
                                fieldName: "repayment_total_amount",
                                showValue: (val) => {
                                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                }
                            },
                            {
                                align: "left",
                                label: "Paid",
                                fieldName: "paid",
                                showValue: (val) => {
                                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                }
                            },
                            {
                                align: "left",
                                label: "Outstanding",
                                fieldName: "outstanding",
                                showValue: (val) => {
                                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                }
                            }
                        ]
                    }
                />}
                {role == 'Funder' && <GlobalTable
                    count={repaymentDetails?.length}
                    showUpperBox={false}
                    rows={repaymentDetails}
                    headCells={
                        [
                            {
                                align: "left",
                                label: "Installment No.",
                                fieldName: "installmentNo"
                            },
                            {
                                align: "left",
                                label: "Date",
                                fieldName: "repayment_time",
                                showValue: (val) => {
                                    return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
                                }
                            },
                            {
                                align: "left",
                                label: "Amount",
                                fieldName: "repayment_total_amount",
                                showValue: (val) => {
                                    return `£ ${(Number(val)).toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                                }
                            },
                            {
                                align: "left",
                                label: "Received",
                                fieldName: "repayment_recived_amount",
                                showValue: (val) => {
                                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                }
                            },
                            {
                                align: "left",
                                label: "Outstanding",
                                fieldName: "repayment_due_amount",
                                showValue: (val) => {
                                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                }
                            },
                            // {
                            //     align: "left",
                            //     label: "Status",
                            //     fieldName: "payment_status_name",
                            //     type: 'badge'
                            // }
                        ]
                    }
                />}
                {
                    role == 'Company' &&
                    <>
                        <Typography variant='h6' color='#000' fontWeight={'600'} mt={3} mb={3} sx={{ letterSpacing: "0.7px" }}>
                            Bidders
                        </Typography>
                        <Box sx={{ background: "#f1f1f1" }} mb={2} mt={2} p={1}>
                            <Typography sx={{ fontSize: '15px', fontWeight: "600", color: "#9f9d9d", letterSpacing: "1px" }}>Fulfilled Bids</Typography>
                        </Box>
                        <GlobalTable
                            count={FinalleaderBoardData?.length}
                            showUpperBox={false}
                            rows={FinalleaderBoardData}
                            headCells={
                                [
                                    {
                                        align: "left",
                                        label: "Rank",
                                        fieldName: "rank",
                                    },
                                    {
                                        align: "left",
                                        label: "Name",
                                        fieldName: "name",
                                        type:'passwordLeaderBoard'
                                    },
                                    {
                                        align: "left",
                                        label: "BID AMOUNT",
                                        fieldName: "bidAmount",
                                        showValue: (val) => {
                                            return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                        }
                                    },
                                    {
                                        align: "left",
                                        label: "FUNDING SHARE",
                                        fieldName: "fundingShare",
                                        showValue: (val) => {
                                            return `${Math.round(Number(val))} %`;
                                        }
                                    },
                                    {
                                        align: "left",
                                        label: "ROI",
                                        fieldName: "roi",
                                        showValue: (val) => {
                                            return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                        }
                                    },
                                    {
                                        align: "left",
                                        label: "ROI",
                                        fieldName: "roi_percent",
                                        showValue: (val) => {
                                            return `${Math.round(Number(val))} %`;
                                        }
                                    }
                                ]
                            }
                        />
                        {/* <Box sx={{ background: "#f1f1f1" }} mb={2} mt={2} p={1}>
                            <Typography sx={{ fontSize: '15px', fontWeight: "600", color: "#9f9d9d", letterSpacing: "1px" }}>Unfulfilled Bids</Typography>
                        </Box>
                        <GlobalTable
                            count={FinalunFullfilledLeaderBoardData?.length}
                            showUpperBox={false}
                            rows={FinalunFullfilledLeaderBoardData}
                            headCells={
                                [
                                    {
                                        align: "left",
                                        label: "Rank",
                                        fieldName: "rank",
                                    },
                                    {
                                        align: "left",
                                        label: "BID AMOUNT",
                                        fieldName: "bidAmount",
                                        showValue: (val) => {
                                            return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                        }
                                    },
                                    {
                                        align: "left",
                                        label: "FUNDING SHARE",
                                        fieldName: "fundingShare",
                                        showValue: (val) => {
                                            return `${Math.round(Number(val))} %`;
                                        }
                                    },
                                    {
                                        align: "left",
                                        label: "ROI",
                                        fieldName: "roi",
                                        showValue: (val) => {
                                            return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                                        }
                                    },
                                    {
                                        align: "left",
                                        label: "ROI",
                                        fieldName: "roi_percent",
                                        showValue: (val) => {
                                            return `${Math.round(Number(val))} %`;
                                        }
                                    }
                                ]
                            }
                        /> */}
                    </>
                }
            </Box>
        </>
    )
}

export default LoanDetails