import { Box, Button, Checkbox, CircularProgress, Container, FormControl, Grid, Input, Slider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputComp from '../../../components/InputComp'
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SliderInputComp from '../../../components/SliderInputComp';
import { useDispatch, useSelector } from 'react-redux';
import { getFeeDetails } from '../../../Redux/Slices/GetFeeDetailsSlice';
import { BidderRank, resetStateGetBidderRank } from '../../../Redux/Slices/GetBidderRankSlice';
import ErrorMessageComp from '../../../components/ErrorMessageComp';
import { AddBid } from '../../../Redux/Slices/AddBidSlice';
import ThousandSeperatorInputComp from '../../../components/ThousandSeperatorInput';
import { toast } from 'react-toastify';
import { GetBid } from '../../../Redux/Slices/GetMyBidDetails';
import { EditBidFunc, resetStateEditBid } from '../../../Redux/Slices/EditBidSlice';
import Loader from '../../../components/Loader';

function EditBid() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [review, setReview] = useState(false)
    const [termsandCondition, settermsandCondition] = useState(true)
    const [clicked, setclicked] = useState(false)
    const { loading, status, error, message, data } = useSelector((state) => state.bidDetails)
    const { handleSubmit, setValue, control, formState: { errors }, getValues, watch } = useForm()
    const formData = getValues()
    const location = useLocation()
    const user_id = localStorage.getItem('bidid')
    const auction_amount = location?.state?.auction_amount
    const auction_id = location?.search?.slice(1, location?.search?.length)
    const myBidId = location?.state?.bidId
    const offer_amount = watch('offer_amount') || data?.offer_amount
    const roi_percent = watch('offer_roi_percentage') 
    const roi_amount = watch('offer_roi_amount') || data?.offer_roi_amount
    const defaultBidderRank = data?.rank_leader_board
    const sharePercentage = Math.round((offer_amount / auction_amount) * 100) || null
    const getROIamountValue = (offer_amount, roi_percent) => {
        console.log(roi_percent,'ROI PERCENT')
        return (Math.round((offer_amount * roi_percent) / 100))
    }
    const { feeDetails } = useSelector((state) => state.feeDetails)
    const { bidderRank, bidderRankLoader } = useSelector((state) => state.getbidderRank)
    const { EditBidloading, EditBidStatus, EditBiderror, EditBidmessage, EditBiddata } = useSelector((state) => state.editBid)
    const marks = [
        {
            value: Math.floor(Number(data?.offer_amount)),
            label: `£ ${Math.floor(Number(data?.offer_amount)).toLocaleString('en-us')}`,
        },
        {
            value: auction_amount,
            label: `£ ${Number(auction_amount).toLocaleString('en-us')}`,
        },
    ];
    const ROI = [
        {
            value: Math.round(feeDetails?.min_roi),
            label: Math.round(feeDetails?.min_roi),
        },
        {
            value: Math.round(feeDetails?.max_roi),
            label: location?.state?.max_roi,
        },
    ];
    const onSubmit = (data) => {
        if (termsandCondition) {
            dispatch(EditBidFunc({ ...data, 'funding_share_amount': offer_amount, 'funding_share_percentage': sharePercentage, 'rank_leader_board': bidderRank || defaultBidderRank, 'bidding_id': myBidId, 'user_id': user_id }))
        }
    }
    useEffect(() => {
        dispatch(getFeeDetails())
        dispatch(GetBid(myBidId))
    }, [])
    useEffect(() => {
        if (data?.offer_amount) {
            setValue('offer_amount', Math.floor(Number(data?.offer_amount)))
            setValue('offer_roi_percentage', data?.offer_roi_percentage)
        }
    }, [data])
    useEffect(() => {
        if (EditBiderror) {
            toast.error('' + EditBiderror, {
                style: { fontSize: "15px" }
            });
        } if (EditBidStatus) {
            toast.success('' + EditBidmessage, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    navigate(`/myAuction/leaderboard?${auction_id}`)
                    dispatch(resetStateEditBid())
                }
            });
        }
    }, [EditBidStatus, EditBiderror])
    console.log(offer_amount, Math.floor(Number(data?.offer_amount)),offer_amount==Math.floor(Number(data?.offer_amount)), 'EDIT BID DATA')
    useEffect(() => {
        dispatch(resetStateEditBid())
        dispatch(resetStateGetBidderRank())
    }, [dispatch])
    return (
        data ?
            <>
                <Typography variant='h4' textTransform={'none'} color='#272D3B' fontWeight={'600'} mt={1} sx={{ letterSpacing: "0.7px" }}>
                    Enter bid
                </Typography>
                <Container maxWidth={'md'} sx={{ mt: 4, ml: 0, pl: '0px !important' }}>
                    <Box component="form" p={4} sx={{ boxShadow: '0px 1px 30px #15223214', borderRadius: '5px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label><Typography variant='nav' fontWeight={'600'} fontSize={'13px'}>{review ? 'Bid Amount' : 'Enter Bid Amount'}</Typography></label>
                                <SliderInputComp disabled={review} dontShowLesserValue={true} fixedValue={Math.floor(Number(data?.offer_amount))} beforeText={'£'} showSlider={!review} required={true} minValue={Math.floor(Number(data?.offer_amount))} maxValue={marks[1]?.value} name={'offer_amount'} watch={watch} marginLeft={'0px'} marks={marks} control={control} setValue={setValue} />
                                {errors.offer_amount?.type === 'required' && <ErrorMessageComp message={'Offer amount is required'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label><Typography variant='nav' fontWeight={'600'} fontSize={'13px'}>{review ? 'ROI (Proposed ROI)' : 'Enter ROI (Proposed ROI)'}</Typography></label>
                                <SliderInputComp fixedValue={data?.offer_roi_percentage} dontShowGreaterValue={offer_amount==Math.floor(Number(data?.offer_amount))} beforeText={'£'} showSlider={!review} required={true} name={'offer_roi_percentage'} amount_name={'offer_roi_amount'} minValue={ROI[0].value} maxValue={ROI[1].value} defaultValue={ROI[0].value} watch={watch} percentageValue={true} amount={getROIamountValue(offer_amount, roi_percent)} marginLeft={'0px'} marks={ROI} control={control} setValue={setValue} />
                                {errors.offer_roi_percentage?.type === 'required' && <ErrorMessageComp message={'ROI is required'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <label><Typography variant='nav' fontWeight={'600'} fontSize={'13px'}>Rank on leader board</Typography></label>
                                    <FormControl className='file-upload-container' style={{ width: '85%' }}>
                                        <p className='file-name' style={{ margin: 0, fontSize: '15px', position: 'absolute', left: '8px', top: '25%' }}>{bidderRank || data?.rank_leader_board}</p>
                                        {!review && <label for="file-upload" class="custom-file-upload">
                                            <Button variant="contained" size='small' sx={{
                                                padding: '7px 30px',
                                                backgroundColor: '#0B2244',
                                                textTransform: 'Uppercase',
                                            }}
                                                onClick={() => dispatch(BidderRank({ auction_id: auction_id, offer_amount: offer_amount, roi_amount: roi_percent }))}
                                            >
                                                {
                                                    bidderRankLoader ? <CircularProgress color="white" size={'25px'} /> : 'Get Rank'
                                                }
                                            </Button>
                                        </label>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label><Typography variant='nav' fontWeight={'600'} fontSize={'13px'}>ROI</Typography></label>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormControl className='DisabledFormControl' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', border: "1px solid grey", padding: "12px 10px", borderRadius: '7px', width: '100%' }}>
                                            <span style={{ width: 'max-content', color: 'grey', fontSize: "15px", marginRight: '10px' }}>£</span>
                                            <input disabled={true} value={roi_amount && Number(roi_amount).toLocaleString('en-us')} style={{ width: '100%', border: "none" }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormControl className='DisabledFormControl' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', border: "1px solid grey", padding: "12px 10px", borderRadius: '7px' }}>
                                            <input disabled={true} value={Number(roi_percent).toLocaleString('en-us')} style={{ width: '100%', border: "none" }} />
                                            <span style={{ width: 'max-content', color: 'grey', fontSize: "15px" }}>%</span>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label><Typography variant='nav' fontWeight={'600'} fontSize={'13px'}>Share of target funding</Typography></label>
                                <FormControl className='DisabledFormControl' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', border: "1px solid grey", padding: "12px 10px", borderRadius: '7px', width: "85%" }}>
                                    <span style={{ width: 'max-content', color: 'grey', fontSize: "15px", marginRight: '10px' }}>£</span>
                                    <input disabled={true} value={Number(offer_amount).toLocaleString('en-us')} style={{ width: '85%', border: "none" }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label><Typography variant='nav' fontWeight={'600'} fontSize={'13px'} color={'#fff'}>abcd </Typography></label>
                                <FormControl className='DisabledFormControl' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', border: "1px solid grey", padding: "12px 10px", borderRadius: '7px', width: "100%" }}>
                                    <input disabled={true} value={Number(sharePercentage).toLocaleString('en-us')} style={{ width: '85%', border: "none" }} />
                                    <span style={{ width: 'max-content', color: 'grey', fontSize: "15px", marginRight: '10px' }}>%</span>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={4} mb={4}>
                            <Grid marginLeft={'10px'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                    <Checkbox sx={{ paddingLeft: 0 }} size='small' name='termsandCondition' checked={termsandCondition} onClick={() => settermsandCondition(!termsandCondition)} /> <Link target='_blank' style={{ color: "#6C6C6D", textTransform: 'none' }} href="/termsandcondition" fontSize={'13px'}>I understand my capital is at risk with this investment.</Link>
                                </Grid>
                                {
                                    clicked && !termsandCondition && <Typography sx={{ fontSize: '10px', color: "red" }}>Please acknowledge this field</Typography>
                                }
                            </Grid>
                        </Grid>
                        <Stack justifyContent="flex-start" alignItems={'center'} direction="row">
                            <Button
                                variant="contained"
                                sx={{
                                    padding: '5px 20px',
                                    backgroundColor: '#B92C82',
                                    textTransform: 'Uppercase'
                                }}
                                onClick={handleSubmit(onSubmit)}>
                                {
                                    EditBidloading ? <CircularProgress color="white" size={'25px'} /> : 'Update Bid'
                                }

                            </Button>
                            <Button onClick={() => setReview(!review)} style={{ marginLeft: "20px", textDecoration: 'underLine', color: "#3A0CA3", fontSize: '13px' }}>{review ? 'Edit Bid' : 'Review the bid'}</Button>
                        </Stack>
                    </Box>
                </Container>
            </>
            : <Loader />
    )
}

export default EditBid