import { FormControl, Slider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

function ThousandSeperatorInputComp({ beforeText,afterText, required, showSlider, control, width, key, setValue, marks, marginLeft, percentageValue, amount_name, name, minValue, maxValue, defaultValue, amount, mt, marginBottom }) {
    return (
        <Controller
            key={key}
            name={name}
            control={control}
            rules={{
                required: required
            }}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
                <div style={{ marginTop: mt || 0 }}>
                    <div style={{ display: 'flex' }}>
                        <FormControl className={percentageValue ? 'DisabledFormControl' : ''} sx={{ width: { lg: percentageValue ? '47%' : '85%', md: '85%', xs: '85%' }, display: 'flex', flexDirection: "row", alignItems: 'center', borderRadius: '7px', border: "1px solid grey", paddingLeft: '10px', boxShadow: '0px 3px 10px #0000000D' }}>
                            <span style={{ fontSize: '15px', color: 'grey' }}>{beforeText}</span>
                            <NumericFormat style={{ border: "none", padding: "16px 5px" }} value={value} thousandSeparator={true} disabled={percentageValue ? true : false} />
                            {afterText && <span style={{ fontSize: '15px', color: 'grey' }}>{beforeText}</span>}
                        </FormControl>
                    </div>
                </div>
            )}
        />
    )
}

export default ThousandSeperatorInputComp