import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    status: false,
    data: '',
    error: false,
    loading: false,
    message: ''
}

export const AddBid = createAsyncThunk("/AddBid", async (bidData) => {
    let data = new FormData();
    for (const key in bidData) {
        if (bidData.hasOwnProperty(key)) {
                data.append(key, bidData[key]);
        }
    }
    const response = await ApihelperFunction({
        urlPath: "api/funder/addBidding",
        formData: data,
        method: "post",
        typecontent: true
    });
    return response;
});

const AddBidSlice = createSlice({
    name: "AddBid",
    initialState,
    reducers: {
        resetStateAddBid: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddBid.pending, (state) => {
                state.loading = true
            })
            .addCase(AddBid.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loading = false
                    state.error = false
                    state.status = action?.payload?.status
                    state.message = action?.payload?.message
                }
                else {
                    state.loading = false
                    state.status = action?.payload?.status
                    state.error = [action?.payload?.errors?.[0]]
                }
                state.data = action?.payload?.data;
            })
            .addCase(AddBid.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERROR'
            })
    }
})

export const { resetStateAddBid } = AddBidSlice.actions;
export default AddBidSlice.reducer