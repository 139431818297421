import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    userNamestatus: null,
    userNamedata: '',
    userNameerror: false,
    userNameloading: false,
    userNamemessage: ''
}

export const CheckUserName = createAsyncThunk("/CheckUserName", async (username) => {
    console.log(username,'USERNAME')
    const response = await ApihelperFunction({
        urlPath: `api/funder/checkusername?username=${username}`,
        method: "get",
    });
    return response;
});

const CheckUserNameSlice = createSlice({
    name: "CheckUserName",
    initialState,
    reducers: {
        resetStateCheckUserName: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(CheckUserName.pending, (state) => {
                state.userNameloading = true
                state.userNameerror = false
                state.userNamemessage = ''
            })
            .addCase(CheckUserName.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.userNameloading = false
                    state.userNameerror = false
                    state.userNamestatus = action?.payload?.status
                    state.userNamemessage = action?.payload?.message
                }
                else {
                    state.userNameloading = false
                    state.userNamestatus = action?.payload?.status
                    state.userNameerror = [action?.payload?.errors]
                    state.userNamemessage = action?.payload?.message
                }
                state.data = action?.payload?.data;
            })
            .addCase(CheckUserName.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERROR'
            })
    }
})

export const { resetStateCheckUserName } = CheckUserNameSlice.actions;
export default CheckUserNameSlice.reducer