import React, { useEffect, useState } from 'react'
import { Box, Stack, Button, Typography, MenuItem, Checkbox, Container, Grid, FormControl, CircularProgress, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyAccount, resetStateCompanyRegistration } from '../../Redux/Slices/CompanyRegistrationSlice';
import { toast } from 'react-toastify';
import ErrorMessageComp from '../../components/ErrorMessageComp';
import info from '../../assets/img/info.png'
import { EditCompamy, resetStateUpdateCompany } from '../../Redux/Slices/EditCompanySlice';
import LoginFormBg from '../../assets/img/login_form_bg.png'

const SignUp6 = () => {

    //values
    const localStorageValue = JSON.parse(localStorage.getItem('signUp6'))
    const location = useLocation()
    const editData = JSON.parse(localStorage.getItem('editData'))
    const actualValue = location?.search == '?edit' ? editData?.Company?.[0]?.company_bank_details : typeof (localStorageValue == String) ? (localStorageValue) : localStorageValue
    const [checked, setChecked] = useState(false)
    const [values, setValues] = useState(actualValue)
    //default values for form
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: values
    })
    const tempNoOfBanks = location?.search == '?edit' ? editData?.Company?.[0]?.company_bank_details : localStorageValue
    const [noOfBanks, setNoOfBanks] = useState(tempNoOfBanks || [{
        bank_name: '',
        sort_code: '',
        account_number: '',
    }])
    const [bankName, setBankName] = useState(values?.bank_name || [])
    const [sortCode, setSortCode] = useState(values?.sort_code || [])
    const [accountNumber, setAccountNumber] = useState(values?.account_number || [])
    const [isValidating, setIsValidating] = useState(false)
    const navigate = useNavigate()
    const step5Data = JSON.parse(localStorage.getItem('signUp5'))
    const step2EditData = JSON.parse(localStorage.getItem('signUp2NewEdit'))
    const step3EditData = JSON.parse(localStorage.getItem('signUp3NewEdit'))
    const step4EditData = JSON.parse(localStorage.getItem('signUp4NewEdit'))
    const step5EditData = JSON.parse(localStorage.getItem('signUp5NewEdit'))
    const dispatch = useDispatch()
    const { loading, status, error, message } = useSelector(state => state.companyRegister)
    const { editCompamy, editCompamyStatus, editCompamyLoader, editCompamyMessage, editCompamyError } = useSelector(state => state.updateCompany)
    //firing toast
    useEffect(() => {
        if (error) {
            toast.error('' + error[0], error[1], error[2], error[3]);
        } if (status === "SUCCESSCOMPANYACCOUNT") {
            toast.success('' + message, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    localStorage.removeItem('signUp2');
                    localStorage.removeItem('signUp3');
                    localStorage.removeItem('signUp4');
                    localStorage.removeItem('signUp5');
                    localStorage.removeItem('signUp6');
                    localStorage.removeItem('role');
                    localStorage.removeItem('userType');
                    localStorage.removeItem('sameAsUserDetails');
                    navigate('/signUp7', { state: 'Company' })
                    dispatch(resetStateCompanyRegistration())
                }
            });
        }
    }, [error, status]);
    useEffect(() => {
        dispatch(resetStateCompanyRegistration())
        dispatch(resetStateUpdateCompany())
    }, [])
    useEffect(() => {
        if (editCompamyError) {
            toast.error('' + editCompamyError[0], editCompamyError[1], editCompamyError[2], editCompamyError[3]);
        } if (editCompamyStatus === "SUCCESS") {
            toast.success('' + editCompamyMessage, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    localStorage.removeItem('signUp2Edit');
                    localStorage.removeItem('signUp3Edit');
                    localStorage.removeItem('signUp4Edit');
                    localStorage.removeItem('signUp5Edit');
                    localStorage.removeItem('signUp6Edit');
                    localStorage.removeItem('signUp2NewEdit');
                    localStorage.removeItem('signUp3NewEdit');
                    localStorage.removeItem('signUp4NewEdit');
                    localStorage.removeItem('signUp5NewEdit');
                    localStorage.removeItem('sameAsUserDetails');
                    navigate('/dashboard')
                    dispatch(resetStateUpdateCompany())
                }
            });
        }
    }, [editCompamyError, editCompamyStatus]);

    // set value in respective array
    const setValue = (row, value, method, data) => {
        if (data?.length > row) {
            let temp;
            temp = [...data]
            temp.splice(row, 1, value)
            method(temp)
        } else {
            method([...data, value])
        }
    }

    //form submit
    const onSubmit = () => {
        if (location?.search == '?edit') {
            if (Object.keys({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData }).length > 0) {
                dispatch(EditCompamy({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData }))
            } else {
                toast.error('' + 'No changes made to submit', {
                    style: { fontSize: "15px" },
                    autoClose: 3000,
                    onOpen: () => {
                        localStorage.removeItem('signUp2Edit');
                        localStorage.removeItem('signUp3Edit');
                        localStorage.removeItem('signUp4Edit');
                        localStorage.removeItem('signUp5Edit');
                        localStorage.removeItem('signUp6Edit');
                        localStorage.removeItem('signUp2NewEdit');
                        localStorage.removeItem('signUp3NewEdit');
                        localStorage.removeItem('signUp4NewEdit');
                        localStorage.removeItem('signUp5NewEdit');
                        localStorage.removeItem('sameAsUserDetails');
                        navigate('/viewProfile')

                    }
                })
            }
        } else {
            setIsValidating(true)
            let bankName = [], sortCode = [], accNumber = [];
            for (let i = 0; i < noOfBanks?.length; i++) {
                bankName?.push(noOfBanks?.[i]?.bank_name)
                sortCode?.push(noOfBanks?.[i]?.sort_code)
                accNumber?.push(noOfBanks?.[i]?.account_number)
            }
            if (values?.accept && (noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number && noOfBanks[noOfBanks.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].sort_code?.trim() !== '')) {
                localStorage.setItem('signUp6', JSON.stringify({ 'bank_name': bankName, 'account_number': accNumber, 'sort_code': sortCode }))
                dispatch(CompanyAccount({ ...step5Data, 'bank_name': bankName, 'account_number': accNumber, 'sort_code': sortCode }))
            }
        }
    }

    //onChange
    const handleChange = (e) => {
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
    }

    //remove bank
    const removeBank = (index) => {
        const updatedBanks = [...noOfBanks]; // Create a copy of the original array
        updatedBanks.splice(index, 1); // Remove one element starting from index 1 (the second bank)
        setNoOfBanks(updatedBanks); // Update the state with the modified array
    };

    // Function to add a new blank JSON object to the array
    const addNewAccount = () => {
        const newBankDetails = [...noOfBanks];
        newBankDetails.push({
            bank_name: '',
            sort_code: '',
            account_number: '',
        });
        setNoOfBanks(newBankDetails);
    };

    // Function to update the corresponding JSON object
    const updateAccountField = (index, field, value) => {
        const updatedBankDetails = [...noOfBanks];
        updatedBankDetails[index][field] = value;
        setNoOfBanks(updatedBankDetails);
    };
    console.log(noOfBanks[noOfBanks.length - 1].bank_name, noOfBanks[noOfBanks.length - 1].bank_name?.trim() !== '', 'BANK DETAILs')
    return (
        <div style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover', paddingBottom: '80px' }}>
            <HorizontalLinearAlternativeLabelStepper val={5} data={noOfBanks} toolTip={noOfBanks?.length > 1 && !(noOfBanks[noOfBanks.length - 1].bank_name || noOfBanks[noOfBanks.length - 1].sort_code || noOfBanks[noOfBanks.length - 1].account_number) ? `You need to either fillup atleast one information for bank details ${noOfBanks?.length} or you have to remove the bank details ${noOfBanks?.length} to go back` : null} />
            <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                <Box
                    component="form"
                    sx={{
                        margin: 'auto',
                    }}
                >
                    {location?.search != '?edit' && <Typography variant='p' component='p' fontWeight={'bold'} textAlign='center' color='#000' fontSize={'15px'} textTransform='none'>
                        Please provide your business bank account details
                    </Typography>}

                    {
                        noOfBanks?.map((number, index) => {
                            return (
                                <>
                                    <Grid container spacing={2}>
                                        {noOfBanks?.length > 1 && <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '64px', marginLeft: '5px' }} width={'100%'}>
                                            {
                                                noOfBanks.length > 1 && <Box width={'100%'} marginLeft={1}><Typography variant='p' component={'p'} mb={0} color={'#B92C82'} fontWeight={600} > Bank details {index + 1}</Typography></Box>
                                            }
                                            {
                                                index != 0 && location?.search != '?edit' && < Button color='primary' onClick={() => removeBank(index)} marginTop={'32px'}><Typography fontWeight={600} variant='p' color={'#B92C82'}>Remove</Typography></Button>
                                            }
                                        </Box>}
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Controller
                                                name='bank_name'
                                                control={control}
                                                // rules={{ required: true }}
                                                render={({ field: { value } }) => (
                                                    <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Bank name *</Typography></label>
                                                        <TextField
                                                            id="outlined-select"
                                                            name='bank_name'
                                                            disabled={location?.search == '?edit'}
                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                            onBlur={handleChange}
                                                            onChange={(e) => { if (e.target.value.length <= 20) { updateAccountField(index, 'bank_name', e.target.value) } }}
                                                            value={noOfBanks[index]?.bank_name}
                                                            sx={{
                                                                mt: 0,
                                                                '& .MuiOutlinedInput-root': {
                                                                    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                    border: 'none',
                                                                    borderRadius: '7px',
                                                                    marginLeft: 'auto',
                                                                    width: { lg: '100%', md: '400px', xs: '100%' },
                                                                    height: { md: '50px', xs: '50px' },
                                                                    boxShadow: '0px 3px 10px #0000000D',
                                                                    opacity: 1
                                                                },
                                                                '& .MuiInputBase-input': {
                                                                    textTransform: 'capitalize'
                                                                }
                                                            }}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                            />
                                            {
                                                location?.search != '?edit' && isValidating && !noOfBanks?.[index]?.bank_name && <ErrorMessageComp message={'Bank name is required'} />
                                            }
                                            {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.bank_name && noOfBanks?.[index]?.bank_name?.trim() === '' && <ErrorMessageComp message={'Bank name can not have only spaces'} />
                                            }
                                            {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.bank_name && noOfBanks?.[index]?.bank_name?.length > 20 && <ErrorMessageComp message={'Bank name can not have more than 20 characters'} />
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Controller
                                                name='sort_code'
                                                control={control}
                                                // rules={{ required: true }}
                                                render={({ field: { value } }) => (
                                                    <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Sort code *</Typography></label>
                                                        <TextField
                                                            id="outlined-select"
                                                            name='sort_code'
                                                            // placeholder='Sort code *'
                                                            disabled={location?.search == '?edit'}
                                                            onBlur={handleChange}
                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s-]/g, '')}
                                                            value={noOfBanks[index]?.sort_code}
                                                            onChange={(e) => { if (e.target.value.trim().length <= 8) { updateAccountField(index, 'sort_code', e.target.value) } }}
                                                            // onChange={(e) => setValue(index, e.target.value, setSortCode, sortCode)} value={sortCode?.[index] || actualValue?.[index]?.sort_code || ''}
                                                            sx={{
                                                                mt: 0,
                                                                '& .MuiOutlinedInput-root': {
                                                                    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                    border: 'none',
                                                                    borderRadius: '7px',
                                                                    marginLeft: 'auto',
                                                                    width: { lg: '100%', md: '400px', xs: '100%' },
                                                                    height: { md: '50px', xs: '50px' },
                                                                    boxShadow: '0px 3px 10px #0000000D',
                                                                    opacity: 1
                                                                }
                                                            }}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                            />
                                            {
                                                location?.search != '?edit' && isValidating && !noOfBanks?.[index]?.sort_code && <ErrorMessageComp message={'Sort code  is required'} />
                                            }
                                            {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.sort_code && noOfBanks?.[index]?.sort_code?.trim() === '' && <ErrorMessageComp message={'Sort code can not have only spaces'} />
                                            }
                                            {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.sort_code && noOfBanks?.[index]?.sort_code?.length > 8 && <ErrorMessageComp message={'Sort code can have maximum 6 characters'} />
                                            }
                                        </Grid>
                                    </Grid >

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Controller
                                                name='account_number'
                                                control={control}
                                                // rules={{ required: true }}
                                                render={({ field: { value } }) => (
                                                    <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Account Number *</Typography></label>
                                                        <TextField
                                                            id="outlined-select"
                                                            name='account_number'
                                                            disabled={location?.search == '?edit'}
                                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                            onBlur={handleChange}
                                                            value={noOfBanks[index]?.account_number}
                                                            onChange={(e) => { if (e.target.value.length <= 20) { updateAccountField(index, 'account_number', e.target.value) } }}
                                                            sx={{
                                                                mt: 0,
                                                                '& .MuiOutlinedInput-root': {
                                                                    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                    border: 'none',
                                                                    borderRadius: '7px',
                                                                    marginLeft: 'auto',
                                                                    width: { lg: '100%', md: '400px', xs: '100%' },
                                                                    height: { md: '50px', xs: '50px' },
                                                                    boxShadow: '0px 3px 10px #0000000D',
                                                                    opacity: 1
                                                                }
                                                            }}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                            />
                                            {
                                                location?.search != '?edit' && isValidating && !noOfBanks?.[index]?.account_number && <ErrorMessageComp message={'Account number is required'} />
                                            }
                                            {
                                                location?.search != '?edit' && isValidating && noOfBanks?.[index]?.account_number && noOfBanks?.[index]?.account_number.length > 20 && <ErrorMessageComp message={'Account number can have maximum 20 digits'} />
                                            }
                                        </Grid>
                                        {
                                            index == 0 && location?.search != '?edit' && <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                <Link href={'/whydoweneedthis'} target='_blank' alignSelf='center' color="#000000" marginTop={5.8} fontSize='12px' fontWeight={'500'} textTransform={'none'}>Why do we need this?<Tooltip title="It is regarding the info of bank details"><img alt='' src={info} width={'13px'} style={{ marginLeft: '5px' }} /></Tooltip></Link>
                                            </Grid>
                                        }
                                    </Grid>
                                </>
                            )
                        })
                    }
                    {location?.search != '?edit' && noOfBanks?.length < 4 && <Grid container spacing={2}>
                        {(noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number && noOfBanks[noOfBanks.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].sort_code?.trim() !== '') &&
                            <Grid mt={2} item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Link onClick={() => (noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number) ?
                                    addNewAccount() : ''} marginTop={0} color="#3A0CA3" justifyContent="center" href="#" fontSize='15px' style={{
                                        textDecoration: 'underline', cursor: (noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number) ? 'pointer' : 'not-allowed'
                                    }} sx={{
                                        fontSize: '13px', textTransform: 'none', fontWeight: '500', textDecoration: "underline", cursor: bankName?.[noOfBanks?.length - 1] && sortCode?.[noOfBanks?.length - 1] && accountNumber?.[noOfBanks?.length - 1] ? 'pointer' : 'not-allowed'
                                    }}>Add more accounts</Link>
                                <ControlPointIcon sx={{
                                    marginTop: { xs: 2, md: 0 },
                                    marginLeft: '15px',
                                    marginBottom: '2px',
                                    color: '#3A0CA3',
                                    cursor: noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number ? 'pointer' : 'not-allowed'
                                }} fontSize='13px' onClick={() => (noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number) ?
                                    addNewAccount() : ''
                                }> </ControlPointIcon>
                            </Grid>
                        }
                    </Grid>}
                    <Grid container spacing={2} mt={4} mb={4}>
                        {location?.search != '?edit' &&
                            <Grid sx={{ margin: 'auto' }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                    <Checkbox sx={{ paddingLeft: 0 }} checked={checked} size='small' name='termsandCondition' required={true} onChange={() => { setValues({ ...values, 'accept': !checked }); setChecked(!checked) }} /> <Link target='_blank' style={{ color: "#6C6C6D", textDecoration: 'underline' }} href="/termsandcondition" fontSize={'13px'}>Accept terms & condition *</Link>
                                </Grid>
                                {isValidating && !values?.accept && <ErrorMessageComp textAlign={'center'} width={'100%'} message={"Please accept the terms and conditions"} />
                                }
                            </Grid>
                        }
                    </Grid>
                    <Stack justifyContent="center" direction="row">
                        <Button disabled={location?.search != '?edit' && !(noOfBanks[noOfBanks.length - 1].bank_name && noOfBanks[noOfBanks.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].account_number && noOfBanks[noOfBanks.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].sort_code?.trim() !== '')}
                            variant="contained" sx={{
                                padding: '10px 60px',
                                backgroundColor: '#B92C82',
                                textTransform: 'Uppercase'
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {
                                !loading && location?.search != '?edit' && <Typography variant='p' color='#fff'>SUBMIT</Typography>
                            }
                            {
                                loading && <CircularProgress color="white" size={'25px'} />
                            }
                            {
                                editCompamyLoader && location?.search == '?edit' && <CircularProgress color="white" size={'25px'} />
                            }
                            {
                                !editCompamyLoader && location?.search == '?edit' && <Typography variant='p' color='#fff'>SUBMIT</Typography>
                            }
                        </Button>
                    </Stack>

                </Box >
            </Container >
        </div>

    );
}
export default SignUp6