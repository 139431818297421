import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    paymentMode: [],
    paymentModeStatus:'',
    paymentModeLoader:false,
    paymentModeError:null
};
export const GetPaymentMode = createAsyncThunk("/GetPaymentMode", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getPaymentMode`,
        method: "get",
    });
    return response;
});
const getPaymentModeSlice = createSlice({
    name: 'PaymentMode',
    initialState,
    reducers: {
        resetStatePaymentMode: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetPaymentMode.pending, (state) => {
                state.paymentModeStatus = 'LOADING'
                state.paymentModeLoader = true
                state.paymentModeError = null
            })
            .addCase(GetPaymentMode.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.paymentModeStatus = "SUCCESS"
                    state.paymentMode = action?.payload?.data
                }
                else {
                    state.paymentModeStatus = "ERROR"
                    state.paymentModeError = [action?.payload?.errors?.[0]]
                }
                state.paymentModeLoader = false
            })
            .addCase(GetPaymentMode.rejected, (state, action) => {
                state.paymentModeStatus = 'ERROR'
                state.paymentModeLoader = false
            })
    }
})

export const { resetState } = getPaymentModeSlice.actions;
export default getPaymentModeSlice.reducer;