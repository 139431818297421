import { Button, Container, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getFeeDetails } from '../../../Redux/Slices/GetFeeDetailsSlice'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { useState } from 'react'
import { CreateAuction, addAuctionData, goToNextStep, goToPerticularStep, resetStateCreateAuction } from '../../../Redux/Slices/CreateAuctionSlice'
import { toast } from 'react-toastify'
import Loader from '../../../components/Loader'

function StepSeven() {
    const dispatch = useDispatch()
    const { fjFeeDetails, brokerFeeDetails } = useSelector((state) => state.feeDetails)
    const [repaymentDueDate, setRepaymentDueDate] = useState([])
    const auctionData = useSelector((state) => state.createAuction)
    console.log(auctionData, "DUDU STEP 7")
    
    const getBrokerFee = (value) => {
        for (let i = 0; i < brokerFeeDetails.length; i++) {
            console.log(value, brokerFeeDetails[i]?.min_amount, 'BROKERS FEE222')
            if (value >= brokerFeeDetails[i]?.min_amount && value <= brokerFeeDetails[i]?.max_amount) {
                if (brokerFeeDetails?.[i]?.broker_fee_percentage) {
                    return Math.round((value * brokerFeeDetails?.[i]?.broker_fee_percentage) / 100)
                } else {
                    return brokerFeeDetails?.[i]?.broker_fee_flat
                }
            }
        }
    }
    let FJ_fee_percentage;
    const getFJFeePercentage = (value) => {
        for (let i = 0; i < fjFeeDetails.length; i++) {
            if (value >= fjFeeDetails[i]?.min_amount && value <= fjFeeDetails[i]?.max_amount) {
                if (fjFeeDetails?.[i]?.fj_fee_percentage) {
                    FJ_fee_percentage = fjFeeDetails?.[i]?.fj_fee_percentage;
                    return Math.round((value * fjFeeDetails?.[i]?.fj_fee_percentage) / 100)
                } else {
                    FJ_fee_percentage = null;
                    return fjFeeDetails?.[i]?.fj_fee_flat
                }
            }
        }
    }
    const auctionDetailsDataSummary = {
        invoice_value: auctionData?.data?.invoice_amount,
        funding_required: auctionData?.data?.auction_amount,
        max_roi_percentage: auctionData?.data?.roi_percent,
        max_roi_amount: auctionData?.data?.roi_amount,
        brokers_fee_amount: Math.round(getBrokerFee(auctionData?.data?.auction_amount)),
        retainer_fee_percentage: auctionData?.data?.retainer_percentage,
        retainer_fee_amount: auctionData?.data?.retainer_amount,
        fj_fee_amount:getFJFeePercentage(auctionData?.data?.auction_amount),
        fj_fee_percentage: FJ_fee_percentage,
        potential_available_first_value: auctionData?.data?.auction_amount - auctionData?.data?.fj_fee - auctionData?.data?.retainer_amount - Math.round(getBrokerFee(auctionData?.data?.auction_amount)),
        total_repayble: auctionData?.data?.auction_amount + auctionData?.data?.roi_amount,
        issue_date: auctionData?.data?.issue_date,
        due_date: auctionData?.data?.due_date,
        noOfInstalments: auctionData?.data?.number_of_instalment
    }
    const repaymentAmount = Math.round(auctionDetailsDataSummary.total_repayble / auctionDetailsDataSummary.noOfInstalments)
    const calculateRepaymentSchedule = (no_of_instalments) => {
        let repayment_due_date_array = [{ Date: dayjs(auctionDetailsDataSummary?.due_date, 'DD-MM-YYYY').add(0, 'day').format('DD-MM-YYYY'), Amount: repaymentAmount }]
        for (let i = 0; i < no_of_instalments - 1; i++) {
            repayment_due_date_array.push({ Date: dayjs(repayment_due_date_array[i].Date, 'DD-MM-YYYY').add(30, 'day').format('DD-MM-YYYY'), Amount: repaymentAmount })
        }
        setRepaymentDueDate(repayment_due_date_array)
    }
    useEffect(() => {
        dispatch(getFeeDetails())
        calculateRepaymentSchedule(auctionDetailsDataSummary?.noOfInstalments)
    }, [])
    return (
        (repaymentAmount == 'NaN') ?
            <Loader />
            :
            <>
                <Typography component={'p'} variant='p' fontSize={'18px'} textTransform={'none'} color={'#B92C82'} fontWeight={'600'} textAlign={'center'} mb={2}>Funding summary</Typography>
                <Container maxWidth='sm'>
                    <Paper style={{ border: '1px solid #cdcdcd', boxShadow: 'none' }}>
                        <Table style={{ borderRadius: '8px' }} className='small-table'>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Invoice Value</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`£ ${auctionDetailsDataSummary?.invoice_value?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Funding Required</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`£ ${auctionDetailsDataSummary?.funding_required?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Max ROI</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`${auctionDetailsDataSummary?.max_roi_percentage}% / £${auctionDetailsDataSummary?.max_roi_amount?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Broker's Fee</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`£${auctionDetailsDataSummary?.brokers_fee_amount?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Retainer Fee</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`${auctionDetailsDataSummary?.retainer_fee_percentage}% / £${auctionDetailsDataSummary?.retainer_fee_amount?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>FJ Fee</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{auctionDetailsDataSummary?.fj_fee_percentage?`${auctionDetailsDataSummary?.fj_fee_percentage}% / £${auctionDetailsDataSummary?.fj_fee_amount.toLocaleString('en-us')}`:`£${auctionDetailsDataSummary?.fj_fee_amount.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Potential Available If Fully Funded</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`£${auctionDetailsDataSummary?.potential_available_first_value?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Total Repayble By You</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{ marginLeft: '30px' }}>{`£ ${auctionDetailsDataSummary?.total_repayble?.toLocaleString('en-us')}`}</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Container>
                <Typography mt={4} component={'p'} variant='p' fontSize={'18px'} textTransform={'none'} color={'#B92C82'} fontWeight={'600'} textAlign={'center'} mb={2}>Repayment Summary</Typography>
                <Container maxWidth='sm'>
                    <Paper style={{ border: '1px solid #cdcdcd', boxShadow: 'none' }}>
                        <Table key={repaymentAmount} style={{ borderRadius: '8px' }} className='small-table'>
                            <TableBody>
                                <TableRow>
                                    <TableCell key={repaymentAmount} style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Invoice Issue Date</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} style={{ marginLeft: '30px' }}>{moment(auctionDetailsDataSummary.issue_date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Invoice Due Date</Typography></TableCell>
                                    <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} style={{ marginLeft: '30px' }}>{moment(auctionDetailsDataSummary.due_date, 'DD/MM/YYYY').format('DD/MM/YYYY')}</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Number Of Instalments</Typography></TableCell>
                                    <TableCell><Typography textTransform={'none'} component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} style={{ marginLeft: '30px' }}>{auctionDetailsDataSummary?.noOfInstalments} Month(s)</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ borderRight: "none" }} colSpan={2}><Typography textTransform={'none'} component={'p'} variant='p' fontSize={'14px'} color={'#000'}>Repayment Due Date(s)</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: "0px", paddingRight: "0px" }}>
                                        <Table>
                                            <TableBody>
                                                {
                                                    repaymentDueDate?.map((x, index) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Repayment ({index + 1}) £{repaymentAmount.toLocaleString('en-us')}</Typography></TableCell>
                                                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} style={{ marginLeft: '16px' }}>{dayjs(x.Date, 'DD-MM-YYYY').format('DD/MM/YYYY')}</Typography></TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}>
                        <Link onClick={() => dispatch(goToPerticularStep(2))} style={{ textDecoration: 'underline', fontWeight: "500", fontSize: '15px' }}>Edit</Link>
                    </div>
                </Container>
                <Stack justifyContent="center" direction="row" margin={3}>
                    <Button variant="contained" sx={{
                        padding: '10px 60px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}
                        onClick={() => dispatch(addAuctionData({ repayments: repaymentDueDate }))}>CONFIRM</Button>
                </Stack>
            </>
    )
}

export default StepSeven