import {
    Button, Card, Container,
    Typography,
    Stack,
    Box,
} from '@mui/material';

import circlecheck from '../../assets/img/check-circle.svg'

import icon1 from '../../assets/img/icon-01_2x.png'
import icon2 from '../../assets/img/icon-02_2x.png'
import icon3 from '../../assets/img/icon-03_2x.png'
import RoundedCardImgAndText from '../../components/RoundedCardImgAndText';
import ListItemWithIconAndText from '../../components/ListItemWithIconAndText';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const ForBusinessesSection = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    return (
        <Container maxWidth={'xl'} sx={{ mb: 10 }} alignContent={'center'}>
            <Card sx={{
                backgroundColor: 'var(--unnamed-color-feeef7)',
                px: { md: 8, xs: 2 },
                pt: { md: 8, xs: 6 },
                pb: { md: 12, xs: 6 },
                borderRadius: '32px',
                mx: { xl: 13, md: 10, xs: 1 }
            }} elevation={0} >
                <Stack ml={2}>
                    <Typography variant='h4' fontSize={'35px'} sx={{ color: 'var(--unnamed-color-b92c82)' }}>
                        FOR BUSINESSES
                    </Typography>
                    <Typography variant='p' fontSize={'20px'} textTransform={'none'} sx={{ color: 'var(--unnamed-color-b92c82)' }} mt={1}>
                        Financing for invoices issued or received
                    </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }}>
                    <Stack width={{ xs: '100%', md: '100%' }}>
                        <Box sx={{
                            display: 'flex', [theme.breakpoints.only('xs')]: {
                                flexWrap: 'wrap',
                            }, justifyContent: 'space-between', width: '100%', alignItems: 'center'
                        }}>
                            <ListItemWithIconAndText image={circlecheck} imagealt={'check'} mt={{ xs: 2, md: 5 }}
                                textWrap={{ maxWidth: '300px', wordWrap: 'break-word' }} >
                                Issued an invoice to your buyer? Utilise cash tied up in unpaid Invoices
                            </ListItemWithIconAndText>
                            <RoundedCardImgAndText image={icon1} imagealt={'icon1'} text={'Improve cash flow'} />
                        </Box>
                        <Box sx={{
                            display: 'flex', [theme.breakpoints.only('xs')]: {
                                flexWrap: 'wrap',
                            }, justifyContent: 'space-between', width: '100%', alignItems: 'center'
                        }}>
                            <ListItemWithIconAndText image={circlecheck} imagealt={'check'}
                                textWrap={{ maxWidth: '90%', wordWrap: 'break-word' }}
                            >
                                For invoices received from suppliers, our innovative reverse invoice
                                finance option gives you more time to pay. You can even pay back in installments.
                            </ListItemWithIconAndText>
                            <RoundedCardImgAndText image={icon2} imagealt={'icon2'} text={'Acquire new clients'} />
                        </Box>
                        <Box sx={{
                            display: 'flex', [theme.breakpoints.only('xs')]: {
                                flexWrap: 'wrap',
                            }, justifyContent: 'space-between', width: '100%', alignItems: 'center'
                        }}>
                            <ListItemWithIconAndText image={circlecheck} imagealt={'check'}
                                textWrap={{ maxWidth: '80%', wordWrap: 'break-word' }}>
                                Set your finance cost limit - auction style lending ensures most competitive rates
                            </ListItemWithIconAndText>
                            <RoundedCardImgAndText image={icon3} imagealt={'icon3'} text={'Fund your next project'} />
                        </Box>
                    </Stack>
                </Stack>
            </Card>
            <Button onClick={() => navigate('/fundYourBuiseness')} sx={{
                position: 'relative',
                left: { lg: '40%', md: '33%', sm: '37%', xs: '22%' },
                padding: { xs: '10px 20px', lg: '20px 35px', md: '20px 30px', },
                top: { lg: -30, md: -40, xs: -20 }, alignSelf: 'center', color: '#fff',
                borderRadius: '11px',
                backgroundColor: 'var(--unnamed-color-b92c82)',
                '&:hover': {
                    backgroundColor: 'var(--unnamed-color-b92c82)',
                    borderColor: 'var(--unnamed-color-b92c82)',
                    boxShadow: 'none',
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: 'var(--unnamed-color-b92c82)',
                    borderColor: 'var(--unnamed-color-b92c82)',
                },
                '&:focus': {
                    boxShadow: 'var(--unnamed-color-b92c82)',
                },
            }}><Typography variant='caption' textTransform={'uppercase'} fontSize={'18px'}>FUND YOUR BUSINESS</Typography></Button>
        </Container >
    )
}

export default ForBusinessesSection
