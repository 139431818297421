import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    investmentDetails: null,
    investmentDetailsStatus: '',
    investmentDetailsLoader: false,
    investmentDetailsError: null
};
export const GetInvestmentDetails = createAsyncThunk("/GetInvestmentDetails", async (data) => {
    const response = await ApihelperFunction({
        urlPath: data?.auctionType == 'open' ? `api/funder/getOpenInvestmentDetail?bidding_id=${data?.biddingID}` : `api/funder/getCloseInvestmentDetail?bidding_id=${data?.biddingID}`,
        method: "get",
    });
    return response;
});
const investmentDetailsSlice = createSlice({
    name: 'CompanyBankDetails',
    initialState,
    reducers: {
        resetStateFundingDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetInvestmentDetails.pending, (state) => {
                state.investmentDetailsStatus = 'LOADING'
                state.investmentDetailsLoader = true
                state.investmentDetailsError = null
            })
            .addCase(GetInvestmentDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.investmentDetailsStatus = "SUCCESS"
                    state.investmentDetails = action?.payload?.data
                }
                else {
                    state.investmentDetails = []
                    state.investmentDetailsStatus = "ERROR"
                    state.investmentDetailsError = [action?.payload?.errors?.[0]]
                }
                state.investmentDetailsLoader = false
            })
            .addCase(GetInvestmentDetails.rejected, (state, action) => {
                state.investmentDetailsStatus = 'ERROR'
                state.investmentDetailsLoader = false
                state.investmentDetails = []
            })
    }
})

export const { resetStateFundingDetails } = investmentDetailsSlice.actions;
export default investmentDetailsSlice.reducer;