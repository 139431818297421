import { Toolbar, Typography } from '@mui/material'
import React from 'react'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
function StatusBadge({ text }) {
    console.log(text,'TEXTTTTTTTTTTTTTTT')
    const selectedColor = text == 'Active' ? '#3CB500' : text == 'Reject' ? '#E10000' : text == 'New' ? '#570271' : text == 'Deactive' ? '#E10000' : '#BCBCBC'
    return (
        <Toolbar sx={{ border: '1px solid', borderColor: selectedColor, borderRadius: '50px', minHeight: 'fit-content !important', padding: '5px 10px  !important', maxWidth: 'max-content' }}>
            {
                text == 'Active' && <CheckCircleOutlineRoundedIcon sx={{ color: selectedColor, marginRight: '5px', fontSize: "17px" }} />
            }
            {
                text == 'Reject' && <CancelOutlinedIcon sx={{ color: selectedColor, marginRight: '5px', fontSize: '17px' }} />
            }
            {
                text == 'Deactive' && <RemoveCircleIcon sx={{ color: selectedColor, marginRight: '5px', fontSize: '17px' }} />
            }
            {
                text == 'New' && <AddCircleOutlineIcon sx={{ color: selectedColor, marginRight: '5px', fontSize: '17px' }} />
            }
            {
                (text != 'Active' && text != 'Reject' && text != 'New' && text != 'Deactive') && <WatchLaterOutlinedIcon sx={{ color: selectedColor, marginRight: '5px', fontSize: '17px' }} />
            }
            <Typography color={selectedColor} variant="overline" component="overline" sx={{ flexGrow: 1, textAlign: 'center', textTransform: 'capitalize' }}>
                {text}
            </Typography>
        </Toolbar>
    )
}

export default StatusBadge