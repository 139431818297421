import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    statusEditEdit: '',
    dataEdit: '',
    errorEditEdit: false,
    loadingEditEdit: false,
    messageEditEdit: ''
}

export const UpdateTradingPartner = createAsyncThunk("/UpdateTradingPartner", async (addFundTransactionReqData) => {
    let data = new FormData();
    for (const key in addFundTransactionReqData) {
        if (addFundTransactionReqData.hasOwnProperty(key)) {
                data.append(key, addFundTransactionReqData[key]);
        }
    }
    const response = await ApihelperFunction({
        urlPath: "api/updateTradingPartnerChangeRequest",
        formData: data,
        method: "post",
        typecontent: true
    });
    return response;
});

const UpdateTradingPartnerSlice = createSlice({
    name: "UpdateTradingPartner",
    initialState,
    reducers: {
        resetStateUpdateTradingPartner: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(UpdateTradingPartner.pending, (state) => {
                state.statusEdit = 'LOADING'
                state.loadingEdit = true
                state.errorEdit = false
                state.messageEdit = ''
            })
            .addCase(UpdateTradingPartner.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loadingEdit = false
                    state.errorEdit = false
                    state.statusEdit = "SUCCESS"
                    state.messageEdit = action?.payload?.message
                    localStorage.removeItem('createTradingPartnerStepOne')
                    localStorage.removeItem('createTradingPartnerStepTwo')
                    localStorage.removeItem('createTradingPartnerStepOneEdit')
                }
                else {
                    state.loadingEdit = false
                    state.statusEdit = "ERROR"
                    state.errorEdit = [action?.payload?.errors]
                }
                state.data = action?.payload?.data;
            })
            .addCase(UpdateTradingPartner.rejected, (state, action) => {
                state.loadingEdit = false
                state.statusEdit = 'ERROR'
            })
    }
})

export const { resetStateUpdateTradingPartner } = UpdateTradingPartnerSlice.actions;
export default UpdateTradingPartnerSlice.reducer