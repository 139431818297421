import React, { useState } from 'react'
import { Stack, CardMedia, Typography, Container, Checkbox, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addAuctionData } from '../../../Redux/Slices/CreateAuctionSlice';
import { useEffect } from 'react';
import ErrorMessageComp from '../../../components/ErrorMessageComp';
import { UserCreditLimit } from '../../../Redux/Slices/CheckCreditLimitSlice';
import ModalComp from '../../../components/ModalComp';
const StepOne = () => {
    const auctionData = useSelector((state) => state.createAuction)
    const creditLimit = useSelector((state) => state.checkUserCreditLimit)
    const dispatch = useDispatch()
    const [activeBox, setActiveBox] = useState(auctionData?.data?.auction_type || 1)
    const [checked, setChecked] = useState(localStorage.getItem('FJChecked'))
    const [clicked, setClicked] = useState(false)
    const [modalMessage, setModalMessage] = useState()
    const [modalSubMessage, setModalSubMessage] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const boxList = [
        {
            img: 'exchange-white.svg',
            heading: 'Seller Financing',
            text: 'My business has issued an invoice to a company for goods or services sold',
            profile: 'Seller',
            auction_type: 1
        },
        {
            img: 'exchange.svg',
            heading: 'Buyer Financing',
            text: 'My business has received an invoice from a company for goods or services purchased',
            auction_type: 2,
            profile: 'Buyer'
        }
    ]
    const handleOnClick = (yourData) => {
        setClicked(true)
        if (checked) {
            localStorage.setItem('FJChecked', checked)
            dispatch(addAuctionData({ auction_type: yourData }));
        }
    };
    useEffect(() => {
        dispatch(UserCreditLimit())
    }, [])
    useEffect(() => {
        if (creditLimit.userCreditLimit) {
            if (creditLimit.userCreditLimit?.credit_limit == '0.00') {
                setModalMessage('No Credit Limit Set')
                setModalSubMessage('Please contact the admin to set your credit limit.')
                setModalOpen(true)
            } else if (creditLimit.userCreditLimit?.availableCreditLimit == 0) {
                setModalMessage('No Credits Left')
                setModalSubMessage('You have used all of your credit limit.Pay auction repayments timely to increase your credit limit')
                setModalOpen(true)
            }
        }
    }, [creditLimit.userCreditLimit])
    console.log(creditLimit.userCreditLimit, 'LIMIT')
    return (
        <>
            <Container maxWidth={'md'} sx={{ mb: '40px' }}>
                <Typography variant='p' component='p' fontWeight={'bold'} textAlign='center' color='#000' fontSize={'15px'} textTransform='none' mb={4}>
                    Create Auction to raise funds for your business against your invoices
                </Typography>
                <Stack maxWidth={'lg'} justifyContent={'center'} direction={{ xs: 'column', md: 'row' }} spacing={7}>
                    {
                        boxList?.map((box, index) => {
                            return (
                                <Box
                                    key={index}
                                    className={activeBox === box.auction_type && 'active'}
                                    sx={{
                                        bgcolor: activeBox === box.auction_type ? '#b92c82' : '#3A0CA3  ',
                                        // background: index == 0 ? 'linear-gradient(#B2235F, #2B0F6B)' : 'linear-gradient(#2B0F6B,#B2235F)',
                                        borderColor: activeBox === box.auction_type ? '#b92c82' : '#0B2244',
                                        color: '#fff',
                                        cursor: 'pointer',
                                        borderRadius: '8px'
                                    }}
                                    p={{ md: '20px', sm: '15px' }}
                                    pt={'30px !important'}
                                    pb={'30px !important'}
                                    border={1}
                                    onClick={() => setActiveBox(box.auction_type)}
                                >
                                    <Stack direction={'column'} alignItems={'center'} justifyContent={'flex-end'}  >
                                        <CardMedia sx={{
                                            width: '39%',
                                            mb: '20px',
                                            objectFit: 'contain',
                                            filter: `invert(${index == 1 ? '1' : '0'})`
                                        }} component='img' src={require(`../../../assets/img/${box.img}`)} />
                                        <Typography variant='h6' sx={{
                                            textAlign: 'center',
                                            mb: '15px',
                                            textTransform: "none",
                                            fontWeight: '600'
                                        }}
                                            fontWeight={500}
                                            color={'#fff'}
                                        >
                                            {box.heading}
                                        </Typography>
                                        <Box sx={{ width: '180px', textAlign: 'center' }}>
                                            <Typography component={'p'} variant='p' sx={{ textAlign: 'center', textTransform: 'none', lineHeight: '1.4', fontFamily: 'CustomFont', fontWeight: '600', fontSize: '12px' }} p={{ md: '', sx: '' }} color={'#fff'}>
                                                {box.text}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            )
                        })
                    }
                </Stack >
                <Stack style={{display:"flex",flexDirection:"row",alignItems:'center',marginTop:'20px',justifyContent:"center"}}>
                    <Checkbox style={{width:'max-content'}} checked={checked} onChange={() => setChecked(!checked)} size='small' name='termsandCondition' /><Typography variant='p' textAlign={'center'} textTransform={'none'} fontSize={'14px'} color={'#000'} width={'max-content'}>The goods or services provided against the invoice meets FJ’s compliance policy</Typography>
                </Stack>
                {!checked && clicked && <Stack justifyContent={'center'}>
                    <ErrorMessageComp width={'100%'} textAlign={'center'} message={"Please acknowledge this FJ's compliance policy"} />
                </Stack>}
                <Stack maxWidth={'lg'} justifyContent={'center'} alignItems={'center'} direction={{ xs: 'column', md: 'row' }} spacing={7} mt={4}>
                    <Link to='/privacyPolicy' style={{ color: "#6C6C6D", textDecoration: 'underline', marginLeft: 0 }} href="#" fontSize={'13px'}>Read FJ’s compliance policy</Link>
                </Stack>
                <Stack justifyContent="center" direction="row" margin={10}>
                    <Button variant="contained" sx={{
                        padding: '10px 60px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}
                        onClick={() => handleOnClick(activeBox)}>NEXT</Button>
                </Stack>
            </Container >
            <ModalComp subText={modalSubMessage} message={modalMessage} open={modalOpen} handleClose={() => setModalOpen(false)} />
        </>
    );
}
export default StepOne 