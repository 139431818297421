import React, { useEffect, useState } from 'react'
import { Box, Stack, Button, MenuItem, Container, Grid, FormControl, Typography, Checkbox, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import InputComp from '../../components/InputComp';
import ErrorMessageComp from '../../components/ErrorMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import { FunderRegistration, resetStateFunderRegistration } from '../../Redux/Slices/FunderRegistrationSlice';
import { toast } from 'react-toastify';
import { CompanyType } from '../../Redux/Slices/CompanyTypeSlice';
import { CompanyCategory } from '../../Redux/Slices/CompanyCategorySlice';
import { Turnover } from '../../Redux/Slices/TurnOverSlice';
import { SourceOfFund } from '../../Redux/Slices/SourceOfFundSlice';
import MyDatePicker from '../../components/Datepicker';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { EditFunder, resetStateUpdateFunder } from '../../Redux/Slices/EditFunderSlice';
import moment from 'moment';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs'
import LoginFormBg from '../../assets/img/login_form_bg.png'

dayjs.extend(customParseFormat);

const SignUp5 = () => {

    //values
    const validUrl = require('valid-url');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [values, setValues] = useState(JSON.parse(localStorage.getItem('signUp5')))
    const { companyType } = useSelector((state) => state.companyType)
    const { companyCategory } = useSelector((state) => state.companyCategory)
    const { turnover } = useSelector((state) => state.turnover)
    const { sourceOfFund } = useSelector((state) => state.sourceOfFund)
    const role = localStorage.getItem('role')
    const editData = JSON.parse(localStorage.getItem('editData'))
    const tempNoOfBanks = location?.search == '?edit' ? editData?.Funder?.funderbank_details : values?.noOfBanks
    const step2Data = JSON.parse(localStorage.getItem('signUp2'))
    const edit2Data = JSON.parse(localStorage.getItem('signUp2Edit'))
    const [noOfBanks, setNoOfBanks] = useState(tempNoOfBanks || [(step2Data?.country && step2Data?.country != 230) ? {
        bank_name: '',
        sort_code: '',
        account_number: '',
    } : {
        bank_name: '',
        sort_code: '',
        account_number: '',
        swift_code: '',
        iban: ''
    }])
    const [bankName, setBankName] = useState(values?.bank_name)
    const [sortCode, setSortCode] = useState(values?.sort_code)
    const [accountNumber, setAccountNumber] = useState(values?.account_number)
    const [accountName, setAccountName] = useState(values?.account_name)
    const [iban, setIban] = useState(values?.iban)
    const [swiftCode, setSwiftCode] = useState(values?.swift_code)
    const [isValidating, setIsValidating] = useState(false)
    const { loading, error, status, message } = useSelector(state => state.funderRegister)
    const step3Data = JSON.parse(localStorage.getItem('signUp3'))
    const step4Data = JSON.parse(localStorage.getItem('signUp4'))
    const step2EditData = JSON.parse(localStorage.getItem('signUp2NewEdit'))
    const step3EditData = JSON.parse(localStorage.getItem('signUp3NewEdit'))
    const step4EditData = JSON.parse(localStorage.getItem('signUp4NewEdit'))
    const step5EditData = JSON.parse(localStorage.getItem('signUp5NewEdit'))
    const signUp5EditData = JSON.parse(localStorage.getItem('signUp5Edit'))
    const [checked, setChecked] = useState(false)
    const actualValue = (location?.search == '?edit' && editData?.user_type == 'Funder') ? editData?.Funder?.funderbank_details : ''
    let editDefaultValuesForCompany, editDefaultValuesForFunder;
    const { editFunder, editFunderStatus, editFunderMessage, editFunderError } = useSelector(state => state.updateFunder)
    const signUp5NewEditData = JSON.parse(localStorage.getItem('signUp5NewEdit'))
    const [editNewData, setEditNewData] = useState(signUp5NewEditData)
    const [dateError, setDateError] = useState(null)


    //get edit data value 
    if (location?.search == '?edit' && !signUp5EditData) {
        editDefaultValuesForCompany = {
            'company_type': editData?.Company?.[0]?.company_type_id,
            'company_category': editData?.Company?.[0]?.company_category_id,
            'established_year': editData?.Company?.[0]?.established_year,
            'trading_period': editData?.Company?.[0]?.trading_period,
            'annual_turnover': editData?.Company?.[0]?.annual_turnover,
            'website': editData?.Company?.[0]?.website?.slice(8, editData?.Company?.[0]?.website?.length)
        }
    }
    if (location?.search == '?edit' && signUp5EditData != null) {
        editDefaultValuesForCompany = signUp5EditData
    }
    if (location?.search == '?edit' && editData?.user_type == 'Funder' && !signUp5EditData) {
        editDefaultValuesForFunder = {
            'source_of_fund': editData?.Funder?.funder_basic_detail?.source_of_fund,
        }
    }
    //default value for forms
    const defaultValues = (role == 1 || role == 'Company') ? location?.search == '?edit' ? { ...editDefaultValuesForCompany } : { established_year: values?.established_year, ...values } : location?.search == '?edit' ? { ...editDefaultValuesForFunder } : { ...values }
    const { handleSubmit, control, formState: { errors },getValues } = useForm({
        defaultValues: defaultValues
    })
    //form submit
    const onSubmit = (data) => {
        if (location?.search == '?edit' && (role == 1 || role == 'Company')) {
            if (validUrl.isUri('https://' + data.website)) {
                localStorage?.setItem("signUp5NewEdit", JSON.stringify({ ...editNewData }))
                localStorage.setItem('signUp5Edit', JSON.stringify({ ...data, 'company_type_id': data?.company_type, 'company_category_id': data?.company_category }))
                navigate('/signUp6?edit')
            } else {
                console.log('validUrlnot')
            }
        } else if (location?.search == '?edit' && (role == 2 || role == 'Funder')) {
            if (Object.keys({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData, ...editNewData }).length > 0) {
                dispatch(EditFunder({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData, ...editNewData }))
            } else {
                toast.error('' + 'No changes made to submit', {
                    style: { fontSize: "15px" },
                    autoClose: 3000,
                    onOpen: () => {
                        localStorage.removeItem('signUp2');
                        localStorage.removeItem('signUp3');
                        localStorage.removeItem('signUp4');
                        localStorage.removeItem('signUp5');
                        localStorage.removeItem('signUp2Edit');
                        localStorage.removeItem('signUp3Edit');
                        localStorage.removeItem('signUp4Edit');
                        localStorage.removeItem('signUp5Edit');
                        localStorage.removeItem('signUp6Edit');
                        localStorage.removeItem('signUp2NewEdit');
                        localStorage.removeItem('signUp3NewEdit');
                        localStorage.removeItem('signUp4NewEdit');
                        localStorage.removeItem('signUp5NewEdit');
                        localStorage.removeItem('sameAsUserDetails');
                        localStorage.removeItem('editData');
                        localStorage.removeItem('editCity');
                        localStorage.removeItem('editState');
                        localStorage.removeItem('editCountry');
                        navigate('/viewProfile')
                    }
                })
            }
        } else {
            setIsValidating(true)
            if (Object.keys(errors)?.length === 0) {
                if ((role == 1 || role == 'Company') && selectedDate && !dateError) {
                    localStorage.setItem('signUp5', JSON.stringify({ ...data, 'established_year': moment(selectedDate).format('DD/MM/YYYY') }))
                    navigate('/signUp6')
                } else if ((role == 2 || role == 'Funder')) {
                    if (values?.accept) {
                        let bankName = [], sortCode = [], accountNumber = [], accountName = [], iban = [], swiftCode = [];
                        for (let i = 0; i < noOfBanks?.length; i++) {
                            bankName?.push(noOfBanks?.[i]?.bank_name)
                            sortCode?.push(noOfBanks?.[i]?.sort_code)
                            accountNumber?.push(noOfBanks?.[i]?.account_number)
                            accountName?.push(noOfBanks?.[i]?.account_name)
                            iban?.push(noOfBanks?.[i]?.iban)
                            swiftCode?.push(noOfBanks?.[i]?.swift_code)
                        }
                        localStorage.setItem('signUp5', JSON.stringify({ ...data, 'bank_name': bankName, 'account_number': accountNumber, 'account_name': accountName, 'sort_code': sortCode, 'iban': iban, 'swift_code': swiftCode }))
                        dispatch(FunderRegistration({ ...step2Data, ...step3Data, ...step4Data, ...data, 'zipcode': step2Data?.zipcode?.toUpperCase(), 'bank_name': bankName, 'account_number': accountNumber, 'account_name': accountName, 'sort_code': sortCode, 'iban': iban, 'swift_code': swiftCode }))
                    }
                }
            }
        }
    }
    //firing toast
    useEffect(() => {
        if (error) {
            toast.error('' + error[0], error[1], error[2], error[3]);
        } if (status === "SUCCESS") {
            toast.success('' + message, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    if ((role == 2 || role == 'Funder')) {
                        dispatch(resetStateFunderRegistration())
                        localStorage.removeItem('signUp2');
                        localStorage.removeItem('signUp3');
                        localStorage.removeItem('signUp4');
                        localStorage.removeItem('signUp5');
                        localStorage.removeItem('signUp2NewEdit');
                        localStorage.removeItem('signUp3NewEdit');
                        localStorage.removeItem('signUp4NewEdit');
                        localStorage.removeItem('signUp5NewEdit');
                        localStorage.removeItem('editData');
                        localStorage.removeItem('editCity');
                        localStorage.removeItem('editState');
                        localStorage.removeItem('editCountry');
                        navigate('/signUp7', { state: 'Funder' })
                    }
                }
            });
        }
    }, [error, status]);
    useEffect(() => {
        if (editFunderError) {
            toast.error('' + editFunderError[0], editFunderError[1], editFunderError[2], editFunderError[3]);
        } if (editFunderStatus === "SUCCESS") {
            toast.success('' + editFunderMessage, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    localStorage.removeItem('signUp2');
                    localStorage.removeItem('signUp3');
                    localStorage.removeItem('signUp4');
                    localStorage.removeItem('signUp5');
                    localStorage.removeItem('signUp2Edit');
                    localStorage.removeItem('signUp3Edit');
                    localStorage.removeItem('signUp4Edit');
                    localStorage.removeItem('signUp5Edit');
                    localStorage.removeItem('signUp6Edit');
                    localStorage.removeItem('signUp2NewEdit');
                    localStorage.removeItem('signUp3NewEdit');
                    localStorage.removeItem('signUp4NewEdit');
                    localStorage.removeItem('signUp5NewEdit');
                    localStorage.removeItem('sameAsUserDetails');
                    localStorage.removeItem('editData');
                    localStorage.removeItem('editCity');
                    localStorage.removeItem('editState');
                    localStorage.removeItem('editCountry');
                    navigate('/dashboard')
                    dispatch(resetStateUpdateFunder())
                }
            });
        }
    }, [editFunderError, editFunderStatus]);

    useEffect(() => {
        if (location?.search == '?edit') {
            if (editData?.Company?.[0]?.established_year) {
                setSelectedDate(dayjs(editData?.Company?.[0]?.established_year, 'DD/MM/YYYY'))
            }
        }
    }, [])

    //onchange
    const handleChange = (e) => {
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
    }

    //data for stepper
    const dataForStepper = role == 1 || role == 'Company' ? values : { ...values, noOfBanks }
    //getCompanyType and getCompanyCategory

    useEffect(() => {
        dispatch(CompanyType())
        dispatch(CompanyCategory())
        dispatch(Turnover())
        if (role == 2 || role == 'Funder') {
            dispatch(SourceOfFund())
        }
    }, [])
    //for datepicker
    const [selectedDate, setSelectedDate] = useState(values?.established_year ? dayjs(values?.established_year, 'DD/MM/YYYY') : dayjs());
    console.log(selectedDate, values, 'selectedDate')
    const handleDateChange = (date) => {
        setSelectedDate(date);
        location?.search == '?edit' && setEditNewData({ ...editNewData, established_year: moment(date).format("DD/MM/YYYY") })
    };
    useEffect(() => {
        dispatch(resetStateFunderRegistration())
    }, [])

    //remove bank
    const removeBank = (index) => {
        const updatedBanks = [...noOfBanks]; // Create a copy of the original array
        updatedBanks.splice(index, 1); // Remove one element starting from index 1 (the second bank)
        setNoOfBanks(updatedBanks); // Update the state with the modified array
    };

    // Function to add a new blank JSON object to the array
    const addNewAccount = () => {
        const newBankDetails = [...noOfBanks];
        newBankDetails.push({
            bank_name: '',
            sort_code: '',
            account_number: '',
        });
        setNoOfBanks(newBankDetails);
    };

    // Function to update the corresponding JSON object
    const updateAccountField = (index, field, value) => {
        const updatedBankDetails = [...noOfBanks];
        updatedBankDetails[index][field] = value;
        setNoOfBanks(updatedBankDetails);
    };
    const backSaveData = () => {
        const data = getValues()
        if (location?.search == '?edit' && (role == 1 || role == 'Company')) {
            if (validUrl.isUri('https://' + data.website)) {
                localStorage?.setItem("signUp5NewEdit", JSON.stringify({ ...editNewData }))
                localStorage.setItem('signUp5Edit', JSON.stringify({ ...data, 'company_type_id': data?.company_type, 'company_category_id': data?.company_category }))
                // navigate('/signUp6?edit')
            } else {
                console.log('validUrlnot')
            }
        } else if (location?.search == '?edit' && (role == 2 || role == 'Funder')) {
            if (Object.keys({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData, ...editNewData }).length > 0) {
                dispatch(EditFunder({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData, ...editNewData }))
            }
        }
    }
    return (
        <div style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover', paddingBottom: '80px' }}>
            <HorizontalLinearAlternativeLabelStepper extraFunctionality={() => backSaveData()} val={4} data={dataForStepper} toolTip={noOfBanks?.length > 1 && !(noOfBanks[noOfBanks?.length - 1].bank_name || noOfBanks[noOfBanks?.length - 1].sort_code || noOfBanks[noOfBanks?.length - 1].account_number || noOfBanks[noOfBanks?.length - 1].account_name) ? `You need to either fillup atleast one information for bank details ${noOfBanks?.length} or you have to remove the bank details ${noOfBanks?.length} to go back` : null} />
            {
                (role == 1 || role == 'Company') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                    <Box
                        // component="form"
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='company_type'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Company type  *</Typography></label>
                                            <TextField
                                                id="outlined-select"
                                                name='company_type'
                                                select
                                                onBlur={handleChange}
                                                onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'company_type_id': e.target.value }) }} value={value || 'Company type *'}
                                                defaultValue=""
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {/* <MenuItem disabled selected value='Company type *'>
                                                    <Typography variant='p' textTransform={'none'}>Company type *</Typography>
                                                </MenuItem> */}
                                                {companyType && companyType?.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        <Typography variant='p' color={'#000'}>{option.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.company_type?.type === 'required' && <ErrorMessageComp message={'Company Type is required'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='company_category'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Business type *</Typography></label>
                                            <TextField
                                                id="outlined-select"
                                                select
                                                name='company_category'
                                                onBlur={handleChange}
                                                onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'company_category_id': e.target.value }) }} value={value || 'Business type *'}
                                                defaultValue=""
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {companyCategory && companyCategory?.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        <Typography variant='p' color={'#000'}>{option.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.company_category?.type === 'required' && <ErrorMessageComp message={'Business type is required'} />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid mt={2} className='registration-datepicker' item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Company registration date *</Typography></label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled={location?.search == '?edit'}
                                        className='custom-datepicker black-datepicker'
                                        format='DD/MM/YYYY'
                                        value={selectedDate}
                                        maxDate={dayjs()}
                                        onError={(newError) => setDateError(newError)}
                                        onChange={(val) => { setSelectedDate(val?.$d) }}
                                        renderInput={(params) => <TextField sx={{ ":-ms-input-placeholder": '#fff' }} {...params} variant="outlined" onChange={(newValue) => console.log(newValue)} />} // Use TextField for input
                                    />
                                </LocalizationProvider>
                                {isValidating && !selectedDate && <ErrorMessageComp message={'Company registration date is required'} />
                                }
                                {dateError && <ErrorMessageComp message={'Please select a valid date'} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='trading_period'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Trading Period *</Typography></label>

                                            <TextField
                                                id="outlined-select"
                                                name='trading_period'
                                                select
                                                onBlur={handleChange}
                                                onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'trading_period': e.target.value }) }} value={value || 'Trading Period *'}
                                                defaultValue=""
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {/* <MenuItem disabled selected value='Trading Period *'>
                                                    <Typography variant='p' textTransform={'none'}>Trading Period *</Typography>
                                                </MenuItem> */}
                                                {turnover && turnover?.tradingperiod?.map((option) => (
                                                    <MenuItem key={option.id} value={option.range}>
                                                        <Typography variant='p' color={'#000'}>{option.range}</Typography>
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.trading_period?.type === 'required' && <ErrorMessageComp message={'Trading period is required'} />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='annual_turnover'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Annual turnover (£) *</Typography></label>

                                            <TextField
                                                id="outlined-select"
                                                name='annual_turnover'
                                                select
                                                onBlur={handleChange}
                                                onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'annual_turnover': e.target.value }) }} value={value || 'Annual turnover (£) *'}
                                                defaultValue=""
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {/* <MenuItem disabled selected value='Annual turnover (£) *'>
                                                    <Typography variant='p' textTransform={'none'}>Annual turnover (£) *</Typography>
                                                </MenuItem> */}
                                                {turnover && turnover?.annualTurnOver?.map((option) => (
                                                    <MenuItem key={option.id} value={option.turnover_range}>
                                                        <Typography variant='p' color={'#000'}>{option.turnover_range}</Typography>
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.annual_turnover?.type === 'required' && <ErrorMessageComp message={'Annual turnover is required'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp ibutton={'You dont need to put https:// again'} setEditData={setEditNewData} editData={editNewData} pattern={/^([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}$/} startText={'https://'} onChanges={handleChange} width='100%' required={true} name={'website'} placeholder={'Website *'} control={control} fieldType={'website'} />
                                {errors.website?.type === 'required' && <ErrorMessageComp message={'Website address is required'} />
                                }
                                {errors.website?.type === 'pattern' && <ErrorMessageComp message={'Website address is invalid.(If you have added https:// again you have to remove it)'} />
                                }
                            </Grid>
                        </Grid>

                        <Stack justifyContent="center" direction="row" margin={10} mb={0}>

                            <Button variant="contained" sx={{
                                padding: '10px 60px',
                                backgroundColor: '#B92C82',
                                textTransform: 'Uppercase'
                            }}
                                onClick={handleSubmit(onSubmit)}
                            >
                                <Typography variant='p' color='#fff'>NEXT</Typography>
                            </Button>
                        </Stack>
                    </Box >
                </Container>
            }
            {
                (role == 2 || role == 'Funder') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                    <Container maxWidth={'100%'} sx={{ paddingBottom: '20px' }}>
                        <Box component='form' container sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
                                noOfBanks?.length > 1 && <Box width={'100%'} marginLeft={1}><Typography variant='p' component={'p'} mb={0} color={'#B92C82'} fontWeight={600} > Bank details {1}</Typography></Box>
                            }
                            <Box width={'48%'} marginLeft={1}>
                                <Controller
                                    name='source_of_fund'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Source of funds *</Typography></label>
                                            <TextField
                                                id="outlined-select"
                                                name='source_of_fund'
                                                select
                                                onBlur={handleChange}
                                                disabled={location?.search == '?edit'}
                                                onChange={(e) => { onChange(e); setEditNewData({ 'source_of_fund': e.target.value }) }} value={value || 'Source of funds *'}
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {/* <MenuItem disabled selected value='Source of funds *'>
                                                    <Typography variant='p' textTransform={'none'}>Source of funds *</Typography>
                                                </MenuItem> */}
                                                {sourceOfFund && sourceOfFund?.map((option) => (
                                                    <MenuItem key={option.id} value={option.name}>
                                                        <Typography variant='p' color={'#000'}>{option.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.source_of_fund?.type === 'required' && <ErrorMessageComp message={'Source of fund is required'} />
                                }

                            </Box>
                            {
                                noOfBanks?.map((number, index) => {
                                    return (
                                        <>
                                            {
                                                index != 0 && <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '64px', marginLeft: '0px' }} width={'100%'}>
                                                    {
                                                        noOfBanks?.length > 1 && <Box width={'100%'} marginLeft={1}><Typography variant='p' component={'p'} mb={0} color={'#B92C82'} fontWeight={600} > Bank details {index + 1}</Typography></Box>
                                                    }
                                                    {
                                                        index != 0 && location?.search != '?edit' && < Button color='primary' onClick={() => removeBank(index)} marginTop={'32px'}><Typography fontWeight={600} variant='p' color={'#B92C82'}>Remove</Typography></Button>
                                                    }
                                                </Box>
                                            }
                                            <Box width={'48%'} marginLeft={1}>
                                                <Controller
                                                    name='bank_name'
                                                    control={control}
                                                    render={({ field: { value } }) => (
                                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Bank name *</Typography></label>
                                                            <TextField
                                                                id="outlined-select"
                                                                name='bank_name'
                                                                disabled={location?.search == '?edit'}
                                                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                                onBlur={handleChange}
                                                                onChange={(e) => {
                                                                    if (e.target.value.length <= 20) {
                                                                        updateAccountField(index, 'bank_name', e.target.value)
                                                                    }
                                                                }}
                                                                value={noOfBanks[index].bank_name}
                                                                sx={{
                                                                    mt: 0,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                        border: 'none',
                                                                        borderRadius: '7px',
                                                                        marginLeft: 'auto',
                                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                                        height: { md: '50px', xs: '50px' },
                                                                        boxShadow: '0px 3px 10px #0000000D',
                                                                        opacity: 1
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        textTransform: 'capitalize'
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                        </FormControl>
                                                    )}
                                                />
                                                {
                                                    isValidating && !noOfBanks?.[index]?.bank_name && <ErrorMessageComp message={'Bank name is required'} />
                                                }
                                                {isValidating && noOfBanks?.[index]?.bank_name?.length > 40 && <ErrorMessageComp message={'Bank name can have maximum 40 characters'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.bank_name?.trim() === '' && <ErrorMessageComp message={'Bank name can not have only spaces'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.bank_name && noOfBanks?.[index]?.bank_name?.length > 20 && <ErrorMessageComp message={'Bank name can have maximum 20 characters'} />
                                                }
                                            </Box>
                                            {!((step2Data?.country && step2Data?.country != 230) || (step2EditData?.country && step2EditData?.country != 230) || (edit2Data?.country && edit2Data?.country != 230)) && <Box width={'48%'} marginLeft={1}>
                                                <Controller
                                                    name='sort_code'
                                                    control={control}
                                                    // rules={{ required: true }}
                                                    render={({ field: { value } }) => (
                                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Sort code *</Typography></label>
                                                            <TextField
                                                                id="outlined-select"
                                                                name='sort_code'
                                                                onBlur={handleChange}
                                                                disabled={location?.search == '?edit'}
                                                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s-]/g, '')}
                                                                onChange={(e) => { if (e.target.value.length <= 8) { updateAccountField(index, 'sort_code', e.target.value) } }}
                                                                value={noOfBanks[index].sort_code}
                                                                sx={{
                                                                    mt: 0,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                        border: 'none',
                                                                        borderRadius: '7px',
                                                                        marginLeft: 'auto',
                                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                                        height: { md: '50px', xs: '50px' },
                                                                        boxShadow: '0px 3px 10px #0000000D',
                                                                        opacity: 1
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                        </FormControl>
                                                    )}
                                                />
                                                {
                                                    isValidating && !noOfBanks?.[index]?.sort_code && <ErrorMessageComp message={'Sort code is required'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.sort_code?.trim() === '' && <ErrorMessageComp message={'Sort code can not have only spaces'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.sort_code && noOfBanks?.[index]?.sort_code?.length > 6 && <ErrorMessageComp message={'Sort code can have maximum 6 characters'} />
                                                }
                                            </Box>}
                                            {((step2Data?.country && step2Data?.country != 230) || (step2EditData?.country && step2EditData?.country != 230) || (edit2Data?.country && edit2Data?.country != 230)) &&
                                                <>
                                                    <Box width={'48%'} marginLeft={1}>
                                                        <Controller
                                                            name='iban'
                                                            control={control}
                                                            // rules={{ required: true }}
                                                            render={({ field: { value } }) => (
                                                                <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                                    <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>IBAN *</Typography></label>
                                                                    <TextField
                                                                        id="outlined-select"
                                                                        // placeholder='IBAN *'
                                                                        name='iban'
                                                                        disabled={location?.search == '?edit'}
                                                                        onBlur={handleChange}
                                                                        onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')}
                                                                        onChange={(e) => { if (e.target.value.length <= 30) { updateAccountField(index, 'iban', e.target.value) } }}
                                                                        value={noOfBanks[index].iban}
                                                                        sx={{
                                                                            mt: 0,
                                                                            '& .MuiOutlinedInput-root': {
                                                                                background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                                border: 'none',
                                                                                borderRadius: '7px',
                                                                                marginLeft: 'auto',
                                                                                width: { lg: '100%', md: '400px', xs: '100%' },
                                                                                height: { md: '50px', xs: '50px' },
                                                                                boxShadow: '0px 3px 10px #0000000D',
                                                                                opacity: 1
                                                                            }
                                                                        }}
                                                                    >
                                                                    </TextField>
                                                                </FormControl>
                                                            )}
                                                        />
                                                        {
                                                            isValidating && !noOfBanks?.[index]?.iban && <ErrorMessageComp message={'IBAN is required'} />
                                                        }
                                                        {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.iban?.trim() === '' && <ErrorMessageComp message={'IBAN can not have only spaces'} />
                                                        }
                                                        {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.iban && noOfBanks?.[index]?.iban?.length > 30 && <ErrorMessageComp message={'IBAN can have maximum 30 characters'} />
                                                        }
                                                    </Box>
                                                    <Box width={'48%'} marginLeft={1}>
                                                        <Controller
                                                            name='swift_code'
                                                            control={control}
                                                            // rules={{ required: true }}
                                                            render={({ field: { value } }) => (
                                                                <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                                    <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Swift code *</Typography></label>
                                                                    <TextField
                                                                        id="outlined-select"
                                                                        // placeholder='Swift code *'
                                                                        name='swift_code'
                                                                        disabled={location?.search == '?edit'}
                                                                        onBlur={handleChange}
                                                                        onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')}
                                                                        onChange={(e) => { if (e.target.value.length <= 30) { updateAccountField(index, 'swift_code', e.target.value) } }}
                                                                        value={noOfBanks[index]?.swift_code?.toUpperCase()}
                                                                        sx={{
                                                                            mt: 0,
                                                                            '& .MuiOutlinedInput-root': {
                                                                                background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                                border: 'none',
                                                                                borderRadius: '7px',
                                                                                marginLeft: 'auto',
                                                                                width: { lg: '100%', md: '400px', xs: '100%' },
                                                                                height: { md: '50px', xs: '50px' },
                                                                                boxShadow: '0px 3px 10px #0000000D',
                                                                                opacity: 1
                                                                            },
                                                                            // '& .MuiInputBase-input': {
                                                                            //     textTransform: 'uppercase'
                                                                            // }
                                                                        }}
                                                                    >
                                                                    </TextField>
                                                                </FormControl>
                                                            )}
                                                        />
                                                        {
                                                            isValidating && !noOfBanks?.[index]?.swift_code && <ErrorMessageComp message={'Swift code is required'} />
                                                        }
                                                        {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.swift_code?.trim() === '' && <ErrorMessageComp message={'Swift code can not have only spaces'} />
                                                        }
                                                        {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.swift_code && noOfBanks?.[index]?.swift_code?.length > 30 && <ErrorMessageComp message={'Swift code can have maximum 30 characters'} />
                                                        }
                                                    </Box>
                                                </>
                                            }
                                            <Box width={'48%'} marginLeft={1}>
                                                <Controller
                                                    name='account_name'
                                                    control={control}
                                                    // rules={{ required: true }}
                                                    render={({ field: { value } }) => (
                                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Account name *</Typography></label>

                                                            <TextField
                                                                id="outlined-select"
                                                                // placeholder='Account name *'
                                                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                                name='account_name'
                                                                disabled={location?.search == '?edit'}
                                                                onBlur={handleChange}
                                                                // onChange={(e) => setValue(index, e.target.value, setAccountName, accountName)} value={accountName?.[index] || actualValue?.[index]?.account_name || ''}
                                                                onChange={(e) => { if (e.target.value.length <= 30) { updateAccountField(index, 'account_name', e.target.value) } }}
                                                                value={noOfBanks[index].account_name}
                                                                sx={{
                                                                    mt: 0,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                        border: 'none',
                                                                        borderRadius: '7px',
                                                                        marginLeft: 'auto',
                                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                                        height: { md: '50px', xs: '50px' },
                                                                        boxShadow: '0px 3px 10px #0000000D',
                                                                        opacity: 1
                                                                    },
                                                                    '& .MuiInputBase-input': {
                                                                        textTransform: 'capitalize'
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                        </FormControl>
                                                    )}
                                                />
                                                {
                                                    isValidating && !noOfBanks?.[index]?.account_name && <ErrorMessageComp message={'Account name is required'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.account_name?.trim() === '' && <ErrorMessageComp message={'Account name can not have only spaces'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.account_name && noOfBanks?.[index]?.account_name?.length > 30 && <ErrorMessageComp message={'Account name can have maximum 30 characters'} />
                                                }
                                            </Box>
                                            <Box width={'48%'} marginLeft={1}>
                                                <Controller
                                                    name='account_number'
                                                    control={control}
                                                    // rules={{ required: true }}
                                                    render={({ field: { value } }) => (
                                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Account number *</Typography></label>
                                                            <TextField
                                                                name='account_number'
                                                                id="outlined-select"
                                                                // placeholder='Account number *'
                                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                                disabled={location?.search == '?edit'}
                                                                onBlur={handleChange}
                                                                // onChange={(e) => setValue(index, e.target.value, setAccountNumber, accountNumber)} value={accountNumber?.[index] || actualValue?.[index]?.account_number || ''}
                                                                onChange={(e) => { if (e.target.value.length <= 20) { updateAccountField(index, 'account_number', e.target.value) } }}
                                                                value={noOfBanks[index].account_number || null}
                                                                sx={{
                                                                    mt: 0,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                        border: 'none',
                                                                        borderRadius: '7px',
                                                                        marginLeft: 'auto',
                                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                                        height: { md: '50px', xs: '50px' },
                                                                        boxShadow: '0px 3px 10px #0000000D',
                                                                        opacity: 1
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                        </FormControl>
                                                    )}
                                                />
                                                {
                                                    isValidating && !noOfBanks?.[index]?.account_number && <ErrorMessageComp message={'Account number is required'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.account_number?.trim() === '' && <ErrorMessageComp message={'Account number can not have only spaces'} />
                                                }
                                                {location?.search != '?edit' && isValidating && noOfBanks?.[index]?.account_number && noOfBanks?.[index]?.account_number?.length > 20 && <ErrorMessageComp message={'Account number can have maximum 20 digits'} />
                                                }
                                            </Box>
                                            {
                                                index == 0 && <Box width={'48%'} marginLeft={1}></Box>
                                            }
                                        </>
                                    )
                                })
                            }
                        </Box>
                    </Container>
                    <Container>
                        {location?.search != '?edit' && noOfBanks?.length < 4 && <Grid container spacing={2}>
                            {((step2Data?.country && step2Data?.country == 230) || (step2EditData?.country && step2EditData?.country == 230) || (edit2Data?.country && edit2Data?.country == 230)) && (noOfBanks[noOfBanks?.length - 1].bank_name && noOfBanks[noOfBanks?.length - 1].account_number && noOfBanks[noOfBanks?.length - 1].sort_code && noOfBanks[noOfBanks?.length - 1]?.account_name && noOfBanks[noOfBanks?.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].account_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].account_number?.trim() !== '' && noOfBanks[noOfBanks.length - 1].sort_code?.trim() !== '') && <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '15px' }}>
                                    <Link marginTop={0} color="#3A0CA3" justifyContent="center" href="#" fontSize='15px' style={{
                                        textTransform: 'none', fontWeight: '500', textDecoration: "underline", cursor: 'pointer'
                                    }}
                                        onClick={() => setNoOfBanks([...noOfBanks, {}])}>Add more accounts</Link>
                                    <ControlPointIcon sx={{
                                        marginTop: { xs: 2, md: 0 },
                                        marginLeft: '10px',
                                        marginBottom: '2px',
                                        color: '#3A0CA3',
                                        cursor: 'pointer'
                                    }} fontSize='15px' onClick={() => setNoOfBanks([...noOfBanks, {}])}> </ControlPointIcon>
                                </div>
                            </Grid>}
                            {((step2Data?.country && step2Data?.country != 230) || (step2EditData?.country && step2EditData?.country != 230) || (edit2Data?.country && edit2Data?.country != 230)) && (noOfBanks[noOfBanks?.length - 1].bank_name && noOfBanks[noOfBanks?.length - 1].account_number && noOfBanks[noOfBanks?.length - 1].iban && noOfBanks[noOfBanks?.length - 1]?.swift_code && noOfBanks[noOfBanks?.length - 1]?.account_name && noOfBanks[noOfBanks?.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].account_name?.trim() !== '' && noOfBanks[noOfBanks.length - 1].account_number?.trim() !== '' && noOfBanks[noOfBanks.length - 1].iban?.trim() !== '' && noOfBanks[noOfBanks.length - 1].swift_code?.trim() !== '') && <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '15px' }}>
                                    <Link marginTop={0} color="#3A0CA3" justifyContent="center" href="#" fontSize='15px' style={{
                                        textTransform: 'none', fontWeight: '500', textDecoration: "underline", cursor: 'pointer'
                                    }}
                                        onClick={() => setNoOfBanks([...noOfBanks, {}])}>Add more accounts</Link>
                                    <ControlPointIcon sx={{
                                        marginTop: { xs: 2, md: 0 },
                                        marginLeft: '10px',
                                        marginBottom: '2px',
                                        color: '#3A0CA3',
                                        cursor: 'pointer'
                                    }} fontSize='15px' onClick={() => setNoOfBanks([...noOfBanks, {}])}> </ControlPointIcon>
                                </div>
                            </Grid>}
                        </Grid>}
                        <Grid container spacing={2} mt={4} mb={0}>
                            {
                                location?.search != '?edit' &&
                                <Grid sx={{ margin: 'auto' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
                                        <Checkbox sx={{ paddingLeft: 0 }} name='accept' checked={values?.accept} onChange={() => { setValues({ ...values, 'accept': !checked }); setChecked(!checked) }} size='small' required={true} /> <Link to="/termsandcondition" target='_blank' style={{ color: "#6C6C6D", textDecoration: 'underline' }} >Accept terms & conditions *</Link>
                                    </Grid>
                                    {isValidating && !values?.accept && <ErrorMessageComp textAlign={'center'} width={'100%'} message={"Please accept the terms and conditions"} />
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={2} mt={0.5} mb={4}>
                                <Typography textAlign={'center'} fontSize={'13px'} width={'100%'} fontWeight={600}>Note* :- If you want to edit bank details contact Admin.</Typography>
                        </Grid>
                    </Container>
                    <Stack justifyContent="center" direction="row" marginBottom={0}>
                        <Button variant="contained" disabled={(location?.search != '?edit' && ((step2Data?.country && step2Data?.country != 230) || (step2EditData?.country && step2EditData?.country != 230) || (edit2Data?.country && edit2Data?.country != 230)) ? !(noOfBanks[noOfBanks?.length - 1].bank_name && noOfBanks[noOfBanks?.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks?.length - 1].iban && noOfBanks[noOfBanks.length - 1].iban?.trim() !== '' && noOfBanks[noOfBanks?.length - 1].account_number && noOfBanks[noOfBanks.length - 1].account_number?.trim() !== '' && noOfBanks[noOfBanks?.length - 1]?.account_name && noOfBanks[noOfBanks.length - 1].account_name?.trim() !== '' && noOfBanks[noOfBanks?.length - 1]?.swift_code && noOfBanks[noOfBanks.length - 1].swift_code?.trim() !== '') : !(noOfBanks[noOfBanks?.length - 1].bank_name && noOfBanks[noOfBanks?.length - 1].bank_name?.trim() !== '' && noOfBanks[noOfBanks?.length - 1].sort_code && noOfBanks[noOfBanks.length - 1].sort_code?.trim() !== '' && noOfBanks[noOfBanks?.length - 1].account_number && noOfBanks[noOfBanks.length - 1].account_number?.trim() !== '' && noOfBanks[noOfBanks?.length - 1]?.account_name && noOfBanks[noOfBanks.length - 1].account_name?.trim() !== ''))} sx={{
                            padding: '10px 60px',
                            backgroundColor: '#B92C82',
                            textTransform: 'Uppercase'
                        }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {
                                !loading && <Typography variant='p' color='#fff'>SUBMIT</Typography>
                            }
                            {
                                loading && <CircularProgress color="white" size={'25px'} />
                            }
                        </Button>
                    </Stack>

                </Container >
            }
        </div >

    );
}
export default SignUp5