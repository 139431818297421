import { FormControl, Slider, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 5000,
        label: '5000',
    },
    {
        value: 10000,
        label: '10000',
    },
];

const ValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
        <span>
            {children}
            {open ? <span>{value}</span> : null}
        </span>
    );
};

const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const removeCommas = (x) => {
    return x?.replace(/,/g, '');
};
function SliderInputComp({ beforeText, fixedValue, dontShowLesserValue, dontShowGreaterValue, required, showSlider, control, width, key, setValue, marks, marginLeft, percentageValue, amount_name, name, minValue, maxValue, defaultValue, amount, mt, marginBottom, disabled }) {
    useEffect(() => {
        if (percentageValue) {
            setValue(amount_name, amount)
        }
    }, [amount])
    console.log(amount,'AMOUNT')
    return (
        <Controller
            key={key}
            name={name}
            control={control}
            rules={{
                required: required
            }}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
                <div style={{ marginTop: mt || 0 }}>
                    {showSlider && <Slider
                        size="small"
                        aria-label="Small"
                        marks={marks}
                        value={typeof value === 'number' ? value : 0}
                        ValueLabelComponent={ValueLabelComponent}
                        onChange={(e, newValue) => {
                            if (dontShowLesserValue) {
                                if (newValue >= fixedValue) {
                                    onChange(newValue)
                                }
                            } else if (dontShowGreaterValue) {
                                if (newValue <= fixedValue) {
                                    onChange(newValue)
                                }
                            } else {
                                onChange(newValue)
                            }
                        }}
                        min={minValue}
                        max={maxValue}
                        defaultValue={defaultValue}
                        sx={{ width: width || '85%', marginBottom: marginBottom || "25px" }}
                    />}
                    <div style={{ display: 'flex' }}>
                        <FormControl className={percentageValue ? 'DisabledFormControl' : ''} sx={{ width: { lg: percentageValue ? '47%' : '85%', md: '85%', xs: '85%' }, display: 'flex', flexDirection: "row", alignItems: 'center', borderRadius: '7px', border: "1px solid grey", paddingLeft: '10px', boxShadow: '0px 3px 10px #0000000D' }}>
                            <span style={{ fontSize: '15px', color: 'grey', marginRight: '15px' }}>{beforeText}</span>
                            <Input
                                key={amount}
                                disabled={percentageValue ? true : disabled ? true : false}
                                disableUnderline={true}
                                value={percentageValue ? amount ? numberWithCommas(amount) : minValue : value ? numberWithCommas(value) : minValue}
                                onChange={(e) => {
                                    const newValue = e.target.value === '' ? '' : Number(removeCommas(e.target.value));
                                    onChange(newValue)

                                    // if (newValue <= maxValue) {
                                    //     onChange(newValue)
                                    // }
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        height: '40px !important',
                                        fontSize: "15px !important"
                                    }
                                }}
                                inputProps={{
                                    step: 1000,
                                    min: 0,
                                    max: 10000,
                                    type: 'text',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </FormControl>
                        {percentageValue &&
                            <FormControl className='DisabledFormControl' sx={{ width: { lg: percentageValue ? '47%' : '85%', md: '85%', xs: '85%' }, display: 'flex', flexDirection: "row", alignItems: 'center', borderRadius: '7px', border: "1px solid grey", paddingRight: '10px', paddingLeft: '5px', boxShadow: '0px 3px 10px #0000000D', marginLeft: '20px' }}>
                                <TextField
                                    name='valuesFromSlider'
                                    value={value}
                                    disabled={percentageValue ? true : false}
                                    onChange={(e) => {
                                        const newValue = e.target.value === '' ? '' : Number(e.target.value);
                                        onChange(newValue);
                                    }}
                                    sx={{
                                        mt: 0,
                                        fieldset: { borderColor: "transparent !important" },
                                        width: "100%",
                                        '& .MuiOutlinedInput-root': {
                                            background: 'transparent',
                                            border: 'none',
                                            marginLeft: marginLeft || 'auto',
                                            width: { lg: percentageValue ? '90%' : '85%', md: '400px', xs: '100%' },
                                            height: { md: '50px', xs: '50px' },
                                            opacity: 1,
                                            fontSize: '15px',
                                            color: '#6C6C6D',
                                            outline: 'none'
                                        },
                                        '& .MuiInputBase-input': {
                                            color: '#000 !important',
                                            border: 'none'
                                        }
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 0,
                                        max: 100,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                                <span style={{ fontSize: '15px', color: 'grey' }}>%</span>
                            </FormControl>
                        }
                    </div>
                </div>
            )}
        />
    )
}

export default SliderInputComp