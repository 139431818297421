import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    stepNo: 1,
    data: '',
    status: '',
    loading: false,
    response: '',
    message: '',
    error: ''
}
export const CreateAuction = createAsyncThunk("/CreateAuction", async (auctionData) => {
    let data = new FormData();
    console.log(data, auctionData, 'DATAAAAAAAAA CREATE AUCTION')
    for (const key in auctionData) {
        if (auctionData?.hasOwnProperty(key)) {
            if (key != 'repayments' && key != 'funding_purpose') {
                data.append(key, auctionData?.[key]);
            }
        }
    }
    data.append('funding_purpose', JSON.stringify(auctionData.funding_purpose));
    data.append('repayments', JSON.stringify(auctionData.repayments));
    const response = await ApihelperFunction({
        urlPath: "api/addCompanyAuctionInitial",
        formData: data,
        method: "post",
        typecontent: true
    });
    return response;
});

const CreateAuctionSlice = createSlice({
    name: "CreateAuction",
    initialState,
    reducers: {
        resetStateCreateAuction: () => initialState,
        addAuctionData: (state, action) => {
            console.log(action?.payload, 'PAYLOAD')
            return {
                ...state,
                data: { ...state.data, ...action.payload },
                stepNo: state.stepNo + 1
            }
        },
        goToNextStep: (state) => {
            state.stepNo += 1
        },
        goToPerticularStep: (state, action) => {
            state.stepNo = action.payload
        },
        backToPreviousStep: (state) => {
            state.stepNo -= 1
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CreateAuction.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(CreateAuction.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loading = false
                    state.error = false
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                }
                else {
                    state.loading = false
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors]
                }
                // state.data = action?.payload?.data;
            })
            .addCase(CreateAuction.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERROR'
            })
    }
})

export const { resetStateCreateAuction, addAuctionData, backToPreviousStep, goToNextStep,goToPerticularStep } = CreateAuctionSlice.actions;
export default CreateAuctionSlice.reducer