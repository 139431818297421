import {
    Box, FormControl, Container, Typography, CircularProgress
} from "@mui/material"
import { useForm } from "react-hook-form"
import LoginCustomButton from "../../components/LoginCustomButton";
import FormContainer from "./FormContainer";
import InputComp from "../../components/InputComp";
import ErrorMessageComp from "../../components/ErrorMessageComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { VerifyEmail, resetStateEmailVerification } from '../../Redux/Slices/EmailVerificationSLice'
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { VerifyEmailForgotPassword, resetStateForgetPassword } from "../../Redux/Slices/ForgotPasswordSlice";


const ForgetPassword = () => {

    //values
    const { handleSubmit, control, formState: { errors }, getValues } = useForm()
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user_Type, setUser_Type] = useState()
    const dataFromSliceA = useSelector(state => state.emailVerify);
    const dataFromSliceB = useSelector(state => state.forgotPassword);
    const { userEmail, userType, status, loading, message, error, token } =
        location.pathname == '/emailVerify' ? dataFromSliceA : dataFromSliceB

    //set user type
    useEffect(() => {
        if (location?.state === 'Company') {
            setUser_Type(1)
        } else if (location?.state === 'Funder') {
            setUser_Type(2)
        }
    }, [location])

    //firing toast and further navigation
    useEffect(() => {
        if (error) {
            toast.error('' + error, {
                style: { fontSize: "15px" }
            });
        } if (status === "SUCCESS") {
            toast.success('' + 'OTP has been emailed to you', {
                style: { fontSize: '15px' },
                onOpen: () => {
                    if (location.pathname == '/emailVerify') {
                        navigate('/emailverification', { state: { useremail: userEmail, usertype: userType } })
                        dispatch(resetStateForgetPassword())
                        dispatch(resetStateEmailVerification())
                        localStorage.setItem('userEmail', userEmail)
                    } else {
                        navigate('/otpVerificaton', { state: { useremail: userEmail, usertype: userType, token: token } })
                        dispatch(resetStateEmailVerification())
                        dispatch(resetStateForgetPassword())
                        localStorage.setItem('token', token)
                    }
                }
            });
        }
    }, [error, status]);

    //form submit
    const onSubmit = (data) => {
        localStorage.setItem('userEmail', data.email)
        location.pathname == '/emailVerify' ?
            dispatch(VerifyEmail({ ...data, user_type: user_Type })) :
            dispatch(VerifyEmailForgotPassword(data))
    }

    //enter key
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const values = getValues()
            localStorage.setItem('userEmail', values.email)
            location.pathname == '/emailVerify' ?
                dispatch(VerifyEmail({ ...values, user_type: user_Type })) :
                dispatch(VerifyEmailForgotPassword(values))
        }
    };

    return <FormContainer>
        <Container
            sx={{
                display: 'flex', flexDirection:
                    'column', alignItems: 'center',
                mt: { md: 0, sm: 0, xs: 5 },
                mb: { md: 10, sm: 0, xs: 5 }
            }}>
            <Box sx={{
                width: { lg: '457px', md: '400px', xs: '290px' },
                color: 'var(--unnamed-color-0b2244)'
            }}>
                <Typography width={'85%'} ml={'auto'} variant="h2" fontWeight={700} align="center">
                    {location?.pathname === '/emailVerify' ? 'Email verification' : 'Forgot password'}
                </Typography>
            </Box>
            <FormControl sx={{ width: { lg: '457px', md: '400px', xs: '290px' }, mt: { md: 1, xs: 1 } }}>
                <InputComp fieldType={'email'} onKeyDown={handleKeyDown} maxLength={40} required={true} name={'email'} placeholder={'Email *'} control={control}
                    pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} startIcon={true} startImg={'email.png'} />

                {errors.email?.type === 'required' && <ErrorMessageComp message={'Email is required'} />
                }
                {errors.email?.type === 'pattern' && <ErrorMessageComp message={'Invalid email address'} />
                }
                {errors.email?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
                }
                <Typography width={'85%'} ml={'auto'} textAlign={'center'} variant="overline" fontWeight={600} color='#A5A5A5' align="center" sx={{ display: "block", marginTop: '15px', textTransform: 'none' }}>
                    {location?.pathname === '/emailVerify' ? 'We will send a verification to your email address.' : 'We will send an OTP to your email address.'}
                </Typography>
            </FormControl>
            <FormControl sx={{ mt: 2, width: { lg: '457px', md: '400px', xs: '290px' } }}>
                <LoginCustomButton onClick={handleSubmit(onSubmit)}>
                    {
                        !loading && <Typography variant="h6" component={'h6'} sx={{ color: '#fff' }}>
                            SUBMIT
                        </Typography>
                    }
                    {
                        loading && <CircularProgress color="white" />
                    }
                </LoginCustomButton>
            </FormControl>
        </Container>
    </FormContainer>
}

export default ForgetPassword