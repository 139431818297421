import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    tradingPartnerListInitial:[],
    tradingPartnerList: [],
    tradingPartnerListStatus: '',
    tradingPartnerListLoader: false,
    tradingPartnerListError: null
};
export const TradingPartnerList = createAsyncThunk("/TradingPartnerList", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getTradingPartnerList`,
        method: "get",
    });
    return response;
});
const tradingPartnerListSlice = createSlice({
    name: 'TradingPartnerList',
    initialState,
    reducers: {
        resetStateTradingPartnerList: () => initialState,
        editTradingPartnerList: (state, action) => {
            state.tradingPartnerList = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(TradingPartnerList.pending, (state) => {
                state.tradingPartnerListStatus = 'LOADING'
                state.tradingPartnerListLoader = true
                state.tradingPartnerListError = null
            })
            .addCase(TradingPartnerList.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.tradingPartnerListStatus = "SUCCESS"
                    state.tradingPartnerListInitial = action?.payload?.data
                    state.tradingPartnerList = action?.payload?.data
                }
                else {
                    state.tradingPartnerListStatus = "ERROR"
                    state.tradingPartnerListError = [action?.payload?.errors?.[0]]
                }
                state.tradingPartnerListLoader = false
            })
            .addCase(TradingPartnerList.rejected, (state, action) => {
                state.tradingPartnerListStatus = 'ERROR'
                state.tradingPartnerListLoader = false
            })
    }
})

export const { resetStateTradingPartnerList, editTradingPartnerList } = tradingPartnerListSlice.actions;
export default tradingPartnerListSlice.reducer;