import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    status: '',
    data: '',
    error: false,
    loading: false,
    message: ''
}

export const SetPassword = createAsyncThunk("/SetPassword", async (passwordData) => {
    let data = new FormData();
    console.log(passwordData)
    data.append('password', passwordData.password);
    data.append('password_confirmation', passwordData.password_confirmation);
    console.log(data)
    const response = await ApihelperFunction({
        urlPath: "api/AddPassword",
        formData: data,
        method: "post",
    });
    return response;
});

const SetPasswordSlice = createSlice({
    name: "SetPassword",
    initialState,
    reducers: {
        resetStateSetPassword: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(SetPassword.pending, (state) => {
                state.status = 'LOADING'
                state.loading=true
                state.error=false
                state.message=''
            })
            .addCase(SetPassword.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loading = false
                    state.error = false
                    state.status = "SUCCESSPASSWORD"
                    state.message = action?.payload?.message
                }
                else {
                    state.loading = false
                    state.status = "ERRORPASSWORD"
                    state.error = [action?.payload?.errors]
                }
                state.data = action?.payload?.data;
            })
            .addCase(SetPassword.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERRORPASSWORD'
                console.log(action.payload, 'PAYLOAD DANGER')
            })
    }
})

export const { resetStateSetPassword } = SetPasswordSlice.actions;
export default SetPasswordSlice.reducer