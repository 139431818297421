import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    companyMyFundingDetails: null,
    companyFundingDetailsStatus: '',
    companyFundingDetailsLoader: false,
    companyFundingDetailsError: null,
    companyOpenFundingCount: ''
};
export const companyFundingDetails = createAsyncThunk("/companyFundingDetails", async (data) => {
    console.log(data, 'function called')
    const response = await ApihelperFunction({
        urlPath: `api/company/getOpenFunding`,
        method: "get",
    });
    return response;
});
const companyFundingDetailsSlice = createSlice({
    name: 'companyFundingDetails',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(companyFundingDetails.pending, (state) => {
                state.companyFundingDetailsStatus = 'LOADING'
                state.companyFundingDetailsLoader = true
                state.companyFundingDetailsError = null
            })
            .addCase(companyFundingDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.companyFundingDetailsStatus = "SUCCESS"
                    state.companyMyFundingDetails = action?.payload?.data
                    state.companyOpenFundingCount = action?.payload?.data?.length
                }
                else {
                    state.companyFundingDetailsStatus = "ERROR"
                    state.companyMyFundingDetails = []
                    state.companyOpenFundingCount = 0
                    state.companyFundingDetailsError = [action?.payload?.errors?.[0]]
                }
                state.companyFundingDetailsLoader = false
            })
            .addCase(companyFundingDetails.rejected, (state, action) => {
                state.companyFundingDetailsStatus = 'ERROR'
                state.companyMyFundingDetails = []
                state.companyFundingDetailsLoader = false
            })
    }
})

export const { resetState } = companyFundingDetailsSlice.actions;
export default companyFundingDetailsSlice.reducer;