import {
    Box, Button,
    Container,
    Stack,
    Typography,
    styled
} from '@mui/material';
import WingImage from '../../assets/img/wing_2.png'
import downloadAppImage from '../../assets/img/download-apps-button-google-play-and-app-store2x.png'
import { useNavigate } from 'react-router-dom';

const CustomButton = styled(Button)(({ theme, customcolor }) =>
    theme.unstable_sx({
        backgroundColor: customcolor, color: '#fff', mr: 4,
        justifyContent: 'start',
        padding: { lg: "10px 20px", xs: '10px 15px' },
        '&:hover': {
            backgroundColor: customcolor,
            borderColor: customcolor,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: customcolor,
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: customcolor,
        },
    })
)

const TopContainer = () => {
    const navigate = useNavigate()
    const CustomButtonText = ({ text1, text2, navigateTo }) => {
        return <Stack>
            <Typography onClick={() => navigate(`/${navigateTo}`)} variant='caption' textAlign='left'>
                {text1}
            </Typography>
            <Typography onClick={() => navigate(`/${navigateTo}`)} variant='caption' textTransform={'uppercase'} fontSize={'0.8125rem'} textAlign='left' fontWeight={'400'}>
                {text2}
            </Typography>
        </Stack>
    }
    return (
        <Box sx={{ backgroundColor: 'var(--unnamed-color-f9fafc)' }} >
            <Container maxWidth={'xl'} sx={{ mb: 10 }}>
                <Stack direction={{ xs: 'column', lg: 'row' }} pb={{ lg: 15, md: 10 }} spacing={0}
                    mx={{ xl: 13, md: 10, xs: 1 }}>
                    <Stack pt={{ xs: 5, md: '6%' }}>
                        <Typography
                            variant='h2' component={'h2'} fontSize={'3.3rem'} fontWeight={800} color={'var(--unnamed-color-0b2244)'}
                            gutterBottom>
                            Release cash from your trade invoices and fund your business growth
                        </Typography>
                        <Box sx={{ color: 'var(--unnamed-color-8b8b8c)', mt: { lg: 5, md: 3, xs: 2 } }}>
                            <Typography variant='h5' component={'h5'} fontSize={'25px'} fontWeight={'400'} textTransform={'none'}>
                                Need more time to pay your suppliers invoice?</Typography>
                            <Typography variant='h5' component={'h5'} fontSize={'25px'} fontWeight={'400'} textTransform={'none'}>
                                Extend your credit period with our first of a kind reverse option
                            </Typography>
                        </Box>
                        <Box sx={{ color: 'var(--unnamed-color-6c6c6d)', mt: { lg: 7, md: 5, xs: 3 } }}>
                            <Typography variant='p' component={'p'} textTransform={'none'} fontSize={'18px'}>
                                Low-Cost | Innovative P2P lending | UKs first sharia invoice financing
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: { lg: 7, md: 5, xs: 3 } }}>
                            <CustomButton customcolor={'var(--unnamed-color-b92c82)'}>
                                <CustomButtonText navigateTo={'releaseMyCash'} text1={'RELEASE MY CASH'} text2={'Learn More'} />
                            </CustomButton>
                            <CustomButton customcolor={'var(--unnamed-color-3a0ca3)'}>
                                <CustomButtonText navigateTo={'investInInvoices'} text1={'INVEST IN INVOICES'} text2={'Learn More'} />
                            </CustomButton>
                        </Box>
                    </Stack>
                    <Stack pr={{ xl: 0, lg: 0, md: 3 }} pb={{ md: '6%', xs: 5 }}>
                        <Container
                            maxWidth={'xl'}
                            sx={{
                                width: {
                                    lg: '100%', md: '70%', xs: '100%'
                                },
                                mt: { lg: 3 }
                            }}>
                            <img src={WingImage} alt='wing' width={'100%'} className='wingImage' style={{ marginLeft: 'auto', display: 'block' }} />
                        </Container>
                        <Container maxWidth={'xl'} sx={{
                            display: 'flex', justifyContent: { lg: 'flex-end', md: 'center', xs: 'center' }
                        }}
                        >
                            <img src={downloadAppImage} alt='downloadAppImage' className='downloadAppImage' style={{ width: { lg: '65%', xs: '70%' } }} />
                        </Container>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}

export default TopContainer
