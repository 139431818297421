import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    companyCategory: null,
    companyCategoryStatus:'',
    companyCategoryLoader:false,
    companyCategoryError:null
};
export const CompanyCategory = createAsyncThunk("/CompanyCategory", async () => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: "api/getCompanyCategory",
        method: "get",
    });
    return response;
});
const companyCategorySlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(CompanyCategory.pending, (state) => {
                state.companyCategoryStatus = 'LOADING'
                state.companyCategoryLoader = true
                state.companyCategoryError = null
            })
            .addCase(CompanyCategory.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.companyCategoryStatus = "SUCCESS"
                    state.companyCategory = action?.payload?.data
                }
                else {
                    state.companyCategoryStatus = "ERROR"
                    state.companyCategoryError = [action?.payload?.errors?.[0]]
                }
                state.companyCategoryLoader = false
            })
            .addCase(CompanyCategory.rejected, (state, action) => {
                state.companyCategoryStatus = 'ERROR'
                state.companyCategoryLoader = false
            })
    }
})

export const { resetState } = companyCategorySlice.actions;
export default companyCategorySlice.reducer;