import { Button, Container, List, ListItem, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFeeDetails } from '../../../Redux/Slices/GetFeeDetailsSlice'
import { useEffect } from 'react'
import { addAuctionData, goToNextStep } from '../../../Redux/Slices/CreateAuctionSlice'

function StepFour() {
    const dispatch = useDispatch()
    const { feeDetails,brokerFeeDetails } = useSelector((state) => state.feeDetails)
    const auctionData = useSelector((state) => state.createAuction)
    const getBrokerFee = (value) => {
        for (let i = 0; i < brokerFeeDetails.length; i++) {
            console.log(value, brokerFeeDetails[i]?.min_amount, 'BROKERS FEE222')
            if (value >= brokerFeeDetails[i]?.min_amount && value <= brokerFeeDetails[i]?.max_amount) {
                if (brokerFeeDetails?.[i]?.broker_fee_percentage) {
                    return Math.round((value * brokerFeeDetails?.[i]?.broker_fee_percentage) / 100)
                } else {
                    return brokerFeeDetails?.[i]?.broker_fee_flat
                }
            }
        }
    }
    const auctionDetailsDataSummary={
        invoice_value:auctionData?.data?.invoice_amount,
        funding_required:auctionData?.data?.auction_amount,
        max_roi_percentage:auctionData?.data?.roi_percent,
        max_roi_amount:auctionData?.data?.roi_amount,
        brokers_fee_amount:Math.round(getBrokerFee(auctionData?.data?.auction_amount)),
        retainer_fee_percentage:auctionData?.data?.retainer_percentage,
        retainer_fee_amount:auctionData?.data?.retainer_amount,
        fj_fee_amount:auctionData?.data?.fj_fee,
        fj_fee_percentage:auctionData?.data?.fj_percentage,
        broker_fee_percentage:auctionData?.data?.broker_fee_percentage,
        potential_available_first_value:auctionData?.data?.auction_amount-auctionData?.data?.fj_fee-auctionData?.data?.retainer_amount-Math.round(Math.round(getBrokerFee(auctionData?.data?.auction_amount))),
        total_repayble:auctionData?.data?.auction_amount+auctionData?.data?.roi_amount,
    }
    useEffect(() => {
        dispatch(getFeeDetails())
    }, [])
    console.log(auctionData,'AUCTIONDATA')
    return (
        <>
            <Typography component={'p'} variant='p' fontSize={'20px'} textTransform={'none'} color={'#B92C82'} fontWeight={'600'} textAlign={'center'} mb={2}>Auction details</Typography>
            <Container maxWidth='sm'>
                <Paper style={{border:'1px solid #cdcdcd',boxShadow:'none'}}>
                    <Table style={{borderRadius: '8px' }} className='small-table'>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Invoice Value</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{`£ ${auctionDetailsDataSummary?.invoice_value?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Funding Required</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{`£ ${auctionDetailsDataSummary?.funding_required?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Max ROI</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{`${auctionDetailsDataSummary?.max_roi_percentage}% / £${auctionDetailsDataSummary?.max_roi_amount?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Broker's Fee</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{auctionDetailsDataSummary?.broker_fee_percentage ? `${auctionDetailsDataSummary?.broker_fee_percentage}% /£${auctionDetailsDataSummary?.brokers_fee_amount?.toLocaleString('en-us')}`:`£${auctionDetailsDataSummary?.brokers_fee_amount?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Retainer Fee</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{`${auctionDetailsDataSummary?.retainer_fee_percentage}% / £${auctionDetailsDataSummary?.retainer_fee_amount?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>FJ Fee</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{auctionDetailsDataSummary?.fj_fee_percentage?`${auctionDetailsDataSummary?.fj_fee_percentage}% / £${auctionDetailsDataSummary?.fj_fee_amount.toLocaleString('en-us')}`:`£${auctionDetailsDataSummary?.fj_fee_amount.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Potential Available If Fully Funded</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{`£${auctionDetailsDataSummary?.potential_available_first_value?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid #cdcdcd" }}><Typography component={'p'} variant='p' fontSize={'14px'}>Total Repayble By You</Typography></TableCell>
                                <TableCell><Typography component={'p'} variant='p' fontWeight={'600'} color={'#000'} fontSize={'14px'} sx={{marginLeft:'30px'}}>{`£ ${auctionDetailsDataSummary?.total_repayble?.toLocaleString('en-us')}`}</Typography></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Container>
            <Stack justifyContent="center" direction="row" margin={10}>
                <Button variant="contained" sx={{
                    padding: '10px 60px',
                    backgroundColor: '#B92C82',
                    textTransform: 'Uppercase'
                }}
                    onClick={() => dispatch(addAuctionData({'final_fund_amount':auctionDetailsDataSummary?.potential_available_first_value,'fully_fund_amount':auctionDetailsDataSummary?.potential_available_first_value}))}>NEXT</Button>
            </Stack>
        </>
    )
}

export default StepFour