import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, Container, StepConnector, Tooltip, Typography, stepConnectorClasses, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import back from '../../assets/img/back.png'
import ModalComp from '../../components/ModalComp';
import { useDispatch } from 'react-redux';
import { backToPreviousStep } from '../../Redux/Slices/CreateAuctionSlice';

const stepsForCompany = [
    "Select Type",
    "Company Registration",
    "User Details",
    "Authorised Person",
    "Company Info",
    "Bank Details"
];
const stepsForFunder = [
    "Select Type",
    "My Information",
    "Create Username",
    "Employement Declaration",
    "Bank Details"
];
const stepsForCreateAuction = [
    'Funding Type',
    'Create Auction',
    'Funding Details',
    'Funding Calculator',
    'Funding Purpose',
    'Repayment Schedule',
    'Submit'
]
const stepsForCreateTradingPartner = [
    'My Trading Partner',
    'Contact Person'
]

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'var(--unnamed-color-3a0ca3)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'var(--unnamed-color-3a0ca3)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 5,
        borderRadius: 1,
    },
}));
export default function     HorizontalLinearAlternativeLabelStepper({ extraFunctionality, showWarningMessage, id, val, data, editData, stepNo, setStepNo, toolTip }) {
    const role = localStorage.getItem('role')
    const profile = localStorage.getItem('profile')
    const [finalSteps, setFinalSteps] = useState()
    console.log(showWarningMessage, 'showWarningMessage')
    useEffect(() => {
        if (location?.pathname == '/createAuction') {
            setFinalSteps(stepsForCreateAuction)
        } else if (location?.pathname == '/createTradingPartner' || location?.pathname == '/editTradingPartner') {
            setFinalSteps(stepsForCreateTradingPartner)
        } else if ((role == 1 || role == 'Company' || profile == 'Company')) {
            setFinalSteps(stepsForCompany)
        } else if ((role == 2 || role == 'Funder' || profile == 'Funder')) {
            setFinalSteps(stepsForFunder)
        } else {
            setFinalSteps()
        }
    }, [role])
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const key = location?.pathname?.slice(1, location?.pathname.length)
    const handlePreviousStep = () => {
        if (location?.pathname == '/createAuction') {
            dispatch(backToPreviousStep())
        } else if (location?.pathname == '/createTradingPartner') {
            localStorage.setItem('createTradingPartnerStepTwo', JSON.stringify(data))
            setStepNo(stepNo - 1)
        } else if (location?.pathname == '/editTradingPartner') {
            localStorage.setItem('createTradingPartnerStepTwo', JSON.stringify(data))
            localStorage.setItem('createTradingPartnerStepTwoEdit', JSON.stringify(editData))
            setStepNo(stepNo - 1)
        } else {
            if (location?.search == '?edit') {
                localStorage.setItem(key, JSON.stringify(data))
                navigate(`/signUp${val}?edit`)
                if (extraFunctionality) {

                    extraFunctionality()
                }
            } else {
                localStorage.setItem(key, JSON.stringify(data))
                navigate(`/signUp${val}`)
            }
        }
    }
    const [openWarningModal, setOpenWarningModal] = useState(false)
    const handleNavigateToProfile = () => {
        if (showWarningMessage) {
            setOpenWarningModal(true)
        } else {
            navigate('/viewProfile'); localStorage.removeItem('editData')
            localStorage.removeItem('signUp2Edit')
            localStorage.removeItem('signUp3Edit')
            localStorage.removeItem('signUp4Edit')
            localStorage.removeItem('signUp5Edit')
        }
    }
    //localStorage.removeItem('createTradingPartnerStepOneEdit'); localStorage.removeItem('createTradingPartnerStepTwoEdit'); localStorage.removeItem('createTradingPartnerStepOne'); localStorage.removeItem('createTradingPartnerStepTwo'); localStorage.removeItem('editData') }
    const handleNavigateToTradingPartner = () => {
        location?.pathname == '/createTradingPartner' ?
            showWarningMessage ? setOpenWarningModal(true) : navigate('/tradingPartner') :
            showWarningMessage ? setOpenWarningModal(true) : navigate(`/viewTradingPartner?${id}`)
    }
    const handleNavigatePreviousPage = () => {
        if (location?.pathname?.includes('signUp')) {
            navigate('/viewProfile'); localStorage.removeItem('signUp2NewEdit'); localStorage.removeItem('signUp3NewEdit'); localStorage.removeItem('signUp4NewEdit'); localStorage.removeItem('signUp5NewEdit'); localStorage.removeItem('signUp6NewEdit'); localStorage.removeItem('signUp2Edit'); localStorage.removeItem('signUp3Edit'); localStorage.removeItem('signUp4Edit'); localStorage.removeItem('signUp5Edit'); localStorage.removeItem('signUp6Edit')
        }
        else {
            if (location?.pathname == '/createTradingPartner') {
                navigate('/tradingPartner')
                localStorage.removeItem('createTradingPartnerStepOneEdit'); localStorage.removeItem('createTradingPartnerStepTwoEdit'); localStorage.removeItem('createTradingPartnerStepOne'); localStorage.removeItem('createTradingPartnerStepTwo'); localStorage.removeItem('editData')
            }
            else if (location?.pathname == '/editTradingPartner') {
                navigate(`/viewTradingPartner?${id}`)
                localStorage.removeItem('createTradingPartnerStepOneEdit'); localStorage.removeItem('createTradingPartnerStepTwoEdit'); localStorage.removeItem('createTradingPartnerStepOne'); localStorage.removeItem('createTradingPartnerStepTwo'); localStorage.removeItem('editData')
            }
        }
    }
    return (
        <Container maxWidth={'lg'} sx={{ pt: 5 }}>
            {!isMobile && val !== 0 && val !== 1 && location?.pathname != '/signUp7' && <>
                {
                    toolTip ? <Tooltip title={toolTip}>
                        <Button>
                            <img alt='' src={back} width='12px' style={{ marginRight: '5px' }} />
                            <Typography variant='p' color='#000' fontSize='13px' fontWeight='600'>Back</Typography>
                        </Button></Tooltip> :
                        <Button onClick={() => handlePreviousStep()} style={{ float: 'left', textDecoration: 'none', color: '#000' }}>
                            <img alt='' src={back} width='12px' style={{ marginRight: '5px' }} />
                            <Typography variant='p' color='#000' fontSize='13px' fontWeight='600'>Back</Typography>
                        </Button>
                }
            </>}
            {
                location?.pathname == '/createAuction' && val == 1 && <Button onClick={() => handlePreviousStep()} style={{ float: 'left', textDecoration: 'none', color: '#000' }}>
                    <img alt='' src={back} width='12px' style={{ marginRight: '5px' }} />
                    <Typography variant='p' color='#000' fontSize='13px' fontWeight='600'>Back</Typography>
                </Button>
            }
            {!isMobile && val !== 0 && val == 1 && location?.pathname != '/signUp7' && location?.search == '?edit' && <Button onClick={() => handleNavigateToProfile()} style={{ float: 'left', textDecoration: 'none', color: '#000' }}>
                <img alt='' src={back} width='12px' style={{ marginRight: '5px' }} />
                <Typography variant='p' color='#000' fontSize='13px' fontWeight='600'>Back to profile</Typography>
            </Button>}
            {!isMobile && val == 1 && (location?.pathname == '/createTradingPartner' || location?.pathname == '/editTradingPartner') && <Button onClick={() => handleNavigateToTradingPartner()} style={{ float: 'left', textDecoration: 'none', color: '#000' }}>
                <img alt='' src={back} width='12px' style={{ marginRight: '5px' }} />
                <Typography variant='p' color='#000' fontSize='13px' fontWeight='600'>Back</Typography>
            </Button>}
            {
                location?.pathname != '/createAuction' && location?.pathname != '/createTradingPartner' && location?.pathname != '/editTradingPartner' && location?.search != '?edit' && <Typography variant="h2" fontWeight={700} align="center" mb={5}>
                    Sign up
                </Typography>
            }
            {
                location?.pathname == '/createTradingPartner' && <Typography variant="h2" fontWeight={700} align="center" mb={5}>
                    Create trading partner
                </Typography>
            }
            {
                location?.pathname == '/editTradingPartner' && <Typography variant="h2" fontWeight={700} align="center" mb={5}>
                    Edit trading partner
                </Typography>
            }
            {
                location?.search == '?edit' && <Typography variant="h2" fontWeight={700} align="center" mb={5}>
                    Edit profile
                </Typography>
            }
            <Box sx={{
                mx: 'auto',
                mt: 30 / 8,
                mb: 30 / 8,
            }}>

                <Container maxWidth={'md'}>
                    {
                        (location?.pathname == '/createAuction' || val !== 0) &&
                        <Stepper activeStep={(location?.pathname == '/createTradingPartner' || location?.pathname == '/editTradingPartner') ? val - 1 : val} alternativeLabel connector={<QontoConnector />} classes={{ root: 'custom-stepper-root' }}>
                            {finalSteps?.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel classes={{ dot: 'custom-stepper-dot', label: 'custom-stepper-label' }}>
                                        <Typography component={'p'} variant='p' sx={{ lineHeight: 1.5, color: '#6C6C6D', fontSize: '12px' }}>
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    }
                </Container>
            </Box>
            <ModalComp handleAction={() => handleNavigatePreviousPage()} ButtonText={"Leave"} closeButton={"Stay"} open={openWarningModal} message={`Do you want to leave?All your changes will be lost`} handleClose={() => { setOpenWarningModal(false) }} />
        </Container>
    );
}
