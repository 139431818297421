import React, { useState } from 'react'
import { Stack, CardMedia, Typography, Container } from '@mui/material';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import LoginFormBg from '../../assets/img/login_form_bg.png'
const SignUp1 = () => {
    const navigate = useNavigate()
    const [activeBox, setActiveBox] = useState(0)
    const boxList = [
        {
            img: 'building.svg',
            heading: 'Register as a Company',
            text: 'If you want to raise finance against an invoice',
            profile: 'Company'
        },
        {
            img: 'Funder.png',
            heading: 'Register as a Funder',
            text: 'If you want returns from funding invoices',
            profile: 'Funder'
        }
    ]
    return (
        <>
            <div style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover' }}>
                <Container maxWidth={'md'} sx={{minHeight: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                    <HorizontalLinearAlternativeLabelStepper val={0} />
                    <Stack maxWidth={'lg'} justifyContent={'center'} direction={{ xs: 'column', md: 'row' }} spacing={7}>
                        {
                            boxList?.map((box, index) => {
                                return (
                                    <Box
                                        className={activeBox === index && 'active'}
                                        sx={{
                                            bgcolor: index === 0 ? '#b92c82' : '#45167A',
                                            borderColor: activeBox === index ? '#b92c82' : '#0B2244',
                                            color: '#000000',
                                            cursor: 'pointer',
                                            borderRadius: '8px'
                                        }}
                                        p={{ md: '20px', sm: '15px' }}
                                        pt={'30px !important'}
                                        pb={'30px !important'}
                                        border={1}
                                        onClick={() => { setActiveBox(index); navigate('/emailVerify', { state: box.profile }); localStorage.setItem('profile', box.profile) }}
                                    >
                                        <Stack direction={'column'} alignItems={'center'} justifyContent={'flex-end'}  >
                                            <CardMedia sx={{
                                                width: index == 0 ? '39%' : '30%',
                                                mb: '20px',
                                                objectFit: 'contain'
                                            }} component='img' src={require(`../../assets/img/${box.img}`)} />
                                            <Typography variant='h6' sx={{
                                                textAlign: 'center',
                                                mb: '15px',
                                                textTransform: "none"
                                            }}
                                                fontWeight={500}
                                                color={'#fff'}
                                            >
                                                {box.heading}
                                            </Typography>
                                            <Box sx={{ width: '180px', textAlign: 'center' }}>
                                                <Typography component={'p'} variant='p' sx={{ textAlign: 'center', textTransform: 'none', lineHeight: '1.4', fontFamily: 'CustomFont', fontWeight: '600', fontSize: '12px' }} p={{ md: '', sx: '' }} color={'#fff'}>
                                                    {box.text}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                )
                            })
                        }
                    </Stack >
                </Container >
            </div>
        </>
    );
}
export default SignUp1 