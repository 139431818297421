import { FormControl, IconButton, InputAdornment, InputLabel, TextField, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import info from '../assets/img/info.png'

function InputComp({ marginLeft, subText, marginTop, ibutton, labelLeftMargin, startText, editName, editData, setEditData, disabled, focusHandle, onKeyDown, inputMode, height, label, name, control, placeholder, className, values, defaultValue, onChanges, maxLength, minLength, length,
    fieldType, pattern, startIcon, endIcon, show, setShow, startImg, endImg, alternativeEndImg, width, required }) {
    const location = useLocation()
    const handleTextInput = (e) => {
        if (fieldType == 'text') {
            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')
        }
        if (fieldType == 'numeric') {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }
    }
    const handleEditChange = (e, name, editName) => {
        if (setEditData) {
            if (editName) {
                setEditData({ ...editData, [editName]: e.target.value })
            } else {
                setEditData({ ...editData, [name]: e.target.value })
            }
        }
    }
    const validateSpace = (value) => {
        // Trim the input and check if it contains only spaces
        if (value && value.toString().trim() === '') {
            return 'It cannot contain only spaces.You need to fill up the data';
        }
        return true;
    };


    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: required, pattern, maxLength, minLength, length, validate: validateSpace }}
            render={({ field: { onChange, value } }) => (
                <FormControl className={className || ''} sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: (marginTop || 2) }}>
                    {
                        label &&
                        <label><Typography variant='nav' fontWeight={'500'} textTransform={'none'} fontSize={'13px'}>{label}</Typography></label>
                    }
                    {
                        location?.pathname.includes('signUp') &&
                        <label style={{ marginLeft: labelLeftMargin }}><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>{placeholder}</Typography>{ibutton && <Tooltip title={ibutton} placement="right-start"><img alt='' src={info} width={'13px'} style={{ marginLeft: '5px' }} /></Tooltip>}</label>
                    }
                    {
                        (location?.pathname.includes('createTradingPartner') || location?.pathname.includes('editTradingPartner') || location?.pathname.includes('myWallet')) &&
                        <label style={{ marginLeft: labelLeftMargin }}><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>{placeholder}</Typography>{ibutton && <Tooltip title={ibutton} placement="right-start"><img alt='' src={info} width={'13px'} style={{ marginLeft: '5px' }} /></Tooltip>}</label>
                    }
                    {
                        subText && <span style={{fontSize:'13px',marginBottom:"10px",color:'#b3b3b3'}}>({subText})</span>
                    }
                    <TextField type={fieldType === 'password' ?
                        show ? 'text' : 'password' : fieldType}
                        placeholder={(location?.pathname == '/login' || location?.pathname == '/forgotpassword' || location?.pathname == '/addPassword' || location?.pathname == '/emailVerify' || location?.pathname == '/resetPassword' || location?.pathname == '/createAuction' || location?.pathname == '/enterBid') ? placeholder : null}
                        name={name}
                        onFocus={focusHandle}
                        onInput={(e) => handleTextInput(e)}
                        onChange={(e) => { onChange(e); handleEditChange(e, name, editName) }}
                        onBlur={onChanges}
                        defaultValue={defaultValue}
                        value={(fieldType == 'email' || fieldType == 'website' || fieldType == 'password' || fieldType == 'registration_number') ? value : fieldType == 'postcode' ? value?.toUpperCase() : value ? (typeof (value) == 'string') ? value?.[0]?.toUpperCase() + value?.slice(1) : value : values}
                        inputMode={inputMode}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                        variant='outlined'
                        id="outlined-basic"
                        sx={{
                            mt: 0,
                            '& .MuiOutlinedInput-root': {
                                background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                border: 'none',
                                borderRadius: '7px',
                                marginLeft: marginLeft || 'auto',
                                width: { lg: width || '85%', md: '400px', xs: '100%' },
                                height: { md: height || '50px', xs: '50px' },
                                boxShadow: '0px 3px 10px #0000000D',
                                opacity: 1,
                                fontSize: '15px',
                                color: '#6C6C6D'
                            },
                            '& .MuiInputBase-input': {
                                color: '#000 !important',
                            }
                        }}
                        InputProps={{
                            inputMode: inputMode,
                            disableUnderline: true,
                            style: {
                                border: 'none',
                                color: '#6C6C6D',
                            },
                            startAdornment: (
                                <InputAdornment position="start" sx={{ pl: 0.5 }}>
                                    {
                                        startIcon ? <img alt='' src={require(`../assets/img/${startImg}`)} width='20px' /> : undefined
                                    }
                                    {
                                        startText ? <Typography variant='p' color={'#000'} textTransform={'none'}>{startText}</Typography> : undefined
                                    }
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start" sx={{ pl: 0 }}>
                                    {
                                        endIcon ? <IconButton onClick={() => setShow(!show)}><img alt='' src={require(`../assets/img/${!show ? alternativeEndImg : endImg}`)} /></IconButton> : undefined
                                    }
                                </InputAdornment>
                            )
                        }} />
                </FormControl>
            )}
        />
    )
}

export default InputComp