import React, { useEffect, useRef, useState } from 'react'
import { Box, Stack, Button, Typography, Container, Grid, MenuItem, FormControl, CircularProgress, Checkbox, TextareaAutosize } from '@mui/material';
import TextField from '@mui/material/TextField';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import InputComp from '../../components/InputComp';
import { useSelector, useDispatch } from "react-redux";
import { CompanyDetails, resetStateCompanyRegistration } from '../../Redux/Slices/CompanyRegistrationSlice';
import { toast } from 'react-toastify';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import { title } from '../../Constants';
import ErrorMessageComp from '../../components/ErrorMessageComp';
import { Employment } from '../../Redux/Slices/EmploymentSlice';
import { CompanyCategory } from '../../Redux/Slices/CompanyCategorySlice';
import { CompanyType } from '../../Redux/Slices/CompanyTypeSlice';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/bootstrap.css";
import LoginFormBg from '../../assets/img/login_form_bg.png'

const SignUp4 = () => {

    //values
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const valuesStep4 = JSON.parse(localStorage.getItem('signUp4'))
    const valuesStep3Temp = JSON.parse(localStorage.getItem('signUp3'))
    const valuesStep3 = {
        contact_email: valuesStep3Temp?.auth_email,
        contact_first_name: valuesStep3Temp?.auth_first_name,
        contact_job_title: valuesStep3Temp?.auth_job_title,
        contact_last_name: valuesStep3Temp?.auth_last_name,
        contact_phone: valuesStep3Temp?.auth_phone,
        contact_phone_code: valuesStep3Temp?.auth_phone_code,
        contact_title: valuesStep3Temp?.auth_title
    }
    const [sameAsUserDetails, setSameAsUserDetails] = useState(JSON.parse(localStorage.getItem('sameAsUserDetails')))
    const { employment } = useSelector((state) => state.employment)
    const { companyCategory } = useSelector((state) => state.companyCategory)
    const [isSubmit, setIsSubmit] = useState(false)
    const { loading, error, status, message } = useSelector(state => state.companyRegister)
    const role = localStorage.getItem('role')
    const [renderKey, setRenderKey] = useState(0);
    const [isFocused, setIsFocused] = useState(false);
    const [stateError, setStateError] = useState(false)
    const editData = JSON.parse(localStorage.getItem('editData'))
    const signUp4EditData = JSON.parse(localStorage.getItem('signUp4Edit'))
    const signUp3EditData = JSON.parse(localStorage.getItem('signUp3Edit'))
    const valuesTemp = (role == 1 || role == 'Company') ? sameAsUserDetails ? valuesStep3 : valuesStep4 : location?.search == '?edit' ? { ...editData?.Funder?.funder_basic_detail, 'business_website': editData?.Funder?.funder_basic_detail?.business_website?.slice(8, editData?.Funder?.funder_basic_detail?.business_website.length) == 'undefined' ? null : editData?.Funder?.funder_basic_detail?.business_website?.slice(8, editData?.Funder?.funder_basic_detail?.business_website.length) } : valuesStep4
    const [values, setValues] = useState(valuesTemp)
    const [phone, setPhone] = useState(values?.contact_phone_code || '44');
    const handleFocus = () => {
        setIsFocused(true);
    };
    const step2Data = JSON.parse(localStorage.getItem('signUp2'))
    const step3Data = JSON.parse(localStorage.getItem('signUp3'))
    const regexForEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    const regexForPhone = /^\d+$/
    let editDefaultValuesForCompany, editDefaultValuesForFunder;
    const signUp4NewEditData = JSON.parse(localStorage.getItem('signUp4NewEdit'))
    const [editNewData, setEditNewData] = useState(signUp4NewEditData)


    //get edit data value 
    if (location?.search == '?edit' && !sameAsUserDetails && !signUp4EditData) {
        editDefaultValuesForCompany = {
            'contact_title': editData?.Company?.[0]?.company_contact_details.title,
            'contact_first_name': editData?.Company?.[0]?.company_contact_details.first_name,
            'contact_last_name': editData?.Company?.[0]?.company_contact_details.last_name,
            'contact_job_title': editData?.Company?.[0]?.company_contact_details.job_title,
            'contact_phone_code': editData?.Company?.[0]?.company_contact_details.phone_code.toString(),
            'contact_phone': editData?.Company?.[0]?.company_contact_details.mobile,
            'contact_email': editData?.Company?.[0]?.company_contact_details.email
        }
    }
    if (location?.search == '?edit' && !sameAsUserDetails && signUp4EditData !== null) {
        editDefaultValuesForCompany = signUp4EditData
    }
    if (location?.search == '?edit' && sameAsUserDetails) {
        editDefaultValuesForCompany = {
            'contact_title': signUp3EditData.auth_title,
            'contact_first_name': signUp3EditData.auth_first_name,
            'contact_last_name': signUp3EditData.auth_last_name,
            'contact_job_title': signUp3EditData.auth_job_title,
            'contact_phone_code': signUp3EditData.auth_phone_code,
            'contact_phone': signUp3EditData.auth_phone,
            'contact_email': signUp3EditData.auth_email
        }
    }
    if (location?.search == '?edit' && editData.user_type == 'Funder' && !signUp4EditData) {
        editDefaultValuesForFunder = {
            'employer_name': editData?.Funder?.funder_basic_detail?.employer_name,
            'job_title': editData?.Funder?.funder_basic_detail?.job_title,
            'company_type': editData?.Funder?.funder_basic_detail?.company_type_id,
            'employer_status': editData?.Funder?.funder_basic_detail?.employer_status,
            'business_address_1': editData?.Funder?.funder_basic_detail?.business_address_1,
            'business_email': editData?.Funder?.funder_basic_detail?.business_email,
            'business_mobile': editData?.Funder?.funder_basic_detail?.business_mobile,
            'business_phone_code': editData?.Funder?.funder_basic_detail?.business_phone_code,
            'business_website': editData?.Funder?.funder_basic_detail?.business_website?.slice(8, editData?.Funder?.funder_basic_detail?.business_website.length) == 'undefined' ? null : editData?.Funder?.funder_basic_detail?.business_website?.slice(8, editData?.Funder?.funder_basic_detail?.business_website.length)
        }
    }
    if (location?.search == '?edit' && editData.user_type == 'Funder' && signUp4EditData !== null) {
        editDefaultValuesForFunder = signUp4EditData
    }
    //default value for form
    const defaultValues = (role == 1 || role == 'Company') ? location?.search == '?edit' ? { ...editDefaultValuesForCompany } : sameAsUserDetails ? { 'contact_title': title[0].value, 'contact_phone_code': phone, ...valuesStep3 } : { 'contact_title': title[0].value, 'contact_phone_code': phone, ...values } : location?.search == '?edit' ? { ...editDefaultValuesForFunder } : { ...values }
    const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
        defaultValues: defaultValues
    })
    const inputStyles = {
        width: '100%',
        border: 'none',
        padding: '8px',
        outline: isFocused ? 'none' : 'none',
        borderBottom: isFocused ? 'none' : 'none'
    };

    //form submit
    const onSubmit = (data) => {
        if (location?.search == '?edit') {
            if (editNewData) {
                localStorage.setItem("signUp4NewEdit", JSON.stringify({ ...editNewData }))
                navigate('/signUp5?edit')
            }
            if ((role == 2 || role == 'Funder')) {
                localStorage.setItem('signUp4Edit', JSON.stringify({ ...data, 'company_type_id': data?.company_type }))
                localStorage.setItem("signUp4NewEdit", JSON.stringify({ ...editNewData }))
                navigate('/signUp5?edit')
            } else {
                localStorage.setItem('signUp4Edit', JSON.stringify({ ...data, 'contact_phone_code': phone }))
                navigate('/signUp5?edit')
            }
        } else {
            setIsSubmit(true)
            if ((role == 1 || role == 'Company')) {
                localStorage.setItem('signUp4', JSON.stringify({ ...data, 'contact_phone_code': phone }))
                dispatch(CompanyDetails({ ...data, ...step2Data, ...step3Data, 'postcode': step2Data?.postcode?.toUpperCase(), 'contact_phone_code': phone }))
            } else if ((role == 2 || role == 'Funder')) {
                localStorage?.setItem('signUp4', JSON.stringify({ ...data }))
                navigate('/signUp5', { state: { ...location.state, ...data } })
            }
        }
    }

    // onchange
    const handleChange = (e) => {
        setStateError(false)
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
        if ((e.target.name) == 'business_email') {
            if (!e.target.value.match(regexForEmail)) {
                setStateError({ ...stateError, [e.target.name]: 'Invalid email' })
            }
        }
        if ((e.target.name) == 'business_mobile') {
            if (!e.target.value.match(regexForPhone)) {
                setStateError({ ...stateError, [e.target.name]: 'Invalid phone number' })
            }
            if (e.target.value.length > 15) {
                setStateError({ ...stateError, [e.target.name]: 'Phone number must have maximum 15 digits' })
            }
            if (e.target.value.length < 8) {
                setStateError({ ...stateError, [e.target.name]: 'Phone number must have minimum 8 digits' })
            }
        }
    }
    //firing toast
    useEffect(() => {
        if (error) {
            toast.error('' + error[0], error[1], error[2], error[3]);
        } if (status === "SUCCESS") {
            toast.success('' + message, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    navigate('/signUp5');
                    dispatch(resetStateCompanyRegistration())
                }
            });
        }
    }, [error, status]);

    //get employment
    useEffect(() => {
        if (role == 2 || role == 'Funder') {
            dispatch(Employment())
            dispatch(CompanyCategory())
            dispatch(CompanyType())
        }
    }, [])

    useEffect(() => {
        if (location?.search == '?edit' && (role == 1 || role == 'Company') && !sameAsUserDetails) {
            setValues(editDefaultValuesForCompany)
            setPhone(editDefaultValuesForCompany?.contact_phone_code)
        }
        if (location?.search == '?edit' && (role == 1 || role == 'Company') && sameAsUserDetails) {
            setValues(signUp3EditData)
            setPhone(signUp3EditData?.auth_phone_code)
        }
    }, [])
    const MyForm = ({ defaultValues, phone }) => {
        const { control, formState: { errors }, handleSubmit, getValues } = useForm({ defaultValues: defaultValues });
        const valuesData = getValues()
        const onSubmit = (data) => {
            localStorage.setItem("signUp4", JSON.stringify({ ...defaultValues }))
            console.log(data, values?.contact_title, 'valuesData')
            dispatch(CompanyDetails({ ...defaultValues, ...step2Data, ...step3Data, 'contact_phone_code': phone }))
        }
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Controller
                                name='contact_title'
                                control={control}
                                // rules={{ required: true }}
                                disabled={true}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl sx={{ width: { lg: 'max-content', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Title *</Typography></label>
                                        <TextField
                                            id="outlined-select"
                                            select
                                            name='contact_title'
                                            placeholder='Select your city'
                                            onBlur={handleChange}
                                            disabled={true}
                                            onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'contact_title': e.target.value }) }} value={value || values?.contact_title || title[0].value}
                                            sx={{
                                                mt: 0,
                                                '& .MuiOutlinedInput-root': {
                                                    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                    border: 'none',
                                                    borderRadius: '7px',
                                                    width: { lg: 'max-content', md: '400px', xs: '100%' },
                                                    height: { md: '50px', xs: '50px' },
                                                    boxShadow: '0px 3px 10px #0000000D',
                                                    opacity: 1
                                                }
                                            }}
                                        >
                                            {title.map((option, index) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <Typography variant='p'>{option.label}</Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                )}
                            />
                            <InputComp labelLeftMargin={'5%'} disabled={true} setEditData={setEditNewData} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='95%' name={'contact_first_name'} placeholder={'First name *'} control={control} fieldType={'text'} required={true} />
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', marginLeft: '10px' }}>
                            {/* {errors.contact_title?.type === 'required' && <ErrorMessageComp message={'Title is required'} />
                            } */}
                            {errors.contact_first_name?.type === 'required' && <ErrorMessageComp message={'First name is required'} />
                            }
                            {
                                errors.contact_first_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid first name'} />
                            }
                            {
                                errors.contact_first_name?.type === 'maxLength' && <ErrorMessageComp message={'First name can have maximum 15 characters'} />
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputComp disabled={true} setEditData={setEditNewData} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='100%' required={true} name={'contact_last_name'} placeholder={'Last name *'} control={control} fieldType={'text'} />
                        {errors.contact_last_name?.type === 'required' && <ErrorMessageComp message={'Last name is required'} />
                        }
                        {
                            errors.contact_last_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid last name'} />
                        }
                        {
                            errors.contact_last_name?.type === 'maxLength' && <ErrorMessageComp message={'Last name can have maximum 15 characters'} />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputComp disabled={true} setEditData={setEditNewData} editData={editNewData} required={true} onChanges={handleChange} width='100%' name={'contact_job_title'} placeholder={'Job title / Capacity *'} control={control} fieldType={'Job title / Capacity'} />
                        {
                            errors.contact_job_title?.type === 'required' && <ErrorMessageComp message={'Job title is required'} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                            <div style={{ width: '35%' }}>
                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code *</Typography></label>
                                <PhoneInput
                                    country={"eg"}
                                    enableSearch={true}
                                    value={phone}
                                    disabled={true}
                                    inputProps={{ readOnly: true }}
                                    onChange={(e) => { setValues({ ...values, 'contact_phone_code': e }); setPhone(e); setEditNewData({ ...editNewData, 'contact_phone_code': e }) }}
                                />
                            </div>
                            <InputComp labelLeftMargin={'5%'} disabled={true} setEditData={setEditNewData} editData={editNewData} editName={'contact_mobile'} fieldType={'numeric'} pattern={/^\d+$/} onChanges={handleChange} width='95%' minLength={8} maxLength={15} required={true} name={'contact_phone'} placeholder={'Phone number *'} control={control} />
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', marginLeft: "13%" }}>
                            {errors.contact_phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                            }
                            {errors.contact_phone?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                            }
                            {errors.contact_phone?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                            }
                            {errors.contact_phone?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 15 digits'} />
                            }
                            {errors.contact_phone?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <InputComp disabled={true} setEditData={setEditNewData} editData={editNewData} maxLength={40} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} onChanges={handleChange} width='100%' required={true} name={'contact_email'} placeholder={'Email address *'} control={control} fieldType={'email'} />
                        {errors.contact_email?.type === 'required' && <ErrorMessageComp message={'Email is required'} />
                        }
                        {
                            errors?.contact_email?.type == 'pattern' && <ErrorMessageComp message={'Invalid email address'} />
                        }
                        {errors.contact_email?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
                        }
                    </Grid>
                </Grid>
                <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                    <Button variant="contained" sx={{
                        padding: '10px 60px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {
                            !loading && <Typography variant='p' color='#fff'>NEXT</Typography>
                        }
                        {
                            loading && <CircularProgress color="white" size={'25px'} />
                        }
                    </Button>
                </Stack>
            </>
        );
    };
    const funderData = getValues()
    //back save data on edit
    const backSaveData = () => {
        const data = getValues()
        if (location?.search == '?edit') {
            if (editNewData) {
                localStorage.setItem("signUp4NewEdit", JSON.stringify({ ...editNewData }))
                // navigate('/signUp5?edit')
            }
            if ((role == 2 || role == 'Funder')) {
                localStorage.setItem('signUp4Edit', JSON.stringify({ ...data, 'company_type_id': data?.company_type }))
                localStorage.setItem("signUp4NewEdit", JSON.stringify({ ...editNewData }))
                // navigate('/signUp5?edit')
            } else {
                localStorage.setItem('signUp4Edit', JSON.stringify({ ...data, 'contact_phone_code': phone }))
                // navigate('/signUp5?edit')
            }
        }
    }
    const data = getValues()
    return (
        <div style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover', paddingBottom: '80px' }}>

            <HorizontalLinearAlternativeLabelStepper val={3} data={values} extraFunctionality={() => backSaveData()} />
            {
                (role == 1 || role == 'Company') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                    <Box
                        // component="form"
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Typography variant='p' component='p' fontWeight={'bold'} textAlign='center' color='#000' fontSize={'15px'} textTransform='none'>
                            Please enter the details of an authorised person in your company
                        </Typography>
                        {
                            location?.search != '?edit' && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                <Checkbox size='small' checked={sameAsUserDetails} name='termsandCondition' required={true} onChange={() => { setSameAsUserDetails(!sameAsUserDetails); localStorage.setItem('sameAsUserDetails', !sameAsUserDetails) }} sx={{ marginTop: '30px' }} /> <Typography variant='p' textTransform={'none'} sx={{ marginTop: '30px' }}>Authorised person same as user details</Typography>
                            </Grid>
                        }
                        {
                            sameAsUserDetails && <MyForm defaultValues={defaultValues} phone={defaultValues?.contact_phone_code} />
                        }
                        {
                            !sameAsUserDetails && <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Controller
                                                name='contact_title'
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl sx={{ width: { lg: 'max-content', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Title *</Typography></label>
                                                        <TextField
                                                            id="outlined-select"
                                                            select
                                                            name='contact_title'
                                                            placeholder='Select your city'
                                                            onBlur={handleChange}
                                                            onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'contact_title': e.target.value }) }} value={value || values?.contact_title || title[0].value}
                                                            sx={{
                                                                mt: 0,
                                                                '& .MuiOutlinedInput-root': {
                                                                    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                    border: 'none',
                                                                    borderRadius: '7px',
                                                                    width: { lg: 'max-content', md: '400px', xs: '100%' },
                                                                    height: { md: '50px', xs: '50px' },
                                                                    boxShadow: '0px 3px 10px #0000000D',
                                                                    opacity: 1
                                                                }
                                                            }}
                                                        >
                                                            {title.map((option, index) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    <Typography variant='p'>{option.label}</Typography>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                            />
                                            <InputComp labelLeftMargin={'5%'} setEditData={setEditNewData} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='95%' name={'contact_first_name'} placeholder={'First name *'} control={control} fieldType={'text'} required={true} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', marginLeft: '10px' }}>
                                            {errors.contact_title?.type === 'required' && <ErrorMessageComp message={'Title is required'} />
                                            }
                                            {errors.contact_first_name?.type === 'required' && <ErrorMessageComp message={'First name is required'} />
                                            }
                                            {
                                                errors.contact_first_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid first name'} />
                                            }
                                            {
                                                errors.contact_first_name?.type === 'maxLength' && <ErrorMessageComp message={'First name can have maximum 15 characters'} />
                                            }
                                            {errors.contact_first_name && <ErrorMessageComp message={errors.contact_first_name.message} />}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='100%' required={true} name={'contact_last_name'} placeholder={'Last name *'} control={control} fieldType={'text'} />
                                        {errors.contact_last_name?.type === 'required' && <ErrorMessageComp message={'Last name is required'} />
                                        }
                                        {
                                            errors.contact_last_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid last name'} />
                                        }
                                        {
                                            errors.contact_last_name?.type === 'maxLength' && <ErrorMessageComp message={'Last name can have maximum 15 characters'} />
                                        }
                                        {errors.contact_last_name && <ErrorMessageComp message={errors.contact_last_name.message} />}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <InputComp setEditData={setEditNewData} editData={editNewData} required={true} onChanges={handleChange} width='100%' name={'contact_job_title'} placeholder={'Job title / Capacity *'} control={control} fieldType={'Job title / Capacity'} />
                                        {
                                            errors.contact_job_title?.type === 'required' && <ErrorMessageComp message={'Job title is required'} />
                                        }
                                        {errors.contact_job_title && <ErrorMessageComp message={errors.contact_job_title.message} />}

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                            <div style={{ width: '35%' }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code *</Typography></label>
                                                <PhoneInput
                                                    country={"eg"}
                                                    enableSearch={true}
                                                    // disabled={location?.search=='?edit'}
                                                    inputProps={{ readOnly: true }}
                                                    value={phone}
                                                    onChange={(e) => { setValues({ ...values, 'contact_phone_code': e }); setPhone(e); setEditNewData({ ...editNewData, 'contact_phone_code': e }) }}
                                                />
                                            </div>
                                            <InputComp labelLeftMargin={'5%'} setEditData={setEditNewData} editData={editNewData} editName={'contact_mobile'} fieldType={'numeric'} pattern={/^\d+$/} onChanges={handleChange} width='95%' minLength={8} maxLength={15} required={true} name={'contact_phone'} placeholder={'Phone number *'} control={control} />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', marginLeft: "17%" }}>
                                            {errors.contact_phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                                            }
                                            {errors.contact_phone?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                                            }
                                            {errors.contact_phone?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                                            }
                                            {errors.contact_phone?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 15 digits'} />
                                            }
                                            {errors.contact_phone?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                                            }
                                            {errors.contact_phone && <ErrorMessageComp message={errors.contact_phone.message} />}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={40} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} onChanges={handleChange} width='100%' required={true} name={'contact_email'} placeholder={'Email address *'} control={control} fieldType={'email'} />
                                        {errors.contact_email?.type === 'required' && <ErrorMessageComp message={'Email is required'} />
                                        }
                                        {errors.contact_email && <ErrorMessageComp message={errors.contact_email.message} />}
                                        {
                                            errors?.contact_email?.type == 'pattern' && <ErrorMessageComp message={'Invalid email address'} />
                                        }
                                        {errors.contact_email?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
                                        }
                                    </Grid>
                                </Grid>
                                <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                                    <Button variant="contained" sx={{
                                        padding: '10px 60px',
                                        backgroundColor: '#B92C82',
                                        textTransform: 'Uppercase'
                                    }}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {
                                            !loading && <Typography variant='p' color='#fff'>NEXT</Typography>
                                        }
                                        {
                                            loading && <CircularProgress color="white" size={'25px'} />
                                        }
                                    </Button>
                                </Stack>
                            </>
                        }
                    </Box >
                </Container >
            }
            {
                (role == 2 || role == 'Funder') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                    <Box
                        key={renderKey}
                        component="form"
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='employer_status'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Your employment status *</Typography></label>
                                            <TextField
                                                id="outlined-select"
                                                select
                                                placeholder='Select your city'
                                                name='employer_status'
                                                onBlur={handleChange}
                                                onChange={(e) => {
                                                    onChange(e); setEditNewData({ ...editNewData, 'employer_status': e.target.value }); {
                                                        if (e.target.value == 'Retired' || e.target.value == 'Unemployed' || e.target.value == 'Other') {
                                                            console.log('VALUE CHANGE')
                                                            setValue('employer_name', '')
                                                            setValue('job_title', '')
                                                            setValue('company_type', '')
                                                            setValue('business_address_1', '')
                                                            setValue('business_mobile', '')
                                                            setValue('business_email', '')
                                                            setValue('business_website', '')
                                                            setPhone('44')
                                                            // Manually trigger a re-render by changing the renderKey
                                                            setRenderKey(renderKey + 1)
                                                        }
                                                    }
                                                }} value={value || 'Your employment status'}
                                                defaultValue=""
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {employment && employment?.map((option, index) => (
                                                    <MenuItem key={option?.id} value={option?.name}>
                                                        <Typography variant='p' color={'#000'}>{option?.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.employer_status?.type === 'required' && <ErrorMessageComp message={'Employment status is required'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditNewData} editData={editNewData} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'} required={funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired'} width='100%' name={'employer_name'} placeholder={`Employer/Business name ${funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}`} control={control} fieldType={'text'} />
                                {errors.employer_name?.type === 'required' && (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') && <ErrorMessageComp message={'Employer name is required'} />
                                }
                                {errors.employer_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid name'} />
                                }
                                {errors.employer_name && <ErrorMessageComp message={errors.employer_name.message} />}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'} setEditData={setEditNewData} editData={editNewData} required={funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired'} onChanges={handleChange} width='100%' name={'job_title'} placeholder={`Job title / Capacity ${funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}`} control={control} fieldType={'Job title / Capacity'} />
                                {errors.job_title?.type === 'required' && (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') && <ErrorMessageComp message={'Job title is required'} />
                                }
                                {errors.job_title && <ErrorMessageComp message={errors.job_title.message} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='company_type'
                                    control={control}
                                    rules={{ required: (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') ? true : false }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Company type {funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}</Typography></label>
                                            <TextField
                                                disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'}
                                                id="outlined-select"
                                                select
                                                name='company_type'
                                                onBlur={handleChange}
                                                onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'company_type_id': e.target.value }) }} value={value}
                                                defaultValue=""
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                            >
                                                {companyCategory && companyCategory?.map((option) => (
                                                    <MenuItem key={option?.id} value={option?.id}>
                                                        <Typography variant='p' color={'#000'}>{option?.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                />
                                {errors.company_type?.type === 'required' && (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') && <ErrorMessageComp message={'Company type is required'} />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name={'business_address_1'}
                                    control={control}
                                    rules={{ required: (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') ? true : false, maxLength: 200 }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Business Address {funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}</Typography></label>
                                            <TextareaAutosize disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'} onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'business_address_1': e.target.value }) }} value={value} required={funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired'} style={{ fontSize: '15px', fontFamily: 'Roboto', borderRadius: '8px', borderColor: 'grey', padding: '20px', width: '100%', height: '100px' }} />
                                        </FormControl>
                                    )}
                                />
                                {errors.business_address_1?.type === 'required' && (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') && <ErrorMessageComp width={'98%'} message={'Business address is required'} />
                                }
                                {errors.business_address_1?.type === 'maxLength' && <ErrorMessageComp message={"This field can have maximum 200 characters"} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <div style={{ width: '35%' }}>
                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code {funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}</Typography></label>
                                        <PhoneInput
                                            country={"eg"}
                                            enableSearch={true}
                                            value={phone}
                                            inputProps={{ readOnly: true }}
                                            disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'}
                                            onChange={(e) => { setValues({ ...values, 'business_phone_code': e }); setPhone(e); setEditNewData({ ...editNewData, 'business_phone_code': e }) }}
                                        />
                                    </div>
                                    <InputComp disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'} labelLeftMargin={'5%'} setEditData={setEditNewData} editData={editNewData} pattern={/^\d+$/} minLength={8} maxLength={15} onChanges={handleChange} width='95%' required={funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired'} name={'business_mobile'} placeholder={`Phone number ${funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}`} control={control} fieldType={'numeric'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', marginLeft: "17%" }}>
                                    {errors.business_phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                                    }
                                    {errors.business_mobile?.type === 'required' && (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') && <ErrorMessageComp message={'Phone number is required'} />
                                    }
                                    {funderData?.business_mobile && errors.business_mobile?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 15 digits'} />
                                    }
                                    {funderData?.business_mobile && errors.business_mobile?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                                    }
                                    {funderData?.business_mobile && errors.business_mobile?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                                    }
                                    {errors.business_mobile && <ErrorMessageComp message={errors?.mobile?.message} />}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <div style={{ width: '35%' }}>
                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code {funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}</Typography></label>
                                        <PhoneInput
                                            country={"eg"}
                                            enableSearch={true}
                                            value={phone}
                                            inputProps={{ readOnly: true }}
                                            onChange={(e) => { setValues({ ...values, 'business_phone_code': e }); setPhone(e); setEditNewData({ ...editNewData, 'business_phone_code': e }) }}
                                        />
                                    </div>
                                    <InputComp labelLeftMargin={'5%'} setEditData={setEditNewData} editData={editNewData} pattern={/^\d+$/} minLength={8} maxLength={15} onChanges={handleChange} width='95%' required={funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired'} name={'business_mobile'} placeholder={`Phone number ${funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}`} control={control} fieldType={'Phone number'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', marginLeft: "17%" }}>
                                    {errors.business_phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                                    }
                                    {errors.business_mobile?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                                    }
                                    {funderData?.business_mobile && errors.business_mobile?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 15 digits'} />
                                    }
                                    {funderData?.business_mobile && errors.business_mobile?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                                    }
                                    {funderData?.business_mobile && errors.business_mobile?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                                    }
                                    {errors.business_mobile && <ErrorMessageComp message={errors?.mobile?.message} />}
                                </div>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} maxLength={40} fieldType={'email'} setEditData={setEditNewData} editData={editNewData} required={funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired'} onChanges={handleChange} width='100%' name={'business_email'} placeholder={`Email ${funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired' ? '*' : ''}`} control={control} />
                                {errors.business_email?.type === 'required' && (funderData?.employer_status == 'Employed' || funderData?.employer_status == 'Self-employed' || funderData?.employer_status == 'Semi-retired') && <ErrorMessageComp message={'Email is required'} />
                                }
                                {funderData?.business_email && errors.business_email?.type === 'pattern' && <ErrorMessageComp message={'Email is invalid'} />
                                }
                                {funderData?.business_email && errors.business_email?.type === 'maxLength' && <ErrorMessageComp message={'Email can have maximum 40 characters'} />
                                }
                                {errors.business_email && <ErrorMessageComp message={errors.business_email.message} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp disabled={funderData?.employer_status == 'Retired' || funderData?.employer_status == 'Unemployed' || funderData?.employer_status == 'Other'} ibutton={'You dont need to put https:// again'} setEditData={setEditNewData} editData={editNewData} pattern={/^([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}$/} startText={'https://'} onChanges={handleChange} width='100%' name={'business_website'} placeholder={`Website`} control={control} fieldType={'website'} />
                                {/* {errors.website?.type === 'required' && <ErrorMessageComp message={'Website address is required'} />
                                } */}
                                {funderData?.business_website && errors?.business_website?.type === 'pattern' && <ErrorMessageComp message={'Website address is invalid.(If you have added https:// again you have to remove it)'} />
                                }
                            </Grid>
                        </Grid>

                        <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                            <Button variant="contained" sx={{
                                padding: '10px 60px',
                                backgroundColor: '#B92C82',
                                textTransform: 'Uppercase'
                            }}
                                onClick={handleSubmit(onSubmit, () => setIsSubmit(true))}
                            >NEXT</Button>
                        </Stack>
                    </Box >
                </Container >
            }
        </div>
    );
}
export default SignUp4