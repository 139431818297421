import { Button, CircularProgress, FormControl, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import righArrow from '../assets/img/right-arrow.png'
import ErrorMessageComp from './ErrorMessageComp'
import { useDispatch, useSelector } from 'react-redux'
import { WithdrawBalance, resetWithdrawBalance } from '../Redux/Slices/CheckWithdrawBalanceValidationSlice'
import { toast } from 'react-toastify'
import Loader from './Loader'

function InputFieldWithButton({ totalBalance, bgColor, setWithdrawValue, setOpenModal, modalType, setAddValue, value }) {
    const [errors, setErrors] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [data, setData] = useState()
    const [validate, setValidate] = useState(false)
    const [unFormattedInput, setUnFormattedInput] = useState();
    const dispatch = useDispatch()
    String.prototype.replaceAt = function (index, replacement) {
        if (index >= 0 && index < this.length) {
            return this.substring(0, index) + replacement + this.substring(index + replacement.length);
        } else {
            return this.toString(); // If index is out of bounds, return the original string
        }
    };
    const { withdrawBalanceStatus, withdrawBalanceLoader, withdrawBalanceError } = useSelector((state) => state.withdrawBalanceValidation)
    const handleInput = (e) => {
        //only number input
        e.target.value = e.target.value.replace(/[^0-9\.]/g, '')

        //only one decimal point
        if (e.target.value.split('.').length > 2)
            e.target.value = e.target.value.replace(/\.+$/, "");

        //2 pointers after decimal
        let index = e.target.value.indexOf('.');
        if (index != -1 && e.target.value.substring(index + 1).length > 2) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
        }
        //adding thousand seperator
        let num, frac, str, finalValue;
        if (index != -1) {
            num = e.target.value.substring(0, index).replaceAll(',', '');
            frac = e.target.value.substring(index + 1);
            str = Number(num).toLocaleString('en-US')
        } else {
            num = e.target.value.replaceAll(',', '')
            str = Number(num).toLocaleString('en-US')
        }
        // num = num;
        if (index != -1) {
            str += '.' + frac
            num += '.' + frac
        } else {
            str = Number(num).toLocaleString('en-US')
        }
        setUnFormattedInput(str)

        //check if appropriate value or not
        modalType == 'add' ? setAddValue(Number(num)) : setWithdrawValue(Number(num))
        if ((Number(num) > Number(totalBalance) || totalBalance == undefined) && modalType != 'add') {
            setErrors(true)
            setErrorMessage('Withdrawl amount should be less than or equal to current balance')
        } else {
            console.log(index, e.target.value.substring(0, index), 'NUM')
            if (index == 0 || (index == 1 && e.target.value.substring(0, index) == 0)) {
                setErrors(true)
                setErrorMessage('Total value should be greater than 1')
            }
            else {
                setErrors(false)
                setErrorMessage('')
            }
        }
    }
    const handleSubmit = () => {
        setValidate(true);
        if (modalType != 'add' && data && !errors) {
            dispatch(WithdrawBalance(data))
        }
        if (modalType == 'add' && data && !errors) {
            setOpenModal(true)
        }
    }
    useEffect(() => {
       if(modalType != 'add'){
        if (!withdrawBalanceLoader && withdrawBalanceStatus == true) {
            setOpenModal(true)
        } else if (!withdrawBalanceLoader && withdrawBalanceStatus == false) {
            toast.error('' + withdrawBalanceError);
        }
       }

    }, [withdrawBalanceLoader, withdrawBalanceStatus])
    useEffect(() => {
        dispatch(resetWithdrawBalance())
    }, [dispatch])
    return (
        <>
            <FormControl fullWidth style={{ background: '#fff', display: 'flex', width: '100%', flexDirection: 'row', marginTop: '10px', borderRadius: '8px', overflow: 'hidden' }}>
                <span class="input-group-addon" style={{ fontSize: '13px', color: '#676565', padding: '8px', paddingRight: 0, fontSize: '16px' }}>£</span>
                <input
                    disabled={totalBalance == undefined && modalType != 'add'}
                    className="dollar-input"
                    type='text'
                    label="Enter something"
                    style={{ border: 'none', paddingLeft: '10px', width: "85%" }}
                    size='small'
                    placeholder='X,XXX.xx'
                    onInput={(e) => handleInput(e)}
                    onChange={(e) => { setData(e.target.value) }}
                    value={unFormattedInput}
                />
                <Button onClick={() => handleSubmit()} size='small' variant="contained" sx={{ width: '15%', background: bgColor, padding: '12px 5px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} >
                    {
                        modalType != 'add' ? withdrawBalanceLoader ? <CircularProgress size={'18px'} color='white' /> : <img src={righArrow} alt='righArrow' width='17px' /> : <img src={righArrow} alt='righArrow' width='17px' />
                    }
                </Button>
            </FormControl >
            {
                errors && <p style={{ color: '#fff', fontSize: "14px", fontWeight: 'bold' }} >{errorMessage}</p>
            }
            {
                validate && !data && <p style={{ color: '#fff', fontSize: "14px", fontWeight: 'bold' }} >Please enter an amount</p>
            }
        </>
    )
}

export default InputFieldWithButton