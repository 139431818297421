import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    totalMyLiveAuction: null,
    myLiveAuction: null,
    myLiveAuctionStatus: '',
    myLiveAuctionLoader: false,
    myLiveAuctionError: null,
    retryLive:0
};
const role = localStorage.getItem('role')
export const MyLiveAuction = createAsyncThunk("/MyLiveAuction", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: role == 'Funder' ? `api/funder/getMyAuctionLive?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}` : `api/company/getMyAuctionLive?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}`,
        method: "get",
    });
    return response;
});
const myLiveAuctionSlice = createSlice({
    name: 'MyLiveAuction',
    initialState,
    reducers: {
        resetStateMyLiveAuction: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(MyLiveAuction.pending, (state) => {
                state.myLiveAuctionStatus = 'LOADING'
                state.myLiveAuctionLoader = true
                state.myLiveAuctionError = null
                state.myLiveAuction = null
            })
            .addCase(MyLiveAuction.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.myLiveAuctionStatus = "SUCCESS"
                    state.myLiveAuction = action?.payload?.data?.auction
                    state.totalMyLiveAuction = action?.payload?.data?.auction?.length
                }
                else {
                    state.myLiveAuctionStatus = "ERROR"
                    state.myLiveAuctionError = [action?.payload?.errors?.[0]]
                    if (action?.payload?.errors?.[0] == 'my auction live detail not found!' || action?.payload?.errors?.[0] == 'my auction details not found!') {
                        state.myLiveAuction = []
                        state.totalMyLiveAuction = 0
                    }
                }
                state.myLiveAuctionLoader = false
            })
            .addCase(MyLiveAuction.rejected, (state, action) => {
                state.myLiveAuctionStatus = 'ERROR'
                state.myLiveAuctionLoader = false
                state.retryLive=state.retryLive+1
            })
    }
})

export const { resetStateMyLiveAuction } = myLiveAuctionSlice.actions;
export default myLiveAuctionSlice.reducer;