import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    allLiveAuction: [],
    allLiveAuctionStatus:'',
    allLiveAuctionLoader:false,
    allLiveAuctionError:null,
    totalCountLive:''
};
export const AllLiveAuction = createAsyncThunk("/AllLiveAuction", async (data) => {
    console.log(data,'function called')
    const response = await ApihelperFunction({
        urlPath: `api/market/getAllAuctionLive?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}`,
        method: "get",
    });
    return response;
});
const allLiveAuctionSlice = createSlice({
    name: 'AllLiveAuction',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(AllLiveAuction.pending, (state) => {
                state.allLiveAuctionStatus = 'LOADING'
                state.allLiveAuctionLoader = true
                state.allLiveAuctionError = null
            })
            .addCase(AllLiveAuction.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.allLiveAuctionStatus = "SUCCESS"
                    state.allLiveAuction = action?.payload?.data?.market
                    state.totalCountLive=action?.payload?.data?.links?.totalRecord
                }
                else {
                    state.allLiveAuctionStatus = "ERROR"
                    state.allLiveAuction = []
                    state.totalCountLive=0
                    state.allLiveAuctionError = [action?.payload?.errors?.[0]]
                }
                state.allLiveAuctionLoader = false
            })
            .addCase(AllLiveAuction.rejected, (state, action) => {
                state.allLiveAuctionStatus = 'ERROR'
                state.allLiveAuctionLoader = false
            })
    }
})

export const { resetState } = allLiveAuctionSlice.actions;
export default allLiveAuctionSlice.reducer;