import { Box, Button, CircularProgress, FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GetPaymentMode } from '../../Redux/Slices/GetPaymentModeSlice'
import { useDispatch, useSelector } from 'react-redux'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { GetCompanyBankDetails } from '../../Redux/Slices/GetCompanyBankDetailsSlice'
import { GetFunderBankDetails } from '../../Redux/Slices/GetFunderBankDetailsSlice'
import { WithdrawFundRequest, resetStateWithdrawFundRequest } from '../../Redux/Slices/WithdrawFundRequestSlice'
import { toast } from 'react-toastify'

function WithdrawTransactionModal({ open, handleClose, money, setWithdrawValue }) {
    const { handleSubmit, control, formState: { errors } } = useForm()
    const { getCompanyBankDetails } = useSelector((state) => state.companyBankDetails)
    const { getFunderBankDetails } = useSelector((state) => state.funderBankDetails)
    const dispatch = useDispatch()
    const role = localStorage.getItem("role")
    const bankList = role == "Company" ? getCompanyBankDetails : getFunderBankDetails
    const { data, status, error, loading, message } = useSelector((state) => state.withdrawTransactionReq)
    const [selectedValue, setSelectedValue] = useState('');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: 'transparent linear-gradient(90deg, #B92C82 0%, #3A0CA3 100%) 0% 0% no-repeat padding-box',
        boxShadow: 24,
        borderRadius: '8px',
        color: '#fff',
        p: 4,
    };
    useEffect(() => {
        role == "Company" ? dispatch(GetCompanyBankDetails()) : dispatch(GetFunderBankDetails())
    }, [])
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const onSubmit = (data) => {
        dispatch(WithdrawFundRequest({ 'total_amount': money, 'transaction_desc': 'money withdraw', 'bank_detail_id': selectedValue }))
    }
    //firing toast
    useEffect(() => {
        if (error) {
            toast.error('' + error[0], error[1], error[2], error[3]);
            handleClose()
            dispatch(resetStateWithdrawFundRequest())
        } if (status === "SUCCESS") {
            toast.success('' + message, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    handleClose()
                    setSelectedValue(null)
                    window.location.reload()
                }
            });
        }
    }, [error, status]);
    useEffect(() => {
        dispatch(resetStateWithdrawFundRequest())
    }, [dispatch])
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <RadioGroup
                    name="radio-buttons"
                    value={selectedValue}
                    onChange={handleChange}
                >
                    {
                        bankList?.map((bankDetails, index) => (
                            <Card onClick={() => setSelectedValue(bankDetails?.id)} style={{ cursor: 'pointer' }}>
                                <CardContent sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <Box sx={{ cursor: 'pointer' }} >
                                        <Typography variant='p' component={'p'}>Bank name : {bankDetails?.bank_name}</Typography>
                                        <Typography variant='p' component={'p'}>Account number : {bankDetails?.account_number}</Typography>
                                        {role != "Company" && <Typography variant='p' component={'p'}>Account name : {bankDetails?.account_name || '--'}</Typography>}
                                        <Typography variant='p' component={'p'}>Sort code : {bankDetails?.sort_code}</Typography>
                                    </Box>
                                    <FormControlLabel
                                        sx={{ margin: '0px', padding: '0px' }}
                                        value={bankDetails?.id}
                                        control={<Radio />}
                                    />
                                </CardContent>
                            </Card>
                        ))
                    }
                </RadioGroup>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
                    <Button onClick={handleSubmit(onSubmit)} sx={{ mt: 2, mr: 1, textTransform: 'none' }} variant="outlined" color='white'>
                        {loading ? <CircularProgress color="white" size={'25px'} /> : 'Withdraw fund'}
                    </Button>
                    <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" color="primary">
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default WithdrawTransactionModal