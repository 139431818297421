import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    EditBidStatus: false,
    EditBiddata: '',
    EditBiderror: false,
    EditBidloading: false,
    EditBidmessage: ''
}

export const EditBidFunc = createAsyncThunk("/EditBid", async (bidData) => {
    let data = new FormData();
    for (const key in bidData) {
        if (bidData.hasOwnProperty(key)) {
                data.append(key, bidData[key]);
        }
    }
    const response = await ApihelperFunction({
        urlPath: "api/funder/updateBidding",
        formData: data,
        method: "post",
        typecontent: true
    });
    return response;
});

const EditBidSlice = createSlice({
    name: "EditBid",
    initialState,
    reducers: {
        resetStateEditBid: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(EditBidFunc.pending, (state) => {
                state.EditBidloading = true
            })
            .addCase(EditBidFunc.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.EditBidloading = false
                    state.EditBiderror = false
                    state.EditBidStatus = action?.payload?.status
                    state.EditBidmessage = action?.payload?.message
                }
                else {
                    state.EditBidloading = false
                    state.status = action?.payload?.status
                    state.EditBiderror = [action?.payload?.errors?.[0]]
                }
                state.EditBiddata = action?.payload?.data;
            })
            .addCase(EditBidFunc.rejected, (state, action) => {
                state.EditBidloading = false
                state.EditBidStatus = 'ERROR'
            })
    }
})

export const { resetStateEditBid } = EditBidSlice.actions;
export default EditBidSlice.reducer