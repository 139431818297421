import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    data: "",
    status: '',
    userEmail: "",
    userType: "",
    token: "",
    loading: false,
    error: false,
    message: ''
};
export const VerifyEmail = createAsyncThunk("/VerifyEmail", async (userdata) => {
    let data = new FormData();
    data.append('email', userdata.email);
    data.append('user_type', userdata.user_type);
    const response = await ApihelperFunction({
        urlPath: "api/register?user_agent= postman&app_version= 1.0&os= android",
        formData: data,
        method: "post",
    });
    return response;
});
export const VerifyOTP = createAsyncThunk("/VerifyOTP", async (userdata) => {
    let data = new FormData();
    data.append('email', userdata.email);
    data.append('user_type', userdata.user_type);
    data.append('otp', userdata.otp);

    const response = await ApihelperFunction({
        urlPath: "api/verifyOtp",
        formData: data,
        method: "post",
    });
    return response;
});
const emailVerificationSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateEmailVerification: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(VerifyEmail.pending, (state) => {
                localStorage.removeItem('signUp2');
                localStorage.removeItem('signUp3');
                localStorage.removeItem('signUp4');
                localStorage.removeItem('signUp5');
                localStorage.removeItem('signUp6');
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(VerifyEmail.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                    localStorage.setItem('role', action?.payload?.data?.user_type)
                }
                else {
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors?.[0]]
                }
                console.log(action.payload, 'PAYLOAD')
                state.loading = false
                state.data = action?.payload?.data;
                state.userEmail = action?.payload?.data?.email;
                state.userType = action?.payload?.data?.user_type;
            })
            .addCase(VerifyEmail.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false
            })
            .addCase(VerifyOTP.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(VerifyOTP.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    console.log(action?.payload?.data, 'PAYLOAD')
                    state.status = "SUCCESSOTPVERIFIED"
                    state.message = action?.payload?.message
                }
                else {
                    state.status = "ERROROTPVERIFIED"
                    state.error = [action?.payload?.errors?.[0]]
                }
                state.token = action?.payload?.data.token
                state.loading = false

                localStorage.setItem('token', action?.payload?.data.token)

                // console.log(action.payload,'payload')
            })
            .addCase(VerifyOTP.rejected, (state, action) => {
                state.status = 'ERROR'
                state.loading = false

                // console.log(action.payload, 'PAYLOAD DANGER')
            })
    }
})

export const { resetStateEmailVerification } = emailVerificationSlice.actions;
export default emailVerificationSlice.reducer;
