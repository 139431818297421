import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
};
export const UserCreditLimit = createAsyncThunk("/UserCreditLimit", async () => {
    const response = await ApihelperFunction({
        urlPath: `api/company/checkUserCreditLimit`,
        method: "get",
    });
    return response;
});
const ChecCreditLimitSlice = createSlice({
    name: 'UserCreditLimit',
    initialState,
    reducers: {
        resetUserCreditLimit: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserCreditLimit.pending, (state) => {
                state.userCreditLimitStatus = 'LOADING'
                state.userCreditLimitLoader = true
                state.userCreditLimitError = null
            })
            .addCase(UserCreditLimit.fulfilled, (state, action) => {
                state.userCreditLimitStatus = action?.payload?.status
                state.userCreditLimit=action?.payload?.data
                state.userCreditLimitMessage=action?.payload?.message
                state.userCreditLimitLoader = false
            })
            .addCase(UserCreditLimit.rejected, (state, action) => {
                state.userCreditLimitStatus = 'ERROR'
                state.userCreditLimitLoader = false
            })
    }
})

export const { resetUserCreditLimit } = ChecCreditLimitSlice.actions;
export default ChecCreditLimitSlice.reducer;