import React from 'react';
import { AppBar, Toolbar, Button, Box, Container, useMediaQuery, useTheme, CssBaseline, Typography, styled, Stack } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../assets/img/Logo1.png'
import MobileDrawer from './MobileDrawer';

const GetStartedButtonStyle = styled(Button)({
  color: '#fff',
  width: '148px',
  height: '53px',
  borderRadius: '7px',
  background: 'transparent linear-gradient(90deg, var(--unnamed-color-b92c82) 0%, var(--unnamed-color-3a0ca3) 100%) 0% 0% no-repeat padding-box;'

})

const Navbar = () => {
  const theme = useTheme()
  const location = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const navigate = useNavigate()
  const GetStartedButton = () => {
    return <GetStartedButtonStyle color="primary" sx={{
    }}><Typography variant='subtitle2' onClick={() => navigate('/login')}>Get Started</Typography></GetStartedButtonStyle>
  }
  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#fff', height: { lg: '102px', xs: '70px' }, boxShadow: '0px 3px 6px #0000000D' }}>
      <CssBaseline />
      <Container maxWidth={'xl'} sx={{ mb: 10 }}>
        <Toolbar sx={{ alignItems: 'center', mx: { xl: 13, md: 10, xs: 1 }, pl: { xl: 0, md: 0, xs: 0 }, height: { lg: '102px', xs: '70px' } }} >
          <Box sx={{ flexGrow: 1, py: { md: 0, xs: 2 } }}>
            <Container maxWidth={'xs'} sx={{ marginRight: 'auto', marginLeft: 'inherit', paddingLeft: "0 !important" }}>
              <Link to='/'><img src={Logo} alt='funding joint logo' width={'60%'} height={'auto'} /></Link>
            </Container>
          </Box>
          {
            isMobile
              ? <MobileDrawer button={<GetStartedButton />} />
              :
              location?.pathname!='/login' && <GetStartedButton />
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
