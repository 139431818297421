import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import upFilled from '../assets/img/upfilled.png'
import downFilled from '../assets/img/downfilled.png'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import ButtonWithBadge from "./ButtonWithBadge";
import StatusBadge from "./StatusBadge";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import ErrorMessageComp from "./ErrorMessageComp";


function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
        selectedRows,
        rows,
        checkedEdit
    } = props;

    return (
        <TableHead>
            <TableRow>
                {checkedEdit &&
                    <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rows?.length == selectedRows?.length}
                            onChange={onSelectAllClick}
                            inputProps={{
                                "aria-label": "select all desserts",
                            }}
                        />
                    </TableCell>}
                {headCells.map((headCell) => (
                    <>
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sx={{ paddingLeft: 4 }}

                        >
                            <div style={{ display: 'flex' }}>
                                {
                                    headCell.label == 'Debit' && <img src={downFilled} alt="" width={'12px'} style={{ marginRight: '5px' }} />
                                }
                                {
                                    headCell.label == 'Credit' && <img src={upFilled} alt="" width={'12px'} style={{ marginRight: '5px' }} />
                                }
                                {
                                    (headCell.label == 'Auction Time Left' || headCell.label == 'No. Of BIDS') &&
                                    <Typography sx={{ lineHeight: 1.4 }} variant="p" fontSize={'11px'} component={'p'} color='#272D3B' fontWeight={'700'} textTransform={'uppercase'} width={'60px'}>{headCell.label}</Typography>
                                }
                                {(headCell.label != 'Auction Time Left' && headCell.label != 'No. Of BIDS') && <Typography sx={{ lineHeight: 1.4, textWrap: 'wrap', width: "50px" }} variant="p" fontSize={'11px'} component={'p'} color='#272D3B' fontWeight={'700'} textTransform={'uppercase'}>{headCell.label}</Typography>}
                            </div>
                        </TableCell>
                    </>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function GlobalTable({
    auctionType,
    sortOptions,
    sortBy,
    filterOptions,
    filterBy,
    setFilterOptions,
    setSortOptions,
    showNumberOfLoans,
    walletUpperBox,
    walletUpperBoxTitle,
    showUpperBox,
    rows,
    headCells,
    count,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    order,
    setOrder,
    checkedEdit,
    type,
    showPagination,
    tableName,
    selectedDateWallet,
    selectedDateRange,
    selectedType
}) {
    const location = useLocation()
    const [selectedDateStart, setSelectedDateStart] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //PROGRESS BAR//
    const getStatus = (val1, val2) => {
        if (val1 == 0) {
            return <ButtonWithBadge text={'New'} textColor={'#00f700'} color={'#fff'} borderColour={'#00f700'} showDots={true} />;
        } else if (val1 == 1) {
            return <ButtonWithBadge text={'In-review'} textColor={'#ff6308'} color={'#fff'} borderColour={'#ff6308'} showDots={true} />;
        } else if (val1 == 2) {
            if (val2 == 0) {
                return <ButtonWithBadge text={'Pending'} textColor={'#e07a3f'} color={'#fff'} borderColour={'#e07a3f'} showDots={true} />;
            } else if (val2 == 1) {
                return <ButtonWithBadge text={'Live'} textColor={'#008640'} color={'#fff'} borderColour={'#008640'} showDots={true} />;
            } else if (val2 == 2) {
                return <ButtonWithBadge text={'Closed'} textColor={'red'} color={'#fff'} borderColour={'red'} showDots={true} />;
            }
        } else if (val1 == 3) {
            return <ButtonWithBadge text={'On hold'} textColor={'#ff6308'} color={'#fff'} borderColour={'#ff6308ed'} showDots={true} />

        } else if (val1 == 4) {
            return <ButtonWithBadge text={'Rejected'} textColor={'#000'} color={'#fff'} borderColour={'#000'} showDots={true} />;
        }
        return 'Unknown';
    }
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '200px' }}>
                <Box sx={{ minWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography variant="overline" color="#3A0CA3" fontWeight={'bold'}>{`£${Math.round(
                        props.bidAmount
                    ).toLocaleString('en-us')} (${props.value}%)`}</Typography>
                </Box>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant={props?.value > 100 ? 'Indeterminate' : "determinate"} {...props} sx={{
                        height: 8,
                        borderRadius: '8px',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#B02464', // Customize the progress bar color here
                        },
                    }} />
                </Box>
                <Box sx={{ minWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="overline" color="text.secondary">£{props.lowerLabel}</Typography>
                    <Typography variant="overline" color="text.secondary">£{Math.round(props.upperLabel).toLocaleString('en-us')}</Typography>
                </Box>
            </Box>
        );
    }
    const getAuctionTimeLeft = (startingValue, endValue) => {
        console.log(startingValue, endValue, 'DATE VALUES')
        const startDate = new Date(startingValue).getTime();
        const endDate = new Date(endValue).getTime();
        const currentDate = new Date().getTime();
        const totalDuration = endDate - startDate;
        const elapsedDuration = currentDate - startDate;
        let text;
        const endDateDifference = new Date(endValue);
        const currentDateDifference = new Date();
        const differenceInMilliseconds = endDateDifference - currentDateDifference;
        const differenceInSeconds = differenceInMilliseconds / 1000;
        const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
        const hours = Math.floor((differenceInSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
        const seconds = Math.floor(differenceInSeconds % 60);
        if (days > 0) {
            text = `${days} days`
        } else if (days == 0 && hours > 0 && minutes > 0) {
            text = `${hours}:${minutes} hrs`
        } else if (days < 0) {
            text = 'Closed'
        }
        const elapsedPercentage = (elapsedDuration / totalDuration) * 100;
        console.log(days, hours, minutes, seconds, elapsedPercentage, 'DHMS')
        return { 'elapsedPercentage': elapsedPercentage, 'text': text }
    }
    const user_id = localStorage.getItem('bidid')
    function emphasizeMiddleLetters(word) {
        if (word.length <= 1) {
            return word;
        }
        // Get the start and end letters
        const startLetter = word[0];
        const endLetter = word[word.length - 1];

        // Emphasize the middle letters
        const middleLetters = word.slice(1, -1).split('').map(letter => '*').join('');

        // Concatenate the start, middle, and end letters
        const emphasizedWord = word?.length > 2 ? startLetter + middleLetters + endLetter : word?.length > 1 ? startLetter + '*' : word;

        return emphasizedWord;
    }
    useEffect(() => {
        if (selectedDateRange && !startDateError && !endDateError) {
            selectedDateRange({ startDate: selectedDateStart, endDate: selectedDateEnd })
            if (selectedDateEnd && selectedDateStart && !startDateError && !endDateError) {
                selectedType('custom')
            }
        }
    }, [selectedDateEnd, selectedDateStart])
    const [startDateError, setStartDateError] = useState(null)
    const [endDateError, setEndDateError] = useState(null)
    const navigate = useNavigate()


    return (
        <Box sx={{
            boxShadow: '0px 3px 6px #272D3B33',
            borderRadius: '0px 8px 8px 8px'
        }}>
            {showNumberOfLoans && <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'flex-end', flexDirection: { md: 'column', sm: 'column', xs: 'column', lg: 'row' } }} p={3} pb={0}>
                <Typography variant="h5" component={'p'} color='#272D3B' fontWeight={'bold'} mb={2}>{tableName}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: { xs: 2, lg: 0 }, width: { md: '100%', sm: '100%', xs: '100%', lg: '50%' } }}>
                    <FormControl sx={{ width: { md: '50%', sm: '50%', xs: '50%', lg: '40%' } }}>
                        <InputLabel id="select-label" sx={{ fontSize: "13px", fontWeight: "bold" }}>Total number of loans (20)</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{ height: '45px', border: '1px solid', borderRadius: "8px" }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="option1">Option 1</MenuItem>
                            <MenuItem value="option2">Option 2</MenuItem>
                            <MenuItem value="option3">Option 3</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>}
            {showUpperBox && <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'flex-end', flexDirection: { md: 'column', sm: 'column', xs: 'column', lg: 'row' } }} p={3} pb={0}>
                <Typography variant="h5" component={'p'} color='#272D3B' fontWeight={'bold'} mb={2}>{tableName}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: { xs: 2, lg: 0 }, width: { md: '100%', sm: '100%', xs: '100%', lg: '50%' } }}>
                    <FormControl className="globalTableSelect" sx={{ width: { md: '50%', sm: '50%', xs: '50%', lg: '35%' } }}>
                        <InputLabel id="select-label" sx={{ fontSize: "13px", fontWeight: "bold" }}>Filter By</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            IconComponent={FilterAltOutlinedIcon}
                            sx={{ height: '45px', border: '1px solid', borderRadius: "8px" }}
                            value={filterBy}
                        >
                            {
                                filterOptions?.map((x) => {
                                    return (
                                        <MenuItem value={x?.value} onClick={() => { setFilterOptions(x?.value); setPage(0) }}>
                                            <Typography variant="p" component={'p'} fontSize={'12px'} color={'#000'}>{x?.name}</Typography>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl className="globalTableSelect" sx={{ width: { md: '50%', sm: '50%', xs: '50%', lg: '35%' }, marginLeft: '20px' }}>
                        <InputLabel id="select-label" sx={{ fontSize: "13px", fontWeight: "bold" }}>Sort By</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={sortBy}
                            IconComponent={SortOutlinedIcon}
                            sx={{ height: '45px', border: '1px solid', borderRadius: "8px" }}
                        >
                            {
                                sortOptions?.map((x) => {
                                    return (
                                        <MenuItem value={x?.value} onClick={() => setSortOptions(x?.value)}>
                                            <Typography variant="p" component={'p'} fontSize={'12px'} color={'#000'}>{x?.name}</Typography>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>}
            {walletUpperBox && < Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', flexDirection: { md: 'column', sm: 'column', xs: 'column', lg: 'row' }, background: 'transparent linear-gradient(90deg, #B92C82 0%, #3A0CA3 100%) 0% 0% no-repeat padding-box' }} p={3} pt={1.5} pb={1.5}>
                <Typography variant="p" component={'p'} color='#fff' fontWeight={'bold'} sx={{ letterSpacing: '0.7px' }} textTransform={'none'}>{walletUpperBoxTitle}</Typography>
                <Box sx={{ display: 'flex', justifyContent: "flex-end", flexDirection: { md: 'column', sm: 'column', xs: 'column', lg: 'row' }, width: { md: '100%', sm: '100%', xs: '100%', lg: 'auto' } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: { xs: 2, lg: 0 }, width: 'max-content' }} mr={{ lg: '30px', md: '0px', sm: '0px', xs: '0px' }}>
                        <ButtonWithBadge classCustom="ButtonWithBadge" handleClick={selectedDateWallet} handleType={selectedType} type='all' text='All' color={type == 'all' ? '#3A0CA3' : '#fff'} textColor={type == 'all' ? '#fff' : '#000'} />
                        <ButtonWithBadge classCustom="ButtonWithBadge" handleClick={selectedDateWallet} handleType={selectedType} type='today' text='Today' color={type == 'today' ? '#3A0CA3' : '#fff'} textColor={type == 'today' ? '#fff' : '#000'} />
                        <ButtonWithBadge classCustom="ButtonWithBadge" handleClick={selectedDateRange} handleType={selectedType} text='weekly' type='weekly' color={type == 'weekly' ? '#3A0CA3' : '#fff'} textColor={type == 'weekly' ? '#fff' : '#000'} />
                        <ButtonWithBadge classCustom="ButtonWithBadge" handleClick={selectedDateRange} handleType={selectedType} text='Monthly' type='monthly' color={type == 'monthly' ? '#3A0CA3' : '#fff'} textColor={type == 'monthly' ? '#fff' : '#000'} />
                    </Box>
                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: { md: 'column', sm: 'column', xs: 'column', lg: 'row' }, justifyContent: 'flex-end', mt: { xs: 2, lg: 0 }, width: { md: '100%', sm: '100%', xs: '100%', lg: 'max-content', alignItems: 'flex-start' } }}>
                        <div style={{ width: '200px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className='custom-datepicker small-datepicker'
                                    format='DD/MM/YYYY'
                                    label="Start date"
                                    value={selectedDateStart}
                                    maxDate={dayjs()}
                                    onError={(error) => setStartDateError(error)}
                                    onChange={(val) => { setSelectedDateStart(val?.$d); selectedDateRange({ startDate: val?.$d }); setStartDateError(null) }}
                                    renderInput={(params) => <TextField sx={{ ":-ms-input-placeholder": '#fff' }} {...params} variant="outlined" onChange={(newValue) => console.log(newValue)} />} // Use TextField for input
                                />
                            </LocalizationProvider>
                            {
                                startDateError && <ErrorMessageComp message={'Please select a valid date'} />
                            }
                        </div>
                        <div style={{ width: '200px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className='custom-datepicker small-datepicker'
                                    format='DD/MM/YYYY'
                                    label="End date"
                                    value={selectedDateEnd}
                                    maxDate={dayjs()}
                                    onError={(error) => setEndDateError(error)}
                                    onChange={(val) => { setSelectedDateEnd(val?.$d); selectedDateRange({ startDate: selectedDateStart, endDate: val?.$d }); selectedType('custom'); setEndDateError(null) }}
                                    renderInput={(params) => <TextField sx={{ ":-ms-input-placeholder": '#fff' }} {...params} variant="outlined" onChange={(newValue) => console.log(newValue)} />} // Use TextField for input
                                />
                            </LocalizationProvider>
                            {
                                endDateError && <ErrorMessageComp message={'Please select a valid date'} />
                            }
                        </div>
                    </Box>
                </Box>
            </Box>}
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                {
                    rows?.length === 0 || !rows?.length ? <Typography variant="h6" component={'h6'} mt={3} mb={3} textAlign={'center'}>No Data Available</Typography> :
                        <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className=" h-100"
                        >
                            <EnhancedTableHead
                                checkedEdit={checkedEdit}
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                setOrder={setOrder}
                                rowCount={rows?.length}
                                rows={rows}
                            />
                            <TableBody>
                                {
                                    rows?.map((row, i) => (
                                        <TableRow
                                            onClick={() => {
                                                { location?.pathname == '/tradingPartner' && navigate(`/viewTradingPartner?${row?.id}`, { state: { from: '/tradingPartner' } }) };
                                                { location?.pathname == '/marketPlace' && navigate(`/myAuction/auctionDetails?${row?.id}`) };
                                                { (location?.pathname == '/myInvestment' || location?.pathname == '/myFunding') && navigate(`/loanDetails?${row?.id}`, { state: auctionType }) };
                                                { location?.pathname == '/myAuction/auctionList' && row?.status_name == 'Approve' && navigate(`/myAuction/auctionDetails?${row?.id}`) }
                                            }}
                                            key={i}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: (location?.pathname == '/tradingPartner' || location?.pathname == '/myInvestment' || location?.pathname == '/myFunding' || location?.pathname == '/marketPlace' || (location?.pathname == '/myAuction/auctionList' && row?.status_name == 'Approve')) ? 'pointer' : '', background: ((location?.pathname == '/myAuction/leaderboard'||location?.pathname=='/loanDetails') && row?.status == 1) ? '#3A0CA3' : ((user_id == row?.userId) && row?.isFullfilled != 0) ? '#B92C82' : '' }}
                                            className={(location?.pathname == '/myAuction/leaderboard'||location?.pathname=='/loanDetails') && (row?.status == 1 || ((user_id == row?.userId) && row?.isFullfilled != 0)) && 'whitetText'}
                                        >
                                            {checkedEdit && <TableCell align="right" >
                                                <Checkbox
                                                    inputProps={{ "aria-label": "Checkbox demo" }}
                                                />
                                            </TableCell>}

                                            {headCells?.map((item, i) => {
                                                return (
                                                    <TableCell
                                                        datatable={headCells[i]?.label}
                                                        align={item.align}
                                                        key={i}
                                                        sx={{ paddingLeft: item.type == 'linearProgress' ? 0 : 4, paddingRight: item.type == 'linearProgress' && 0 }}
                                                    >
                                                        {
                                                            item?.type === 'Link' ? item.showValue
                                                                ? <Typography onClick={() => navigate(`/${item.navigateTo}?${row[item.id]}`)} style={{ textWrap: 'nowrap', textTransform: 'none', color: '#3A0CA3', fontSize: '15px', fontWeight: 600, cursor: 'pointer' }}>{item.showValue(row[item.fieldName])}</Typography>
                                                                : <Typography onClick={() => navigate(`/${item.navigateTo}?${row[item.id]}`)} style={{ textWrap: 'nowrap', textTransform: 'none', color: '#3A0CA3', fontSize: '15px', fontWeight: 600, cursor: 'pointer' }}>{row[item.fieldName]}</Typography>
                                                                : ''
                                                        }
                                                        {
                                                            item?.type === 'auction_number' ? item.showValue
                                                                ? <Typography style={{ textWrap: 'wrap', textTransform: 'none', fontSize: '12px', width: "100px" }}>{item.showValue(row[item.fieldName])}</Typography>
                                                                : <Typography style={{ textWrap: 'wrap', textTransform: 'none', fontSize: '12px', width: "100px" }}>{row[item.fieldName]}</Typography>
                                                                : ''
                                                        }
                                                        {
                                                            item?.type === 'company' ? item.showValue
                                                                ? <Typography style={{ textWrap: 'wrap', textTransform: 'none', color: '#3A0CA3', fontSize: '12px', fontWeight: 600, width: "80px", overflow: 'hidden' }}>{item.showValue(row[item.fieldName])}</Typography>
                                                                : <Typography style={{ textWrap: 'wrap', textTransform: 'none', color: '#3A0CA3', fontSize: '12px', fontWeight: 600, width: "80px", overflow: 'hidden' }}>{row[item.fieldName]}</Typography>
                                                                : ''
                                                        }
                                                        {
                                                            (row?.auction_status != 0 && item?.type === 'roundProgress') &&
                                                            <CircularProgressbar styles={{ width: "100px" }} className={getAuctionTimeLeft(row[item.fieldName], row[item.subFieldName]).elapsedPercentage > 100 ? 'closed' : ''} value={getAuctionTimeLeft(row[item.fieldName], row[item.subFieldName]).elapsedPercentage} text={getAuctionTimeLeft(row[item.fieldName], row[item.subFieldName]).text} strokeWidth={10} />
                                                        }
                                                        {
                                                            (row?.status == 0 && row?.auction_status == 0 && item?.type === 'roundProgress') &&
                                                            <Typography style={{ textWrap: 'nowrap', textTransform: 'none', color: '#3A0CA3', fontSize: '12px', fontWeight: 600 }}>Not yet Approved</Typography>
                                                        }
                                                        {
                                                            (row?.status == 2 && row?.auction_status == 0 && item?.type === 'roundProgress') &&
                                                            <CircularProgressbar styles={{ width: "100px" }} className={'pending'} value={100} text={'Pending'} strokeWidth={10} />
                                                        }
                                                        {
                                                            (row?.status == 4 && row?.auction_status == 0 && item?.type === 'roundProgress') &&
                                                            <CircularProgressbar styles={{ width: "100px" }} className={'rejected'} value={100} text={'Rejected'} strokeWidth={10} />
                                                        }
                                                        {
                                                            item?.type === 'linearProgress' && <LinearProgressWithLabel lowerLabel={0} upperLabel={row[item.upperLabel]} bidAmount={row[item.bidAmount]} value={Math.round(row[item.bidAmount] / row[item.upperLabel] * 100)} />
                                                        }
                                                        {
                                                            item?.type === 'creditRating' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ color: item.showValue(row[item.fieldName]) == 'High Risk' ? '#E10000' : item.showValue(row[item.fieldName]) == 'Above Average Risk' ? '#f75c14' : item?.showValue(row[item.fieldName]) == 'Average Risk' ? '#f7883a' : item?.showValue(row[item.fieldName]) == 'Low Risk' ? '#0eab5a' : '#272D3B' }} component={'p'} sx={{ fontWeight: 'normal' }}>{item.showValue(row[item.fieldName])}</Typography>
                                                        }
                                                        {
                                                            !item.type ? item.showValue
                                                                ? <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ color: item.color == 'danger' ? '#E10000' : item.color == 'success' ? '#3CB500' : item?.color || '#272D3B' }} component={'p'} sx={{ fontWeight: 'normal', color: '#000', width: '100px' }}>{item.showValue(row[item.fieldName])}</Typography>
                                                                : <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ color: item.color == 'danger' ? '#E10000' : item.color == 'success' ? '#3CB500' : item?.color || '#272D3B' }} component={'p'} sx={{ fontWeight: 'normal', color: '#000', width: '100px' }}>{row[item.fieldName]}</Typography> : ''
                                                        }
                                                        {
                                                            item.type == 'money' ? item.showValue
                                                                ? <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '700', color: '#000000' }}>{item.showValue(row[item.fieldName])}</Typography>
                                                                : <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '700', color: '#000000' }}>{row[item.fieldName]}</Typography> : ''
                                                        }
                                                        {
                                                            item.type == 'password' ? item.showValue
                                                                ? <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '700', color: '#000000' }}>{item.showValue(row[item.fieldName])}</Typography>
                                                                : <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '700', color: '#000000' }}>{row[item.fieldName]}</Typography> : ''
                                                        }
                                                        {
                                                            item.type == 'passwordLeaderBoard' && <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '700', color: '#000000' }}>{user_id == row[item.subFieldName] ? row[item.fieldName] : emphasizeMiddleLetters(row[item.fieldName])}<span style={{ fontSize: "12px", fontWeight: '500' }}></span></Typography>
                                                        }
                                                        {
                                                            item?.type === 'badge' ? <StatusBadge text={row[item.fieldName] || (item?.showValue && item.showValue(row[item.fieldName]))} /> : ''
                                                        }
                                                        {
                                                            item?.type === 'edit' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: '#000000' }}>Edit</Typography>
                                                        }
                                                        {
                                                            item?.type === 'debit' && row[item.subFieldName] == 'debit' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: '#E10000' }}>{`-£ ${Number(row[item.fieldName]).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'debit' && row[item.subFieldName] != 'debit' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: '#E10000' }}>____</Typography>
                                                        }
                                                        {
                                                            item?.type === 'credit' && row[item.subFieldName] == 'credit' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: '#3CB500' }}>{`+£ ${Number(row[item.fieldName]).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'credit' && row[item.subFieldName] != 'credit' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: '#3CB500' }}>____</Typography>
                                                        }
                                                        {
                                                            item?.type === 'Description & Date' && <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: "#3a0ca3" }}>{row[item.fieldName]}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'Description & Date' && <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: '#000', marginTop: '5px' }}>{moment(row[item.subFieldName]).format('DD/MM/YYYY')}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'walletStatus' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: row[item.fieldName] == 'Approved' ? '#3CB500' : row[item.fieldName] == 'Rejected' ? 'red' : '#272D3B' }}>{row[item.fieldName]}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'roi' && <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: "#272D3B", textWrap: 'nowrap', width: '80px' }}>{row[item.fieldName]}% / £{Math.round(row[item.subFieldName]).toLocaleString('en-us')}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'roi_percent' && <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '600', color: "#272D3B", textWrap: 'nowrap', width: '80px' }}> {Math.round(row[item.fieldName]%row[item.subFieldName]*100)||0} %</Typography>
                                                        }
                                                        {
                                                            item?.type === 'DateTransaction' && <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} style={{ fontWeight: '500', color: "#272D3B", textWrap: 'nowrap' }}>{row[item.checkFieldName.transaction_type] == 'Withdraw' ? row[item.fieldNameTwo] : row[item.fieldNameOne]}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'auctionStatus' && getStatus(row[item.subFieldName], row[item.fieldName])
                                                        }
                                                        {
                                                            item?.type === 'splitField' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ color: item.color == 'danger' ? '#E10000' : item.color == 'success' ? '#3CB500' : item?.color || '#272D3B' }} component={'p'} sx={{ fontWeight: 'normal', color: '#000' }}>{item.showValue(row[item.fieldName]) || row[item.fieldName]}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'fundingShare' && <Typography variant="p" textTransform="none" fontSize={'13px'} style={{ color: item.color == 'danger' ? '#E10000' : item.color == 'success' ? '#3CB500' : item?.color || '#272D3B' }} component={'p'} sx={{ fontWeight: '700', color: '#000' }}>{`${(Math.floor(Number(row[item.fieldName])) / Math.floor(Number(row[item.secondFieldName]))) * 100} %`}</Typography>
                                                        }
                                                        {
                                                            item?.type === 'splitField' && row[item?.checkingValue] == '1' &&
                                                            <Box sx={{ background: "#fff", padding: "5px 10px", borderRadius: '4px', boxShadow: '0px 0px 5px #d2d2d2', marginTop: '10px', width: "max-content" }}>
                                                                <Typography variant="p" component={'p'} textTransform="none" fontSize={'13px'} fontWeight={600} style={{ color: '#272D3B' }}>{item?.secondLabel}</Typography>
                                                                <Typography variant="p" component={'p'} textTransform="none" fontSize={'11px'} style={{ color: '#272D3B' }} sx={{ fontWeight: 'normal', color: '#000' }}>{row[item.secondFieldName]}</Typography>
                                                            </Box>
                                                        }
                                                    </TableCell>
                                                );
                                            })}

                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                }
            </TableContainer >
            {showPagination == 'true' && <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Box >
    );
}
