import {
    Button, Card, List, Container,
    Typography,
    Stack,
    Box
} from '@mui/material';

import circlecheck1 from '../../assets/img/check-circle1.svg'
import icon4 from '../../assets/img/icon-04_2x.png'
import icon5 from '../../assets/img/icon-05_2x.png'
import icon6 from '../../assets/img/icon-06_2x.png'
import ListItemWithIconAndText from '../../components/ListItemWithIconAndText';
import RoundedCardImgAndText from '../../components/RoundedCardImgAndText';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const ForFunderSection = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    return (
        <Container maxWidth={'xl'} sx={{ mb: 10 }}>
            <Card sx={{
                backgroundColor: 'var(--unnamed-color-f1f4ff)',
                px: { md: 8, xs: 2 },
                pt: { md: 8, xs: 6 },
                pb: { md: 12, xs: 6 },
                borderRadius: '32px',
                mx: { xl: 13, md: 10, xs: 1 }
            }}
                elevation={0}
            >
                <Stack ml={2}>
                    <Typography variant='h4' fontSize={'35px'} sx={{ color: 'var(--unnamed-color-3a0ca3)' }}>
                        FOR FUNDERS</Typography>
                    <Typography textTransform={'none'} fontSize={'20px'} variant='p' sx={{ color: 'var(--unnamed-color-3a0ca3)' }} mt={1}>
                        P2P investing with no fees</Typography>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} >
                    <Stack width={{ xs: '100%', md: '100%' }}>
                        <Box sx={{
                            display: 'flex', [theme.breakpoints.only('xs')]: {
                                flexWrap: 'wrap',
                            }, justifyContent: 'space-between', width: '100%', alignItems: 'center'
                        }}>
                            <ListItemWithIconAndText image={circlecheck1} imagealt={'check1'}
                                mt={{ xs: 1, md: 5 }} >
                                Fund credit-worthy UK businesses
                            </ListItemWithIconAndText>
                            <RoundedCardImgAndText image={icon4} imagealt={'icon6'} text={'Invest in trade invoices'} />
                        </Box>
                        <Box sx={{
                            display: 'flex', [theme.breakpoints.only('xs')]: {
                                flexWrap: 'wrap',
                            }, justifyContent: 'space-between', width: '100%', alignItems: 'center'
                        }}>
                            <ListItemWithIconAndText image={circlecheck1} imagealt={'check1'} mt={{ xs: 1, md: 7 }}
                                textWrap={{ maxWidth: '70%', wordWrap: 'break-word' }}
                            >
                                Fixed-income, short-term sharia asset class investment with strong returns. Capital at risk.
                            </ListItemWithIconAndText>
                            <RoundedCardImgAndText image={icon5} imagealt={'icon6'} text={'Invest minimum £100'} />
                        </Box>
                        <Box sx={{
                            display: 'flex', [theme.breakpoints.only('xs')]: {
                                flexWrap: 'wrap',
                            }, justifyContent: 'space-between', width: '100%', alignItems: 'center'
                        }}>
                            <ListItemWithIconAndText image={circlecheck1} imagealt={'check1'} mt={{ xs: 1, md: 9 }}
                            >
                                Set your ROI - peer-to-peer lending gives you control
                            </ListItemWithIconAndText>
                            <RoundedCardImgAndText image={icon6} imagealt={'icon6'} text={'Sharia returns, no fees'} />
                        </Box>
                    </Stack>
                </Stack>
            </Card>
            <Button onClick={() => navigate('/beAFunder')} sx={{
                position: 'relative',
                left: { lg: '40%', md: '33%', sm: '29%', xs: '25%' },
                padding: { xs: '10px 20px', lg: '20px 35px', md: '20px 30px', },
                top: { lg: -30, md: -40, xs: -20 }, alignSelf: 'center', color: '#fff',
                borderRadius: '11px',
                width: { lg: '18%', md: '33%', sm: '37%', xs: '50%' },
                backgroundColor: 'var(--unnamed-color-3a0ca3)',
                '&:hover': {
                    backgroundColor: 'var(--unnamed-color-3a0ca3)',
                    borderColor: 'var(--unnamed-color-3a0ca3)',
                    boxShadow: 'none',
                },
                '&:active': {
                    boxShadow: 'none',
                    backgroundColor: 'var(--unnamed-color-3a0ca3)',
                    borderColor: 'var(--unnamed-color-3a0ca3)',
                },
                '&:focus': {
                    boxShadow: 'var(--unnamed-color-3a0ca3)',
                },
            }}><Typography variant='caption' textTransform={'uppercase'} fontSize={'18px'}>BE A FUNDER</Typography></Button>
        </Container >
    )
}

export default ForFunderSection
