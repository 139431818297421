import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import checked from '../assets/img/checked.png'

function InformationCard({ data, marginRight }) {
    const cardStyle = {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 30px #15223214',
        borderRadius: '8px',
        padding: '20px',
        marginTop: '15px',
        height: "100%"
    }
    console.log(data,'DETAILS')
    return (
        <div style={{ marginRight: marginRight, flex: 1 }}>
            <Card sx={cardStyle}>
                <Typography variant='h6' color='#B92C82' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                    {data.infoName}
                </Typography>
                {
                    data?.details?.map((details) => {
                        return (
                            details?.heading != 'Purpose of funding' ?
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                                    <img src={checked} alt='' width={'15px'} style={{ marginRight: '15px' }} />
                                    <Box>
                                        <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{details?.heading}</Typography>
                                        <Typography variant='h6' fontSize={'15px'} component={'h6'} fontWeight={'600'} textTransform={(details?.type == 'website' || details?.type == 'reg_no') ? 'none' : 'capitalize'}>{details?.data}</Typography>
                                    </Box>
                                </Box> :
                                // <p>details.data</p>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '15px' }}>
                                    <img src={checked} alt='' width={'15px'} style={{ marginRight: '15px' }} />
                                    <Box>
                                        <Typography variant='overline' color={'#272D3B'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>{details?.heading}</Typography>
                                        {details?.data && details?.data?.map((x)=>{
                                            return(
                                                <Typography variant='h6' fontSize={'15px'} component={'h6'} fontWeight={'600'} textTransform={(details?.type == 'website' || details?.type == 'reg_no') ? 'none' : 'capitalize'}>{x}</Typography>
                                            )
                                        })}
                                    </Box>
                                </Box>
                        )
                })
                }
            </Card>
        </div>
    )
}

export default InformationCard