import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import UpperLayout from '../../components/UpperLayout'
import InformationCard from '../../components/InformationCard'
import { useTheme } from '@emotion/react'
import TradingPartnerCard from '../../components/TradingPartnerDetailsCard'
import StatusBadgeTradingPartner from '../../components/StatusBadgeTradingPartner'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TradingPartnerDetails } from '../../Redux/Slices/GetTradingPartnerDetailsSlice'
import ModalComp from '../../components/ModalComp'
import { Button } from 'react-scroll'
import back from '../../assets/img/back.png'
import Loader from '../../components/Loader'

function ViewTradingPartner() {
    const theme = useTheme()
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const location = useLocation()
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const { tradingPartnerDetails } = useSelector((state) => state.tradingPartnerDetails)
    useEffect(() => {
        dispatch(TradingPartnerDetails(location.search.slice(1, location.search.length)))
    }, [])
    useEffect(() => {
        if (tradingPartnerDetails?.previous_request?.status_name && location?.state?.from == "/tradingPartner") {
            setOpenModal(true)
        }
    }, [tradingPartnerDetails])
    console.log(location?.state, location, 'STATEEEEEEEEEE')
    return (

        !tradingPartnerDetails ? <Loader /> : <>
            <Typography textTransform={'none'} variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                Trading partners
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <StatusBadgeTradingPartner text={'Active'} disabled={tradingPartnerDetails?.status_name != 'Active'} />
                <StatusBadgeTradingPartner text={'Pending'} disabled={tradingPartnerDetails?.status_name != 'New'} />
                <StatusBadgeTradingPartner text={'Rejected'} disabled={tradingPartnerDetails?.status_name != 'Reject'} />
            </Box>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
                <TradingPartnerCard />
            </Box>
            <ModalComp closeButton={"Ok"} open={openModal} message={`Your previous profile change request status is ${tradingPartnerDetails?.previous_request?.status_name == 'New' ? 'pending' : tradingPartnerDetails?.previous_request?.status_name?.toLowerCase()}`} handleClose={() => { setOpenModal(false); localStorage.removeItem('prevReq') }} />
        </>

    )
}

export default ViewTradingPartner