import MinimalLayout from "../layout/MinimalLayout"
import SignUp7 from "../pages/SignUp/SignUp7"
import SignUp1 from "../pages/SignUp/SignUp1"
import SignUp2 from "../pages/SignUp/SignUp2"
import SignUp3 from "../pages/SignUp/SignUp3"
import SignUp4 from "../pages/SignUp/SignUp4"
import SignUp5 from "../pages/SignUp/SignUp5"
import SignUp6 from "../pages/SignUp/SignUp6"

const LoginRoute = {
    path: '',
    element: <MinimalLayout />,
    children: [
        {
            path: '/signUp1',
            children: [
                {
                    path: '',
                    element: <SignUp1 />
                }
            ]
        },
        {
            path: '/signUp2',
            children: [
                {
                    path: '',
                    element: <SignUp2 />
                }
            ]
        },
        {
            path: '/signUp3',
            children: [
                {
                    path: '',
                    element: <SignUp3 />
                }
            ]
        },
        {
            path: '/signUp4',
            children: [
                {
                    path: '',
                    element: <SignUp4 />
                }
            ]
        },
        {
            path: '/signUp5',
            children: [
                {
                    path: '',
                    element: <SignUp5 />
                }
            ]
        },
        {
            path: '/signUp6',
            children: [
                {
                    path: '',
                    element: <SignUp6 />
                }
            ]
        },
        {
            path: '/signUp7',
            children: [
                {
                    path: '',
                    element: <SignUp7 />
                }
            ]
        },
    ]
}

export default LoginRoute
