import React from 'react'
import DashboardPageOne from '../pages/Dashboard/DashboardPageOne'
import MarketPlace from '../pages/Dashboard/MarketPlace'
import DashboardLayout from '../layout/DashboardLayout/Sidebar'
import Wallet from '../pages/Dashboard/Wallet'
import TradingPartner from '../pages/Dashboard/TradingPartner'
import LeaderBoard from '../pages/Dashboard/LeaderBoard'
import AuctionDetails from '../pages/Dashboard/AuctionDetails'
import CreateAuction from '../pages/Dashboard/CreateAuction'
import CreateTradingPartner from '../pages/Dashboard/AddTradingPartner'
import MyInvestment from '../pages/Dashboard/MyInvestment'
import AuctionList from '../pages/Dashboard/AuctionList'
import TermsAndCondition from '../pages/TermsAndCondition'
import ViewTradingPartner from '../pages/Dashboard/ViewTradingPartner'
import ViewProfile from '../pages/ViewProfile'
import CreateBid from '../pages/Dashboard/Bidding/CreateBid'
import ReviewBid from '../pages/Dashboard/Bidding/ReviewBid'
import EditBid from '../pages/Dashboard/Bidding/EditBid'
import MyFunding from '../pages/Dashboard/MyFunding'
import LoanDetails from '../pages/Dashboard/LoanDetails'
import NotificationList from '../pages/Dashboard/NotificationList'

export const DashboardRoute = {
    path: '/',
    element: <DashboardLayout />,
    children: [
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardPageOne />
                },
            ]
        },
        {
            path: "marketPlace",
            element: <MarketPlace />,
        },
        {
            path: "myInvestment",
            element: <MyInvestment />,
        },
        {
            path: "myFunding",
            element: <MyFunding />,
        },
        {
            path: "loanDetails",
            element: <LoanDetails />,
        },
        {
            path: "myWallet",
            element: <Wallet />,
        },
        {
            path: "tradingPartner",
            element: <TradingPartner />,
        },
        {
            path: 'myAuction',
            children: [
                {
                    path: 'leaderBoard',
                    element: <LeaderBoard />
                },
                {
                    path: 'auctionDetails',
                    element: <AuctionDetails />
                },
                {
                    path: 'auctionList',
                    element: <AuctionList />
                },
            ]
        },
        {
            path: "createAuction",
            element: <CreateAuction />,
        },
        {
            path: "createTradingPartner",
            element: <CreateTradingPartner />,
        },
        {
            path: "editTradingPartner",
            element: <CreateTradingPartner />,
        },
        {
            path: "viewTradingPartner",
            element: <ViewTradingPartner />,
        },
        {
            path: "termsandcondition",
            element: <TermsAndCondition />,
        },
        {
            path: "whydoweneedthis",
            element: <TermsAndCondition />,
        },
        {
            path: "sophisticatedinvestor",
            element: <TermsAndCondition />,
        },
        {
            path: "viewProfile",
            element: <ViewProfile />,
        },
        {
            path: "enterBid",
            element: <CreateBid />,
        },
        {
            path: "editBid",
            element: <EditBid />,
        },
        {
            path: "notificationList",
            element: <NotificationList />,
        },
    ]
}

