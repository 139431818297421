import { Drawer, Box, IconButton, Typography, Stack, Divider } from "@mui/material"
import { useState } from "react";
import { Menu } from '@mui/icons-material'

import { Link } from "react-router-dom";

const MobileDrawer = ({ button }) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <>
            <Drawer
                anchor="left"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)} sx={{mt: 103}}>
                <Stack p={2} spacing={2} marginTop={5}>
                    <Box >
                        <Link to={''} style={{ textDecoration: 'none', color: '#000' }}>
                            <Typography>
                                WHAT WE DO
                            </Typography>
                        </Link>
                    </Box>
                    <Divider />
                    <Box >
                        <Link to={''} style={{ textDecoration: 'none', color: '#000' }}>
                            <Typography>
                                HOW IT WORKS
                            </Typography>
                        </Link>
                    </Box>
                    <Divider />
                    <Box >
                        <Link to={''} style={{ textDecoration: 'none', color: '#000' }}>
                            <Typography>
                                FAQ
                            </Typography>
                        </Link>
                    </Box>
                    <Divider />
                    <Box >
                        {button}
                    </Box>
                </Stack>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <Menu />
            </IconButton>
        </>
    )
}

export default MobileDrawer
