import React from 'react';
import Button from '@mui/material/Button';

const ButtonWithBadge = ({ text, color, textColor, handleClick, type, handleType, classCustom, borderColour, showDots }) => {
  const handleChange = () => {
    handleType(type)
    if (type == 'all') {
      handleClick(null)
    } else if (type == 'today') {
      handleClick(new Date())
    } else if (type == 'weekly') {
      handleClick({ startDate: startDateString, endDate: endDateString })
    } else if (type == 'monthly') {
      handleClick({ startDate: startDateFormatted, endDate: endDateFormatted })
    }
  }
  // Get the current date
  const currentDate = new Date();

  // Find the start of the current week (Sunday)
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

  // Find the end of the current week (Saturday)
  const endOfWeek = new Date(currentDate);
  endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

  // Format the start and end dates as strings
  const startDateString = startOfWeek.toISOString().split('T')[0];
  const endDateString = endOfWeek.toISOString().split('T')[0];
  // Calculate the start of the current month
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Calculate the end of the current month
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Format the dates if needed (e.g., to display them)
  const startDateFormatted = startOfMonth.toISOString().slice(0, 10); // YYYY-MM-DD
  const endDateFormatted = endOfMonth.toISOString().slice(0, 10); // YYYY-MM-DD
  return (
    <Button className={classCustom} onClick={() => handleChange()} size='small' variant="contained" sx={{ borderRadius: '15px', fontSize: '10px', color: textColor, background: color, marginLeft: '5px', fontWeight: '500', padding: '7px', border: `1px solid ${borderColour}`,width:'100px' }}>
      {
        showDots && <div style={{ background: `${textColor}`, width: '5px', height: '5px', borderRadius: '50%' ,marginRight:'7px'}}></div>
      }
      {text}
    </Button>
  );
};

export default ButtonWithBadge;
