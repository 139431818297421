import React, { useEffect, useState } from 'react'
import HorizontalLinearAlternativeLabelStepper from '../../SignUp/Stepper'
import { Typography } from '@mui/material'
import StepOneCreateTradingPartner from './StepOneCreateTradingPartner'
import StepTwoCreateTradingPartner from './StepTwoCreateTradingPartner'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TradingPartnerDetails, resetStateTradingPartnerDetails } from '../../../Redux/Slices/GetTradingPartnerDetailsSlice'
import Loader from '../../../components/Loader'

function CreateTradingPartner() {
    const [stepNo, setStepNo] = useState(1)
    const location = useLocation()
    const dispatch = useDispatch()
    const { tradingPartnerDetails } = useSelector((state) => state.tradingPartnerDetails)
    useEffect(() => {
        dispatch(TradingPartnerDetails(location.search.slice(1, location.search.length)))
    }, [])
    console.log(tradingPartnerDetails, 'tradingPartnerDetails')
    return (
        ((location?.pathname == '/editTradingPartner' && tradingPartnerDetails) || location?.pathname == '/createTradingPartner') ? <>
            {
                stepNo == 1 && <StepOneCreateTradingPartner editValue={location?.pathname == '/editTradingPartner' ? tradingPartnerDetails : null} setStepNo={setStepNo} stepNo={stepNo} />
            }
            {
                stepNo == 2 && <StepTwoCreateTradingPartner editValue={location?.pathname == '/editTradingPartner' ? tradingPartnerDetails : null} setStepNo={setStepNo} stepNo={stepNo} />
            }
        </> : <Loader />

    )
}

export default CreateTradingPartner