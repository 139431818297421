import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import GlobalTable from '../../components/GlobalTable'
import { useDispatch, useSelector } from 'react-redux'
import { TradingPartnerList, resetStateTradingPartnerList } from '../../Redux/Slices/GetTradingPartnerListSlice'
import { resetStateTradingPartnerDetails } from '../../Redux/Slices/GetTradingPartnerDetailsSlice'

function TradingPartner() {
    const { tradingPartnerListInitial } = useSelector((state) => state.tradingPartnerList)
    const dispatch = useDispatch()
    useEffect(() => {
            dispatch(TradingPartnerList())
            dispatch(resetStateTradingPartnerDetails())
    }, [])
    useEffect(()=>{
        dispatch(resetStateTradingPartnerDetails())
        dispatch(resetStateTradingPartnerList())
    },[dispatch])

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography textTransform={'none'} variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                    Trading partner
                </Typography>
            </Box>
            <GlobalTable
                count={tradingPartnerListInitial?.length}
                tableName='Live Auctions'
                rows={tradingPartnerListInitial}
                headCells={[
                    {
                        align: "left",
                        label: "Company",
                        fieldName: "name",
                        type: 'Link',
                        id: 'id',
                        navigateTo: 'viewTradingPartner'
                    },
                    {
                        align: "left",
                        label: "Email",
                        fieldName: "contact_email",
                    },
                    {
                        align: "left",
                        label: "Company Type",
                        fieldName: "company_type",
                        showValue: (val) => {
                            return val.name;
                        },
                    },
                    {
                        align: "left",
                        label: "Business Type",
                        fieldName: "company_category",
                        showValue: (val) => {
                            return val.name;
                        },
                    },
                    {
                        align: "left",
                        label: "Status",
                        fieldName: "status_name",
                        type: 'badge'
                    }
                ]}
            />
        </>
    )
}

export default TradingPartner