import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    status: '',
    data: '',
    error: false,
    loading: false,
    message: ''
}

export const AddFundRequest = createAsyncThunk("/AddFundRequest", async (addFundTransactionReqData) => {
    let data = new FormData();
    for (const key in addFundTransactionReqData) {
        if (addFundTransactionReqData.hasOwnProperty(key)) {
                data.append(key, addFundTransactionReqData[key]);
        }
    }
    console.log(data,addFundTransactionReqData,'DATA FORM')
    const response = await ApihelperFunction({
        urlPath: "api/addTransactionRequest",
        formData: data,
        method: "post",
        typecontent: true
    });
    return response;
});

const AddFundRequestSlice = createSlice({
    name: "AddFundRequest",
    initialState,
    reducers: {
        resetStateAddFundRequest: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddFundRequest.pending, (state) => {
                state.status = 'LOADING'
                state.loading = true
                state.error = false
                state.message = ''
            })
            .addCase(AddFundRequest.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loading = false
                    state.error = false
                    state.status = "SUCCESS"
                    state.message = action?.payload?.message
                }
                else {
                    state.loading = false
                    state.status = "ERROR"
                    state.error = [action?.payload?.errors]
                }
                state.data = action?.payload?.data;
            })
            .addCase(AddFundRequest.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERROR'
            })
    }
})

export const { resetStateAddFundRequest } = AddFundRequestSlice.actions;
export default AddFundRequestSlice.reducer