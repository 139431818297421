import { Button, CircularProgress, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateAuction, resetStateCreateAuction } from '../../../Redux/Slices/CreateAuctionSlice'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function StepEight() {
    const auctionData = useSelector((state) => state.createAuction)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { status } = useSelector((state) => state?.createAuction)
    console.log(status, 'STATS')
    //firing toast
    useEffect(() => {
        if (auctionData.error) {
            toast.error('' + auctionData.error[0], auctionData.error[1], auctionData.error[2], auctionData.error[3]);
        } if (auctionData.status === "SUCCESS") {
            toast.success('' + auctionData.message, {
                style: { fontSize: "15px" },
                onOpen: () => {
                    dispatch(resetStateCreateAuction())
                    localStorage.removeItem('FJChecked')
                    navigate('/myAuction/auctionList?AllAuction')
                }
            });

        }
    }, [auctionData.error, auctionData.status]);
    return (
        <Container maxWidth={'xs'}>
            <Typography variant='p' component={'p'} color={'#B92C82'} textAlign={'center'} fontWeight={'700'} fontSize={'20px'} textTransform={'none'} width={'50%'} margin={'auto'}>
                Submit your auction for approval
            </Typography>
            <Typography variant='p' component={'p'} textAlign={'center'} textTransform={'none'} width={'70%'} margin={'auto'}> Once approved, it will be released onto the marketplace for funders</Typography>
            <Stack justifyContent="center" direction="row" margin={5}>
                <Button variant="contained"
                    onClick={() => dispatch(CreateAuction({ ...auctionData.data }))}
                    sx={{
                        padding: '10px 60px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}>{
                        status == 'LOADING' ? <CircularProgress /> : 'SUBMIT'
                    }</Button>
            </Stack>
        </Container>

    )
}

export default StepEight