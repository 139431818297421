import React, { useEffect, useState } from 'react'
import { Box, Stack, Button, Typography, Container, Grid, MenuItem, FormControl, Checkbox, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import InputComp from '../../components/InputComp';
import { title } from '../../Constants';
import ErrorMessageComp from '../../components/ErrorMessageComp';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/bootstrap.css";
import LoginFormBg from '../../assets/img/login_form_bg.png'
import { useDispatch, useSelector } from 'react-redux';
import { CheckUserName, resetStateCheckUserName } from '../../Redux/Slices/CheckUserNameSlice';
import { toast } from 'react-toastify';



const SignUp3 = () => {

    const navigate = useNavigate()
    const role = localStorage.getItem('role')
    const [values, setValues] = useState(JSON.parse(localStorage.getItem('signUp3')))
    const [isValidating, setIsValidating] = useState(false)
    const email = localStorage.getItem('userEmail')
    const signUp3EditData = JSON.parse(localStorage.getItem('signUp3Edit'))
    const [phone, setPhone] = useState(values?.auth_phone_code || '44');
    const editData = JSON.parse(localStorage.getItem('editData'))
    let editDefaultValuesForCompany, editDefaultValuesForFunder;
    const signUp3NewEditData = JSON.parse(localStorage.getItem('signUp3NewEdit'))
    const [editNewData, setEditNewData] = useState(signUp3NewEditData)
    const [checked, setChecked] = useState(false)
    const { userNamestatus, userNameloading } = useSelector((state) => state.userNameCheck)

    //form submit
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            dispatch(resetStateCheckUserName())
        }
    }, [dispatch])

    const onSubmit = (data) => {
        if (location?.search == '?edit') {
            if (editNewData) {
                localStorage.setItem('signUp3NewEdit', JSON.stringify({ ...editNewData }))
            }
            localStorage.setItem('signUp3Edit', JSON.stringify({ ...data, 'auth_phone_code': phone }))
            navigate('/signUp4?edit')
        } else {
            setIsValidating(true)
            if (role == 2 || role == 'Funder') {
                if (Object.keys(errors).length === 0 && values?.termsandCondition) {
                    dispatch(resetStateCheckUserName)
                    dispatch(CheckUserName(data.username))
                }
            } else {
                if (Object.keys(errors).length === 0) {
                    console.log(data, 'authdata')
                    localStorage.setItem('signUp3', JSON.stringify({ ...data, 'auth_phone_code': phone }))
                    navigate('/signUp4')
                }
            }
        }
    }


    // onChange
    const handleChange = (e) => {
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
    }


    //get edit data value 
    const location = useLocation();
    if (location?.search == '?edit' && !signUp3EditData) {
        editDefaultValuesForCompany = {
            'auth_title': editData?.name?.slice(0, editData?.name?.indexOf(' ')),
            'auth_first_name': editData?.first_name,
            'auth_last_name': editData?.last_name,
            'auth_job_title': editData?.job_title,
            'auth_phone_code': editData?.phone_code,
            'auth_phone': editData?.mobile,
            'auth_email': editData?.email
        }
    }
    if (location?.search == '?edit' && signUp3EditData != null) {
        editDefaultValuesForCompany = signUp3EditData
    }
    if (location?.search == '?edit' && editData.user_type == 'Funder' && !signUp3EditData) {
        editDefaultValuesForFunder = {
            'username': editData?.Funder?.username,
        }
    }
    if (location?.search == '?edit' && editData.user_type == 'Funder' && signUp3EditData) {
        editDefaultValuesForFunder = { ...signUp3EditData }
    }

    //default values of forms
    const defaultValues = (role == 1 || role == 'Company') ? location?.search == '?edit' ? { ...editDefaultValuesForCompany } : { 'auth_title': title[0].value, 'auth_phone_code': phone, 'auth_email': email, ...values } : location?.search == '?edit' ? { ...editDefaultValuesForFunder } : { ...values }
    const { handleSubmit, control, formState: { errors }, getValues } = useForm({
        defaultValues: defaultValues,
    })

    useEffect(() => {
        if (location?.search == '?edit' && !signUp3EditData) {
            setValues(editDefaultValuesForCompany)
            setPhone(editDefaultValuesForCompany?.auth_phone_code)
        }
        if (location?.search == '?edit' && signUp3EditData) {
            setValues(signUp3EditData)
            setPhone(signUp3EditData?.auth_phone_code)
        }
    }, [])

    //back save data on edit
    const backSaveData = () => {
        const data = getValues()
        if (location?.search == '?edit') {
            if (editNewData) {
                localStorage.setItem('signUp3NewEdit', JSON.stringify({ ...editNewData }))
            }
            localStorage.setItem('signUp3Edit', JSON.stringify({ ...data, 'auth_phone_code': phone }))
        }
    }
    useEffect(() => {
        if (role == 2 || role == 'Funder') {
            const data = getValues()
            if (userNamestatus == true && !userNameloading) {
                localStorage.setItem('signUp3', JSON.stringify({ ...data, termsandCondition: values?.termsandCondition }))
                navigate('/signUp4')
            }
            else if (userNamestatus == false && !userNameloading) {
                toast.error('' + "Username is already taken", {
                    style: { fontSize: "15px" }
                });
            }
        }
    }, [userNameloading, userNamestatus])
    console.log(userNamestatus,'USERNAMESTATUS')
    return (
        <div style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover', paddingBottom: '80px' }}>
            <HorizontalLinearAlternativeLabelStepper val={2} data={values} extraFunctionality={() => backSaveData()} />
            {
                (role == 1 || role == 'Company') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                    <Box
                        component="form"
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Typography variant='p' component='p' color='#000' fontWeight={'bold'} textAlign='center' fontSize={'15px'} textTransform='none'>
                            Please enter your personal details
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Controller
                                        name='auth_title'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: 'max-content', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Title *</Typography></label>
                                                <TextField
                                                    id="outlined-select"
                                                    select
                                                    name='auth_title'
                                                    placeholder='Select your city'
                                                    onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'auth_title': e.target.value }) }} value={value || title[0].value}
                                                    onBlur={handleChange}
                                                    defaultValue=""
                                                    sx={{
                                                        mt: 0,
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            width: { lg: 'max-content', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1
                                                        }
                                                    }}
                                                >
                                                    {title.map((option, index) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            <Typography variant='p'>{option.label}</Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        )}
                                    />
                                    <InputComp labelLeftMargin={'5%'} setEditData={setEditNewData} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='95%' required={true} name={'auth_first_name'} placeholder={'First name *'} control={control} fieldType={'text'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', marginLeft: '10px' }}>
                                    {errors.auth_title?.type === 'required' && <ErrorMessageComp message={'Title is required'} />
                                    }
                                    {errors.auth_first_name?.type === 'required' && <ErrorMessageComp message={'First name is required'} />
                                    }
                                    {errors.auth_first_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid first name'} />
                                    }
                                    {errors.auth_first_name?.type === 'maxLength' && <ErrorMessageComp message={'First name can have maximum 15 characters'} />
                                    }
                                    {errors.auth_first_name && <ErrorMessageComp message={errors.auth_first_name.message} />}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={15} fieldType={'text'} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='100%' name={'auth_last_name'} placeholder={'Last name *'} control={control} required={true} />
                                {errors.auth_last_name?.type === 'required' && <ErrorMessageComp message={'Last name is required'} />
                                }
                                {errors.auth_last_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid last name'} />
                                }
                                {errors.auth_last_name?.type === 'maxLength' && <ErrorMessageComp message={'Last name can have maximum 15 characters'} />
                                }
                                {errors.auth_last_name && <ErrorMessageComp message={errors.auth_last_name.message} />}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditNewData} editData={editNewData} required={true} onChanges={handleChange} width='100%' name={'auth_job_title'} placeholder={'Job title / Capacity *'} control={control} fieldType={'Job title / Capacity *'} />
                                {errors.auth_job_title?.type === 'required' && <ErrorMessageComp message={'Job title is required'} />
                                }
                                {errors.auth_job_title && <ErrorMessageComp message={errors.auth_job_title.message} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {/* <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Code</Typography></label> */}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <div style={{ width: '35%' }}>
                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code *</Typography></label>
                                        <PhoneInput
                                            country={"eg"}
                                            enableSearch={true}
                                            value={phone}
                                            disabled={location?.search == '?edit'}
                                            inputProps={{ readOnly: true }}
                                            onChange={(e) => { setValues({ ...values, 'auth_phone_code': e }); setPhone(e); setEditNewData({ ...editNewData, 'auth_phone_code': e }) }}
                                        />
                                    </div>
                                    <InputComp labelLeftMargin={'5%'} disabled={location?.search == '?edit'} setEditData={setEditNewData} editData={editNewData} pattern={/^\d+$/} onChanges={handleChange} required={true} minLength={8} maxLength={15} width='95%' name={'auth_phone'} placeholder={'Phone number *'} control={control} fieldType={'numeric'} />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', marginLeft: '17%' }}>
                                    {errors.auth_phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                                    }
                                    {errors.auth_phone?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                                    }
                                    {errors.auth_phone?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be of minimum 8 digits'} />
                                    }
                                    {errors.auth_phone?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be of maximum 15 digits'} />
                                    }
                                    {errors.auth_phone?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                                    }
                                    {errors.auth_phone && <ErrorMessageComp message={errors.auth_phone.message} />}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp disabled={true} setEditData={setEditNewData} editData={editNewData} maxLength={40} required={true} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} onChanges={handleChange} width='100%' name={'auth_email'} placeholder={'Email address *'} control={control} fieldType={'email'} />
                                {
                                    errors?.auth_email?.type == 'pattern' && <ErrorMessageComp message={'Invalid email address'} />
                                }
                                {errors.auth_email?.type === 'required' && <ErrorMessageComp message={'Email is required'} />
                                }
                                {errors.auth_email?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
                                }
                                {errors.auth_email && <ErrorMessageComp message={errors.auth_email.message} />}

                            </Grid>
                        </Grid>
                        <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                            <Button variant="contained" sx={{
                                padding: '10px 60px',
                                backgroundColor: '#B92C82',
                                textTransform: 'Uppercase'
                            }}
                                onClick={handleSubmit(onSubmit)}>
                                <Typography variant='p' color='#fff'>NEXT</Typography>
                            </Button>
                        </Stack>
                    </Box >
                </Container>
            }
            {
                (role == 2 || role == 'Funder') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                    <Box
                        component="form"
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} m={'auto'}>
                                <InputComp disabled={location?.search == '?edit'} onChanges={handleChange} required={true} width='100%' name={'username'} placeholder={'User name *'} control={control} fieldType={'email'} />
                                {
                                    location?.search != '?edit' && <Typography variant='overline' textTransform={'none'} color='#6C6C6D' sx={{ float: "right" }} mt={2}>Admin will review and approve</Typography>
                                }
                                {errors.username?.type === 'required' && <ErrorMessageComp message={'User Name is required'} />
                                }
                                {errors.username && <ErrorMessageComp message={errors.username.message} />}
                            </Grid>
                        </Grid>
                        <Grid container mt={2}>
                            {location?.search != '?edit' && <Grid item xs={12} sm={8} md={8} lg={8} xl={8} m={'auto'}>
                                <Checkbox checked={values?.termsandCondition} size='small' name='termsandCondition' required={true} onChange={() => { setValues({ ...values, 'termsandCondition': !checked }); setChecked(!checked) }} /> <Typography variant='overline' textTransform={'none'} color='#6C6C6D'>I confirm I have read and accept the <Link target='_blank' color="#3A0CA3 !important" to="/sophisticatedinvestor" style={{ textDecoration: 'underline' }}>Sophisticated Investor / HNWI Declaration</Link> *</Typography>
                            </Grid>}
                            {isValidating && !values?.termsandCondition && <ErrorMessageComp textAlign={'center'} width={'100%'} message={"Please acknowledge the Sophisticated Investor / HNWI Declaration"} />
                            }
                        </Grid>
                        <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                            <Button variant="contained" sx={{
                                padding: '10px 60px',
                                backgroundColor: '#B92C82',
                                textTransform: 'Uppercase'
                            }}
                                onClick={handleSubmit(onSubmit)}>
                                {
                                    userNameloading ? <CircularProgress color='white' size={'25px'} /> : 'NEXT'
                                }
                            </Button>
                        </Stack>
                    </Box >
                </Container>
            }
        </div>
    );
}
export default SignUp3