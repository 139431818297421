import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    getCompanyBankDetails: [],
    getCompanyBankDetailsStatus:'',
    getCompanyBankDetailsLoader:false,
    getCompanyBankDetailsError:null
};
export const GetCompanyBankDetails = createAsyncThunk("/GetCompanyBankDetails", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getCompanyBankDetails`,
        method: "get",
    });
    return response;
});
const getCompanyBankDetailsSlice = createSlice({
    name: 'CompanyBankDetails',
    initialState,
    reducers: {
        resetStateCompanyBankDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCompanyBankDetails.pending, (state) => {
                state.getCompanyBankDetailsStatus = 'LOADING'
                state.getCompanyBankDetailsLoader = true
                state.getCompanyBankDetailsError = null
            })
            .addCase(GetCompanyBankDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.getCompanyBankDetailsStatus = "SUCCESS"
                    state.getCompanyBankDetails = action?.payload?.data
                }
                else {
                    state.getCompanyBankDetailsStatus = "ERROR"
                    state.getCompanyBankDetailsError = [action?.payload?.errors?.[0]]
                }
                state.getCompanyBankDetailsLoader = false
            })
            .addCase(GetCompanyBankDetails.rejected, (state, action) => {
                state.getCompanyBankDetailsStatus = 'ERROR'
                state.getCompanyBankDetailsLoader = false
            })
    }
})

export const { resetState } = getCompanyBankDetailsSlice.actions;
export default getCompanyBankDetailsSlice.reducer;