import React, { useEffect, useState } from 'react'
import InputComp from '../../../components/InputComp'
import { Autocomplete, Box, Button, Container, FormControl, Grid, Stack, TextField, Typography, } from '@mui/material'
import ErrorMessageComp from '../../../components/ErrorMessageComp'
import { useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../Redux/Slices/StateSlice'
import { Country } from '../../../Redux/Slices/CountrySlice'
import { City } from '../../../Redux/Slices/CitySlice'
import SelectInputComp from '../../../components/SelectInputComp'
import { CompanyType } from '../../../Redux/Slices/CompanyTypeSlice'
import { CompanyCategory } from '../../../Redux/Slices/CompanyCategorySlice'
import HorizontalLinearAlternativeLabelStepper from '../../SignUp/Stepper'
import PhoneInput from 'react-phone-input-2'
import Loader from '../../../components/Loader'

function StepOneCreateTradingPartner({ setStepNo, editValue }) {
    const location = useLocation()
    const dispatch = useDispatch()
    const stepOneData = localStorage.getItem('createTradingPartnerStepOne')
    const [values, setValues] = useState(JSON.parse(localStorage.getItem('createTradingPartnerStepOne')) || { ...editValue, state: editValue?.state_id, city: editValue?.city_id })
    const [phone, setPhone] = useState(values?.phone_code || 44)
    const { country } = useSelector((state) => state.country)
    const { city } = useSelector((state) => state.city)
    const { state } = useSelector((state) => state.state)
    const { companyType } = useSelector((state) => state.companyType)
    const { companyCategory } = useSelector((state) => state.companyCategory)
    const step2EditData = JSON.parse(localStorage.getItem('createTradingPartnerStepTwoEdit'))
    const [editData, setEditData] = useState(JSON.parse(localStorage.getItem('createTradingPartnerStepOneEdit')))
    const [error, setError] = useState(null)
    const { handleSubmit, control, formState: { errors }, getValues } = useForm({
        defaultValues: location?.pathname == '/editTradingPartner' ? {
            company_name: editValue?.name, 'bussiness_type': editValue?.company_type_id, 'bussiness_category': editValue?.company_category_id, 'mobile': editValue?.phone_number, ...values, 'website': values?.website?.slice(8, values?.website?.length)
        } : {
            ...values,
            country: 230,
            phone_code: 44,
            'website': values?.website?.slice(8, editValue?.website?.length) || null
        }
    })
    const [formValues, setFormValues] = useState(getValues())
    //onChange
    const handleChange = (e, val) => {
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
    }
    const handleCountryChange = (e, val, name, idName) => {
        values != 'null' ?
            setValues({ ...values, [name]: val?.id, [idName]: val?.id }) : setValues({ [name]: val?.id, [idName]: val?.id })
        location?.pathname == '/editTradingPartner' && setEditData({ ...editData, [name]: val?.id })
    }
    const onSubmit = (data) => {
        console.log(data, 'DATAAAAAAAAA')
        location?.pathname == '/createTradingPartner' ?
            localStorage.setItem("createTradingPartnerStepOne", JSON.stringify({ ...values, ...data, 'website': 'https://' + data?.website })) : localStorage.setItem("createTradingPartnerStepOneEdit", editData?.website ? JSON.stringify({ ...editData, 'website': 'https://' + editData?.website }) : JSON.stringify({ ...editData })); localStorage.setItem("createTradingPartnerStepOne", JSON.stringify({ ...values, ...data, 'website': 'https://' + data?.website, 'phone_code': phone, 'state': values?.state, 'state_id': values?.state, 'city': values?.city, 'city_id': values?.city }))
        setStepNo(2)
    }
    //get country value
    useEffect(() => {
        dispatch(Country())
        dispatch(CompanyType())
        dispatch(CompanyCategory())
    }, [])

    //get state value
    useEffect(() => {
        if (location?.pathname == '/createTradingPartner' && formValues?.country) {
            dispatch(State(formValues?.country))
        }
    }, [formValues?.country])

    //get city value
    useEffect(() => {
        if (location?.pathname == '/createTradingPartner' && values?.state) {
            dispatch(City(values?.state))
        }
    }, [values?.state])
    //get state value for edit
    useEffect(() => {
        if (location?.pathname == '/editTradingPartner' && formValues?.country_id) {
            dispatch(State(formValues?.country_id))
        }
    }, [formValues?.country_id])

    //get city value for edit
    useEffect(() => {
        if (location?.pathname == '/editTradingPartner' && values?.state) {
            dispatch(City(values?.state_id))
        }
    }, [values?.state_id])
    console.log(values, city?.find((city) => city?.id === values?.city), state?.find((state) => state?.id === values?.state), city, 'VALUES')
    return (
        (location?.pathname == '/createTradingPartner' || state?.find((state) => state?.id === values?.state)) ?
            <>
                <HorizontalLinearAlternativeLabelStepper showWarningMessage={Object.keys({ ...editData, ...step2EditData }).length > 0 ? true : false} val={1} data={values} stepNo={1} setStepNo={setStepNo} id={editValue?.id} />
                <Container maxWidth={'md'} sx={{ mb: '40px' }}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp disabled={location?.pathname == '/editTradingPartner'} setEditData={setEditData} editData={editData} maxLength={40} onChanges={handleChange} width='100%' name={'company_name'} required={true} placeholder={'Company name *'} control={control} fieldType={'company_name'} />
                                {errors.company_name?.type === 'required' && <ErrorMessageComp message={'Company name is required'} />
                                }
                                {errors.company_name?.type === 'maxLength' && <ErrorMessageComp message={'Company name can have maximum 40 characters'} />
                                }
                                {errors.company_name && <ErrorMessageComp message={errors.company_name.message} />}

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditData} editData={editData} disabled={location?.pathname == '/editTradingPartner'} pattern={/^[a-zA-Z0-9]*$/} onChanges={handleChange} width='100%' name={'registration_number'} placeholder={'Company registration number'} control={control} fieldType={'registration_number'} />
                                {errors.registration_number?.type === 'pattern' && <ErrorMessageComp message={'Registration number can only have alphabets and numeric value'} />
                                }
                                {errors.registration_number && <ErrorMessageComp message={errors.registration_number.message} />}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
                                <SelectInputComp setEditData={setEditData} editData={editData} placeholderLabel={'Company type'} width='100%' name={'bussiness_type'} required={true} placeholder={'Payment mode'} control={control} fieldType={'company_name'} options={companyType} />
                                {errors.bussiness_type?.type === 'required' && <ErrorMessageComp message={'Company type is required'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
                                <SelectInputComp setEditData={setEditData} editData={editData} placeholderLabel={'Business type'} width='100%' name={'bussiness_category'} required={true} placeholder={'Payment mode'} control={control} fieldType={'company_name'} options={companyCategory} />
                                {errors.bussiness_category?.type === 'required' && <ErrorMessageComp message={'Company category is required'} />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditData} editData={editData} maxLength={200} required={true} onChanges={handleChange} width='100%' name={'address_1'} placeholder={'Building no. / name *'} control={control} fieldType={'address_1'} />
                                {errors.address_1?.type === 'required' && <ErrorMessageComp message={'Building name is required'} />
                                }
                                {errors.address_1?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                }
                                {errors.address_1 && <ErrorMessageComp message={errors.address_1.message} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditData} editData={editData} maxLength={200} onChanges={handleChange} width='100%' name={'address_2'} placeholder={'Street *'} required={true} control={control} fieldType={'address_2'} />
                                {errors.address_2?.type === 'required' && <ErrorMessageComp message={'Street is required'} />
                                }
                                {errors.address_2?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                }
                                {errors.address_2 && <ErrorMessageComp message={errors.address_2.message} />}

                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditData} editData={editData} maxLength={200} onChanges={handleChange} width='100%' name={'address_3'} placeholder={'Town'} control={control} fieldType={'address_3'} />
                                {errors.address_3?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                }
                                {errors.address_3 && <ErrorMessageComp message={errors.address_3.message} />}

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {
                                    country && <Controller
                                        name='country'
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select Country *</Typography></label>
                                                <Autocomplete
                                                    name='country'
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={country}
                                                    onBlur={onChange}
                                                    getOptionLabel={(country) => country?.name}
                                                    disabled
                                                    defaultValue={{ id: 230, name: "United Kingdom" }}
                                                    value={country?.find((country) => country?.id === values?.country)}
                                                    sx={{
                                                        mt: 0,
                                                        fontSize: '15px',
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1,
                                                            fontSize: '15px'
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='country' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {state && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='state'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select County/State *</Typography></label>
                                            <Autocomplete
                                                name='state'
                                                disablePortal
                                                id="combo-box-demo"
                                                options={state}
                                                onChange={(e, val) => handleCountryChange(e, val, 'state', 'state_id')}
                                                onBlur={onChange}
                                                getOptionLabel={(state) => state?.name}
                                                value={state?.find((state) => state?.id == values?.state)|| null}
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                                renderInput={(params) => <TextField name='state' style={{ fontSize: '14px' }} {...params}
                                                />}
                                            />
                                        </FormControl>
                                    )}
                                />
                                {errors.state?.type === 'required' && <ErrorMessageComp message={'County is required'} />
                                }
                            </Grid>
                            }

                            {city && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Controller
                                    name='city'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select city</Typography></label>
                                            <Autocomplete
                                                name='city'
                                                disablePortal
                                                id="combo-box-demo"
                                                options={city}
                                                onChange={(e, val) => handleCountryChange(e, val, 'city', 'city_id')}
                                                onBlur={onChange}
                                                getOptionLabel={(city) => city?.name}
                                                value={city?.find((city) => city?.id === values?.city) || null}
                                                sx={{
                                                    mt: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                        background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                        border: 'none',
                                                        borderRadius: '7px',
                                                        marginLeft: 'auto',
                                                        width: { lg: '100%', md: '400px', xs: '100%' },
                                                        height: { md: '50px', xs: '50px' },
                                                        boxShadow: '0px 3px 10px #0000000D',
                                                        opacity: 1
                                                    }
                                                }}
                                                renderInput={(params) => <TextField name='city' style={{ fontSize: '14px' }} {...params}
                                                />}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Grid>}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditData} editData={editData} minLength={3} maxLength={10} onChanges={handleChange} width='100%' required={true} name={'postcode'} placeholder={'Postcode *'} control={control} fieldType={'postcode'} pattern={/^[a-zA-Z0-9\s]*$/} />
                                {errors.postcode?.type === 'required' && <ErrorMessageComp message={'Postcode is required'} />
                                }
                                {errors.postcode?.type === 'maxLength' && <ErrorMessageComp message={'Postcode can have max 10 numbers'} />
                                }
                                {errors.postcode?.type === 'minLength' && <ErrorMessageComp message={'Postcode can have minimum 3 numbers'} />
                                }
                                {errors.postcode?.type === 'pattern' && <ErrorMessageComp message={'Postcode can have only alphanumeric characters'} />
                                }
                                {errors.postcode && <ErrorMessageComp message={errors.postcode.message} />}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                    <div style={{ width: '35%' }}>
                                        <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code *</Typography></label>
                                        <PhoneInput
                                            country={"eg"}
                                            enableSearch={true}
                                            inputProps={{ readOnly: true }}
                                            value={JSON.stringify(phone)}
                                            disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'}
                                            onChange={(e) => { setValues({ ...values, 'phone_code': e }); setPhone(e); setEditData({ ...editData, 'phone_code': e }) }}
                                        />
                                    </div>
                                    <InputComp labelLeftMargin={'5%'} setEditData={setEditData} editData={editData} fieldType={'numeric'} pattern={/^\d+$/} onChanges={handleChange} width='95%' minLength={8} maxLength={15} required={true} name={'mobile'} placeholder={'Phone number *'} control={control} />
                                </div>
                                <div style={{ display: "flex", flexDirection: 'column', marginLeft: "17%" }}>
                                    {errors.phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                                    }
                                    {errors.mobile?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                                    }
                                    {errors.mobile?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                                    }
                                    {errors.mobile?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 15 digits'} />
                                    }
                                    {errors.mobile?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                                    }
                                    {errors.mobile && <ErrorMessageComp message={errors.mobile.message} />}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputComp setEditData={setEditData} editData={editData} pattern={/^([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}$/} startText={'https://'} onChanges={handleChange} width='100%' required={true} name={'website'} placeholder={'Website *'} control={control} fieldType={'website'} />
                                {errors.website?.type === 'required' && <ErrorMessageComp message={'Website address is required'} />
                                }
                                {errors.website?.type === 'pattern' && <ErrorMessageComp message={'Website address is invalid.(If you have added https:// again you have to remove it)'} />
                                }
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={2}>
                        <Grid mt={4} className='registration-datepicker' item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disabled={location?.search == '?edit'}
                                    className='custom-datepicker black-datepicker'
                                    format='DD/MM/YYYY'
                                    value={selectedDate}
                                    maxDate={dayjs()}
                                    onError={(newError) => setError(newError)}
                                    onChange={(val) => { setSelectedDate(val?.$d) }}
                                    renderInput={(params) => <TextField sx={{ ":-ms-input-placeholder": '#fff' }} {...params} variant="outlined" onChange={(newValue) => console.log(newValue)} />} // Use TextField for input
                                />
                            </LocalizationProvider>
                            {error && <ErrorMessageComp message={'Please select a valid date'} />}
                        </Grid>
                    </Grid> */}
                        <Stack justifyContent="center" direction="row" margin={10}>
                            <Button variant="contained" sx={{
                                padding: '10px 60px',
                                backgroundColor: '#B92C82',
                                textTransform: 'Uppercase'
                            }}
                                onClick={handleSubmit(onSubmit)}>NEXT</Button>
                        </Stack>
                    </Box >
                </Container>
            </> : <Loader />
    )
}

export default StepOneCreateTradingPartner