import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    status: false,
    data: null,
    error: false,
    loading: false,
    message: ''
}

export const GetBid = createAsyncThunk("/GetBid", async (id) => {
    const response = await ApihelperFunction({
        urlPath: `api/funder/getBidding?id=${id}`,
        method: "get",
        typecontent: true
    });
    return response;
});

const GetBidSlice = createSlice({
    name: "GetBid",
    initialState,
    reducers: {
        resetStateGetBid: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetBid.pending, (state) => {
                state.loading = true
            })
            .addCase(GetBid.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.loading = false
                    state.error = false
                    state.status = action?.payload?.status
                    state.message = action?.payload?.message
                }
                else {
                    state.loading = false
                    state.status = action?.payload?.status
                    state.error = [action?.payload?.errors?.[0]]
                }
                state.data = action?.payload?.data;
            })
            .addCase(GetBid.rejected, (state, action) => {
                state.loading = false
                state.status = 'ERROR'
            })
    }
})

export const { resetStateGetBid } = GetBidSlice.actions;
export default GetBidSlice.reducer