import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    editCompamy: null,
    editCompamyStatus: '',
    editCompamyLoader: false,
    editCompamyError: null,
    editCompamyMessage: '',
};
export const EditCompamy = createAsyncThunk("/EditCompamy", async (editCompanyData) => {
    let data = new FormData();
    for (const key in editCompanyData) {
        if (editCompanyData.hasOwnProperty(key)) {
            if (key != 'website' && key != 'postcode') {
                data.append(key, editCompanyData[key])
            }
        }
    }
    if (editCompanyData?.website) {
        data.append('website', 'https://' + editCompanyData.website);
    }
    if (editCompanyData?.postcode) {
        data.append('postcode', editCompanyData.postcode.toUpperCase());
    }
    console.log(data, editCompanyData, 'DATA')
    const response = await ApihelperFunction({
        urlPath: `api/updateCompanyDetailRequest`,
        method: "post",
        formData: data,
        typecontent: true
    });
    return response;
});
const editCompamySlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateUpdateCompany: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(EditCompamy.pending, (state) => {
                state.editCompamyStatus = 'LOADING'
                state.editCompamyLoader = true
                state.editCompamyError = null
            })
            .addCase(EditCompamy.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.editCompamyStatus = "SUCCESS"
                    state.editCompamyMessage = action?.payload?.message
                    state.editCompamy = action?.payload?.data
                }
                else {
                    state.editCompamyStatus = "ERROR"
                    state.editCompamyError = [action?.payload?.errors?.[0]]
                }
                state.editCompamyLoader = false
            })
            .addCase(EditCompamy.rejected, (state, action) => {
                state.editCompamyStatus = 'ERROR'
                state.editCompamyLoader = false
            })
    }
})

export const { resetStateUpdateCompany } = editCompamySlice.actions;
export default editCompamySlice.reducer;