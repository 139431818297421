import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    turnover: null,
    turnoverStatus:'',
    turnoverLoader:false,
    turnoverError:null
};
export const Turnover = createAsyncThunk("/Turnover", async () => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: "api/getTurnoverAndTradingperiod",
        method: "get",
    });
    return response;
});
const turnoverSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(Turnover.pending, (state) => {
                state.turnoverStatus = 'LOADING'
                state.turnoverLoader = true
                state.turnoverError = null
            })
            .addCase(Turnover.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.turnoverStatus = "SUCCESS"
                    state.turnover = action?.payload?.data
                }
                else {
                    state.turnoverStatus = "ERROR"
                    state.turnoverError = [action?.payload?.errors?.[0]]
                }
                state.turnoverLoader = false
            })
            .addCase(Turnover.rejected, (state, action) => {
                state.turnoverStatus = 'ERROR'
                state.turnoverLoader = false
            })
    }
})

export const { resetState } = turnoverSlice.actions;
export default turnoverSlice.reducer;