import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    getNotificationDetails: null,
    getNotificationDetailsStatus: '',
    getNotificationDetailsLoader: false,
    getNotificationDetailsError: null,
    totalRecord:null,
    totalRead:null
};
export const GetNotificationDetails = createAsyncThunk("/GetNotificationDetails", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/notification?offset=0&limit=20`,
        method: "get",
    });
    return response;
});
const getNotificationDetailsSlice = createSlice({
    name: 'CompanyBankDetails',
    initialState,
    reducers: {
        resetStateLeaderBoardDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetNotificationDetails.pending, (state) => {
                state.getNotificationDetailsStatus = 'LOADING'
                state.getNotificationDetailsLoader = true
                state.getNotificationDetailsError = null
            })
            .addCase(GetNotificationDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.getNotificationDetailsStatus = "SUCCESS"
                    state.getNotificationDetails = action?.payload?.data?.notifications
                    state.totalRecord=action?.payload?.data?.links?.total_unread
                    state.totalRead=action?.payload?.data?.links?.total_read
                }
                else {
                    state.getNotificationDetails = []
                    state.getNotificationDetailsStatus = "ERROR"
                    state.getNotificationDetailsError = [action?.payload?.errors?.[0]]
                }
                state.getNotificationDetailsLoader = false
            })
            .addCase(GetNotificationDetails.rejected, (state, action) => {
                state.getNotificationDetailsStatus = 'ERROR'
                state.getNotificationDetailsLoader = false
                state.getNotificationDetails = []
            })
    }
})

export const { resetStateLeaderBoardDetails } = getNotificationDetailsSlice.actions;
export default getNotificationDetailsSlice.reducer;