import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    fundingDetails: null,
    fundingDetailsStatus: '',
    fundingDetailsLoader: false,
    fundingDetailsError: null
};
export const GetFundingDetails = createAsyncThunk("/GetFundingDetails", async (data) => {
    const response = await ApihelperFunction({
        urlPath: data?.auctionType == 'open' ? `api/company/getOpenFundingDetail?auction_id=${data?.auctionID}` : `api/company/getCloseFundingDetail?auction_id=${data?.auctionID}`,
        method: "get",
    });
    return response;
});
const fundingDetailsSlice = createSlice({
    name: 'CompanyBankDetails',
    initialState,
    reducers: {
        resetStateFundingDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFundingDetails.pending, (state) => {
                state.fundingDetailsStatus = 'LOADING'
                state.fundingDetailsLoader = true
                state.fundingDetailsError = null
            })
            .addCase(GetFundingDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.fundingDetailsStatus = "SUCCESS"
                    state.fundingDetails = action?.payload?.data
                }
                else {
                    state.fundingDetails = []
                    state.fundingDetailsStatus = "ERROR"
                    state.fundingDetailsError = [action?.payload?.errors?.[0]]
                }
                state.fundingDetailsLoader = false
            })
            .addCase(GetFundingDetails.rejected, (state, action) => {
                state.fundingDetailsStatus = 'ERROR'
                state.fundingDetailsLoader = false
                state.fundingDetails = []
            })
    }
})

export const { resetStateFundingDetails } = fundingDetailsSlice.actions;
export default fundingDetailsSlice.reducer;