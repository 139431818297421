import {
    Box, FormControl, Container, Typography, CircularProgress
} from "@mui/material"
import { useForm } from "react-hook-form"
import LoginCustomButton from "../../components/LoginCustomButton";
import FormContainer from "./FormContainer";
import InputComp from "../../components/InputComp";
import ErrorMessageComp from "../../components/ErrorMessageComp";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { SetPassword, resetStateSetPassword } from "../../Redux/Slices/SetPasswordSLice";
import { toast } from "react-toastify";
import { SetPasswordForgotPasswod } from "../../Redux/Slices/ForgotPasswordSlice";


const AddPassword = () => {
    //**********values*************
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const { handleSubmit, control, formState: { errors }, getValues } = useForm()
    const [data, setData] = useState({})
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataFromSliceA = useSelector(state => state.setPassword);
    const dataFromSliceB = useSelector(state => state.forgotPassword);
    const { loading, error, status, message } = location.pathname == '/addPassword' ? dataFromSliceA : dataFromSliceB
    const handleKeyDown = (e) => {
        console.log(e.target.name, e.target.value, 'event')
        if (e.key === 'Enter') {
            const values = getValues()
            if (values?.password === values?.password_confirmation) {
                if (location?.pathname == '/resetPassword') {
                    dispatch(SetPasswordForgotPasswod({ ...values, token: location.state }))
                } else {
                    dispatch(SetPassword(values))
                }
            } else {
                toast.error('' + 'Passwords do not match. Please try again', {
                    style: { fontSize: '15px' },
                });
            }
        }
    };

    //form submit
    const onSubmit = (data) => {
        if (data?.password === data?.password_confirmation) {
            if (location?.pathname == '/resetPassword') {
                dispatch(SetPasswordForgotPasswod({ ...data, token: location.state }))
            } else {
                dispatch(SetPassword(data))
            }
        } else {
            toast.error('' + 'Passwords do not match. Please try again', {
                style: { fontSize: '15px' },
            });
        }
    }


    //firing toast and further navigation
    useEffect(() => {
        if (error) {
            toast.error('' + 'Password does not meet requirements. Please try again', {
                style: { fontSize: "15px" }
            });
        } if (status === "SUCCESSPASSWORD") {
            toast.success('' + message, {
                style: { fontSize: '15px' },
                onOpen: () => {
                    localStorage.removeItem('signUp2');
                    localStorage.removeItem('signUp3');
                    localStorage.removeItem('signUp4');
                    localStorage.removeItem('signUp5');
                    localStorage.removeItem('signUp6')
                    if (location.pathname == '/addPassword') {
                        dispatch(resetStateSetPassword())
                        navigate('/signUp2')

                    } else {
                        dispatch(resetStateSetPassword())
                        navigate('/login')
                    }
                }
            });
        }
    }, [error, status]);

    return <FormContainer>
        <Container
            sx={{
                display: 'flex', flexDirection:
                    'column', alignItems: 'center',
                mt: { md: 0, sm: 0, xs: 5 },
                mb: { md: 10, sm: 0, xs: 5 }
            }}>
            <Box sx={{
                width: { lg: '457px', md: '400px', xs: '290px' },
                color: 'var(--unnamed-color-0b2244)'
            }}>
                <Typography width='85%' ml={'auto'} variant="h2" fontWeight={700} align="center">
                    {location?.pathname === '/addPassword' ? 'Create password' : 'Add new password'}
                </Typography>
                <Typography textTransform={'none'} width='85%' ml={'auto'} mt={'30px'} fontSize={'12px'} component={'p'} variant="p" fontWeight={600} fontFamily={'customFont'} align="center">
                    The minimum password length is 8 characters and must contain atleast 1 lowercase letter,
                    1 capital letter,1 number and 1 special character
                </Typography>
            </Box>
            <FormControl sx={{ width: { lg: '457px', md: '400px', xs: '290px' }, mt: { md: 1, xs: 1 } }}>
                <InputComp name={'password'}
                    placeholder={'New Password *'} control={control}
                    fieldType={'password'} startIcon={true} startImg={'lock.png'} endIcon={true} endImg={'eye.png'} alternativeEndImg={'hide.png'} show={show} setShow={setShow} />
                {errors.password?.type === 'required' && <ErrorMessageComp message={'Password is required'} />}
                <InputComp name={'password_confirmation'}
                    onKeyDown={handleKeyDown}
                    placeholder={'Confirm Password *'} control={control}
                    fieldType={'password'} startIcon={true} startImg={'lock.png'} endIcon={true} endImg={'eye.png'} alternativeEndImg={'hide.png'} show={show2} setShow={setShow2} />
                {errors.password?.type === 'required' && <ErrorMessageComp message={'Confirm password is required'} />}
            </FormControl>
            <FormControl sx={{ mt: 2, width: { lg: '457px', md: '400px', xs: '290px' } }}>
                <LoginCustomButton onClick={handleSubmit(onSubmit)}>
                    {
                        !loading && <Typography variant="h6" component={'h6'} sx={{ color: '#fff' }}>
                            SUBMIT
                        </Typography>
                    }
                    {
                        loading && <CircularProgress color="white" />
                    }
                </LoginCustomButton>
            </FormControl>
        </Container>
    </FormContainer>
}

export default AddPassword