import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    transactionHistory: [],
    transactionHistoryInitial: [],
    transactionHistoryStatus: '',
    transactionHistoryLoader: false,
    transactionHistoryError: null
};
export const TransactionHistory = createAsyncThunk("/TransactionHistory", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getTransactionHistroy`,
        method: "get",
    });
    return response;
});
const transactionHistorySlice = createSlice({
    name: 'TransactionHistory',
    initialState,
    reducers: {
        resetStateTransactionHistory: () => initialState,
        editTransactionHistory: (state, action) => {
            console.log(action.payload, 'PAYLOAD Transaction')
            state.transactionHistory = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(TransactionHistory.pending, (state) => {
                state.transactionHistoryStatus = 'LOADING'
                state.transactionHistoryLoader = true
                state.transactionHistoryError = null
            })
            .addCase(TransactionHistory.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.transactionHistoryStatus = "SUCCESS"
                    let newArray = []
                    for (let i = 0; i < action?.payload?.data?.length; i++) {
                        if (action?.payload?.data?.[i]?.transaction_type?.transaction_type == 'Withdrawal') {
                            newArray.push({ ...action?.payload?.data?.[i], transaction_time: action?.payload?.data?.[i]?.created_at })
                        } else {
                            newArray.push({ ...action?.payload?.data?.[i] })
                        }
                    }
                    const dateString = "25-11-2023";

                    // Split the date string into day, month, and year
                    const [day, month, year] = dateString.split("-");

                    // Creating a Date object with proper parsing
                    const date = new Date(`${year}-${month}-${day}`);
                    newArray.sort((a, b) => {
                        // Extracting day, month, and year from the date strings
                        const getDateParts = dateString => {
                            const [day, month, year] = dateString.split('-').map(Number);
                            return { day, month, year };
                        };

                        // Convert date strings to objects with day, month, and year
                        const dateA = getDateParts(a.transaction_time);
                        const dateB = getDateParts(b.transaction_time);

                        // Create Date objects for comparison (Year, Month - 1, Day)
                        const dateObjA = new Date(dateA.year, dateA.month - 1, dateA.day);
                        const dateObjB = new Date(dateB.year, dateB.month - 1, dateB.day);

                        // Compare the dates
                        return dateObjB - dateObjA; // Descending order, change to dateObjA - dateObjB for ascending
                    });
                    console.log(newArray, 'SORTED ARRAY')
                    state.transactionHistoryInitial = newArray
                    state.transactionHistory = newArray
                }
                else {
                    state.transactionHistoryStatus = "ERROR"
                    state.transactionHistoryError = [action?.payload?.errors?.[0]]
                }
                state.transactionHistoryLoader = false
            })
            .addCase(TransactionHistory.rejected, (state, action) => {
                state.transactionHistoryStatus = 'ERROR'
                state.transactionHistoryLoader = false
            })
    }
})

export const { resetStateTransactionHistory, editTransactionHistory } = transactionHistorySlice.actions;
export default transactionHistorySlice.reducer;