import React, { useEffect, useState } from 'react'
import GlobalTable from '../../components/GlobalTable'
import { Box, Tab, Tabs, Typography, makeStyles } from '@mui/material'
import { useLocation } from 'react-router-dom';
import { dummyDataInvestment } from '../../Constants';
import { useDispatch, useSelector } from 'react-redux';
import { AllClosedAuction } from '../../Redux/Slices/GetAllAuctionClosedSlice';
import { AllLiveAuction } from '../../Redux/Slices/GetAllAuctionLiveSlice';
import Loader from '../../components/Loader';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MarketPlace() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [sortBy, setSortBy] = useState('Newest_to_Oldest')
  const [filterBy, setFilterBy] = useState()
  const filterOptions = [{
    name: 'Buyer Finance',
    value: 'Buyer Finance'
  },
  {
    name: 'Seller Finance',
    value: 'Seller Finance'
  },
  {
    name: 'Clear Filters',
    value: ''
  }]
  const sortOptions = [{
    name: 'Newest to Oldest',
    value: 'Newest_to_Oldest'
  },
  {
    name: 'Oldest to Newest',
    value: 'Oldest_to_Newest'
  },
  {
    name: 'Clear Filters',
    value: ''
  }]
  const { allLiveAuction, allLiveAuctionLoader, totalCountLive } = useSelector((state) => state.marketLiveAuction)
  const { allClosedAuction, allClosedAuctionLoader, totalCountClosed } = useSelector((state) => state.marketClosedAuction)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(AllLiveAuction({ page: page, limit: limit, sortBy: sortBy, filterBy: filterBy }))
    dispatch(AllClosedAuction({ page: page, limit: limit, sortBy: sortBy, filterBy: filterBy }))
  }, [page, limit, filterBy, sortBy])
  return (
    <>
      <Typography variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
        {
          location.pathname == '/marketPlace' ? 'Market Place' : 'My Investment'
        }
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 0 }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
            '& .MuiTabs-indicator': {
              display: 'none', // Remove the indicator from the selected tab
            },
          }}>
            <Tab label={location.pathname == '/marketPlace' ? `Live Auctions (${totalCountLive || 0})` : 'Open Auctions (10)'} {...a11yProps(0)} sx={{
              borderRadius: '8px 8px 0px 0px',
              backgroundColor: '#FBFBFB',
              letterSpacing: 0.8,
              color: '#000',
              fontWeight: '600',
              fontSize: '15px',
              '&.Mui-selected': {
                backgroundColor: '#B92C82', // Remove the underline of the selected tab
                color: '#fff', // Customize the text color of the selected tab
                fontWeight: '500',
                letterSpacing: 0.4
              }
            }} />
            <Tab label={location.pathname == '/marketPlace' ? `Closed Auctions (${totalCountClosed || 0})` : 'Closed Auctions (4)'} {...a11yProps(1)} sx={{
              borderRadius: '8px 8px 0px 0px',
              backgroundColor: '#FBFBFB',
              letterSpacing: 0.8,
              color: '#000',
              fontWeight: '600',
              fontSize: '15px',
              '&.Mui-selected': {
                backgroundColor: '#B92C82', // Remove the underline of the selected tab
                color: '#fff', // Customize the text color of the selected tab
                fontWeight: '500',
                letterSpacing: 0.4
              }
            }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} >
          {
            allLiveAuctionLoader ?
              <Loader /> :
              <GlobalTable
                sortOptions={sortOptions}
                sortBy={sortBy}
                setSortOptions={setSortBy}
                filterOptions={filterOptions}
                filterBy={filterBy}
                setFilterOptions={setFilterBy}
                showPagination='true'
                rowsPerPage={limit}
                setRowsPerPage={setLimit}
                page={page}
                setPage={setPage}
                count={totalCountLive}
                showUpperBox={location.pathname == '/marketPlace' ? true : false}
                showNumberOfLoans={location.pathname == '/marketPlace' ? false : true}
                tableName={location.pathname == '/marketPlace' ? 'Live Auctions' : 'Investments'}
                rows={location?.pathname == '/marketPlace' ? allLiveAuction : dummyDataInvestment}
                headCells={
                  location?.pathname == '/marketPlace' ?
                    [
                      {
                        align: "left",
                        label: "Auction Type",
                        fieldName: "auction_type_name",
                      },
                      {
                        align: "left",
                        label: "Auction Number",
                        type: "auction_number",
                        fieldName: "auction_number",
                      },
                      {
                        align: "left",
                        label: "Company",
                        fieldName: "companies",
                        type: 'company',
                        showValue: (val) => {
                          return val?.name;
                        },
                      },
                      {
                        align: "left",
                        label: "Funding Required",
                        fieldName: "auction_amount",
                        type: 'money',
                        showValue: (val) => {
                          return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                        },
                      },
                      {
                        align: "left",
                        label: "Funding Achieved",
                        fieldName: "fundingAchieved",
                        upperLabel: "auction_amount",
                        bidAmount: 'totalBidAmount',
                        type: 'linearProgress'
                      },
                      {
                        align: "left",
                        label: "Max ROI",
                        type: 'roi',
                        fieldName: "roi_percent",
                        subFieldName: "roi_amount"
                      },
                      {
                        align: "left",
                        label: "Credit Rating",
                        type:'creditRating',
                        fieldName: "companies",
                        showValue: (val) => {
                          return val?.company_credit_rating?.credit_rating;
                        },
                      },
                      {
                        align: "left",
                        label: "Auction Time Left",
                        fieldName: "auction_start_date",
                        subFieldName: "auction_end_date",
                        type: 'roundProgress'
                      },
                      {
                        align: "left",
                        label: "No. Of BIDS",
                        fieldName: "totalBidCount",
                      }
                    ] :
                    [
                      {
                        align: "left",
                        label: "AUCTION No.",
                        fieldName: "auctionType",
                      },
                      {
                        align: "left",
                        label: "LOAN STATUS",
                        fieldName: "auctionNumber",
                        color: 'success'
                      },
                      {
                        align: "left",
                        label: "Company",
                        fieldName: "company",
                        type: 'company'
                      },
                      {
                        align: "left",
                        label: "FUNDING AMOUNT",
                        fieldName: "fundingRequired",
                        type: 'money'
                      },
                      {
                        align: "left",
                        label: "BID AMOUNT",
                        fieldName: "fundingAchieved",
                        type: 'money'
                      },
                      {
                        align: "left",
                        label: "FUNDING SHARE",
                        fieldName: "maxRoi",
                      },
                      {
                        align: "left",
                        label: "ROI",
                        fieldName: "creditRating",
                      },
                      {
                        align: "left",
                        label: "ROI",
                        fieldName: "auctionTimeLeft",
                      }
                    ]
                }
              />
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} >
          {
            allClosedAuctionLoader ?
              <Loader /> :
              <GlobalTable
                sortOptions={sortOptions}
                sortBy={sortBy}
                setSortOptions={setSortBy}
                filterOptions={filterOptions}
                filterBy={filterBy}
                setFilterOptions={setFilterBy}
                showPagination='true'
                rowsPerPage={limit}
                setRowsPerPage={setLimit}
                page={page}
                setPage={setPage}
                count={totalCountClosed}
                showUpperBox={location.pathname == '/marketPlace' ? true : false}
                showNumberOfLoans={location.pathname == '/marketPlace' ? false : true}
                tableName={location.pathname == '/marketPlace' ? 'Closed Auctions' : 'Investments'}
                rows={location?.pathname == '/marketPlace' ? allClosedAuction : dummyDataInvestment}
                headCells={
                  location?.pathname == '/marketPlace' ?
                    [
                      {
                        align: "left",
                        label: "Auction Type",
                        fieldName: "auction_type_name",
                      },
                      {
                        align: "left",
                        label: "Auction Number",
                        fieldName: "auction_number",
                        type: "auction_number",
                      },
                      {
                        align: "left",
                        label: "Company",
                        fieldName: "companies",
                        type: 'company',
                        showValue: (val) => {
                          return val?.name;
                        },
                      },
                      {
                        align: "left",
                        label: "Funding Required",
                        fieldName: "auction_amount",
                        type: 'money',
                        showValue: (val) => {
                          return `£ ${Math?.round(val).toLocaleString('en-us')}`;
                        },
                      },
                      {
                        align: "left",
                        label: "Funding Achieved",
                        fieldName: "fundingAchieved",
                        upperLabel: "auction_amount",
                        bidAmount: 'totalBidAmount',
                        type: 'linearProgress'
                      },
                      {
                        align: "left",
                        label: "Max ROI",
                        type: 'roi',
                        fieldName: "roi_percent",
                        subFieldName: "roi_amount"
                      },
                      {
                        align: "left",
                        type:'creditRating',
                        label: "Credit Rating",
                        fieldName: "companies",
                        showValue: (val) => {
                          return val?.company_credit_rating?.credit_rating;
                        },
                      },
                      {
                        align: "left",
                        label: "Auction Time Left",
                        fieldName: "auction_start_date",
                        subFieldName: "auction_end_date",
                        type: 'roundProgress'
                      },
                      {
                        align: "left",
                        label: "No. Of BIDS",
                        fieldName: "totalBidCount",
                      }
                    ] :
                    [
                      {
                        align: "left",
                        label: "AUCTION NUMBER",
                        fieldName: "auctionType",
                      },
                      {
                        align: "left",
                        label: "LOAN STATUS",
                        fieldName: "auctionNumber",
                        color: 'success'
                      },
                      {
                        align: "left",
                        label: "Company",
                        fieldName: "company",
                        type: 'company'
                      },
                      {
                        align: "left",
                        label: "FUNDING AMOUNT",
                        fieldName: "fundingRequired",
                        type: 'money'
                      },
                      {
                        align: "left",
                        label: "BID AMOUNT",
                        fieldName: "fundingAchieved",
                        type: 'money'
                      },
                      {
                        align: "left",
                        label: "FUNDING SHARE",
                        fieldName: "maxRoi",
                      },
                      {
                        align: "left",
                        label: "ROI",
                        fieldName: "creditRating",
                      },
                      {
                        align: "left",
                        label: "ROI",
                        fieldName: "auctionTimeLeft",
                      }
                    ]
                }
              />
          }
        </CustomTabPanel>
      </Box>
    </>
  )
}

export default MarketPlace