import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    tradingPartnerDetails: null,
    tradingPartnerDetailsStatus: '',
    tradingPartnerDetailsLoader: false,
    tradingPartnerDetailsError: null
};
export const TradingPartnerDetails = createAsyncThunk("/TradingPartnerDetails", async (id) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getTradingPartnerDetail?id=${id}`,
        method: "get",
    });
    return response;
});
const tradingPartnerDetailsSlice = createSlice({
    name: 'TradingPartnerDetails',
    initialState,
    reducers: {
        resetStateTradingPartnerDetails: () => initialState,
        editTradingPartnerDetails: (state, action) => {
            state.tradingPartnerDetails = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(TradingPartnerDetails.pending, (state) => {
                state.tradingPartnerDetailsStatus = 'LOADING'
                state.tradingPartnerDetailsLoader = true
                state.tradingPartnerDetailsError = null
            })
            .addCase(TradingPartnerDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.tradingPartnerDetailsStatus = "SUCCESS"
                    state.tradingPartnerDetails = action?.payload?.data
                }
                else {
                    state.tradingPartnerDetailsStatus = "ERROR"
                    state.tradingPartnerDetailsError = [action?.payload?.errors?.[0]]
                }
                state.tradingPartnerDetailsLoader = false
            })
            .addCase(TradingPartnerDetails.rejected, (state, action) => {
                state.tradingPartnerDetailsStatus = 'ERROR'
                state.tradingPartnerDetailsLoader = false
            })
    }
})

export const { resetStateTradingPartnerDetails, editTradingPartnerDetails } = tradingPartnerDetailsSlice.actions;
export default tradingPartnerDetailsSlice.reducer;