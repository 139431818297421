import {
    Box,
    Typography, List, Stack, Container,
} from '@mui/material';

import Company from '../../assets/img/cv@2x.png'
import Bank from '../../assets/img/bank@2x.png'
import Fund from '../../assets/img/funds@2x.png'
import PaperClip from '../../assets/img/paper-clip@2x.png'
import CreditCard from '../../assets/img/credit-card@2x.png'
import FundJoint from '../../assets/img/Funding-Joint-7@2x.png'
import ListItemWithIconAndText from '../../components/ListItemWithIconAndText';

const HowToWorkSection = () => {
    const fontSize = { md: '25px', xs: '15px' }
    const marginTop = { xs: 1, md: 2 }
    const iconSize = { xl: '43px', md: '33px', xs: '22px' }

    return (
        <Box sx={{ backgroundColor: 'var(--unnamed-color-f9fafc)' }}>
            <Container maxWidth={'xl'} sx={{ mb: 0 }} style={{paddingRight:'0px'}}>
            <Stack direction={{ xs: 'column', sm: 'row' }} ml={{ xl: 13, md: 10, xs: 1 }}>
                <Stack py={{ xl: 22, lg: 15, md: 10, sm: 7, xs: 5 }} width={{sm:'60%',xs:'100%'}}>
                <Typography variant='h2' component={'h2'} fontSize={'50px'} fontWeight={'800'} align='left' sx={{ marginBottom: { lg: 1, md: 1, xs: 1 } }}>How it Works</Typography>
                    <List sx={{ mt: { xs: 1, md: 1.5 } }}>
                        <ListItemWithIconAndText textSize={'23px'} image={Company} iconSize={iconSize} imagealt={'company'} fontSize={fontSize} mt={marginTop}>
                            Companies create their business profile. Funders register to invest.
                        </ListItemWithIconAndText>
                        <ListItemWithIconAndText textSize={'23px'} image={Bank} iconSize={iconSize} imagealt={'Bank'} fontSize={fontSize} mt={marginTop}>
                            Companies create an auction and set their finance terms. Funds raised
                            against either invoices issued or invoices received.
                        </ListItemWithIconAndText>
                        <ListItemWithIconAndText textSize={'23px'} image={Fund} iconSize={iconSize} imagealt={'Fund'} fontSize={fontSize} mt={marginTop}>
                            Funders browse the marketplace and bid to invest in credit-worthy opportunities.
                        </ListItemWithIconAndText>
                        <ListItemWithIconAndText textSize={'23px'} image={PaperClip} iconSize={iconSize} imagealt={'PaperClip'} fontSize={fontSize} mt={marginTop}>
                            Leader board displays auction dynamics. Most competitive bids fund the opportunity.
                        </ListItemWithIconAndText>
                        <ListItemWithIconAndText textSize={'23px'} image={CreditCard} iconSize={iconSize} imagealt={'CreditCard'} fontSize={fontSize} mt={marginTop}>
                            Funds are released to the company. Repayments and returns are collected on behalf of funders.
                        </ListItemWithIconAndText>
                    </List>
                </Stack>
                <Stack mt={{ lg: 15, md: 22, sm: 1 }}
                    justifyContent={'center'}
                    alignItems={'flex-end'}
                    width={{md:'35%',sm:'40%',xs:'100%'}}
                    ml={'auto'}
                    pb={{sm:0,xs:6}}>
                        <img src={FundJoint} alt='FundJoint' height={'auto'} width={'100%'} style={{objectFit:'cover'}}/>
                </Stack>
            </Stack>
            </Container>
        </Box>
    )
}

export default HowToWorkSection
