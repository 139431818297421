import { Box, Button, CircularProgress, Container, FormControl, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import InputComp from '../../../components/InputComp'
import { Controller, useForm } from 'react-hook-form'
import ErrorMessageComp from '../../../components/ErrorMessageComp'
import { title } from '../../../Constants'
import PhoneInput from 'react-phone-input-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AddTradingPartner, resetStateAddTradingPartner } from '../../../Redux/Slices/AddTradingPartnerSlice'
import HorizontalLinearAlternativeLabelStepper from '../../SignUp/Stepper'
import { toast } from 'react-toastify'
import { UpdateTradingPartner, resetStateUpdateTradingPartner } from '../../../Redux/Slices/UpdateTradingPartnerChangeRequestSlice'
import ModalComp from '../../../components/ModalComp'

function StepTwoCreateTradingPartner({ setStepNo, editValue }) {
  const [values, setValues] = useState(JSON.parse(localStorage.getItem('createTradingPartnerStepTwo')) || editValue)
  const [phone, setPhone] = useState(values?.contact_phone_code || '44')
  const [openModal,setOpenModal] = useState(false)
  const id = localStorage.getItem('id')
  const [editData, setEditData] = useState(JSON.parse(localStorage.getItem('createTradingPartnerStepTwoEdit')))
  const { handleSubmit, control, formState: { errors }, getValues } = useForm({
    defaultValues: { 'contact_phone_code': phone, contact_title: 'Mr', ...values }
  })
  const { status, error, loading, message } = useSelector((state) => state.addTradingPartner)
  const { statusEdit, errorEdit, loadingEdit, messageEdit } = useSelector((state) => state.updateTradingPartner)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const previousValue = JSON.parse(localStorage.getItem('createTradingPartnerStepOne'))
  const previousEditValue = JSON.parse(localStorage.getItem('createTradingPartnerStepOneEdit'))
  // onChange
  const handleChange = (e) => {
    values != 'null' ?
      setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
  }
  const onSubmit = (data) => {
    if (location?.pathname == '/createTradingPartner') {
      console.log({...data,...previousValue, 'company_id': id},'TRADING PARTNER')
      dispatch(AddTradingPartner({ ...data, ...previousValue, 'company_id': id }))
    } else {
      if (Object.keys({ ...editData, ...previousEditValue }).length > 0) {
        dispatch(UpdateTradingPartner({ ...editData, ...previousEditValue, 'trading_partner_id': location.search.slice(1, location.search.length), 'company_id': id }))
      } 
      else {
        toast.error('' + 'No changes made to submit', {
          style: { fontSize: "15px" },
          onOpen: () => {
            navigate(`/viewTradingPartner${location.search}`)
            dispatch(resetStateAddTradingPartner())
          }
        })
      }
    }
  }
  //firing toast
  useEffect(() => {
    if (error) {
      toast.error('' + error[0], error[1], error[2], error[3]);
    } if (status === "SUCCESS") {
      toast.success('' + message, {
        style: { fontSize: "15px" },
        onOpen: () => {
          if(localStorage.getItem('lastPageUrl')=='createAuction'){
            setOpenModal(true)
          }else{
            navigate('/tradingPartner');
          }
          dispatch(resetStateAddTradingPartner())
        }
      });
    }
  }, [error, status]);
  //firing toast
  useEffect(() => {
    if (errorEdit) {
      toast.error('' + errorEdit[0], errorEdit[1], errorEdit[2], errorEdit[3]);
    } if (statusEdit === "SUCCESS") {
      toast.success('' + messageEdit, {
        style: { fontSize: "15px" },
        onOpen: () => {
          navigate('/tradingPartner');
          dispatch(resetStateUpdateTradingPartner())
        }
      });
    }
  }, [errorEdit, statusEdit]);
  console.log(editValue, 'Edit value')
  return (
    <>
      <HorizontalLinearAlternativeLabelStepper val={2} data={values} editData={editData} stepNo={2} setStepNo={setStepNo} />
      <Container maxWidth={'md'} sx={{ mb: '40px' }}>
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Controller
                  name='contact_title'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ width: { lg: 'max-content', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                      <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Title *</Typography></label>
                      <TextField
                        id="outlined-select"
                        select
                        name='contact_title'
                        placeholder='Select your city'
                        onBlur={handleChange}
                        disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'}
                        onChange={(e) => { onChange(e); setEditData({ ...editData, 'contact_title': e.target.value }) }} value={value || values?.contact_title || title[0].value}
                        sx={{
                          mt: 0,
                          '& .MuiOutlinedInput-root': {
                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                            border: 'none',
                            borderRadius: '7px',
                            width: { lg: 'max-content', md: '400px', xs: '100%' },
                            height: { md: '50px', xs: '50px' },
                            boxShadow: '0px 3px 10px #0000000D',
                            opacity: 1
                          }
                        }}
                      >
                        {title.map((option, index) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Typography variant='p'>{option.label}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                />
                <InputComp disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'} setEditData={setEditData} editData={editData} labelLeftMargin={'5%'} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='95%' name={'contact_first_name'} placeholder={'First name *'} control={control} fieldType={'text'} required={true} />
              </div>
              <div style={{ display: "flex", flexDirection: 'column', marginLeft: '10px' }}>
                {errors.contact_title?.type === 'required' && <ErrorMessageComp message={'Title is required'} />
                }
                {errors.contact_first_name?.type === 'required' && <ErrorMessageComp message={'First name is required'} />
                }
                {
                  errors.contact_first_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid first name'} />
                }
                {
                  errors.contact_first_name?.type === 'maxLength' && <ErrorMessageComp message={'First name can have maximum 15 characters'} />
                }
                {errors.contact_first_name && <ErrorMessageComp message={errors.contact_first_name.message} />}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputComp disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'} setEditData={setEditData} editData={editData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='100%' required={true} name={'contact_last_name'} placeholder={'Last name *'} control={control} fieldType={'text'} />
              {errors.contact_last_name?.type === 'required' && <ErrorMessageComp message={'Last name is required'} />
              }
              {
                errors.contact_last_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid last name'} />
              }
              {
                errors.contact_last_name?.type === 'maxLength' && <ErrorMessageComp message={'Last name can have maximum 15 characters'} />
              }
              {errors.contact_last_name && <ErrorMessageComp message={errors.contact_last_name.message} />}

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputComp disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'} setEditData={setEditData} editData={editData} required={true} onChanges={handleChange} width='100%' name={'contact_job_title'} placeholder={'Job title / Capacity *'} control={control} fieldType={'Job title / Capacity'} />
              {
                errors.contact_job_title?.type === 'required' && <ErrorMessageComp message={'Job title is required'} />
              }
              {errors.contact_job_title && <ErrorMessageComp message={errors.contact_job_title.message} />}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                <div style={{ width: '35%' }}>
                  <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code *</Typography></label>
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phone}
                    disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'}
                    inputProps={{ readOnly: true }}
                    onChange={(e) => { setValues({ ...values, 'contact_phone_code': e }); setPhone(e); setEditData({ ...editData, 'contact_phone_code': e }) }}
                  />
                </div>
                <InputComp disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'} setEditData={setEditData} editData={editData} labelLeftMargin={'5%'} fieldType={'numeric'} pattern={/^\d+$/} onChanges={handleChange} width='95%' minLength={8} maxLength={12} required={true} name={'contact_mobile'} placeholder={'Phone number *'} control={control} />
              </div>
              <div style={{ display: "flex", flexDirection: 'column', marginLeft: "17%" }}>
                {errors.contact_phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                }
                {errors.contact_mobile?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                }
                {errors.contact_mobile?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                }
                {errors.contact_mobile?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 12 digits'} />
                }
                {errors.contact_mobile?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                }
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputComp disabled={location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active'} setEditData={setEditData} editData={editData} maxLength={40} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} onChanges={handleChange} width='100%' required={true} name={'contact_email'} placeholder={'Email address *'} control={control} fieldType={'email'} />
              {errors.contact_email?.type === 'required' && <ErrorMessageComp message={'Email is required'} />
              }
              {
                errors?.contact_email?.type == 'pattern' && <ErrorMessageComp message={'Invalid email address'} />
              }
              {errors.contact_email?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
              }
            </Grid>
          </Grid>
          {!(location?.pathname == '/editTradingPartner' && editValue?.status_name != 'Active') && <Stack justifyContent="center" direction="row" margin={10}>
            <Button variant="contained" sx={{
              padding: '10px 60px',
              backgroundColor: '#B92C82',
              textTransform: 'Uppercase'
            }}
              onClick={handleSubmit(onSubmit)}>
              {
                !loading && <Typography variant='p' color='#fff'>SUBMIT</Typography>
              }
              {
                loading && <CircularProgress color="white" size={'25px'} />
              }
            </Button>
          </Stack>}
          <ModalComp closeButton={'Ok'} open={openModal} handleClose={()=>{setOpenModal(false);navigate('/createAuction')}} message={'Thank You'} subText={'If you have created this trading partner whilst creating an auction,please proceed with auction creation'}/>
        </Box>
      </Container>
    </>
  )
}

export default StepTwoCreateTradingPartner