import React from 'react';
import ContainerWithForm from './ContainerWithForm';
import HowToWorkSection from './HowToWorkSection';
import ForFunderSection from './ForFunderSection';
import ForBusinessesSection from './ForBusinessesSection';
import TopContainer from './TopContainer';
import { Link as ScrollLink, Element, Events, animateScroll as scroll } from 'react-scroll';
import { AppBar, Toolbar, Button, Box, Container, useMediaQuery, useTheme, CssBaseline, Typography, styled, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/Logo1.png'
import MobileDrawer from '../../layout//Navbar/MobileDrawer';

const GetStartedButtonStyle = styled(Button)({
  color: '#fff',
  width: '148px',
  height: '53px',
  borderRadius: '7px',
  background: 'transparent linear-gradient(90deg, var(--unnamed-color-b92c82) 0%, var(--unnamed-color-3a0ca3) 100%) 0% 0% no-repeat padding-box;'

})

const HomePage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const navigate = useNavigate()
  const GetStartedButton = () => {
    return <GetStartedButtonStyle onClick={() => navigate('/login')} color="primary" sx={{
    }}><Typography variant='caption' textTransform={'uppercase'} fontSize={'14px'}>Get Started</Typography></GetStartedButtonStyle>
  }
  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#fff', height: { lg: '102px', xs: '70px' }, boxShadow: '0px 3px 6px #0000000D' }}>
        <CssBaseline />
        <Container maxWidth={'xl'} sx={{ mb: 10 }}>
          <Toolbar sx={{ alignItems: 'center', mx: { xl: 13, md: 10, xs: 1 }, pl: { xl: 0, md: 0, xs: 0 }, height: { lg: '102px', xs: '70px' } }} >
            <Box sx={{ flexGrow: 1, py: { md: 0, xs: 2 } }}>
              <Container maxWidth={'xs'} sx={{ marginRight: 'auto', marginLeft: 'inherit', paddingLeft: "0 !important" }}>
                <Link to='/'><img src={Logo} alt='funding joint logo' width={'60%'} height={'auto'} /></Link>
              </Container>
            </Box>
            {isMobile
              ? <MobileDrawer button={<GetStartedButton />} />
              : <>
                <Box sx={{ mr: 4 }}>
                  <ScrollLink
                    to="whatwedo"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-150} // Adjust this offset as needed
                    style={{ textDecoration: 'none', color: '#000',cursor:'pointer' }}
                  >
                    <Typography variant='nav'>WHAT WE DO</Typography>
                  </ScrollLink>
                </Box>
                <Box sx={{ mr: 4 }}>
                  <ScrollLink
                    to="howitworks"
                    spy={true}
                    smooth={true}
                    duration={500}
                    style={{ textDecoration: 'none', color: '#000',cursor:'pointer' }}
                  >
                    <Typography variant='nav'>HOW IT WORKS</Typography>
                  </ScrollLink>
                </Box>
                <Box sx={{ mr: 4 }}>
                  <Link target='_blank' to='/faq' style={{ textDecoration: 'none', color: '#000' }}>
                    <Typography variant='nav'>FAQ</Typography></Link>
                </Box>
                <GetStartedButton />
              </>}
          </Toolbar>
        </Container>
      </AppBar>
      <Element>
        <div className="section">
          <TopContainer />
        </div>
      </Element>

      <Element name="whatwedo">
        <div className="section">
          {/* ################# TopContainer ################# */}
          <Typography variant='h2' component={'h2'} fontSize={'50px'} fontWeight={'800'} align='center' sx={{ marginBottom: { lg: 10, md: 7, xs: 5 } }}>What We do</Typography>
          {/* ################# ForBusinessesSection ################# */}
          <ForBusinessesSection />
          {/* ################# ForFunderSection ################# */}
          <ForFunderSection />
        </div>
      </Element>

      <Element name="howitworks">
        <div className="section">
          {/* ################# HowToWorkSection ################ */}
          <HowToWorkSection />
          {/*#################### ContainerWithForm #################### */}
          <ContainerWithForm />
        </div>
      </Element>
    </>
  );
};

export default HomePage;

