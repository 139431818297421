import { FormControl, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

function SelectInputComp({ marginTop, setEditData, dropdownColor, editData, editName, startText, placeholderLabel, options, disabled, focusHandle, onKeyDown, inputMode, height, label, name, control, placeholder, className, values, defaultValue, onChanges, maxLength, minLength, length,
    fieldType, pattern, startIcon, endIcon, show, setShow, startImg, endImg, alternativeEndImg, width, required }) {
    const handleTextInput = (e) => {
        if (fieldType == 'text') {
            e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')
        }
        if (fieldType == 'numeric') {
            e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }
    }
    const handleEditChange = (e, name, editName) => {
        if (setEditData) {
            if (editName) {
                setEditData({ ...editData, [editName]: e.target.value })
            } else {
                setEditData({ ...editData, [name]: e.target.value })
            }
        }
    }
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: required }}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%', marginTop: marginTop||2 }}}>
                    <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>{`${placeholderLabel} ${required && '*'}`}</Typography></label>
                    <TextField
                        id="outlined-select"
                        name={name}
                        select
                        disabled={disabled}
                        onChange={(e) => { onChange(e); handleEditChange(e, name, editName) }} value={value || placeholderLabel}
                        defaultValue=""
                        sx={{
                            mt: 0,
                            '& .MuiOutlinedInput-root': {
                                background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                border: 'none',
                                borderRadius: '7px',
                                marginLeft: 'auto',
                                width: { lg: '100%', md: '400px', xs: '100%' },
                                height: { md: '50px', xs: '50px' },
                                boxShadow: '0px 3px 10px #0000000D',
                                opacity: 1,
                            }
                        }}
                    >
                        {options && options?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                <Typography variant='p' color={'#000' || dropdownColor}>{option.name}</Typography>
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
        />
    )
}

export default SelectInputComp