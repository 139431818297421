export const cities = [
    {
        label: 'Select your city',
        value: 'Select your city',
    },
    {
        label: 'DEL',
        value: '1',
    },
    {
        label: 'MI',
        value: '2',
    },
    {
        label: 'RCB',
        value: '3',
    },
    {
        label: 'Kolkata',
        value: '4',
    },
];
export const states = [
    {
        label: 'Select your state',
        value: 'Select your state',
    },
    {
        label: 'West Bengal',
        value: '1',
    },
    {
        label: 'Jharkhand',
        value: '2',
    },
    {
        label: 'Odisha',
        value: '3',
    },
];
export const countries = [
    {
        label: 'Select your Country *',
        value: 'Select your Country *',
    },
    {
        label: 'IND',
        value: '1',
    },
    {
        label: 'USA',
        value: '2',
    },
    {
        label: 'AUS',
        value: '3',
    },
];
export const title = [
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Ms', value: 'Ms' },
];
export const phoneCode = [
    { label: '+91', value: '+91' },
    { label: '+1', value: '+1' }
];
export const company_type = [
    {
        value: 'Company Type*',
        label: 'Company Type*',
    },
    {
        value: '1',
        label: 'Pvt Ltd',
    },
    {
        value: '2',
        label: 'Product Based',
    },
    {
        value: '3',
        label: 'MNC',
    },
];
export const business_type = [
    {
        value: 'Business Type*',
        label: 'Business Type*',
    },
    {
        value: '1',
        label: 'B2B',
    },
    {
        value: '2',
        label: 'B2C',
    }
];

export const years = [
    {
        value: 'How long has the company been trading?',
        label: 'How long has the company been trading?',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
];
export const annualTurnover = [
    {
        value: 'Annual TurnOver £',
        label: 'Annual TurnOver £',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
]

export const bankNames = [
    {
        value: 'Bank name',
        label: 'Bank name',
    },
    {
        value: 'ICICI',
        label: 'ICICI',
    },
    {
        value: 'HDFC',
        label: 'HDFC',
    },
    {
        value: 'PNB',
        label: 'PNB',
    },
];

export const sortCodes = [
    {
        value: 'Sort code',
        label: 'Sort code',
    },
    {
        value: '12F',
        label: '12F',
    },
    {
        value: '34G',
        label: '34G',
    },
    {
        value: '98B',
        label: '98B',
    },
];

export const employeementStatus = [
    {
        value: 'Your Employeement Status *',
        label: 'Your Employeement Status *',
    },
    {
        value: 'Salaried',
        label: 'Salaried',
    },
    {
        value: 'Intern',
        label: 'Intern',
    },
    {
        value: 'Active',
        label: 'Active',
    },
]

export const dummyDataWallet = [
    {
        date: '01 Apr. 2021',
        description: "Balance brought forward",
        debit: 'xxxx',
        credit: 'xxx.x',
        balance: 'xxxx.xx'
    },
    {
        date: '01 Apr. 2021',
        description: "Balance brought forward",
        debit: 'xxxx',
        credit: 'xxx.x',
        balance: 'xxxx.xx'
    },
    {
        date: '01 Apr. 2021',
        description: "Balance brought forward",
        debit: 'xxxx',
        credit: 'xxx.x',
        balance: 'xxxx.xx'
    },
    {
        date: '01 Apr. 2021',
        description: "Balance brought forward",
        debit: 'xxxx',
        credit: 'xxx.x',
        balance: 'xxxx.xx'
    }
]
export const dummyDataInvestment = [{
    auctionType: '987-8987-784',
    auctionNumber: "Open",
    company: 'Company name',
    fundingRequired: '£ X,XXX',
    fundingAchieved: '£ 800',
    maxRoi: "8.9%",
    creditRating: '£ XX.XX',
    auctionTimeLeft: 'X.x%',
  },
  {
    auctionType: '987-8987-784',
    auctionNumber: "Open",
    company: 'Company name',
    fundingRequired: '£ X,XXX',
    fundingAchieved: '£ 800',
    maxRoi: "8.9%",
    creditRating: '£ XX.XX',
    auctionTimeLeft: 'X.x%',
  },
  {
    auctionType: '987-8987-784',
    auctionNumber: "Open",
    company: 'Company name',
    fundingRequired: '£ X,XXX',
    fundingAchieved: '£ 800',
    maxRoi: "8.9%",
    creditRating: '£ XX.XX',
    auctionTimeLeft: 'X.x%',
  },
  {
    auctionType: '987-8987-784',
    auctionNumber: "Open",
    company: 'Company name',
    fundingRequired: '£ X,XXX',
    fundingAchieved: '£ 800',
    maxRoi: "8.9%",
    creditRating: '£ XX.XX',
    auctionTimeLeft: 'X.x%',
  }]

export const dummyDataTrading = [
    {
        company: 'Company name',
        email: "info@fundingpoint.com",
        companyType: 'Sole proprietorship',
        businessType: 'Medical Industry',
        business_type: 'Pending'
    },
    {
        company: 'Company name',
        email: "info@fundingpoint.com",
        companyType: 'Sole proprietorship',
        businessType: 'Medical Industry',
        business_type: 'Rejected'
    },
    {
        company: 'Company name',
        email: "info@fundingpoint.com",
        companyType: 'Sole proprietorship',
        businessType: 'Medical Industry',
        business_type: 'Rejected'
    },
    {
        company: 'Company name',
        email: "info@fundingpoint.com",
        companyType: 'Sole proprietorship',
        businessType: 'Medical Industry',
        business_type: 'Approved'
    },
    {
        company: 'Company name',
        email: "info@fundingpoint.com",
        companyType: 'Sole proprietorship',
        businessType: 'Medical Industry',
        business_type: 'Approved'
    },
    {
        company: 'Company name',
        email: "info@fundingpoint.com",
        companyType: 'Sole proprietorship',
        businessType: 'Medical Industry',
        business_type: 'Pending'
    },
]
export const dummyDataBidders = [
    {
        rank: 'Rank 01',
        name: "Andrew",
        bid_amount: '£ 800',
        funding_share: '8.9%',
        roi: '£ 20.00',
        roi_second:'2.5%'
    },
    {
        rank: 'Rank 01',
        name: "Andrew",
        bid_amount: '£ 800',
        funding_share: '8.9%',
        roi: '£ 20.00',
        roi_second:'2.5%'
    },
    {
        rank: 'Rank 01',
        name: "Andrew",
        bid_amount: '£ 800',
        funding_share: '8.9%',
        roi: '£ 20.00',
        roi_second:'2.5%'
    },
    {
        rank: 'Rank 01',
        name: "Andrew",
        bid_amount: '£ 800',
        funding_share: '8.9%',
        roi: '£ 20.00',
        roi_second:'2.5%'
    },
    {
        rank: 'Rank 01',
        name: "Andrew",
        bid_amount: '£ 800',
        funding_share: '8.9%',
        roi: '£ 20.00',
        roi_second:'2.5%'
    },
    {
        rank: 'Rank 01',
        name: "Andrew",
        bid_amount: '£ 800',
        funding_share: '8.9%',
        roi: '£ 20.00',
        roi_second:'2.5%'
    },
]