import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    // userStatus: '',
    // userStatusStatus:'',
    // userStatusLoader:false,
    // userStatusError:null,
    // userStatusMessage:''
};
export const UserStatus = createAsyncThunk("/UserStatus", async (data) => {
    const response = await ApihelperFunction({
        urlPath: `api/checkUserStatus`,
        method: "get",
    });
    return response;
});
const userStatusSlice = createSlice({
    name: 'UserStatus',
    initialState,
    reducers: {
        resetUserStatus: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserStatus.pending, (state) => {
                state.userStatusStatus = 'LOADING'
                state.userStatusLoader = true
                state.userStatusError = null
            })
            .addCase(UserStatus.fulfilled, (state, action) => {
                state.userStatus=action?.payload?.status
                state.userStatusMessage=action?.payload?.message
                state.userStatusLoader = false
            })
            .addCase(UserStatus.rejected, (state, action) => {
                state.userStatusStatus = 'ERROR'
                state.userStatusLoader = false
            })
    }
})

export const { resetUserStatus } = userStatusSlice.actions;
export default userStatusSlice.reducer;