import React, { useEffect, useState } from 'react'
import { Box, Stack, Button, Container, Typography, FormControl, Grid, Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import InputComp from '../../components/InputComp';
import ErrorMessageComp from '../../components/ErrorMessageComp';
import { title } from '../../Constants';
import { useDispatch, useSelector } from 'react-redux';
import { Country } from '../../Redux/Slices/CountrySlice';
import { State } from '../../Redux/Slices/StateSlice';
import { City } from '../../Redux/Slices/CitySlice';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Loader from '../../components/Loader';
import ModalComp from '../../components/ModalComp';
import LoginFormBg from '../../assets/img/login_form_bg.png'
const SignUp2 = () => {
    //values
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = localStorage.getItem('role')
    const [values, setValues] = useState(JSON.parse(localStorage.getItem('signUp2')))
    const { country } = useSelector((state) => state.country)
    const { state } = useSelector((state) => state.state)
    const email = localStorage.getItem('userEmail')
    const { city } = useSelector((state) => state.city)
    const [phone, setPhone] = useState(values?.phone_code || '44');
    const [isSubmit, setIsSubmit] = useState(false)
    const editData = JSON.parse(localStorage.getItem('editData'))
    const step2EditData = JSON.parse(localStorage.getItem('signUp2NewEdit'))
    const step3EditData = JSON.parse(localStorage.getItem('signUp3NewEdit'))
    const step4EditData = JSON.parse(localStorage.getItem('signUp4NewEdit'))
    const step5EditData = JSON.parse(localStorage.getItem('signUp5NewEdit'))
    const step6EditData = JSON.parse(localStorage.getItem('signUp6NewEdit'))

    console.log(Object.keys({ ...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData, ...step6EditData }).length, 'LENGTHHHHHHHHHHHH')
    let prevReq,fromEdit;
    if (localStorage.getItem('prevReq') != 'undefined') {
        prevReq = JSON.parse(localStorage.getItem('prevReq'))
    }
    if (localStorage.getItem('fromEdit') != 'undefined') {
        fromEdit = JSON.parse(localStorage.getItem('fromEdit'))
    }
    const signUp2EditData = JSON.parse(localStorage.getItem('signUp2Edit'))
    let editCity2EditData, editState2EditData, editCountry2EditData;
    if (localStorage.getItem('editCity') != 'undefined') {
        console.log(localStorage.getItem('editCity'), 'UNDEFINEDDDDDDDDDDDDD')
        editCity2EditData = JSON.parse(localStorage.getItem('editCity'))
    }
    if (localStorage.getItem('editState') != 'undefined') {
        editState2EditData = JSON.parse(localStorage.getItem('editState'))
    }
    if (localStorage.getItem('editCountry') != 'undefined') {
        editCountry2EditData = JSON.parse(localStorage.getItem('editCountry'))
    }
    let editDefaultValuesForCompany, editDefaultValuesForFunder;
    let editCountry, editState = null, editCity = null;
    const [editNewData, setEditNewData] = useState()
    //form submit
    const onSubmit = (data) => {
        if (location?.search == '?edit') {
            if (editNewData) {
                localStorage.setItem('signUp2NewEdit', JSON.stringify({ ...editNewData }))
            }
            if (role == 1 || role == 'Company') {
                localStorage.setItem('signUp2Edit', JSON.stringify({ ...data, 'country': defaultValues?.country, 'state': values?.state, 'city': values?.city }))
            } else {
                localStorage.setItem('signUp2Edit', JSON.stringify({ ...data, 'country': values?.country, 'state': values?.state, 'city': values?.city }))
                localStorage.setItem('editCountry', JSON.stringify(country?.find((country) => country?.id == values?.country)))
            }
            localStorage.setItem('editCity', JSON.stringify(city?.find((city) => city?.id == values?.city)))
            localStorage.setItem('editState', JSON.stringify(state?.find((state) => state?.id == values?.state)))
            navigate('/signUp3?edit')
        } else {
            setIsSubmit(true)
            if (defaultValues?.country) {
                if ((role == 1 || role == 'Company')) {
                    localStorage.setItem('signUp2', JSON.stringify({ ...data, 'country': defaultValues?.country, state: values?.state, city: values?.city }))
                    if (Object.keys(errors).length === 0) {
                        navigate('/signUp3')
                    }
                } else {
                    localStorage.setItem('signUp2', JSON.stringify({ ...data, 'phone_code': phone, 'country': values?.country, state: values?.state, city: values?.city }))
                    if (Object.keys(errors).length === 0) {
                        navigate('/signUp3')
                    }
                }
            }
        }
    }


    //onChange
    const handleChange = (e, val) => {
        values != 'null' ?
            setValues({ ...values, [e.target.name]: e.target.value }) : setValues({ [e.target.name]: e.target.value })
    }
    const handleCountryChange = (e, val, name) => {
        // console.log(e, val, name, 'event')
        values != 'null' ?
            setValues({ ...values, [name]: val?.id }) : setValues({ [name]: val?.id })
        location?.search == '?edit' && setEditNewData({ ...editNewData, [name]: val?.id })
    }

    //get city value
    useEffect(() => {
        if (location?.search == '?edit') {
            if ((role == 1 || role == 'Company') && !values?.state) {
                dispatch(City(editDefaultValuesForCompany?.state))
            }
            else if ((role == 1 || role == 'Company') && values?.state) {
                dispatch(City(values?.state))
            }
            else if ((role == 2 || role == 'Funder') && !values?.state) {
                dispatch(City(editDefaultValuesForFunder?.state?.id))
            }
            else if ((role == 2 || role == 'Funder') && values?.state) {
                dispatch(City(values?.state))
            }
        } else {
            if (values?.state) {
                dispatch(City(values?.state))
            }
        }
    }, [values?.state, editDefaultValuesForFunder?.state])

    //get edit data value for company
    const location = useLocation();
    if (location?.search == '?edit' && (role == 1 || role == 'Company') && !signUp2EditData) {
        editDefaultValuesForCompany = {
            'company_name': editData?.Company?.[0]?.name,
            'postcode': editData?.Company?.[0]?.postcode,
            'registration_number': editData?.Company?.[0]?.registration_number,
            'address_1': editData?.Company?.[0]?.address_1,
            'address_2': editData?.Company?.[0]?.address_2,
            'address_3': editData?.Company?.[0]?.address_3,
            'country': editData?.Company?.[0]?.country_id,
            'state': editData?.Company?.[0]?.state_id,
            'city': editData?.Company?.[0]?.city_id
        }
        editState = { id: editData?.Company?.[0]?.state_id, name: editData?.Company?.[0]?.stat?.name }
        editCity = { id: editData?.Company?.[0]?.city_id, name: editData?.Company?.[0]?.cit?.name }
    }
    if (location?.search == '?edit' && (role == 1 || role == 'Company') && signUp2EditData) {
        editDefaultValuesForCompany = { ...signUp2EditData }
        editState = editState2EditData
        editCity = editCity2EditData
    }



    //get edit data value for funder
    if (location?.search == '?edit' && editData?.user_type == 'Funder' && !signUp2EditData) {
        editDefaultValuesForFunder = {
            'title': editData?.Funder?.title,
            'first_name': editData?.Funder?.first_name,
            'last_name': editData?.Funder?.last_name,
            'mobile': editData?.Funder?.mobile,
            'phone_code': editData?.Funder?.phone_code,
            'address_1': editData?.Funder?.funder_basic_detail?.address_1,
            'address_2': editData?.Funder?.funder_basic_detail?.address_2,
            'address_3': editData?.Funder?.funder_basic_detail?.address_3,
            'email': editData?.Funder?.email,
            'country': editData?.Funder?.funder_basic_detail?.country_id,
            'state': editData?.Funder?.funder_basic_detail?.state_id,
            'city': editData?.Funder?.funder_basic_detail?.city_id,
            'zipcode': editData?.Funder?.funder_basic_detail?.zipcode
        }
        editCountry = { id: editData?.Funder?.funder_basic_detail?.country_id, name: editData?.Funder?.funder_basic_detail?.country?.name }
        editState = { id: editData?.Funder?.funder_basic_detail?.state_id, name: editData?.Funder?.funder_basic_detail?.state?.name }
        editCity = { id: editData?.Funder?.funder_basic_detail?.city_id, name: editData?.Funder?.funder_basic_detail?.city?.name }
    }
    if (location?.search == '?edit' && editData?.user_type == 'Funder' && signUp2EditData) {
        editDefaultValuesForFunder = { ...signUp2EditData }
        editCountry = editCountry2EditData
        editState = editState2EditData
        editCity = editCity2EditData
    }


    useEffect(() => {
        dispatch(Country())
        if (location?.search == '?edit' && !signUp2EditData) {
            setValues(editDefaultValuesForCompany)
        }
        if (location?.search == '?edit' && signUp2EditData) {
            setValues(signUp2EditData)
        }
        if (location?.search == '?edit' && editData?.user_type == 'Funder' && !signUp2EditData) {
            setValues(editDefaultValuesForFunder)
        }
    }, [])

    //setting default values for forms
    const defaultValues = (role == 1 || role == 'Company') ? location?.search == '?edit' ? { ...editDefaultValuesForCompany } : { country: 230, ...values } : location?.search == '?edit' ? { ...editDefaultValuesForFunder } : { 'title': title[0].value, 'phone_code': '44', 'email': email, ...values }
    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: defaultValues
    })
    console.log(editNewData, 'NEW EDIT DATA')
    //get state value
    useEffect(() => {
        if (defaultValues?.country) {
            dispatch(State(defaultValues?.country))
        }
        if ((role == 2 || role == 'Funder')) {
            if (values?.country) {
                dispatch(State(values?.country))
            }
        }
    }, [values?.country])
    const [openModal, setOpenModal] = useState(false)
    useEffect(() => {
        if (prevReq && fromEdit) {
            setOpenModal(true)
        }
    }, [])
    return (
        country ?
            <div maxWidth={'xl'} style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover', paddingBottom: '80px' }}>
                <HorizontalLinearAlternativeLabelStepper val={1} data={values} showWarningMessage={Object.keys({ ...editNewData,...step2EditData, ...step3EditData, ...step4EditData, ...step5EditData, ...step6EditData }).length > 0 ? true : false} />
                {
                    (role == 1 || role == 'Company') &&
                    <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                        <Box component="form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp disabled={location?.search == '?edit'} maxLength={40} onChanges={handleChange} width='100%' name={'company_name'} required={true} placeholder={'Company name *'} control={control} fieldType={'company_name'} />
                                    {errors.company_name?.type === 'required' && <ErrorMessageComp message={'Company name is required'} />
                                    }
                                    {errors.company_name?.type === 'maxLength' && <ErrorMessageComp message={'Company name can have maximum 40 characters'} />
                                    }
                                    {errors.company_name && <ErrorMessageComp message={errors.company_name.message} />}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp disabled={location?.search == '?edit'} pattern={/^[a-zA-Z0-9]*$/} onChanges={handleChange} width='100%' name={'registration_number'} required={true} placeholder={'Company registration number *'} control={control} fieldType={'registration_number'} />
                                    {errors.registration_number?.type === 'required' && <ErrorMessageComp message={'Registration number is required'} />
                                    }
                                    {errors.registration_number?.type === 'pattern' && <ErrorMessageComp message={'Registration number can only have alphabets and numeric value'} />
                                    }
                                    {errors.registration_number && <ErrorMessageComp message={errors.registration_number.message} />}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={200} required={true} onChanges={handleChange} width='100%' name={'address_1'} placeholder={'Building no. / name *'} control={control} fieldType={'address_1'} />
                                    {errors.address_1?.type === 'required' && <ErrorMessageComp message={'Building name is required'} />
                                    }
                                    {errors.address_1?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                    }
                                    {errors.address_1 && <ErrorMessageComp message={errors.address_1.message} />}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={200} onChanges={handleChange} width='100%' name={'address_2'} placeholder={'Street *'} required={true} control={control} fieldType={'address_2'} />
                                    {errors.address_2?.type === 'required' && <ErrorMessageComp message={'Street is required'} />
                                    }
                                    {errors.address_2?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                    }
                                    {errors.address_2 && <ErrorMessageComp message={errors.address_2.message} />}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={200} onChanges={handleChange} width='100%' name={'address_3'} placeholder={'Town'} control={control} fieldType={'address_3'} />
                                    {errors.address_3?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                    }
                                    {errors.address_3 && <ErrorMessageComp message={errors.address_3.message} />}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Controller
                                        name='country'
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select country *</Typography></label>
                                                <Autocomplete
                                                    name='country'
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={country}
                                                    onChange={(e, val) => handleCountryChange(e, val, 'country')}
                                                    onBlur={onChange}
                                                    getOptionLabel={(country) => country?.name}
                                                    disabled
                                                    defaultValue={{ id: 230, name: "United Kingdom" }}
                                                    value={country?.find((country) => country?.id === values?.country)}
                                                    sx={{
                                                        mt: 0,
                                                        fontSize: '15px',
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1,
                                                            fontSize: '15px'
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='country' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />

                                    {isSubmit && !defaultValues?.country && <ErrorMessageComp message={'Country is required'} />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {state && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Controller
                                        name='state'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select County/State *</Typography></label>
                                                <Autocomplete
                                                    disabled={!defaultValues?.country}
                                                    name='state'
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={state}
                                                    onChange={(e, val) => handleCountryChange(e, val, 'state')}
                                                    onBlur={onChange}
                                                    getOptionLabel={(state) => state?.name}
                                                    defaultValue={editState}
                                                    value={state?.find((state) => state?.id == values?.state) || null}
                                                    sx={{
                                                        mt: 0,
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='state' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                    {errors?.state?.type == 'required' && <ErrorMessageComp message={'County/State is required'} />
                                    }
                                </Grid>}

                                {city && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Controller
                                        name='city'
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select city</Typography></label>
                                                <Autocomplete
                                                    disabled={!values?.state}
                                                    name='city'
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={city}
                                                    onChange={(e, val) => handleCountryChange(e, val, 'city')}
                                                    onBlur={onChange}
                                                    getOptionLabel={(city) => city?.name}
                                                    defaultValue={editCity}
                                                    value={city?.find((city) => city?.id === values?.city) || null}
                                                    sx={{
                                                        mt: 0,
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='city' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </Grid>}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} minLength={3} maxLength={10} onChanges={handleChange} width='100%' required={true} name={'postcode'} placeholder={'Postcode *'} control={control} pattern={/^[a-zA-Z0-9\s]*$/} fieldType={'postcode'} />
                                    {errors.postcode?.type === 'required' && <ErrorMessageComp message={'Postcode is required'} />
                                    }
                                    {errors.postcode?.type === 'maxLength' && <ErrorMessageComp message={'Zipcode can have max 10 numbers'} />
                                    }
                                    {errors.postcode?.type === 'minLength' && <ErrorMessageComp message={'Zipcode can have minimum 3 numbers'} />
                                    }
                                    {errors.postcode?.type === 'pattern' && <ErrorMessageComp message={'Postcode can have only alphanumeric characters'} />
                                    }
                                    {errors.postcode && <ErrorMessageComp message={errors.postcode.message} />}
                                </Grid>
                            </Grid>
                            <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                                <Button variant="contained" sx={{
                                    padding: '10px 60px',
                                    backgroundColor: '#B92C82',
                                    textTransform: 'Uppercase'
                                }}
                                    onClick={handleSubmit(onSubmit)}>NEXT</Button>
                            </Stack>
                        </Box >
                    </Container >
                }
                {
                    (role == 2 || role == 'Funder') && <Container maxWidth={'md'} sx={{ minHeight: '60vh' }}>
                        <Box component="form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Controller
                                            name='title'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl sx={{ width: { lg: 'max-content', md: '100%', xs: '100%' }, mt: 2, display: 'flex' }}>
                                                    <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Title *</Typography></label>
                                                    <TextField
                                                        id="outlined-select"
                                                        select
                                                        name='title'
                                                        placeholder='Select your city'
                                                        onBlur={handleChange}
                                                        onChange={(e) => { onChange(e); setEditNewData({ ...editNewData, 'auth_title': e.target.value }) }} value={value || title[0].value}
                                                        defaultValue={title[0].value}
                                                        sx={{
                                                            mt: 0,
                                                            '& .MuiOutlinedInput-root': {
                                                                background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                                border: 'none',
                                                                borderRadius: '7px',
                                                                width: { lg: 'max-content', md: '400px', xs: '100%' },
                                                                height: { md: '50px', xs: '50px' },
                                                                boxShadow: '0px 3px 10px #0000000D',
                                                                opacity: 1
                                                            }
                                                        }}
                                                    >
                                                        {title.map((option, index) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                <Typography variant='p'>{option.label}</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            )}
                                        />
                                        <InputComp labelLeftMargin={'5%'} setEditData={setEditNewData} editName={'auth_first_name'} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='95%' required={true} name={'first_name'} placeholder={'First name *'} control={control} fieldType={'text'} />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: 'column', marginLeft: '15px' }}>
                                        {errors.title?.type === 'required' && <ErrorMessageComp message={'Title is required'} />
                                        }
                                        {errors.first_name?.type === 'required' && <ErrorMessageComp message={'First name is required'} />
                                        }
                                        {
                                            errors.first_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid first name'} />
                                        }
                                        {
                                            errors.first_name?.type === 'maxLength' && <ErrorMessageComp message={'First name can have maximum 15 characters'} />
                                        }
                                        {errors.first_name && <ErrorMessageComp message={errors.first_name.message} />}

                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editName={'auth_last_name'} editData={editNewData} maxLength={15} pattern={/^[A-Za-z\s]+$/} onChanges={handleChange} width='100%' required={true} name={'last_name'} placeholder={'Last name *'} control={control} fieldType={'text'} />
                                    {errors.last_name?.type === 'required' && <ErrorMessageComp message={'Last name is required'} />
                                    }
                                    {
                                        errors.last_name?.type === 'pattern' && <ErrorMessageComp message={'Invalid last name'} />
                                    }
                                    {
                                        errors.last_name?.type === 'maxLength' && <ErrorMessageComp message={'Last name can have maximum 15 characters'} />
                                    }
                                    {errors.last_name && <ErrorMessageComp message={errors.last_name.message} />}

                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp maxLength={40} disabled={true} required={true} pattern={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/} onChanges={handleChange} width='100%' name={'email'} placeholder={'Email address *'} control={control} fieldType={'email'} />
                                    {
                                        errors?.email?.type == 'required' && <ErrorMessageComp message={'Email address is required'} />
                                    }
                                    {errors.email?.type === 'maxLength' && <ErrorMessageComp message={'Email address can have maximum 40 characters'} />
                                    }
                                    {errors.email && <ErrorMessageComp message={errors.email.message} />}

                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                        <div style={{ width: '35%' }}>
                                            <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Phone code *</Typography></label>
                                            <PhoneInput
                                                country={"eg"}
                                                enableSearch={true}
                                                value={phone}
                                                inputProps={{ readOnly: true }}
                                                disabled={location?.search == '?edit'}
                                                onChange={(e) => { setValues({ ...values, 'phone_code': e }); setPhone(e); setEditNewData({ ...editNewData, 'phone_code': e }) }}
                                            />
                                        </div>
                                        <InputComp labelLeftMargin={'5%'} setEditData={setEditNewData} editData={editNewData} disabled={location?.search == '?edit'} pattern={/^\d+$/} minLength={8} maxLength={15} onChanges={handleChange} width='95%' required={true} name={'mobile'} placeholder={'Phone number *'} control={control} fieldType={'numeric'} />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: 'column', marginLeft: "13%" }}>
                                        {errors.phone_code?.type === 'required' && <ErrorMessageComp message={'Code is required'} />
                                        }
                                        {errors.mobile?.type === 'required' && <ErrorMessageComp message={'Phone number is required'} />
                                        }
                                        {errors.mobile?.type === 'maxLength' && <ErrorMessageComp message={'Phone number must be maximum of 15 digits'} />
                                        }
                                        {errors.mobile?.type === 'minLength' && <ErrorMessageComp message={'Phone number must be minimum of 8 digits'} />
                                        }
                                        {errors.mobile?.type === 'pattern' && <ErrorMessageComp message={'Phone number can only have numbers'} />
                                        }
                                        {errors.mobile && <ErrorMessageComp message={errors.mobile.message} />}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} maxLength={200} required={true} onChanges={handleChange} width='100%' name={'address_1'} placeholder={'Building no. / name *'} control={control} fieldType={'address_3'} />
                                    {errors.address_1?.type === 'required' && <ErrorMessageComp message={'Building no is required'} />
                                    }
                                    {errors.address_1?.type === 'maxLength' && <ErrorMessageComp message={'This field can have maximum 200 characters'} />
                                    }
                                    {errors.address_1 && <ErrorMessageComp message={errors.address_1.message} />}

                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} required={true} onChanges={handleChange} width='100%' name={'address_2'} placeholder={'Street *'} control={control} fieldType={'country'} />
                                    {errors.address_2?.type === 'required' && <ErrorMessageComp message={'Street is required'} />
                                    }
                                    {errors.address_2 && <ErrorMessageComp message={errors.address_2.message} />}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} onChanges={handleChange} width='100%' name={'address_3'} placeholder={'Town'} control={control} fieldType={'address_3'} />
                                    {errors.address_3 && <ErrorMessageComp message={errors.address_3.message} />}
                                </Grid>
                                {country && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Controller
                                        name='country'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select Country *</Typography></label>
                                                <Autocomplete
                                                    name='country'
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={country}
                                                    onChange={(e, val) => handleCountryChange(e, val, 'country')}
                                                    onBlur={onChange}
                                                    defaultValue={editCountry}
                                                    getOptionLabel={(country) => country?.name}
                                                    value={country?.find((country) => country?.id === values?.country) || null}
                                                    sx={{
                                                        mt: 0,
                                                        fontSize: '15px',
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1,
                                                            fontSize: '15px'
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='country' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                    {errors.country?.type === 'required' && <ErrorMessageComp message={'Country is required'} />
                                    }
                                </Grid>}
                            </Grid>
                            <Grid container spacing={2}>
                                {state && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Controller
                                        name='state'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select County/State *</Typography></label>
                                                <Autocomplete
                                                    disabled={!values?.country}
                                                    name='state'
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    defaultValue={editState}
                                                    options={state}
                                                    onChange={(e, val) => handleCountryChange(e, val, 'state')}
                                                    onBlur={onChange}
                                                    getOptionLabel={(state) => state?.name}
                                                    value={state?.find((state) => state?.id === values?.state) || null}
                                                    sx={{
                                                        mt: 0,
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='state' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                    {errors.state?.type === 'required' && <ErrorMessageComp message={'State is required'} />
                                    }
                                </Grid>}

                                {city && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Controller
                                        name='city'
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ width: { lg: '100%', md: '100%', xs: '100%' }, mt: 2 }}>
                                                <label><Typography variant='nav' fontWeight={'500'} fontSize={'13px'}>Select city</Typography></label>
                                                <Autocomplete
                                                    disabled={!values?.state}
                                                    name='city'
                                                    disablePortal
                                                    defaultValue={editCity}
                                                    id="combo-box-demo"
                                                    options={city}
                                                    onChange={(e, val) => handleCountryChange(e, val, 'city')}
                                                    onBlur={onChange}
                                                    getOptionLabel={(city) => city?.name}
                                                    value={city?.find((city) => city?.id === values?.city) || null}
                                                    sx={{
                                                        mt: 0,
                                                        '& .MuiOutlinedInput-root': {
                                                            background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
                                                            border: 'none',
                                                            borderRadius: '7px',
                                                            marginLeft: 'auto',
                                                            width: { lg: '100%', md: '400px', xs: '100%' },
                                                            height: { md: '50px', xs: '50px' },
                                                            boxShadow: '0px 3px 10px #0000000D',
                                                            opacity: 1
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name='city' style={{ fontSize: '14px' }} {...params}
                                                    />}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </Grid>}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputComp setEditData={setEditNewData} editData={editNewData} minLength={3} maxLength={10} onChanges={handleChange} width='100%' required={true} name={'zipcode'} placeholder={'Postcode/Zipcode *'} control={control} fieldType={'postcode'} pattern={/^[a-zA-Z0-9\s]*$/} />
                                    {errors.zipcode?.type === 'required' && <ErrorMessageComp message={'Zipcode is required'} />
                                    }
                                    {errors.zipcode?.type === 'maxLength' && <ErrorMessageComp message={'Zipcode can have max 10 numbers'} />
                                    }
                                    {errors.zipcode?.type === 'minLength' && <ErrorMessageComp message={'Zipcode can have minimum 3 numbers'} />
                                    }
                                    {errors.zipcode?.type === 'pattern' && <ErrorMessageComp message={'Zipcode/Postcode can have only alphanumeric characters'} />
                                    }
                                    {errors.zipcode && <ErrorMessageComp message={errors.zipcode.message} />}
                                </Grid>
                            </Grid>
                            <Stack justifyContent="center" direction="row" margin={10} mb={0}>
                                <Button variant="contained" sx={{
                                    padding: '10px 60px',
                                    backgroundColor: '#B92C82',
                                    textTransform: 'Uppercase'
                                }}
                                    onClick={handleSubmit(onSubmit)}>NEXT</Button>
                            </Stack>
                        </Box >
                    </Container >
                }
                <ModalComp closeButton={"Ok"} open={openModal} message={`Your previous profile change request status is ${prevReq}`} handleClose={() => { setOpenModal(false); localStorage.removeItem('prevReq'); localStorage.removeItem('fromEdit') }} />
            </div> : <Loader />

    );
}
export default SignUp2 