import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    logoutStatus: '',
    logoutLoader: false,
    logoutError: null,
    message: ''
};
export const Logout = createAsyncThunk("/Logout", async () => {
    console.log('func called')
    const response = await ApihelperFunction({
        urlPath: `api/logout`,
        method: "post",
    });
    return response;
});
const logoutSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetStateLogout: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(Logout.pending, (state) => {
                state.logoutStatus = 'LOADING'
                state.logoutLoader = true
                state.logoutError = null
            })
            .addCase(Logout.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.logoutStatus = "SUCCESS"
                }
                else {
                    state.logoutStatus = "ERROR"
                }
                state.logoutLoader = false
                state.message = action?.payload?.message
            })
            .addCase(Logout.rejected, (state, action) => {
                state.logoutStatus = 'ERROR'
                state.logoutLoader = false
            })
    }
})

export const { resetStateLogout } = logoutSlice.actions;
export default logoutSlice.reducer;