import MinimalLayout from "../layout/MinimalLayout"
import AddPassword from "../pages/Login/AddPassword"
import EmailVerification from "../pages/Login/EmailVerification"
import ForgetPassword from "../pages/Login/ForgetPassword"
import Login from "../pages/Login/Login"


const LoginRoute = {
    path: '',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            children: [
                {
                    path: '',
                    element: <Login />
                }
            ]
        },
        {
            path: 'forgotpassword',
            children: [
                {
                    path: '',
                    element: <ForgetPassword />
                }
            ]
        },
        {
            path: 'emailVerify',
            children: [
                {
                    path: '',
                    element: <ForgetPassword />
                }
            ]
        },
        {
            path: 'addPassword',
            children: [
                {
                    path: '',
                    element: <AddPassword />
                }
            ]
        },
        {
            path: 'resetPassword',
            children: [
                {
                    path: '',
                    element: <AddPassword />
                }
            ]
        },
        {
            path: 'emailverification',
            children: [
                {
                    path: '',
                    element: <EmailVerification />
                }
            ]
        },
        {
            path: 'otpVerificaton',
            children: [
                {
                    path: '',
                    element: <EmailVerification />
                }
            ]
        }
    ]
}

export default LoginRoute
