import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/img/Group 237.png'
import dashboard from '../../assets/img/house.png'
import marketPlace from '../../assets/img/monitor.png'
import auction from '../../assets/img/pie-chart.png'
import settings from '../../assets/img/settings.png'
import wallet from '../../assets/img/folder.png'
import avatar from '../../assets/img/avatar.png'
import investment from '../../assets/img/dollar-funding.svg'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import { Card, Collapse, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ExpandMore as ExpandMoreIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { UserStatus, resetUserStatus } from '../../Redux/Slices/CheckUserStatusSlice';
import { toast } from 'react-toastify';
import { TradingPartnerList } from '../../Redux/Slices/GetTradingPartnerListSlice';
import { WalletBalance } from '../../Redux/Slices/GetWalletBalanceSlice';
import { WalletHistory } from '../../Redux/Slices/GetWalletHistorySlice';
import { TransactionHistory } from '../../Redux/Slices/GetTransactionHistorySlice';
import { Profile } from '../../Redux/Slices/GetProfileSlice';
import checked from '../../assets/img/checked.png'
import arrow from '../../assets/img/arrow_leader_board.png'

const drawerWidth = 240;

const listItemSelected = {
    opacity: '1',
    backgroundColor: "rgba(255,255,255,0.1)",
}
const listItemDisabled = {
    cursor: 'not-allowed'
}
const listItemStyles = {
    opacity: '0.8'
};
const listItemTextSelected = {
    fontWeight: 'bold'
}
const listItemTextStyles = {
    fontWeight: 'normal'
}

function DashboardLayout(props) {
    const { window } = props;
    const dispatch = useDispatch()
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [pathName, setPathName] = React.useState()
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const location = useLocation()
    const [open, setOpen] = React.useState(location?.pathname == '/myAuction/auctionList' ? true : false);

    const handleClick = () => {
        setOpen(!open);
    };
    const navigate = useNavigate()
    const role = localStorage.getItem('role')
    const { userStatus, userStatusMessage, userStatusLoader } = useSelector((state) => state.userStatus)
    const handleNaviate = (pathName) => {
        dispatch(UserStatus())
        setPathName(pathName)
    }
    React.useEffect(() => {
        if (userStatusLoader) {
            toast.info('' + 'Please wait', {
                style: { fontSize: "15px" },
                autoClose: 750
            });
        } else if (userStatus == false && !userStatusLoader) {
            toast.error('' + userStatusMessage, {
                style: { fontSize: "15px" }
            });
        } else if (userStatus == true && !userStatusLoader) {
            if (pathName == 'tradingPartner') {
                dispatch(TradingPartnerList())
            }
            if (pathName == 'myWallet') {
                dispatch(WalletBalance())
                dispatch(WalletHistory())
                dispatch(TransactionHistory())
            }
            if (pathName == 'viewProfile') {
                dispatch(Profile())
            }
            navigate(`/${pathName}`);

        }
    }, [userStatus, userStatusLoader])
    React.useEffect(() => {
        return () => {
            dispatch(resetUserStatus()); // Dispatch the reset action
        };
    }, [dispatch]);
    React.useEffect(() => {
        if (location?.pathname == '/myAuction/auctionList') {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [location?.pathname])
    const drawer = (
        <div>
            <Toolbar />
            <List component="nav" className='sidebar_component'>
                <ListItemButton sx={{ paddingLeft: '0px', paddingTop: '0px' }}>
                    <img src={logo} alt='' />
                </ListItemButton>
                <ListItemButton
                    selected={location.pathname === '/dashboard'}
                    onClick={() => navigate("/dashboard")} sx={{ opacity: '0.5' }}
                    style={location.pathname === '/dashboard' ? listItemSelected : listItemStyles}
                >
                    <ListItemIcon>
                        <img src={dashboard} alt='' width='20px' />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant='p' color='#fff' sx={location.pathname === '/dashboard' ? listItemTextSelected : listItemTextStyles}>Dashboard</Typography>
                    </ListItemText>
                </ListItemButton>
                {
                    <ListItemButton
                        selected={location.pathname === '/marketPlace'}
                        onClick={() => navigate("/marketPlace")}
                        style={location.pathname === '/marketPlace' ? listItemSelected : listItemStyles}
                    >
                        <ListItemIcon>
                            <img src={marketPlace} alt='' width='20px' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='p' color='#fff' sx={location.pathname === '/marketPlace' ? listItemTextSelected : listItemTextStyles}>Market Place</Typography>
                        </ListItemText>
                    </ListItemButton>
                }
                {
                    role == 'Company' && <>
                        <ListItemButton
                            onClick={handleClick}
                            style={location.pathname === '/myAuctions' ? listItemSelected : listItemStyles}
                        >
                            <ListItemIcon>
                                <img src={auction} alt='' width='20px' />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant='p' color='#fff' sx={location.pathname === '/myAuctions' ? listItemTextSelected : listItemTextStyles}>
                                    My Auctions
                                </Typography>
                            </ListItemText>
                            {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => navigate("/createAuction")}
                                >
                                    <ListItemIcon>
                                        <img src={checked} alt='' width='13px' style={{ filter: 'brightness(0) invert(1)' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='p' fontSize={'13px'} color='#fff' sx={location.pathname === '/myAuctions' ? listItemTextSelected : listItemTextStyles}>
                                            Create auctions
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => navigate("/myAuction/auctionList?AllAuction")}
                                >
                                    <ListItemIcon>
                                        <img src={checked} alt='' width='13px' style={{ filter: 'brightness(0) invert(1)' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='p' fontSize={'13px'} color='#fff' sx={location.search === '?AllAuction' ? listItemTextSelected : listItemTextStyles}>
                                            All auction
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => navigate("/myAuction/auctionList?LiveAuction")}
                                >
                                    <ListItemIcon>
                                        <img src={checked} alt='' width='13px' style={{ filter: 'brightness(0) invert(1)' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='p' fontSize={'13px'} color='#fff' sx={location.search === '?LiveAuction' ? listItemTextSelected : listItemTextStyles}>
                                            Live auction
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }}
                                    onClick={() => navigate("/myAuction/auctionList?ClosedAuction")}
                                >
                                    <ListItemIcon>
                                        <img src={checked} alt='' width='13px' style={{ filter: 'brightness(0) invert(1)' }} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant='p' fontSize={'13px'} color='#fff' sx={location.search === '?ClosedAuction' ? listItemTextSelected : listItemTextStyles}>
                                            Closed auction
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {
                    role == 'Funder' && <ListItemButton

                        selected={location.pathname === '/myAuction'}
                        onClick={() => handleNaviate("myAuction/auctionList?AllAuction")}
                        sx={{ opacity: '0.5' }}
                        style={location.pathname === '/myAuction' ? listItemSelected : listItemStyles}
                    >
                        <ListItemIcon>
                            <img src={auction} alt='' width='20px' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='p' color='#fff' sx={location.pathname === '/myAuction/auctionList' ? listItemTextSelected : listItemTextStyles}>My Bids</Typography>
                        </ListItemText>
                    </ListItemButton>}
                {
                    role == 'Company' && <ListItemButton

                        selected={location.pathname === '/myFunding'}
                        onClick={() => handleNaviate("myFunding")}
                        style={location.pathname === '/myFunding' ? listItemSelected : listItemStyles}
                    >
                        <ListItemIcon>
                            <img src={investment} alt='' width='20px' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='p' color='#fff' sx={location.pathname === '/myFunding' ? listItemTextSelected : listItemTextStyles}>
                                My Funding
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                }
                {
                    role == 'Funder' && <ListItemButton

                        selected={location.pathname === '/myInvestment'}
                        onClick={() => handleNaviate("myInvestment")}
                        style={location.pathname === '/myInvestment' ? listItemSelected : listItemStyles}
                    >
                        <ListItemIcon>
                            <img src={investment} alt='' width='20px' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='p' color='#fff' sx={location.pathname === '/myInvestment' ? listItemTextSelected : listItemTextStyles}>
                                My Investment
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                }
                {
                    role == 'Company' && <ListItemButton

                        selected={location.pathname === '/tradingPartner'}
                        onClick={() => handleNaviate('tradingPartner')}
                        style={(location.pathname === '/tradingPartner' || location.pathname === '/viewTradingPartner' || location.pathname === '/editTradingPartner') ? listItemSelected : listItemStyles}
                    >
                        <ListItemIcon>
                            <img src={marketPlace} alt='' width='20px' />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant='p' color='#fff' sx={(location.pathname === '/tradingPartner' || location.pathname === '/viewTradingPartner' || location.pathname === '/editTradingPartner') ? listItemTextSelected : listItemTextStyles}>
                                My Trading Partners
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                }
                <ListItemButton

                    selected={location.pathname === '/myWallet'}
                    onClick={() => handleNaviate('myWallet')}
                    style={location.pathname === '/myWallet' ? listItemSelected : listItemStyles}
                >
                    <ListItemIcon>
                        <img src={wallet} alt='' width='20px' />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant='p' color='#fff' sx={location.pathname === '/myWallet' ? listItemTextSelected : listItemTextStyles}>
                            My Wallet
                        </Typography>
                    </ListItemText>
                </ListItemButton>
                <ListItemButton

                    selected={location.pathname === '/viewProfile'}
                    onClick={() => handleNaviate('viewProfile')}
                    style={location.pathname === '/viewProfile' ? listItemSelected : listItemStyles}
                >
                    <ListItemIcon>
                        <img src={avatar} alt='' width='20px' />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant='p' color='#fff' sx={location.pathname === '/viewProfile' ? listItemTextSelected : listItemTextStyles}>
                            Profile
                        </Typography>
                    </ListItemText>
                </ListItemButton>
                {/* <ListItemButton
                    style={location.pathname === '/settings' ? listItemSelected : listItemStyles}
                >
                    <ListItemIcon>
                        <img src={settings} alt='' width='20px' />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant='p' color='#fff' sx={location.pathname === '/settings' ? listItemTextSelected : listItemTextStyles}>
                            Settings
                        </Typography>
                    </ListItemText>
                </ListItemButton> */}
                {location?.pathname == '/tradingPartner' && <ListItemButton
                    onClick={() => navigate('/createTradingPartner')}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Card style={{ width: '160px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", padding: '15px', borderRadius: '20px' }}>
                        <Box sx={{ background: '#FF007C', marginBottom: '15px', textAlign: 'center', borderRadius: '50%', width: '30px', height: "30px" }}>
                            <AddIcon style={{ color: '#fff' }} />
                        </Box>
                        <Typography variant='h6' fontSize={'14px'} fontWeight={'bold'} textAlign={'center'}>ADD<br /> NEW TRADING<br /> PARTNER</Typography>
                    </Card>
                </ListItemButton>}
                {location?.pathname == '/myAuction/auctionDetails' && <ListItemButton
                    onClick={() => handleNaviate(`myAuction/leaderboard${location?.search}`)}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Card style={{ width: '150px', height: '170px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "column", padding: '15px', borderRadius: '20px' }}>
                        <Box sx={{ background: '#FF007C', marginBottom: '15px', textAlign: 'center', borderRadius: '50%', width: '30px', height: "30px" }}>
                            <img src={arrow} width={'30px'} />
                        </Box>
                        <Typography variant='h6' fontSize={'14px'} fontWeight={'bold'} textAlign={'center'}>VIEW LEADER<br /> BOARD</Typography>
                    </Card>
                </ListItemButton>}
            </List>
        </div >
    );

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const container = window != undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                    }}
                >
                    <Toolbar sx={{ backgroundColor: '#fff' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' }, color: '#000' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Header />
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflowX: 'hiidden' },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, marginLeft: isMobile ? '0px' : `${drawerWidth}px`, width: { sm: `calc(100% - ${drawerWidth}px)`, paddingTop: '90px' } }}
            >
                <Outlet />
            </Box>
        </>
    );
}

DashboardLayout.propTypes = {
    window: PropTypes.func,
};

export default DashboardLayout;