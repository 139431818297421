import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    getLeaderBoardDetails: null,
    getLeaderBoardDetailsStatus: '',
    getLeaderBoardDetailsLoader: false,
    getLeaderBoardDetailsError: null
};
const role = localStorage.getItem('role')
console.log(role,'ROLEEEEEEEEEEEEEEEEEE')
export const GetLeaderBoardDetails = createAsyncThunk("/GetLeaderBoardDetails", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: data?.role == 'company' ? `api/${data?.role}/getAuctionLeaderBord?auction_id=${data?.auction_id}` : `api/${data?.role}/getLeaderBoard?auction_id=${data?.auction_id}`,
        method: "get",
    });
    return response;
});
const getLeaderBoardDetailsSlice = createSlice({
    name: 'CompanyBankDetails',
    initialState,
    reducers: {
        resetStateLeaderBoardDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetLeaderBoardDetails.pending, (state) => {
                state.getLeaderBoardDetailsStatus = 'LOADING'
                state.getLeaderBoardDetailsLoader = true
                state.getLeaderBoardDetailsError = null
            })
            .addCase(GetLeaderBoardDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.getLeaderBoardDetailsStatus = "SUCCESS"
                    state.getLeaderBoardDetails = action?.payload?.data
                }
                else {
                    state.getLeaderBoardDetails = []
                    state.getLeaderBoardDetailsStatus = "ERROR"
                    state.getLeaderBoardDetailsError = [action?.payload?.errors?.[0]]
                }
                state.getLeaderBoardDetailsLoader = false
            })
            .addCase(GetLeaderBoardDetails.rejected, (state, action) => {
                state.getLeaderBoardDetailsStatus = 'ERROR'
                state.getLeaderBoardDetailsLoader = false
                state.getLeaderBoardDetails = []
            })
    }
})

export const { resetStateLeaderBoardDetails } = getLeaderBoardDetailsSlice.actions;
export default getLeaderBoardDetailsSlice.reducer;