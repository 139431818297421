import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    totalMyClosedAuction: null,
    myClosedAuction: [null],
    myClosedAuctionStatus: '',
    myClosedAuctionLoader: false,
    myClosedAuctionError: null,
    retry:0
};
const role = localStorage.getItem('role')
export const MyClosedAuction = createAsyncThunk("/MyClosedAuction", async (data) => {
    const response = await ApihelperFunction({
        urlPath: role == 'Funder' ? `api/funder/getMyAuctionClose?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}` : `api/company/getMyAuctionClose?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}`,
        method: "get",
    });
    return response;
});
const myClosedAuctionSlice = createSlice({
    name: 'MyClosedAuction',
    initialState,
    reducers: {
        resetStateMyClosedAuction: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(MyClosedAuction.pending, (state) => {
                state.myClosedAuctionStatus = 'LOADING'
                state.myClosedAuctionLoader = true
                state.myClosedAuctionError = null
                state.myClosedAuction = null
            })
            .addCase(MyClosedAuction.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.myClosedAuctionStatus = "SUCCESS"
                    state.myClosedAuction = action?.payload?.data?.auction
                    state.totalMyClosedAuction = action?.payload?.data?.auction?.length
                }
                else {
                    state.myClosedAuctionStatus = "ERROR"
                    state.myClosedAuctionError = [action?.payload?.errors?.[0]]
                    if (action?.payload?.errors?.[0] == 'my auction close detail not found!'||action?.payload?.errors?.[0] == 'funder my auction not found!') {
                        state.myClosedAuction = []
                        state.totalMyClosedAuction = 0
                    }
                }
                state.myClosedAuctionLoader = false
            })
            .addCase(MyClosedAuction.rejected, (state, action) => {
                console.log("Rejected api")
                state.myClosedAuctionStatus = 'ERROR'
                state.myClosedAuctionLoader = false
                state.retry=state.retry+1
            })
    }
})

export const { resetStateMyClosedAuction } = myClosedAuctionSlice.actions;
export default myClosedAuctionSlice.reducer;