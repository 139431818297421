import React from 'react'
import { Box, Typography, Container, Button } from '@mui/material';
import HorizontalLinearAlternativeLabelStepper from './Stepper';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginFormBg from '../../assets/img/login_form_bg.png'

const SignUp7 = () => {
    const navigate = useNavigate()
    const role = localStorage.getItem('role')
    const location = useLocation()
    console.log(location, 'LOCATION')
    return (
        <div style={{ backgroundImage: `url(${LoginFormBg})`, backgroundSize: 'cover' }}>
            <HorizontalLinearAlternativeLabelStepper val={6} />
            <Container maxWidth={'md'} sx={{ m: '100px auto', mb: 0, pb: '100px', minHeight: '50vh' }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: 'auto',
                        maxWidth: { lg: '457px', md: '400px', xs: '290px' }
                    }}
                >
                    <Typography color='#B92C82' mb={0} variant='h5' fontWeight={'bold'} textAlign='center'>
                        Thank you
                    </Typography>
                    {
                        location?.state == 'Company' && <Typography variant='p' component='p' color='#000' textAlign='center' textTransform={'none'} mb={3}>
                            Your registration will now be processed. We will be in touch shortly to request copies of your annual accounts, bank statements or other information we may require.
                        </Typography>
                    }
                    {
                        location?.state == 'Funder' && <Typography variant='p' component='p' color='#000' textAlign='center' textTransform={'none'} mb={3}>
                            for submitting your registration. We will be in touch shortly to request copies of your ID and other documents or information we may require.
                        </Typography>
                    }
                    <Button variant='contained' onClick={() => navigate('/login')} sx={{
                        backgroundColor: '#B92C82',
                    }}>Back to Login</Button>
                </Box >
            </Container>
        </div>
    );
}
export default SignUp7