import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Profile } from '../Redux/Slices/GetProfileSlice'

function ViewProfile() {
    const { profile } = useSelector((state) => state.profile)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(Profile())
    }, [])
    const data = profile?.user_type == 'Company' ? profile?.Company[0] : profile?.Funder
    return (
        <>
            {
                profile?.user_type == 'Company' && <Grid container spacing={2} alignItems='stretch' sx={{ flexGrow: 1 }}>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>Company Information</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <Box width={'60%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Company Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Registration Number</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.registration_number}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Company Type</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.company_type?.name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Bussiness Type</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.company_category?.name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Registered On</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.established_year}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Annual Turnover</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.annual_turnover}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Trading Period</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.trading_period}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Website</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.website}</Typography>
                                        </Box>
                                    </Box>
                                    <Box width={'40%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Building No/ Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.address_1}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Street</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.address_2}</Typography>
                                        </Box>
                                        {
                                            (data?.address_3 && data?.address_3 != 0) && <Box mb={2}>
                                                <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Town</Typography>
                                                <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.address_3}</Typography>
                                            </Box>
                                        }
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Country</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.count?.name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>State/County</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.stat?.name}</Typography>
                                        </Box>
                                        {
                                            data?.cit && <Box mb={2}>
                                                <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>City</Typography>
                                                <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.cit?.name}</Typography>
                                            </Box>
                                        }
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Postcode</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.postcode}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>User Details</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <Box width={'60%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Title</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{profile?.name?.slice(0, profile?.name?.indexOf(' '))}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>First Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{profile?.first_name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Last Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{profile?.last_name}</Typography>
                                        </Box>

                                    </Box>
                                    <Box width={'40%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Mobile</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{`(+${profile?.phone_code})${profile?.mobile}`}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Email</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{profile?.email}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Job Title</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{profile?.job_title}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>Authorised Person Info</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <Box width={'60%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Title</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.company_contact_details?.title}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>First Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.company_contact_details?.first_name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Last Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.company_contact_details?.last_name}</Typography>
                                        </Box>
                                    </Box>
                                    <Box width={'40%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Mobile</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{`(+${data?.company_contact_details?.phone_code}) ${data?.company_contact_details?.mobile}`}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Email</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.company_contact_details?.email}</Typography>
                                        </Box>

                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Job Title</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.company_contact_details?.job_title}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>Company Bank Details</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Sr. No</Typography></TableCell>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>BANK NAME</Typography></TableCell>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>ACCOUNT NUMBER</Typography>	</TableCell>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>SORT CODE</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.company_bank_details?.map((row, index) => (
                                                    <TableRow
                                                        key={index + 1}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{index + 1}</Typography></TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row.bank_name}</Typography>
                                                        </TableCell>
                                                        <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row.account_number}</Typography></TableCell>
                                                        <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row.sort_code}</Typography></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            }
            {
                profile?.user_type == 'Funder' && <Grid container spacing={2} alignItems='stretch' sx={{ flexGrow: 1 }}>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>Funder Information</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <Box width={'60%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>User Name</Typography>
                                            <Typography textTransform={'none'} variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.username}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Title</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.title}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>First Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.first_name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Last Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.last_name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Email Address</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.email}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Mobile Number</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{`(+${data?.phone_code})${data?.mobile}`}</Typography>
                                        </Box>
                                    </Box>
                                    <Box width={'40%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Building No/Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.address_1}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Street</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.funder_basic_detail?.address_2}</Typography>
                                        </Box>
                                        {(data?.funder_basic_detail?.address_3 && data?.funder_basic_detail?.address_3 != 0) && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Town</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.address_3}</Typography>
                                        </Box>}
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Country</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.country?.name}</Typography>
                                        </Box>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>State/County</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.state?.name}</Typography>
                                        </Box>
                                        {data?.funder_basic_detail?.city && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>City</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.city?.name}</Typography>
                                        </Box>}
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Postcode</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.zipcode}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>Employer Details</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <Box width={'60%'}>
                                        <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Employement Status</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.employer_status}</Typography>
                                        </Box>
                                        {data?.funder_basic_detail?.employer_name && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Employer/Business Name</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.employer_name}</Typography>
                                        </Box>}
                                        {data?.funder_basic_detail?.job_title && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Job Title/Capacity</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.job_title}</Typography>
                                        </Box>}
                                        {data?.funder_basic_detail?.company_type?.name && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Business Type</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.company_type?.name}</Typography>
                                        </Box>}
                                    </Box>
                                    <Box width={'40%'}>
                                        {data?.funder_basic_detail?.business_address_1 && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Address</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{data?.funder_basic_detail?.business_address_1}</Typography>
                                        </Box>}
                                        {data?.funder_basic_detail?.business_mobile && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Mobile No</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.funder_basic_detail?.business_phone_code?`(+${data?.funder_basic_detail?.business_phone_code})${data?.funder_basic_detail?.business_mobile}`:data?.funder_basic_detail?.business_mobile}</Typography>
                                        </Box>}
                                        {data?.funder_basic_detail?.business_email && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Email</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.funder_basic_detail?.business_email}</Typography>
                                        </Box>}
                                        {data?.funder_basic_detail?.business_website && <Box mb={2}>
                                            <Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Website</Typography>
                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'} textTransform={'none'}>{data?.funder_basic_detail?.business_website}</Typography>
                                        </Box>}
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} mx={'auto'}>
                        <Paper style={{ height: '100%', boxShadow: '0px 0px 4px #cfcfcf' }}>
                            <Box sx={{ padding: '10px 10px', borderRadius: '3px' }}>
                                <Box sx={{ backgroundImage: 'linear-gradient(to right,#b92c82, #3a0ca3)', color: '#fff', padding: '10px', borderRadius: '3px' }}>Funder Bank Details</Box>
                                <Box sx={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', padding: '10px' }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>Sr. No</Typography></TableCell>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>BANK NAME</Typography></TableCell>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>ACCOUNT NUMBER</Typography>	</TableCell>
                                                    <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>ACCOUNT NAME</Typography></TableCell>
                                                    {
                                                        data?.funderbank_details?.[0]?.sort_code != '0' && < TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>SORT CODE</Typography></TableCell>
                                                    }
                                                    {
                                                        data?.funderbank_details?.[0]?.iban != '0' && <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>SWIFT CODE</Typography></TableCell>
                                                    }
                                                    {
                                                        data?.funderbank_details?.[0]?.swift_code != '0' && <TableCell align="left"><Typography variant='p' component={'p'} color={'grey'} fontSize={'15px'} textTransform={'none'} sx={{ verticalAlign: 'top' }}>IBAN</Typography></TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.funderbank_details?.map((row, index) => (
                                                    <TableRow
                                                        key={index + 1}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{index + 1}</Typography></TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row.bank_name}</Typography>
                                                        </TableCell>
                                                        <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row?.account_number}</Typography></TableCell>
                                                        <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row?.account_name}</Typography></TableCell>
                                                        {row.sort_code != 0 && <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row?.sort_code}</Typography></TableCell>}
                                                        {row?.swift_code != '0' && <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row?.swift_code}</Typography></TableCell>}
                                                        {row?.iban != '0' && <TableCell align="left"><Typography variant='h6' component={'h6'} fontSize={'15px'} fontWeight={'600'}>{row?.iban}</Typography></TableCell>}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid >
            }
        </>
    )
}

export default ViewProfile