import React, { useEffect, useState } from 'react'
import GlobalTable from '../../components/GlobalTable'
import { Box, Tab, Tabs, Typography, makeStyles } from '@mui/material'
import { companyFundingDetails } from '../../Redux/Slices/ShowCompanyFundingDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { companyFundingDetailsClosed } from '../../Redux/Slices/ShowCompanyMyFundingClosedSlice';
import Loader from '../../components/Loader';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MyFunding() {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch()
    const { companyMyFundingDetails, companyFundingDetailsLoader, companyOpenFundingCount } = useSelector((state) => state.myFunding)
    const { companyMyFundingDetailsClosed, companyFundingDetailsClosedLoader, companyClosedFundingCount } = useSelector((state) => state.myFundingClosed)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        dispatch(companyFundingDetails())
        dispatch(companyFundingDetailsClosed())
    }, [])
    return (
        !companyMyFundingDetails ? <Loader /> : <>
            <Typography variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                My Funding
            </Typography>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 0 }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                    }}>
                        <Tab label={`Open Auctions (${companyOpenFundingCount})`} {...a11yProps(0)} sx={{
                            borderRadius: '8px 8px 0px 0px',
                            backgroundColor: '#FBFBFB',
                            letterSpacing: 0.8,
                            color: '#000',
                            fontWeight: '600',
                            fontSize: '15px',
                            '&.Mui-selected': {
                                backgroundColor: '#B92C82',
                                color: '#fff',
                                fontWeight: '500',
                                letterSpacing: 0.4
                            }
                        }} />
                        <Tab label={`Completed Auctions (${companyClosedFundingCount})`} {...a11yProps(1)} sx={{
                            borderRadius: '8px 8px 0px 0px',
                            backgroundColor: '#FBFBFB',
                            letterSpacing: 0.8,
                            color: '#000',
                            fontWeight: '600',
                            fontSize: '15px',
                            '&.Mui-selected': {
                                backgroundColor: '#B92C82',
                                color: '#fff',
                                fontWeight: '500',
                                letterSpacing: 0.4
                            }
                        }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} >
                    <GlobalTable
                        auctionType={'open'}
                        count={companyOpenFundingCount}
                        rows={companyMyFundingDetails}
                        headCells={
                            [
                                {
                                    align: "left",
                                    label: "AUCTION NUMBER",
                                    fieldName: "auction_number",
                                },
                                {
                                    align: "left",
                                    label: "LOAN STATUS",
                                    fieldName: "loan_status_name",
                                    color: 'success'
                                },
                                {
                                    align: "left",
                                    label: "FUNDING AMOUNT",
                                    fieldName: "auction_amount",
                                    type: 'money',
                                    showValue: (val) => {
                                        return `£ ${Math.floor(Number(val)).toLocaleString('en-us')}`;
                                    }
                                },
                                {
                                    align: "left",
                                    label: "BID AMOUNT",
                                    fieldName: "fund_raise_amount",
                                    type: 'money',
                                    showValue: (val) => {
                                        return `£ ${Math.floor(Number(val)).toLocaleString('en-us')}`;
                                    }
                                },
                                {
                                    align: "left",
                                    label: "FUNDING SHARE",
                                    type: 'fundingShare',
                                    fieldName: 'auction_amount',
                                    secondFieldName: 'fund_raise_amount'
                                },
                                {
                                    align: "left",
                                    label: "ROI",
                                    type: 'money',
                                    fieldName: "fund_raise_roi",
                                    showValue: (val) => {
                                        return `£ ${Math.floor(Number(val)).toLocaleString('en-us')}`;
                                    }
                                },
                                {
                                    align: "left",
                                    label: "ROI",
                                    fieldName: "fund_raise_roi",
                                    subfieldName:"fund_raise_amount",
                                    type: 'roi_percent',
                                    // showValue: (val) => {
                                    //     return `${val} %`;
                                    // }
                                }
                            ]
                        }
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <GlobalTable
                        auctionType={'Closed'}
                        count={companyClosedFundingCount}
                        rows={companyMyFundingDetailsClosed}
                        headCells={
                            [
                                {
                                    align: "left",
                                    label: "AUCTION NUMBER",
                                    fieldName: "auction_number",
                                },
                                {
                                    align: "left",
                                    label: "LOAN STATUS",
                                    fieldName: "loan_status_name",
                                    color: 'success'
                                },
                                {
                                    align: "left",
                                    label: "FUNDING AMOUNT",
                                    fieldName: "auction_amount",
                                    type: 'money',
                                    showValue: (val) => {
                                        return Math.floor(Number(val));
                                    }
                                },
                                {
                                    align: "left",
                                    label: "BID AMOUNT",
                                    fieldName: "fund_raise_amount",
                                    type: 'money',
                                    showValue: (val) => {
                                        return Math.floor(Number(val));
                                    }
                                },
                                // {
                                //     align: "left",
                                //     label: "FUNDING SHARE",
                                //     fieldName: "maxRoi",
                                // },
                                {
                                    align: "left",
                                    label: "ROI",
                                    fieldName: "roi_amount",
                                    showValue: (val) => {
                                        return Math.floor(Number(val));
                                    }
                                },
                                {
                                    align: "left",
                                    label: "ROI",
                                    fieldName: "roi_percent",
                                }
                            ]
                        }
                    />
                </CustomTabPanel>
            </Box>
        </>
    )
}

export default MyFunding