import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material'
import React from 'react'

function ModalComp({ loading, closeButton, open, handleClose, message,subText, ButtonText, handleAction }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: 'transparent linear-gradient(90deg, #B92C82 0%, #3A0CA3 100%) 0% 0% no-repeat padding-box',
        boxShadow: 24,
        borderRadius: '8px',
        color: '#fff',
        p: 4,
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                    {message}
                </Typography>
                {
                    subText && <Typography id="modal-modal-description" sx={{ mt: 1, fontSize: '15px',color:'#c7c7c7' }}>
                        {subText}
                    </Typography>
                }
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        ButtonText && <Button onClick={handleAction} sx={{ mt: 2, mr: 1 }} variant="contained" color="error">
                            {loading ? <CircularProgress color='white' size={'25px'} /> : ButtonText}
                        </Button>
                    }
                    <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained" color="primary">
                        {
                            closeButton || 'Close'
                        }
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ModalComp