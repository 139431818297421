import React, { useEffect, useState } from 'react'
import GlobalTable from '../../components/GlobalTable'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { funderInvestmentDetails } from '../../Redux/Slices/ShowFunderInvestmentOpenSlice';
import { funderInvestmentClosedDetails } from '../../Redux/Slices/ShowFunderInvestmentClosedSlice';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MyInvestment() {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { funderMyInvestmentDetails, funderOpenInvestmentCount } = useSelector((state) => state.myInvestment)
  const { funderMyInvestmentDetailsClosed, funderClosedInvestmentCount } = useSelector((state) => state.myInvestmentClosed)
  const location = useLocation()
  useEffect(() => {
    dispatch(funderInvestmentDetails())
    dispatch(funderInvestmentClosedDetails())
  }, [])
  return (
    !funderMyInvestmentDetails ? <Loader /> : <>
      <Typography variant='h4' color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
        My Investment
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 0 }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}>
            <Tab label={`Open Auctions (${funderOpenInvestmentCount})`} {...a11yProps(0)} sx={{
              borderRadius: '8px 8px 0px 0px',
              backgroundColor: '#FBFBFB',
              letterSpacing: 0.8,
              color: '#000',
              fontWeight: '600',
              fontSize: '15px',
              '&.Mui-selected': {
                backgroundColor: '#B92C82',
                color: '#fff',
                fontWeight: '500',
                letterSpacing: 0.4
              }
            }} />
            <Tab label={`Completed Auctions (${funderClosedInvestmentCount})`} {...a11yProps(1)} sx={{
              borderRadius: '8px 8px 0px 0px',
              backgroundColor: '#FBFBFB',
              letterSpacing: 0.8,
              color: '#000',
              fontWeight: '600',
              fontSize: '15px',
              '&.Mui-selected': {
                backgroundColor: '#B92C82',
                color: '#fff',
                fontWeight: '500',
                letterSpacing: 0.4
              }
            }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} >
          <GlobalTable
            auctionType='open'
            count={funderOpenInvestmentCount}
            rows={funderMyInvestmentDetails}
            headCells={
              [
                {
                  align: "left",
                  label: "AUCTION NUMBER",
                  fieldName: "company_auction",
                  showValue: (val) => {
                    return val.auction_number;
                  },
                },
                {
                  align: "left",
                  label: "LOAN STATUS",
                  fieldName: "company_auction",
                  showValue: (val) => {
                    return val.loan_status_name;
                  },
                  color: 'success'
                },
                {
                  align: "left",
                  label: "Company",
                  type: 'company',
                  fieldName: "company_auction",
                  showValue: (val) => {
                    return val.companies.name;
                  },
                },
                {
                  align: "left",
                  label: "FUNDING AMOUNT",
                  fieldName: "company_auction",
                  type: 'money',
                  showValue: (val) => {
                    return `£ ${Math.round(Number(val.auction_amount)).toLocaleString('en-us')}`;
                  }
                },
                {
                  align: "left",
                  label: "BID AMOUNT",
                  fieldName: "offer_amount",
                  type: 'money',
                  showValue: (val) => {
                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                  }
                },
                {
                  align: "left",
                  label: "FUNDING SHARE",
                  type: 'money',
                  fieldName: "funding_share_percentage",
                  showValue: (val) => {
                    return `${Math.round(Number(val))} %`;
                  },
                },
                {
                  align: "left",
                  label: "ROI",
                  type: 'money',
                  fieldName: "offer_roi_amount",
                  showValue: (val) => {
                    return `£ ${Math.round(Number(val)).toLocaleString('en-us')}`;
                  },
                },
                {
                  align: "left",
                  label: "ROI",
                  type: 'money',
                  fieldName: "offer_roi_percentage",
                  showValue: (val) => {
                    return `${Math.round(Number(val))} %`;
                  },
                }
              ]
            }
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <GlobalTable
            auctionType='close'
            count={funderClosedInvestmentCount}
            rows={funderMyInvestmentDetailsClosed}
            headCells={
              [
                {
                  align: "left",
                  label: "AUCTION NUMBER",
                  fieldName: "company_auction",
                  showValue: (val) => {
                    return val.auction_number;
                  },
                },
                {
                  align: "left",
                  label: "LOAN STATUS",
                  fieldName: "company_auction",
                  showValue: (val) => {
                    return val.loan_status_name;
                  },
                  color: 'success'
                },
                {
                  align: "left",
                  label: "Company",
                  type: 'company',
                  fieldName: "company_auction",
                  showValue: (val) => {
                    return val.companies.name;
                  },
                },
                {
                  align: "left",
                  label: "FUNDING AMOUNT",
                  fieldName: "company_auction",
                  type: 'money',
                  showValue: (val) => {
                    return Math.round(Number(val.auction_amount));
                  }
                },
                {
                  align: "left",
                  label: "BID AMOUNT",
                  fieldName: "offer_amount",
                  type: 'money',
                  showValue: (val) => {
                    return Math.round(Number(val));
                  }
                },
                {
                  align: "left",
                  label: "FUNDING SHARE",
                  type: 'money',
                  fieldName: "funding_share_percentage",
                  showValue: (val) => {
                    return `${Math.round(Number(val))} %`;
                  },
                },
                {
                  align: "left",
                  label: "ROI",
                  type: 'money',
                  fieldName: "offer_roi_amount",
                  showValue: (val) => {
                    return Math.round(Number(val));
                  },
                },
                {
                  align: "left",
                  label: "ROI",
                  type: 'money',
                  fieldName: "offer_roi_percentage",
                  showValue: (val) => {
                    return `${Math.round(Number(val))} %`;
                  },
                }
              ]
            }
          />
        </CustomTabPanel>
      </Box>
    </>
  )
}

export default MyInvestment