import { Button, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetFundingPurpose } from '../../../Redux/Slices/GetFundingPurposeSlice'
import { addAuctionData } from '../../../Redux/Slices/CreateAuctionSlice'
import ErrorMessageComp from '../../../components/ErrorMessageComp'
import Loader from '../../../components/Loader'

function StepFive() {
    const auctionData = useSelector((state) => state.createAuction)
    console.log(auctionData,"DUDU STEP 5")

    const [clickedButton, setClickedButton] = useState()
    const [error, setError] = useState(false)
    const { getFundingPurpose } = useSelector((state) => state.fundingPurpose)
    const reasonList = getFundingPurpose
    const dispatch = useDispatch()
    const [checked, setChecked] = useState(auctionData?.data?.funding_purpose || [])
    const handleClick = (data) => {
        setClickedButton(data)
        if (data == 'ALL') {
            let newArray = []
            for (let i = 0; i < getFundingPurpose.length; i++) {
                newArray.push(getFundingPurpose[i].purpose)
            }
            setChecked(newArray)
        }
        if (data == 'RESET') {
            setChecked([])
        }
    }
    const handleChecked = (data) => {
        if (checked?.includes(data)) {
            let newarray = [...checked]
            newarray.splice(newarray.indexOf(data), 1)
            setChecked(newarray)
        } else {
            setChecked([...checked, data])
        }
    }
    useEffect(() => {
        dispatch(GetFundingPurpose())
    }, [])
    const onSubmit = () => {
        if (checked?.length > 0) {
            setError(false)
            dispatch(addAuctionData({ funding_purpose: checked }))
        } else {
            setError(true)
        }
    }
    return (
        getFundingPurpose ?
            <>
                <Typography component={'p'} variant='p' fontSize={'20px'} textTransform={'none'} color={'#B92C82'} fontWeight={'600'} textAlign={'center'} mb={2}>Tell potential funders why you require funding.</Typography>
                <Container maxWidth={'md'}>
                    <Grid className='btn-group' container spacing={2} style={{ marginTop: '32px' }}>
                        {
                            reasonList?.map((x, index) => {
                                return (
                                    <Grid key={x.purpose} item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <div class="select" style={{ cursor: 'pointer', height: '100%', border: '1px solid #6C6C6D', borderRadius: '8px' }}>
                                            <input onClick={() => handleChecked(x.purpose)} checked={checked?.includes(x.purpose) ? true : false} type="checkbox" id={x.purpose} style={{ padding: '10px' }} />
                                            <label class="btn btn-warning button_select" for={x.purpose} style={{ margin: '0px auto', padding: '10px', cursor: 'pointer' }}><Typography variant='p' component={'p'} fontSize={'15px'} textTransform={'none'} style={{ width: "100%", textAlign: 'center' }}>{x.purpose}</Typography></label>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid width='350px' container margin={'auto'} spacing={2}>
                        <Grid item xs={6} mt={4} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                            <Button onClick={() => handleClick('ALL')} className={`selectionButton ${clickedButton === 'ALL' ? 'activeSelectionButton' : ''}`} variant='outlined' style={{ borderRadius: '20px', padding: "5px 25px", width: "150px" }}>ALL</Button>
                        </Grid>
                        <Grid item xs={6} mt={4} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <Button onClick={() => handleClick('RESET')} className={`selectionButton ${clickedButton === 'RESET' ? 'activeSelectionButton' : ''}`} variant='outlined' style={{ borderRadius: '20px', padding: "5px 25px", width: "150px" }}>RESET</Button>
                        </Grid>
                    </Grid>
                    <Grid container mt={2}>
                        {
                            error && <ErrorMessageComp width={'100%'} textAlign={'center'} message={'You have to choose atleast one funding purpose'} />
                        }
                    </Grid>
                    <Stack justifyContent="center" direction="row" margin={10}>
                        <Button variant="contained" sx={{
                            padding: '10px 60px',
                            backgroundColor: '#B92C82',
                            textTransform: 'Uppercase'
                        }}
                            onClick={() => onSubmit()}>APPLY</Button>
                    </Stack>
                </Container >
            </> : <Loader />
    )
}

export default StepFive