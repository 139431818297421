import MinimalLayout from "../layout/MinimalLayout"
import Disclaimer from "../pages/Disclaimer"
import Faq from "../pages/Faq"
import HomePage from "../pages/Home"


const MainRoute = {
    path: '',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            children: [
                {
                    path: '',
                    element: <HomePage />
                }
            ]
        },
        {
            path: '/faq',
            children: [
                {
                    path: '',
                    element: <Faq />
                }
            ]
        },
        {
            path: '/disclaimer',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/termsOfService',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/privacyPolicy',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/contactUs',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/releaseMyCash',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/investInInvoices',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/fundYourBuiseness',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
        {
            path: '/beAFunder',
            children: [
                {
                    path: '',
                    element: <Disclaimer />
                }
            ]
        },
    ]
}

export default MainRoute
