import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    employment: null,
    employmentStatus:'',
    employmentLoader:false,
    employmentError:null
};
export const Employment = createAsyncThunk("/Employment", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getEmplyoment`,
        method: "get",
    });
    return response;
});
const employmentSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(Employment.pending, (state) => {
                state.employmentStatus = 'LOADING'
                state.employmentLoader = true
                state.employmentError = null
            })
            .addCase(Employment.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.employmentStatus = "SUCCESS"
                    state.employment = action?.payload?.data
                }
                else {
                    state.employmentStatus = "ERROR"
                    state.employmentError = [action?.payload?.errors?.[0]]
                }
                state.employmentLoader = false
            })
            .addCase(Employment.rejected, (state, action) => {
                state.employmentStatus = 'ERROR'
                state.employmentLoader = false
            })
    }
})

export const { resetState } = employmentSlice.actions;
export default employmentSlice.reducer;