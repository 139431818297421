import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    allClosedAuction: [],
    allClosedAuctionStatus: '',
    allClosedAuctionLoader: false,
    allClosedAuctionError: null,
    totalCountClosed: ''
};
export const AllClosedAuction = createAsyncThunk("/AllClosedAuction", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/market/getAllAuctionClose?offset=${data?.page}&limit=${data?.limit}&sortby=${data?.sortBy}&auction_type=${data?.filterBy}`,
        method: "get",
    });
    return response;
});
const allClosedAuctionSlice = createSlice({
    name: 'AllClosedAuction',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(AllClosedAuction.pending, (state) => {
                state.allClosedAuctionStatus = 'LOADING'
                state.allClosedAuctionLoader = true
                state.allClosedAuctionError = null
            })
            .addCase(AllClosedAuction.fulfilled, (state, action) => {
                console.log('action?.payload?.data', action?.payload?.data)
                if (action?.payload?.status) {
                    state.allClosedAuctionStatus = "SUCCESS"
                    state.allClosedAuction = action?.payload?.data.market
                    state.totalCountClosed = action?.payload?.data?.links?.totalRecord
                }
                else {
                    state.allClosedAuction = []
                    state.totalCountClosed = 0
                    state.allClosedAuctionStatus = "ERROR"
                    state.allClosedAuctionError = [action?.payload?.errors?.[0]]
                }
                state.allClosedAuctionLoader = false
            })
            .addCase(AllClosedAuction.rejected, (state, action) => {
                state.allClosedAuctionStatus = 'ERROR'
                state.allClosedAuctionLoader = false
            })
    }
})

export const { resetState } = allClosedAuctionSlice.actions;
export default allClosedAuctionSlice.reducer;