import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    getWalletText: null,
    getWalletTextStatus:'',
    getWalletTextLoader:false,
    getWalletTextError:null
};
export const GetWalletText = createAsyncThunk("/GetWalletText", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getWalletText`,
        method: "get",
    });
    return response;
});
const getWalletTextSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetWalletText.pending, (state) => {
                state.getWalletTextStatus = 'LOADING'
                state.getWalletTextLoader = true
                state.getWalletTextError = null
            })
            .addCase(GetWalletText.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.getWalletTextStatus = "SUCCESS"
                    state.getWalletText = action.payload?.data?.wallet_text
                }
                else {
                    state.getWalletTextStatus = "ERROR"
                    state.getWalletTextError = [action?.payload?.errors?.[0]]
                }
                state.getWalletTextLoader = false
            })
            .addCase(GetWalletText.rejected, (state, action) => {
                state.getWalletTextStatus = 'ERROR'
                state.getWalletTextLoader = false
            })
    }
})

export const { resetState } = getWalletTextSlice.actions;
export default getWalletTextSlice.reducer;