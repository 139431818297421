import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GlobalTable from '../../components/GlobalTable'
import UpperLayout from '../../components/UpperLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetLeaderBoardDetails, resetStateLeaderBoardDetails } from '../../Redux/Slices/GetLeaderBoardDetailsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { getAuctionDetails, resetStateAuctionDetails } from '../../Redux/Slices/GetAuctionDetailsSlice'

function LeaderBoard() {
    const role = localStorage.getItem('role')
    const bidId = localStorage.getItem('bidid')
    const navigate = useNavigate()
    const location = useLocation()
    const auction_id = location?.search?.slice(1, location?.search?.length)
    const dispatch = useDispatch()
    const { auctionDetails } = useSelector((state) => state.getAuctionDetails)
    const { getLeaderBoardDetails, getLeaderBoardDetailsLoader } = useSelector((state) => state.getLeaderBoardDetails)
    const auctionEndTime = new Date(auctionDetails?.auction_end_date).getTime()
    const CurrentTime = new Date().getTime()
    let hasBid = false
    let myBidId = false
    getLeaderBoardDetails?.company_bidding_by_rank?.map((x) => {
        if (x?.user_id == bidId) {
            hasBid = true
            myBidId = x?.id
        }
    })
    const data = [
        {
            heading: 'Auction Type',
            data: auctionDetails?.auction_type_name
        },
        {
            heading: 'Funding Target',
            data: `£${Number(auctionDetails?.auction_amount)?.toLocaleString('en-us')}`
        },
        {
            heading: 'Funding achieved against target',
            value: Math.round(auctionDetails?.totalBidAmount / auctionDetails?.auction_amount * 100),
            type: 'linearProgress',
            data: (auctionDetails?.totalBidAmount ? Math.round(auctionDetails?.totalBidAmount) : Math.round(auctionDetails?.totalBidAmount)),
            upperLabel: auctionDetails?.auction_amount,
            bidAmount: 'totalBidAmount',
        },
        {
            heading: 'Total Bids',
            data: String(auctionDetails?.totalBidCount).padStart(2, '0')
        },
        {
            heading: 'Total Funded',
            data: `£${Number(auctionDetails?.totalBidAmount)?.toLocaleString('en-us')}`
        },
        {
            heading: 'Overall ROI',
            data: `£ ${Number(getLeaderBoardDetails?.overallRoiAmount)?.toLocaleString('en-us')} / ${Math.floor(Number(getLeaderBoardDetails?.overallRoiPercentage))}%`
        }
    ]
    let leaderBoardData = []
    let FinalleaderBoardData = []
    let unFullfilledLeaderBoardData = []
    let FinalunFullfilledLeaderBoardData = []
    let sum = 0
    for (let i = 0; i < getLeaderBoardDetails?.company_bidding_by_rank?.length; i++) {
        let x = getLeaderBoardDetails?.company_bidding_by_rank[i]
        if (x?.is_split == 0) {
            sum += Number(getLeaderBoardDetails?.company_bidding_by_rank[i]?.offer_amount)
        } else {
            sum += Number(getLeaderBoardDetails?.company_bidding_by_rank[i]?.funding_share_amount)
        }
        if (Number(auctionDetails?.auction_amount) >= sum) {
            if (x?.is_split == 1) {
                unFullfilledLeaderBoardData?.push(x)
            }
            leaderBoardData?.push(x)
        } else {
            unFullfilledLeaderBoardData?.push(x)
        }
    }
    FinalleaderBoardData = leaderBoardData?.map((x) => {
        return (
            {
                rank: x?.rank_leader_board,
                name: x?.users?.first_name,
                userId: x?.user_id,
                bidAmount: x?.is_split == 0 ? x?.offer_amount : x?.funding_share_amount,
                fundingShare: x?.is_split == 0 ? x?.return_amount > 0 ? x?.return_share_percentage : x?.funding_share_percentage : x?.funding_share_percentage,
                roi: Math.round(Number(x?.funding_roi_amount)),
                roi_percent: x?.offer_roi_percentage,
                isSplit: x?.is_split,
                status: x?.status
            }
        )
    })
    FinalunFullfilledLeaderBoardData = unFullfilledLeaderBoardData?.map((x) => {
        return (
            {
                rank: x?.rank_leader_board,
                name: x?.users?.first_name,
                userId: x?.user_id,
                bidAmount: x?.is_split == 0 ? x?.offer_amount : x?.return_amount,
                fundingShare: x?.is_split == 0 ? x?.return_amount > 0 ? x?.return_share_percentage : x?.funding_share_percentage : Math.round(Number(x?.return_share_percentage)),
                roi: x?.is_split == 0 ? Math.round(Number(x?.offer_roi_amount)) : Math.round(Number(x?.return_roi_amount)),
                roi_percent: x?.offer_roi_percentage,
                isSplit: x?.is_split,
                isFullfilled: 0
            }
        )
    })
    useEffect(() => {
        dispatch(getAuctionDetails(auction_id))
        dispatch(GetLeaderBoardDetails({ auction_id: auction_id, role: role == 'Company' ? 'company' : 'funder' }))
    }, [])
    useEffect(() => {
        dispatch(resetStateLeaderBoardDetails())
        dispatch(resetStateAuctionDetails())
    }, [dispatch])
    console.log(getLeaderBoardDetails, (!auctionDetails || !getLeaderBoardDetails), 'DETAILS')
    return (
        (!auctionDetails || !getLeaderBoardDetails) ? <Loader /> : <>
            <Typography variant='h4' textTransform={'none'} color='#272D3B' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                {getLeaderBoardDetails?.companies?.name}
            </Typography>
            <UpperLayout data={data} />
            <Box sx={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between" }}>
                <Typography variant='h6' color='#B92C82' fontWeight={'600'} mb={3} sx={{ letterSpacing: "0.7px" }}>
                    Bidders
                </Typography>
                {
                    role == 'Funder' && <Button variant="contained" sx={{
                        height: "40px",
                        fontSize: '15px',
                        padding: '5px 10px',
                        backgroundColor: '#B92C82',
                        textTransform: 'Uppercase'
                    }}
                        disabled={auctionEndTime < CurrentTime}
                        onClick={() => hasBid ? navigate(`/editBid?${auction_id}`, { state: { auction_amount: Math.round(auctionDetails?.auction_amount), bidId: myBidId, max_roi: Math.round(auctionDetails?.roi_percent) } }) : navigate(`/enterBid?${auction_id}`, { state: { auction_amount: Math.round(auctionDetails?.auction_amount), max_roi: Math.round(auctionDetails?.roi_percent) } })}>
                        {hasBid ? "Edit Bid" : 'Bid now'}
                    </Button>
                }
            </Box>
            <Box sx={{ background: "#f1f1f1" }} mb={2} mt={2} p={1}>
                <Typography sx={{ fontSize: '15px', fontWeight: "600", color: "#9f9d9d", letterSpacing: "1px" }}>Fulfilled Bids</Typography>
            </Box>
            {
                FinalleaderBoardData && FinalleaderBoardData?.length < 0 ? <Loader /> : <GlobalTable
                    count={FinalleaderBoardData?.length}
                    rows={FinalleaderBoardData}
                    headCells={[
                        {
                            align: "left",
                            label: "RANK",
                            fieldName: "rank",
                            showValue: (val) => {
                                return `${val}`;
                            },
                        },
                        {
                            align: "left",
                            label: "NAME",
                            fieldName: "name",
                            subFieldName: "userId",
                            type: 'passwordLeaderBoard',
                        },
                        {
                            align: "left",
                            label: "BID AMOUNT",
                            fieldName: "bidAmount",
                            showValue: (val) => {
                                return `£ ${Number(val).toLocaleString('en-us')}`;
                            },
                        },
                        {
                            align: "left",
                            label: "FUNDING SHARE",
                            fieldName: "fundingShare",
                            showValue: (val) => {
                                return `${Number(val).toFixed(0)} %`;
                            },
                        },
                        {
                            align: "left",
                            label: "ROI",
                            fieldName: "roi",
                            showValue: (val) => {
                                return `£ ${Number(val)?.toLocaleString('en-us')}`;
                            },
                        },
                        {
                            align: "left",
                            label: "ROI",
                            fieldName: "roi_percent",
                            showValue: (val) => {
                                return `${val} %`;
                            },
                        }
                    ]}
                />
            }
            <Box sx={{ background: "#f1f1f1" }} mb={2} mt={2} p={1}>
                <Typography sx={{ fontSize: '15px', fontWeight: "600", color: "#9f9d9d", letterSpacing: "1px" }}>Unfulfilled Bids</Typography>
            </Box>
            {
                FinalunFullfilledLeaderBoardData && FinalunFullfilledLeaderBoardData?.length < 0 ? <Loader /> : <GlobalTable
                    count={FinalunFullfilledLeaderBoardData?.length}
                    rows={FinalunFullfilledLeaderBoardData}
                    headCells={[
                        {
                            align: "left",
                            label: "RANK",
                            fieldName: "rank",
                            showValue: (val) => {
                                return `${val}`;
                            },
                        },
                        {
                            align: "left",
                            label: "NAME",
                            fieldName: "name",
                            subFieldName: "userId",
                            type: 'passwordLeaderBoard',
                        },
                        {
                            align: "left",
                            label: "BID AMOUNT",
                            fieldName: "bidAmount",
                            showValue: (val) => {
                                return `£ ${Number(val).toLocaleString('en-us')}`;
                            },
                        },
                        {
                            align: "left",
                            label: "FUNDING SHARE",
                            fieldName: "fundingShare",
                            showValue: (val) => {
                                return `${Number(val).toFixed(0)} %`;
                            },
                        },
                        {
                            align: "left",
                            label: "ROI",
                            fieldName: "roi",
                            showValue: (val) => {
                                return `£ ${Number(val)?.toLocaleString('en-us')}`;
                            },
                        },
                        {
                            align: "left",
                            label: "ROI",
                            fieldName: "roi_percent",
                            showValue: (val) => {
                                return `${val} %`;
                            },
                        }
                    ]}
                />
            }
        </>
    )
}

export default LeaderBoard