import { Box, Card, CardContent, Typography } from "@mui/material"


const RoundedCardImgAndText = ({ image, imagealt, text }) => {
    return (
        <Card sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '65px',
            padding: { md: '25px 00px', xs: '10px 0px' },
            marginBottom: 3,
            width: { lg: '40%', xs: '100%' },
            boxShadow: '0px 3px 6px #0000001A'
        }}>
            <Box sx={{
                width: { lg: '40px', xs: '30px' },
                height: { lg: '40px', xs: '30px' },
                marginRight: 2,
                alignItems: "center",
                borderRadius: '50%',
            }}>
                <img src={image} alt={imagealt} width={'100%'} />
            </Box>
            <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }}>
                <Typography textTransform={'none'} variant="h5" fontWeight={'600'}
                    sx={{
                        color: 'var(--unnamed-color-0b2244)',
                    }}
                >
                    {text}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default RoundedCardImgAndText