import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    funderDashboardDetails: null,
    funderDashboardDetailsStatus:'',
    funderDashboardDetailsLoader:false,
    funderDashboardDetailsError:null
};
export const getFunderDashboardDetails = createAsyncThunk("/getFunderDashboardDetails", async (id) => {
    const response = await ApihelperFunction({
        urlPath: `api/funder/dashboard`,
        method: "get",
    });
    return response;
});
const getFunderDashboardDetailsSlice = createSlice({
    name: 'FeeDetails',
    initialState,
    reducers: {
        resetFunderDashboardDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFunderDashboardDetails.pending, (state) => {
                state.funderDashboardDetailsStatus = 'LOADING'
                state.funderDashboardDetailsLoader = true
                state.funderDashboardDetailsError = null
            })
            .addCase(getFunderDashboardDetails.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.funderDashboardDetailsStatus = "SUCCESS"
                    state.funderDashboardDetails = action?.payload?.data
                }
                else {
                    state.funderDashboardDetailsStatus = "ERROR"
                    state.funderDashboardDetailsError = [action?.payload?.errors?.[0]]
                }
                state.funderDashboardDetailsLoader = false
            })
            .addCase(getFunderDashboardDetails.rejected, (state, action) => {
                state.funderDashboardDetailsStatus = 'ERROR'
                state.funderDashboardDetailsLoader = false
            })
    }
})

export const { resetFunderDashboardDetails } = getFunderDashboardDetailsSlice.actions;
export default getFunderDashboardDetailsSlice.reducer;