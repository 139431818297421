import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    getFunderBankDetails: [],
    getFunderBankDetailsStatus:'',
    getFunderBankDetailsLoader:false,
    getFunderBankDetailsError:null
};
export const GetFunderBankDetails = createAsyncThunk("/GetFunderBankDetails", async (data) => {
    console.log('function called')
    const response = await ApihelperFunction({
        urlPath: `api/getFunderBankDetails`,
        method: "get",
    });
    return response;
});
const getFunderBankDetailsSlice = createSlice({
    name: 'CompanyBankDetails',
    initialState,
    reducers: {
        resetStateCompanyBankDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFunderBankDetails.pending, (state) => {
                state.getFunderBankDetailsStatus = 'LOADING'
                state.getFunderBankDetailsLoader = true
                state.getFunderBankDetailsError = null
            })
            .addCase(GetFunderBankDetails.fulfilled, (state, action) => {
                console.log('action?.payload?.data',action?.payload?.data)
                if (action?.payload?.status) {
                    state.getFunderBankDetailsStatus = "SUCCESS"
                    state.getFunderBankDetails = action?.payload?.data
                }
                else {
                    state.getFunderBankDetailsStatus = "ERROR"
                    state.getFunderBankDetailsError = [action?.payload?.errors?.[0]]
                }
                state.getFunderBankDetailsLoader = false
            })
            .addCase(GetFunderBankDetails.rejected, (state, action) => {
                state.getFunderBankDetailsStatus = 'ERROR'
                state.getFunderBankDetailsLoader = false
            })
    }
})

export const { resetState } = getFunderBankDetailsSlice.actions;
export default getFunderBankDetailsSlice.reducer;