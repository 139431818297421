import { createTheme } from "@mui/material";

const primary = '#0A1D56'
const theme = createTheme({
    palette: {
        primary: {
            light: "#3f4483",
            main: primary,
            dark: "#00002d",
            contrastText: "#fff",
        },
        secondary: {
            light: "#8188ff",
            main: "#626BFF",
            dark: "#212bdb",
            contrastText: "#fff",
        },
        white: {
            light: "#fff",
            main: "#fff",
            dark: "#fff",
            contrastText: "#fff",
        },
        gray: {
            light: "#F5F5F5",
            main: "#777",
            dark: "#333",
            contrastText: "#7f7f7f",
        },
        error: {
            light: "#E10000",
            main: "#E10000",
            dark: "#E10000",
            contrastText: "#fff",
        },
        blue:{
            main:"#3A0CA3"
        },
        white:{
            main:"#fff"
        }
    },
    typography: {
        fontSize: 16,
        fontStyle: "400",
        fontDisplay: "block",
        h1: {
            fontFamily: 'CustomFont',
            fontSize: '2rem',
            fontWeight: 500,
            color: '#F9FAFC',
            textTransform: "capitalize",
            marginBlockEnd: "0.9375rem",
            lineHeight: "1.4",
            letterSpacing: '0.2px',
            '@media (max-width:900px)': {
                fontSize: '1.9375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '1.36rem',
            },
        },
        h2: {
            fontSize: '1.35rem',
            color: '#0B2244',
            // textTransform: "capitalize",
            '@media (max-width:900px)': {
                fontSize: '1.375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '1.25rem',
            },
        },
        h4: {
            fontSize: '1.5625rem',
            fontWeight: 600,
            textTransform: "capitalize",
            '@media (max-width:900px)': {
                fontSize: '1.375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '1.18rem',
            },
        },
        h5: {
            fontSize: '1.1875rem',
            color: '#8b8b8c',
            fontWeight: 500,
            textTransform: "capitalize",
            '@media (max-width:900px)': {
                fontSize: '1.0625rem',
            },
            '@media (max-width:600px)': {
                fontSize: '0.9375rem',
            },
        },
        h6: {
            fontSize: '1.0625rem',
            fontWeight: 500,
            textTransform: "capitalize",
            lineHeight: 'inherit',
            '@media (max-width:900px)': {
                fontSize: '0.9375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '0.9375rem',
            },
        },
        caption: {
            fontFamily: 'CustomFont',
            fontSize: '1.0625rem',
            fontWeight: 400,
            textTransform: "capitalize",
            '@media (max-width:900px)': {
                fontSize: '0.9375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '0.6375rem',
            },
        },
        nav: {
            fontSize: '0.9375rem',
            fontWeight: 500,
            textTransform: "capitalize",
            '@media (max-width:900px)': {
                fontSize: '0.9375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '0.8125rem',
            },
        },
        p: {
            fontSize: '0.9375rem',
            color: '#848485',
            fontWeight: 500,
            textTransform: "capitalize",
            lineHeight: 'inherit',
            '@media (max-width:900px)': {
                fontSize: '0.8125rem',
            },
            '@media (max-width:600px)': {
                fontSize: '0.8125rem',
            },
        },
        overline: {
            fontSize: '0.8125rem',
            color: '#fff',
            lineHeight: 'inherit',
            letterSpacing: '0.6px',
            textTransform: "uppercase",
        },
        body2: {
            fontWeight: '700',
            fontSize: '2.187rem',
            '@media (max-width:900px)': {
                fontSize: '1.9375rem',
            },
            '@media (max-width:600px)': {
                fontSize: '1.56rem',
            },
        },
        button: {
            fontSize: '1.05rem',
            letterSpacing: '1px',
            textTransform: "capitalize",
            '@media (max-width:900px)': {
                fontSize: '0.875rem',
            },
            '@media (max-width:600px)': {
                fontSize: '0.775rem',
            },
        },
    },
    tooltip: {
        backgroundColor: "#333",
        color: "#fff",
        fontSize: 11,
    },
    tab: {
        fontWeight: 'bold', // Customize the font weight of the tabs
        textTransform: 'none', // Customize the text transform of the tabs
        '&.Mui-selected': {
            backgroundColor: 'lightblue', // Customize the background color of the selected tab
            color: 'red', // Customize the text color of the selected tab
        },
    },
});


export default theme;
