import React, { useEffect } from 'react'
import { Grid, Card, CardContent, Typography, Box, Tooltip } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Bar, BarChart, XAxis, Legend, YAxis } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyDashboardDetails, resetCompanyDashboardDetails } from '../../Redux/Slices/GetDashboardSlice';
import { getFunderDashboardDetails, resetFunderDashboardDetails } from '../../Redux/Slices/GetDashboardFunderDetailsSlice';
import Loader from '../../components/Loader';


function DashboardPageOne() {
  const role = localStorage.getItem('role')
  const userName = localStorage.getItem('userName')
  const userStatus = localStorage.getItem('userStatus')
  const dispatch = useDispatch()
  const { companyDashboardDetails } = useSelector((state) => state.companyDashboard)
  const { funderDashboardDetails } = useSelector((state) => state.funderDashboard)
  // const {userStatus } = useSelector(state => state.login)
  const { profile } = useSelector((state) => state.profile)
  const getCreditRating = (rating) => {
    if (rating > 80 && rating <= 100) {
      return 'Low Risk'
    } else if (rating > 60 && rating <= 80) {
      return 'Average Risk'
    } else if (rating > 40 && rating <= 59) {
      return 'Above Average Risk'
    } else {
      return 'High Risk'
    }
  }
  const getCreditRatingColor = (rating) => {
    if (rating > 80 && rating <= 100) {
      return '#83f65d'
    } else if (rating > 60 && rating <= 80) {
      return '#ff9050'
    } else if (rating > 40 && rating <= 59) {
      return '#f75b00'
    } else {
      return '#f70000'
    }
  }
  const data = [
    { name: 'Total funding target', value: Math.round((Number(companyDashboardDetails?.total_funding_target) - Number(companyDashboardDetails?.total_funding_received))) },
    { name: 'Total funding received', value: Math.round((Number(companyDashboardDetails?.total_funding_received))) },
  ];
  const dummyData = [
    { name: 'Total funding target', value: 0 },
    { name: 'Total funding received', value: 100 },
  ];
  const dummyFunderData = [
    { name: 'Remaining amount', value: 0 },
    { name: 'Return amount', value: 100 },
  ];
  const funderData = [
    { name: 'Remaining amount', value: Math.round(Number(funderDashboardDetails?.total_funded) - Number(funderDashboardDetails?.total_return)) },
    { name: 'Return amount', value: Math.round(Number(funderDashboardDetails?.total_return)) },
  ];
  const ROIData = [
    { name: 'Avg Max ROI Limit', value: Number(companyDashboardDetails?.avg_max_roi_limit) > 0 ? Number(companyDashboardDetails?.avg_max_roi_limit).toFixed(2) : 0 },
    { name: 'Avg ROI Given', value: Number(companyDashboardDetails?.avg_roi_given) > 0 ? Number(companyDashboardDetails?.avg_roi_given).toFixed(2) : 0 },
  ];
  const auctionData = [
    { name: 'Live auction', value: Math.round(Number(companyDashboardDetails?.live_auction)) },
    { name: 'Total auction', value: Math.round(Number(companyDashboardDetails?.total_auction)) },
  ];
  const BidData = [
    { name: 'Total Bids', value: Math.round(Number(funderDashboardDetails?.total_bids)) },
    { name: 'Successful Bids', value: funderDashboardDetails?.successful_bids },
  ];

  const COLORS = ['#FF33CC', '#7030A0'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text x={x} y={y} fill="#000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`£ ${Number(value).toLocaleString('en-us')}`}
        </text>
      </>
    );
  };
  useEffect(() => {
    role == 'Company' ? dispatch(getCompanyDashboardDetails()) : dispatch(getFunderDashboardDetails())
  }, [])
  useEffect(()=>{
    role=='Company'?dispatch(resetCompanyDashboardDetails()):dispatch(resetFunderDashboardDetails())
  },[dispatch])
  console.log(userStatus,'STATUS')
  return (
    (userStatus==0||userStatus==5)?<Typography variant='h2' textAlign={'center'} color={'#000'}>Your Account Is Not Activated Yet</Typography>:
    (role == 'Company' ? !companyDashboardDetails : !funderDashboardDetails) ? <Loader /> : <>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ background: 'transparent linear-gradient(106deg, #ED4886 0%, #B855A4 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                {role == 'Company' ? 'My credit limit' : 'My wallet balance'}
              </Typography>
              <Typography variant="h4" color="#fff" mt={3} fontWeight={700} sx={{ letterSpacing: '1px' }}>
                {role == 'Company' ? `£ ${Number(companyDashboardDetails?.my_credit_limit).toLocaleString('en-us')}` : `£ ${Number(funderDashboardDetails?.my_wallet_balance) || 0}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ background: 'transparent linear-gradient(106deg, #875EC0 0%, #5446BA 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardContent>
              <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                {role == 'Company' ? 'My credit rating' : 'Total auctions bid'}
              </Typography>
              <Typography variant="h4" fontSize={role == 'Company' ? '20px' : '1.5625rem'} fontWeight={700} color={role == 'Company' ? '#fff' : '#fff'} mt={3}>
                {role == 'Company' ? companyDashboardDetails?.my_credit_rating : Math.round(Number(funderDashboardDetails?.total_auction_bid))}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {role == 'Company' &&
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ background: 'transparent linear-gradient(106deg, #46C5F3 0%, #6592DA 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <CardContent>
                <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none', lineHeight: 'inherit' }}>
                  Repayments overdue <span style={{ fontSize: '27px', fontWeight: 'bold', marginLeft: '10px' }}>{companyDashboardDetails?.repayments_overdue}</span>
                </Typography>
                <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none', lineHeight: 'inherit', display: 'block' }}>
                  Repayments due in next 7 days <span style={{ fontSize: '27px', fontWeight: 'bold', marginLeft: '10px' }}>{companyDashboardDetails?.repayments_due_7_days}</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>}
        {role == 'Funder' &&
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ background: 'transparent linear-gradient(106deg, #46C5F3 0%, #6592DA 100%) 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardContent>
                <Typography variant="overline" component="overline" color='#fff' sx={{ textTransform: 'none' }}>
                  Live auctions bid
                </Typography>
                <Typography variant="h4" fontWeight={700} color='#fff' mt={3}>
                  {funderDashboardDetails?.live_auction_bid}
                </Typography>
              </CardContent>
            </Card>
          </Grid>}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={role == 'Company' ? 4 : 7}>
          <Card>
            <CardContent>
              <Typography variant="h5" color="#B92C82" fontWeight={600}>
                {role=='Company'?userName:profile?.Funder?.name}
              </Typography>
              {role == 'Company' && !(Number(companyDashboardDetails?.total_funding_target) == 0 && Number(companyDashboardDetails?.total_funding_received) == 0) && <Card sx={{ display: 'flex', flexDirection: "column", height: '100%', boxShadow: 'none' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', padding: '0px', paddingTop: '25px' }}>
                    <Typography variant="overline" color='#000' sx={{ textTransform: 'none', textWrap: 'nowrap' }}>
                      {'Total funded'}
                    </Typography>
                    <Typography fontSize={'18px'} variant="h5" color='#000' mt={1} fontWeight={700}>
                      {role == 'Company' && `£ ${Math.round(Number(companyDashboardDetails?.total_funding_received)).toLocaleString('en-us')}`}
                    </Typography>
                  </CardContent>
                </Box>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={30}
                      outerRadius={70}
                      label={renderCustomizedLabel}
                      labelLine={false}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Card>}
              {role == 'Company' && Number(companyDashboardDetails?.total_funding_target) == 0 && Number(companyDashboardDetails?.total_funding_received) == 0 && <Card sx={{ display: 'flex', flexDirection: "column", height: '100%', boxShadow: 'none' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', padding: '0px', paddingTop: '25px' }}>
                    <Typography variant="overline" color='#000' sx={{ textTransform: 'none', textWrap: 'nowrap' }}>
                      {'Total funded'}
                    </Typography>
                    <Typography fontSize={'18px'} variant="h5" color='#000' mt={1} fontWeight={700}>
                      {role == 'Company' && `£ ${Math.round(Number(companyDashboardDetails?.total_funding_received)).toLocaleString('en-us')}`}
                    </Typography>
                  </CardContent>
                </Box>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={dummyData}
                      cx="50%"
                      cy="50%"
                      innerRadius={30}
                      outerRadius={70}
                    // label
                    // labelLine={true}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Card>}
              {role == 'Funder' && !(funderDashboardDetails?.total_funded == 0 && funderDashboardDetails?.total_return == 0) && <Card sx={{ display: 'flex', height: '100%', boxShadow: 'none' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                    <Typography variant="overline" color='#000' sx={{ textTransform: 'none', textWrap: 'nowrap' }}>
                      {'Total return'}
                    </Typography>
                    <Typography fontSize={'18px'} variant="h5" color='#000' mt={1} fontWeight={700}>
                      {`${Math.round((funderDashboardDetails?.total_return / (Number(funderDashboardDetails?.total_funded))) * 100) || 0}% / £ ${Math.round(Number(funderDashboardDetails?.total_return)).toLocaleString('en-us')}`}
                    </Typography>
                  </CardContent>
                </Box>
                <ResponsiveContainer width="70%" height={300}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={funderData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={100}
                      label
                      labelLine={true}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Card>}
              {role == 'Funder' && (funderDashboardDetails?.total_funded == 0 && funderDashboardDetails?.total_return == 0) && <Card sx={{ display: 'flex', height: '100%', boxShadow: 'none' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                    <Typography variant="overline" color='#000' sx={{ textTransform: 'none', textWrap: 'nowrap' }}>
                      {'Total return'}
                    </Typography>
                    <Typography fontSize={'18px'} variant="h5" color='#000' mt={1} fontWeight={700}>
                      {`${Math.round((funderDashboardDetails?.total_return / (Number(funderDashboardDetails?.total_funded))) * 100) || 0}% / £ ${Math.round(Number(funderDashboardDetails?.total_return)).toLocaleString('en-us')}`}
                    </Typography>
                  </CardContent>
                </Box>
                <ResponsiveContainer width="70%" height={300}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={dummyFunderData}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={100}
                    // label
                    // labelLine={true}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Card>}
            </CardContent>
          </Card>
        </Grid>
        {role == 'Company' &&
          <>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Card>
                <Card>
                  <CardContent>
                    <Typography variant="h5" color="#B92C82" fontWeight={600} marginLeft={'15px'}>
                      ROI Limit
                    </Typography>
                    <ResponsiveContainer height={400}>
                      <BarChart data={ROIData} >
                        <XAxis dataKey="name" />
                        <Tooltip />
                        <Bar dataKey="value" label={{ position: "top"}}>
                          {ROIData.map((entry, index) => (
                            <Cell width={100} cursor="pointer" fill={index === 0 ? '#EB4886' : '#7030a0'} key={`cell-${index}`} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              </Card>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <Card>
                  <CardContent>
                    <Typography variant="h5" color="#B92C82" fontWeight={600} marginLeft={'15px'}>
                      Auctions
                    </Typography>
                    <div style={{ display: 'flex' }}>
                      <ResponsiveContainer height={400}>
                        <BarChart data={auctionData}>
                          <XAxis dataKey="name" />
                          <Tooltip />
                          <Bar dataKey="value" label={{ position: "top" }}>
                            {auctionData.map((entry, index) => (
                              <Cell width={100} cursor="pointer" fill={index === 0 ? '#EB4886' : '#7030a0'} key={`cell-${index}`} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </CardContent>
                </Card>
              </Card>
            </Grid>
          </>
        }
        {
          role == 'Funder' &&
          <Grid item xs={12} sm={6} md={5}>
            <Card>
              <Card>
                <CardContent>
                  <Typography textTransform={'none'} variant="h5" color="#B92C82" fontWeight={600} marginLeft={'15px'}>
                    My bids
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <ResponsiveContainer height={300}>
                      <BarChart data={BidData}>
                        <XAxis dataKey="name" />
                        <Tooltip />
                        <Bar width={110} dataKey="value" fill={"#EB4886"} label={{ position: "top" }}>
                          {BidData.map((entry, index) => (
                            <Cell width={100} cursor="pointer" fill={index === 0 ? '#EB4886' : '#7030a0'} key={`cell-${index}`} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    <div>
                      <p style={{ fontSize: '13px', textAlign: "center" }}>Bid success rate</p>
                      <div style={{ marginBottom: '40px', background: '#EFEFEF', height: '130px', width: "130px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <p style={{ fontSize: "25px", fontWeight: "600" }}>{`${Math.round(Number((Math.round(Number(funderDashboardDetails?.successful_bids)) / Math.round(Number(funderDashboardDetails?.total_bids))) * 100)) || 0}%`}</p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        }
      </Grid >
    </>
  )
}

export default DashboardPageOne