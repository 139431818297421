import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApihelperFunction } from "../../API/ApiHelperFunction";

const initialState = {
    feeDetails: [],
    brokerFeeDetails:[],
    fjFeeDetails:[],
    feeDetailsStatus:'',
    feeDetailsLoader:false,
    feeDetailsError:null
};
export const getFeeDetails = createAsyncThunk("/getFeeDetails", async (id) => {
    const response = await ApihelperFunction({
        urlPath: `api/getFeeDetail`,
        method: "get",
    });
    return response;
});
const getFeeDetailsSlice = createSlice({
    name: 'FeeDetails',
    initialState,
    reducers: {
        resetStateFeeDetails: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFeeDetails.pending, (state) => {
                state.feeDetailsStatus = 'LOADING'
                state.feeDetailsLoader = true
                state.feeDetailsError = null
            })
            .addCase(getFeeDetails.fulfilled, (state, action) => {
                if (action?.payload?.status) {
                    state.feeDetailsStatus = "SUCCESS"
                    state.feeDetails = action?.payload?.data?.fee
                    state.brokerFeeDetails=action?.payload?.data?.brokerFee
                    state.fjFeeDetails=action?.payload?.data?.fjFee
                }
                else {
                    state.feeDetailsStatus = "ERROR"
                    state.feeDetailsError = [action?.payload?.errors?.[0]]
                }
                state.feeDetailsLoader = false
            })
            .addCase(getFeeDetails.rejected, (state, action) => {
                state.feeDetailsStatus = 'ERROR'
                state.feeDetailsLoader = false
            })
    }
})

export const { resetStateFeeDetails } = getFeeDetailsSlice.actions;
export default getFeeDetailsSlice.reducer;